import { Badge } from 'react-bootstrap';
import * as moment from 'moment-timezone';

export function LoteRow({
  index,
  lote,
  bonificado,
  conferidos,
  onLoteRowClicked,
}) {
  return (
    <tr
      id={'lote-linha' + index}
      key={index}
      data-key={index}
      className="lote-linha"
      onClick={() => {
        onLoteRowClicked('lote-linha' + index);
      }}
    >
      <td className="d-flex justify-content-between align-items-center">
        {lote.descricao}
        {bonificado ? (
          <Badge bg="primary" title="Produto Bonificado" className=" fs-4">
            B
          </Badge>
        ) : (
          ''
        )}
      </td>
      <td>{moment(new Date(lote.data_hora_entrada)).format('DD/MM/yyyy')}</td>
      <td>{lote.embalagem}</td>
      <td>
        {Number(lote.saldo) - Number(lote.quantidade_conferida) ||
          Number(lote.saldo)}
      </td>
      <td>{lote.quantidade_atendida || 0}</td>
      <td>{conferidos || 0}</td>
    </tr>
  );
}
