import { Fragment, useEffect, useState } from 'react';
import React from 'react';
import api from '../../services/api';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import $ from 'jquery';
import CurrencyInput from 'react-currency-masked-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import Moment from 'react-moment';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as xlsx from 'xlsx/xlsx.mjs';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { toast } from 'react-toastify';

registerLocale('pt', pt);
xlsx.set_cptable(cpexcel);

var currentProduto = null;
var currentCluster = null;
var respMsg = '';
var jsonPlanilha = {};

const token = JSON.parse(localStorage.getItem('token'));
const codigo_usuario = token && token.userName ? token.userName : '';

function ShowAlert(title, message) {
  $('#modal-alert .modal-title').html(title);
  $('#modal-alert .modal-body').html(message);
  window.$('#modal-alert').modal('show');
}

function EstornoDeTitulo() {
  const [titulo, setTitulo] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [precosRelatorio, setPrecosRelatorio] = useState([]);
  const [importacaoData, setImportacaoData] = useState([]);
  const [locais, setLocais] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [embalagens, setEmbalagens] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const hiddenFileInput = React.useRef(null);
  const [dtaDe, setDtaDe] = useState(new Date());
  const [dtaAte, setDtaAte] = useState(new Date());

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/empresa/v1/segmento');
      if (res.data != null) {
        setSegmentos(res.data.results);
        console.log('---Segmentos---');
        console.log(res.data);
      }
    }

    $('.card-embalagem').hide();
    $('.card-relacionados').hide();
    $('#boxEmpresas3').hide();
    $('#boxSegmentos2').hide();
    fetchData();
    BuscaEmpresas();
    BuscaClusters();

    $('.segmento-inverter').change(function () {
      $('.segmento-todos').prop('checked', false);
      for (var i = 0; i < $('.segmento-preco').length; i++) {
        if ($('.segmento-preco').eq(i).prop('checked') == true) {
          $('.segmento-preco').eq(i).prop('checked', false);
        } else {
          $('.segmento-preco').eq(i).prop('checked', true);
        }
      }
    });

    $('.empresa-inverter').change(function () {
      for (var i = 0; i < $('.empresas').length; i++) {
        if ($('.empresas').eq(i).prop('checked') == true) {
          $('.empresas').eq(i).prop('checked', false);
        } else {
          $('.empresas').eq(i).prop('checked', true);
        }
      }
    });

    $('.empresas-filtro-inverter').change(function () {
      for (var i = 0; i < $('.empresas-filtro').length; i++) {
        if ($('.empresas-filtro').eq(i).prop('checked') == true) {
          $('.empresas-filtro').eq(i).prop('checked', false);
        } else {
          $('.empresas-filtro').eq(i).prop('checked', true);
        }
      }
    });

    $('.segmentos-filtro-inverter').change(function () {
      for (var i = 0; i < $('.segmentos-filtro').length; i++) {
        if ($('.segmentos-filtro').eq(i).prop('checked') == true) {
          $('.segmentos-filtro').eq(i).prop('checked', false);
        } else {
          $('.segmentos-filtro').eq(i).prop('checked', true);
        }
      }
    });
  }, []);

  $(document).ready(function () {
    $('.btn-estorno').hide();
    $('.data-estorno').hide();

    $('.segmento-todos').change(function () {
      $('.segmento-inverter').prop('checked', false);
      if (this.checked) {
        $('.segmento-preco').prop('checked', true);
      } else {
        $('.segmento-preco').prop('checked', false);
      }
    });

    $('.local-todos').prop('checked', true);
    $('.local').prop('checked', true);
    $('.local').change(function () {
      $('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).show();
        $(
          $(
            ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
          ).find('input'),
        ).prop('checked', true);
      } else {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).hide();
        $(
          $(
            ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
          ).find('input'),
        ).prop('checked', false);
      }
    });

    $('.local-todos').change(function () {
      $('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $('.local').prop('checked', true);
        $('.empresas').prop('checked', true);
        $('.box-empresa').show();
      } else {
        $('.local').prop('checked', false);
        $('.empresas').prop('checked', false);
        $('.box-empresa').hide();
      }
    });

    $('.local-todos-cluster').prop('checked', true);
    $('.local-cluster').prop('checked', true);
    $('.local-cluster').change(function () {
      //$('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $(
          ".box-empresa-cluster[data-cidade='" +
            $(this).attr('data-cidade') +
            "']",
        ).show();
        $(
          $(
            ".box-empresa-cluster[data-cidade='" +
              $(this).attr('data-cidade') +
              "']",
          ).find('input'),
        ).prop('checked', false);
      } else {
        $(
          ".box-empresa-cluster[data-cidade='" +
            $(this).attr('data-cidade') +
            "']",
        ).hide();
        $(
          $(
            ".box-empresa-cluster[data-cidade='" +
              $(this).attr('data-cidade') +
              "']",
          ).find('input'),
        ).prop('checked', false);
      }
    });

    $('.local-todos-cluster').change(function () {
      //$('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $('.local-cluster').prop('checked', true);
        $('.empresas-cluster').prop('checked', false);
        $('.box-empresa-cluster').show();
      } else {
        $('.local-cluster').prop('checked', false);
        $('.empresas-cluster').prop('checked', false);
        $('.box-empresa-cluster').hide();
      }
    });

    $('.empresas-filtro-todos').change(function () {
      if (this.checked) {
        $('.empresas-filtro').prop('checked', true);
      } else {
        $('.empresas-filtro').prop('checked', false);
      }
    });

    $('.segmentos-filtro-todos').change(function () {
      if (this.checked) {
        $('.segmentos-filtro').prop('checked', true);
      } else {
        $('.segmentos-filtro').prop('checked', false);
      }
    });

    $('#table-line-todos').change(function () {
      if (this.checked) {
        $('.table-line').prop('checked', true);
      } else {
        $('.table-line').prop('checked', false);
      }
    });
    $('#table-spinner').hide();

    if (importacaoData.length == 0) {
      $('#tblImportacao').hide();
      $('#tblConfig').show();
    } else {
      $('#tblImportacao').show();
      $('#tblConfig').hide();
    }
    if (precosRelatorio.length == 0) {
      $('#tblRelatorio').hide();
    }

    $(document).mousedown(function (event) {
      var $target = $(event.target);

      if (
        !$target.closest('.dropdown-dialog').length &&
        $('.dropdown-dialog').is(':visible')
      ) {
        $('.dropdown-dialog').fadeOut('fast');
      }
    });
  });

  async function BuscaClusters(reset = true) {
    const res = await api.get('/empresa/v1/cluster');

    if (res.data.results != null) {
      res.data.results = res.data.results.filter((obj) => obj.status == 'A');
      for (var a = 0; a < res.data.results.length; a++) {
        res.data.results[a].childs = [];
        const res2 = await api.get(
          '/empresa/v1/clusterEmpresa/' + res.data.results[a].seqcluster,
        );

        if (res2.data.results != null) {
          res.data.results[a].childs = res2.data.results.filter(
            (obj) => obj.status == 'A',
          );
        }

        if (reset) {
          setTimeout(function () {
            $('.box-cluster-empresa').hide();
          }, 1);
        }
      }

      setClusters(res.data.results);
      console.log('---Clusters---');
      console.log(res.data.results);
    }
  }

  async function AddCluster() {
    const res = await api.post('/empresa/v1/cluster_create', {
      seqcluster: currentCluster,
      descricao: $('#txtClusterName').val(),
      status: 'A',
      usuario: '' + token.userName,
    });
    if (res.data.codResult != 1) {
      window.$('#modal-add-cluster').modal('hide');
      ShowAlert('Cluster', res.data.msg);
    }

    BuscaClusters();
  }

  async function AddEmpresaCluster() {
    var empresas = $('input[name=ckbEmpresa2]:checked');

    empresas.each(async function (item) {
      const res = await api.post('/empresa/v1/clusterEmpresa_create', {
        seqcluster: currentCluster,
        nroempresa: $(this).val(),
        status: 'A',
        usuario: '' + token.userName,
      });
      if (res.data.codResult != 1) {
        ShowAlert('Cluster', res.data.msg);
      }
      window.$('#modal-add').modal('hide');
    });

    BuscaClusters(false);
  }

  async function SetClusterSelected() {
    var seqcluster = $('#ddlCluster').val();
    if (seqcluster != 0) {
      var currentClusterEmpresas = clusters.filter(
        (obj) => obj.seqcluster == seqcluster,
      );

      $('.empresas').parent().parent().parent().hide();
      $('.empresas').prop('checked', false);
      $('.local').prop('checked', false);
      $('.local-todos').prop('checked', false);
      for (var i = 0; i < currentClusterEmpresas[0].childs.length; i++) {
        $(
          'input[data-empresaid=' +
            currentClusterEmpresas[0].childs[i].nroempresa +
            ']',
        )
          .parent()
          .parent()
          .parent()
          .show();
        $(
          'input[data-empresaid=' +
            currentClusterEmpresas[0].childs[i].nroempresa +
            ']',
        ).prop('checked', true);
      }
    } else {
      $('.empresas').parent().parent().parent().show();
      $('.empresas').prop('checked', true);
      $('.local').prop('checked', true);
      $('.local-todos').prop('checked', true);
    }
  }

  async function BuscaEmpresas() {
    const res = await api.get('/empresa/listaempresa/');
    var result = null;

    if (res.data.results != null) {
      res.data.results.sort(dynamicSort('cidade'));
      setLocais(distinct(res.data.results, 'cidade'));
      res.data.results.sort(dynamicSort('nroempresa'));
      setEmpresas(res.data.results);
      console.log('---Empresas---');
      console.log(res.data.results);
    }
  }

  async function BuscaEmbalagens() {
    const res = await api.get(
      '/produto/v1/embalagem/' + currentProduto.seqfamilia,
    );
    var result = null;

    if (res.data.results != null) {
      setEmbalagens(res.data.results);
      console.log('---Embalagens---');
      console.log(res.data.results);
      $('.card-embalagem').show();
      $('.card-relacionados').show();
    }
  }

  async function BuscaProduto(event) {
    if (event.key === 'Enter' || event.key == undefined) {
      setImportacaoData([]);
      try {
        const res = await api.get(
          '/produto/v1/produto/' +
            document.getElementById('txtCodigoProduto').value,
        );

        if (res.data.results != null) {
          SetSelectedProduto(res.data.results[0]);
          BuscaEmbalagens();
          BuscaProdutosRelacionados();
          setTimeout(function () {
            $('.embalagens').val('');
          }, 100);
        }
      } catch (ex) {
        ClearFields();
        alert('Nenhum produto foi localizado. ');
        console.log(ex);
      }
    }
  }

  async function BuscaProdutosRelacionados() {
    const res = await api.get(
      '/produto/v1/produtorelacionado/' +
        document.getElementById('txtCodigoProduto').value,
    );

    if (res.data.results != null) {
      //setProdutosRelacionados(res.data.results);
    }
  }

  function FindEmpresa(id) {
    var toReturn = null;
    empresas.forEach(function (item, a) {
      if (parseInt(empresas[a].nroempresa) == parseInt(id)) {
        toReturn = empresas[a].nome_reduzido;
      }
    });

    return toReturn;
  }

  const handleClickUpload = (event) => {
    hiddenFileInput.current.click();
  };

  async function readUploadFile(e) {
    setImportacaoData([]);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        for (var i = 0; i < json.length; i++) {
          json[i].status =
            "<span class='text-orange'><i class='fa fa-clock'></i> Aguardando processamento</span>";
        }
        console.log(json);

        $('#tblImportacao').show();
        $('#btnProcessar').show();

        $('#tblConfig').hide();

        setImportacaoData(json);
        jsonPlanilha = json;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  async function ProcessarPlanilha(method, status) {
    var json = jsonPlanilha;
    var errorCount = 0;
    var successCount = 0;
    var scrollInitial = 0;
    $('#btnUpload, #btnDownload').hide();

    for (var i = 0; i < json.length; i++) {
      try {
        if (method == 'Compra') {
          const res = await api.post('/produto/v1/statuscompra/', {
            nroempresa: json[i].nroempresas.toString(),
            status: status,
            seqproduto: json[i].codproduto,
            chapa_usuario: token.userName,
          });

          if (res.data.msg != null) {
            if (res.data.codResult == 1) {
              json[i].status =
                "<span class='text-red'><i class='fa fa-times'></i> " +
                res.data.msg +
                '</span>';
              errorCount += 1;
            } else {
              json[i].status =
                "<span class='text-green'><i class='fa fa-check'></i> " +
                res.data.msg +
                '</span>';
            }
          } else {
            json[i].status =
              "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento.</span>";
            errorCount += 1;
          }
        }

        if (method == 'Venda') {
          const res = await api.post('/produto/v1/statusvenda/', {
            nroempresa: json[i].nroempresas.toString(),
            status: status,
            seqproduto: json[i].codproduto,
            chapa_usuario: token.userName,
            nrosegmento: json[i].nrosegmentos,
            qtdembalagem: json[i].qtdembalagens,
          });

          if (res.data.msg != null) {
            if (res.data.codResult == 1) {
              json[i].status =
                "<span class='text-red'><i class='fa fa-times'></i> " +
                res.data.msg +
                '</span>';
              errorCount += 1;
            } else {
              json[i].status =
                "<span class='text-green'><i class='fa fa-check'></i> " +
                res.data.msg +
                '</span>';
            }
          } else {
            json[i].status =
              "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento.</span>";
            errorCount += 1;
          }
        }
      } catch (ex) {
        json[i].status =
          "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento. (" +
          ex +
          ')</span>';
        errorCount += 1;
      }

      setImportacaoData(json);
      forceUpdate();

      if (
        $('.import-row').eq(i).offset().top >
        $(window).scrollTop() + $(window).height()
      ) {
        $('html, body').animate(
          { scrollTop: $(window).scrollTop() + $(window).height() },
          'slow',
          function () {},
        );
      }

      scrollInitial += 110;
    }
    $('#btnUpload, #btnDownload').show();
    hiddenFileInput.current.value = null;
    $('html, body').animate({ scrollTop: 0 }, 'slow', function () {
      ShowAlert(
        "<i class='fa fa-check'></i> Importação Finalizada",
        'A importação da planilha em excel foi finalizada. <br/><br/><b>Total de registros:</b> ' +
          json.length +
          " <br/><span class='text-success'><b>Processados com sucesso:</b> " +
          successCount +
          "</span> <br/><span class='text-danger'><b>Processados com erro:</b> " +
          errorCount +
          '</span>',
      );
    });
  }

  function AtivarCompra() {
    if (currentProduto != null) {
      CallCompra('A');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function InativarCompra() {
    if (currentProduto != null) {
      CallCompra('I');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function SuspenderCompra() {
    if (currentProduto != null) {
      CallCompra('S');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  async function CallCompra(status) {
    try {
      $('#table-spinner').show();
      var empresasStr = '';
      var empresas = $('input[name=ckbEmpresa]:checked');

      empresas.each(function (i) {
        empresasStr += $(empresas[i]).val() + ',';
      });
      empresasStr = empresasStr.slice(0, -1);

      const res = await api.post('/produto/v1/statuscompra/', {
        nroempresa: empresasStr,
        status: status,
        seqproduto: currentProduto.seqproduto,
        chapa_usuario: token.userName,
      });

      if (res.data.msg != null) {
        ShowAlert('Atenção', res.data.msg);
      }

      $('#table-spinner').hide();
    } catch (ex) {
      ShowAlert('Erro', 'Não foi possível completar a ação.');
      $('#table-spinner').hide();
    }
  }

  var currentVendaStatus = '';
  function AtivarVenda() {
    if (currentProduto != null) {
      currentVendaStatus = 'A';
      window.$('#modal-embalagens').modal('show');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function ShowBtnEstorno(index) {
    $('.btn-estorno').hide();
    $('#btn_' + index).show();

    $('.data-estorno').hide();
    $('#data_' + index).show();
  }
  function InativarVenda() {
    if (currentProduto != null) {
      currentVendaStatus = 'I';
      window.$('#modal-embalagens').modal('show');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function SuspenderVenda() {
    if (currentProduto != null) {
      currentVendaStatus = 'S';
      window.$('#modal-embalagens').modal('show');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  async function CallVenda() {
    try {
      $('#table-spinner').show();
      var empresasStr = '';
      var empresas = $('input[name=ckbEmpresa]:checked');
      empresas.each(function (i) {
        empresasStr += $(empresas[i]).val() + ',';
      });
      empresasStr = empresasStr.slice(0, -1);

      var segmentoStr = '';
      var segmentos = $('input[name=ckbSegmento]:checked');
      segmentos.each(function (i) {
        segmentoStr += $(segmentos[i]).val() + ',';
      });
      segmentoStr = segmentoStr.slice(0, -1);

      var embalagemStr = '';
      var embalagens = $('input[name=ckbEmbalagem]:checked');
      embalagens.each(function (i) {
        embalagemStr += $(embalagens[i]).val() + ',';
      });
      embalagemStr = embalagemStr.slice(0, -1);

      const res = await api.post('/produto/v1/statusvenda/', {
        nroempresa: empresasStr,
        status: currentVendaStatus,
        seqproduto: currentProduto.seqproduto,
        chapa_usuario: token.userName,
        nrosegmento: segmentoStr,
        qtdembalagem: embalagemStr,
      });

      if (res.data.msg != null) {
        window.$('#modal-embalagens').modal('hide');
        ShowAlert('Atenção', res.data.msg);
      }

      $('#table-spinner').hide();
    } catch (ex) {
      window.$('#modal-embalagens').modal('hide');
      ShowAlert('Erro', 'Não foi possível completar a ação.');
      $('#table-spinner').hide();
    }
  }

  function SetSelectedProduto(p) {
    currentProduto = p;
    document.getElementById('txtDescricaoProduto').value =
      currentProduto.descreduzida;
    console.log(currentProduto);
  }

  function ClearFields() {
    document.getElementById('txtCodigoProduto').value = '';
    document.getElementById('txtDescricaoProduto').value = '';
    setEmbalagens([]);
    $('.card-embalagem').hide();
    $('.card-relacionados').hide();
    currentProduto = null;
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function BuscarTitulos() {
    $('#table-spinner').show();
    $('.rbdEstorno').prop('checked', false);
    var nroempresa = $('#ddlEmpresa').val().split('|')[0];
    var dataDe = moment(dtaDe).format('DD-MM-yyyy');
    var dataAte = moment(dtaAte).format('DD-MM-yyyy');
    var valorMin = $('#txtValorDe').val();
    var valorMax = $('#txtValorAte').val();

    if (valorMin == '' || valorMax == '') {
      ShowAlert('Atenção', 'Informe os valores antes de continuar.');
      $('#table-spinner').hide();
      return;
    }

    const res = await api.get(
      '/financeiro/busca_titulo/' +
        nroempresa +
        '/' +
        dataDe +
        '/' +
        dataAte +
        '/' +
        valorMin +
        '/' +
        valorMax,
    );

    if (res.data.results.length > 0) {
      console.log(res.data);
      setTitulo(res.data.results);
    } else {
      ShowAlert('Atenção', 'Nenhum título foi localizado.');
      setTitulo([]);
      $('.rbdEstorno').prop('checked', false);
    }

    $('#table-spinner').hide();
  }

  async function EstornarTitulo(seqTitulo, dataLancamento) {
    if (window.confirm('Deseja realmente fazer o estorno deste título?')) {
      if (dataLancamento == '') {
        ShowAlert('Atenção', 'Informe uma data para o estorno.');
        return;
      }

      const res = await api.post('/financeiro/estorno_titulo', {
        codusuario: codigo_usuario,
        datalancamento: moment(dataLancamento).format('DD-MM-yyyy'),
        seqtitulo: seqTitulo,
      });

      if (res.data != null) {
        if (res.data.codResult == 0) {
          ShowAlert('Sucesso', res.data.msg);
        } else {
          ShowAlert('erro', res.data.msg);
        }

        BuscarTitulos();

        $('.rbdEstorno').prop('checked', false);
      }
    }
  }

  async function Closemodal() {
    window.$('.modal').modal('hide');
  }
  return (
    <Fragment>
      <div className="content-header">
        <h1 style={{ marginLeft: 20 }}>Estorno de Títulos</h1>
      </div>

      <div className="content">
        <div id="box-preco-manual">
          <div className=" ">
            <div className=" ">
              <div className="card">
                <div className="card-body">
                  <div
                    id="table-spinner"
                    className="mb-3"
                    style={{
                      textAlign: 'center',
                      textAlign: 'center',
                      position: 'absolute',
                      zIndex: '9',
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255,255,255,.5)',
                      paddingTop: '20px',
                    }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>

                  <div id="tblConfig">
                    <div className="row">
                      <div className="col-4  col-md-4 col-lg-2">
                        <label className="text-md">Empresa</label>
                        <select id="ddlEmpresa" className="form-control">
                          {empresas.map((item, index) => (
                            <option
                              key={index}
                              value={item.nroempresa + '|' + item.seqpessoa}
                            >
                              {item.nome_reduzido}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-4 col-md-4 col-lg-2">
                        <label className="text-md">Data de</label>
                        <DatePicker
                          selected={dtaDe}
                          onChange={(date: Date) => setDtaDe(date)}
                          className="form-control "
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <div className="col-4 col-md-4 col-lg-2">
                        <label className="text-md">Data até</label>
                        <DatePicker
                          selected={dtaAte}
                          onChange={(date: Date) => setDtaAte(date)}
                          className="form-control "
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <div className="col-4 col-md-4 col-lg-2">
                        <label className="text-md">Valor De</label>
                        <CurrencyInput
                          id="txtValorDe"
                          type="search"
                          allowempty="true"
                          allowleadingzeroes="false"
                          className="form-control"
                          placeholder="R$ 0,00"
                        />
                      </div>
                      <div className="col-4 col-md-4 col-lg-2">
                        <label className="text-md">Valor Até</label>
                        <CurrencyInput
                          id="txtValorAte"
                          type="search"
                          allowempty="true"
                          allowleadingzeroes="false"
                          className="form-control"
                          placeholder="R$ 1.000.000,00"
                        />
                      </div>
                      <div className="col-4  col-md-4  col-lg-2">
                        <label className="text-md">&nbsp;</label>
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              BuscarTitulos();
                            }}
                          >
                            <i className="fa fa-search fa-fw"></i> Buscar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card"
                style={{ overflow: 'auto', maxHeight: '74vh' }}
              >
                <div className="card-bo dy">
                  <div
                    id="no-product"
                    className={
                      'text-center pt-5 pb-5 ' +
                      (titulo.length > 0 ? 'd-none' : '')
                    }
                  >
                    <h4>
                      <i className="fa fa-info-circle fa-fw"></i> Nenhum título
                      localizado
                    </h4>
                    Selecione uma empresa e data para buscar os títulos.
                  </div>
                </div>

                <table
                  className={
                    'table table-striped table-hover tabl e-sm table-borde red pb-0 mb-0 ' +
                    (titulo.length == 0 ? 'd-none' : '')
                  }
                >
                  <thead className="">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Cod. Espécie</th>
                      <th scope="col">Empresas</th>
                      <th scope="col">Nº Título</th>
                      <th scope="col">Serie</th>
                      <th scope="col">Seq. Título</th>
                      <th scope="col">Valor Nominal</th>
                      <th scope="col">Valor Original</th>
                      <th scope="col">Valor Pago</th>
                      <th scope="col">Data</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody id="table-body">
                    {titulo.map((item, index) => (
                      <tr
                        className="import-row"
                        style={{ cursor: 'pointer' }}
                        key={index}
                        onClick={() => {
                          $('#rdbEstorno' + index).click();
                          ShowBtnEstorno(index);
                        }}
                      >
                        <th scope="col">
                          <input
                            id={'rdbEstorno' + index}
                            type="radio"
                            name="rdbestorno"
                            className="rbdEstorno"
                            onChange={() => {
                              ShowBtnEstorno(index);
                            }}
                          />
                        </th>
                        <td style={{ 'vertical-align': 'middle' }}>
                          {item.codespecie}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          {item.nroempresa}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          {item.nrotitulo}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          {item.serietitulo}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          {item.seqtitulo}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          R${' '}
                          {parseFloat(
                            item.vlrnominal.toString().replace(',', '.'),
                          )
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          R${' '}
                          {parseFloat(
                            item.vlroriginal.toString().replace(',', '.'),
                          )
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          R${' '}
                          {parseFloat(item.vlrpago.toString().replace(',', '.'))
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}
                        </td>
                        <td style={{ 'vertical-align': 'middle' }}>
                          {moment(item.dtainclusao).format('DD/MM/yyyy')}
                        </td>
                        <td
                          style={{ 'vertical-align': 'middle', width: '135px' }}
                        >
                          <input
                            type="date"
                            id={'data_' + index}
                            className="form-control data-estorno"
                            locale="pt"
                            dateFormat="dd/MM/yyyy"
                          />
                        </td>
                        <td
                          style={{ 'vertical-align': 'middle' }}
                          className="text-center"
                        >
                          <button
                            id={'btn_' + index}
                            className="btn btn-danger btn-sm  btn-estorno"
                            onClick={() => {
                              EstornarTitulo(
                                item.seqtitulo,
                                $('#data_' + index).val(),
                              );
                            }}
                          >
                            Estornar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-alert" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-embalagens" className="modal " role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content " style={{ width: 900 }}>
            <div className="modal-header">
              <h5 className="modal-title">
                Selecione os segmentos e as embalagens
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="text-md">Segmentos</h4>
                        <hr />
                        <div id="boxSegmentos" className="row">
                          <div className="col-sm-12">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  name="ckbSegTodos"
                                  className="form-check-input segmento-todos"
                                  type="checkbox"
                                />
                                Marcar Todos
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  name="ckbSegInvert"
                                  className="form-check-input segmento-inverter"
                                  type="checkbox"
                                />
                                Inverter Seleção
                              </label>
                            </div>
                          </div>
                          {segmentos.map((item, index) => (
                            <div className="col-sm-12" key={index}>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="ckbSegmento"
                                    className="form-check-input segmento-preco"
                                    value={item.nrosegmento}
                                    type="checkbox"
                                  />
                                  {item.descsegmento}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-9">
                    <h4 className="text-md">Embalagens</h4>
                    <div
                      id="boxEmbalagem"
                      className="row bg-gray-light p-3 rounded"
                    >
                      {embalagens.map((item, index) => (
                        <div
                          className="col-sm-3 box-empresa-cluster"
                          key={index}
                        >
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                name="ckbEmbalagem"
                                className="form-check-input embalagem"
                                value={item.qtdembalagem}
                                type="checkbox"
                              />
                              {item.embalagem + ' - ' + item.qtdembalagem}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  CallVenda();
                }}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-add-cluster" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Adicionar novo cluster</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <input
                  type="text"
                  id="txtClusterName"
                  className="form-control"
                  placeholder="Digite o nome do cluster"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  AddCluster();
                }}
              >
                Incluir
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EstornoDeTitulo;
