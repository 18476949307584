import moment from "moment-timezone";

export function ModalRefaturamento({ onCloseModal, selectedRows }) {
  return (
    <div id="modal-refaturamento" className="modal fade" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Confirmação de refaturamento em lote
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Sequência Cliente</th>
                  <th>Número do Pedido</th>
                  <th>Quantidade</th>
                  <th>Embalagem</th>
                  <th>Valor</th>
                  <th>Data Movimentação</th>
                  <th>Estoque Lote</th>
                </tr>
              </thead>
              <tbody>
                {selectedRows.map((item, index) => (
                  <tr key={index}>
                    <td>{item.sequencia_cliente}</td>
                    <td>{item.numero_pedido}</td>
                    <td>{item.finalQuantidade}</td>
                    <td>{item.finalEmbalagem}</td>
                    <td>
                      R$
                      {parseFloat(
                        item.finalValor.toString().replace(',', '.'),
                      )
                        .toFixed(2)
                        .toString()
                        .replace('.', ',')}
                    </td>
                    <td>
                      {moment(item.data_movimentacao, 'DD/MM/yyyy').format(
                        'DD/MM/yyyy',
                      )}
                    </td>
                    <td>{item.selectedEstoqueLote}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary">
              Confirmar
            </button>
            <button
              type="button"
              className="btn text-red"
              data-dismiss="modal"
              onClick={onCloseModal}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
