export function ModalFaturarSuccess({ onCloseModal, billingResponse, onCloseModalAndClearBillingSuccessResponse }) {
  return (
    <div id="modal-faturar-success" className="modal" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{billingResponse.msg}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center">
              <label className="m-0">Número DF Produto:</label>
              <p className="m-0 ml-1">
                {billingResponse.Numero_DF_produto == null
                  ? ''
                  : billingResponse.Numero_DF_produto}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <label className="m-0">Número DF Vasilhame:</label>
              <p className="m-0 ml-1">
                {billingResponse.Numero_Df_vasilhame == null
                  ? ''
                  : billingResponse.Numero_Df_vasilhame}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <label className="m-0">Número Pedido Produto:</label>
              <p className="m-0 ml-1">
                {billingResponse.numero_pedido_produto == null
                  ? ''
                  : billingResponse.numero_pedido_produto}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <label className="m-0">Número Pedido Vasilhame:</label>
              <p className="m-0 ml-1">
                {billingResponse.numero_pedido_vasilhame == null
                  ? ''
                  : billingResponse.numero_pedido_vasilhame}
              </p>
            </div>
          </div>
          <div className="modal-footer d-flex">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onCloseModalAndClearBillingSuccessResponse}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
