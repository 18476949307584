import moment from 'moment';
import { Badge } from 'react-bootstrap';

import obaLogo from '../../assets/oba.png';
import './printer.css';

export function PrinterMap({ cd, currentStock, productList, mapToPrintRef }) {
  return (
    <div className="PDFContainer container p-4" ref={mapToPrintRef}>
      <div className="PDFHeader p-2">
        <img height="32px" src={obaLogo} alt="Logo Oba Hortifruti" />
        <div className="mt-2 mb-2 d-flex align-items-center border-bottom border-dark-subtle">
          <p className="mr-4 mb-1" style={{ fontSize: '24px' }}>
            <strong>CD:</strong> {cd}
          </p>
          <p className="mr-4 mb-1 fs-3" style={{ fontSize: '24px' }}>
            <strong>Lista:</strong> {productList[0].sequencia_lista}
          </p>
          <p className="mr-4 mb-1 fs-3" style={{ fontSize: '24px' }}>
            <strong> Data hora:</strong>{' '}
            {moment(new Date()).format('DD/MM/YYYY - hh:mm a')}
          </p>
        </div>

        <div className="border-bottom border-dark-subtle">
          <p className="mb-1 fs-3" style={{ fontSize: '24px' }}>
            <strong>Separador:</strong>
          </p>
        </div>
      </div>

      <div className="PDFBody overflow-hidden p-2">
        <div className="row" style={{ fontSize: '24px' }}>
          <p className="ml-2">
            {currentStock.seqproduto} - {currentStock.produto}
          </p>{' '}
          <p className="ml-4">{currentStock.volume}</p>{' '}
          <p className="ml-4">Lote: {currentStock.lote}</p>
        </div>
        <div
          className="row text-center mx-2 bg-light"
          style={{ fontSize: '24px' }}
        >
          <div className="col-2 border border-dark p-1">Cód. Cliente</div>
          <div className="col-4 border border-dark p-1">Nome</div>
          <div className="col-3 border border-dark p-1">Gerado</div>
          <div className="col-3 border border-dark p-1">Conferido</div>
        </div>
        {productList.map((product) => (
          <div
            className="row mx-2"
            key={product.sequencia_pessoa + product.bonificado}
            style={{ fontSize: '18px' }}
          >
            <div className="col-2 border border-dark p-1 d-flex align-items-center justify-content-center">
              {product.sequencia_pessoa}
            </div>
            <div className="col-4 border border-dark d-flex align-items-center justify-content-between">
              {product.fantasia}
              {product.bonificado === 'S' && (
                <Badge
                  bg="primary"
                  title="Produto Bonificado"
                  className=" fs-4 ml-2"
                >
                  B
                </Badge>
              )}
            </div>
            <div className="col-3 p-1 border border-dark d-flex align-items-center justify-content-end">
              {product.quantidade_atendida}
            </div>
            <div className="col-3 p-1 border border-dark d-flex align-items-center justify-content-end">
              {product.quantidade_conferida > 0
                ? product.quantidade_conferida
                : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
