import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../services/api';

const hasToken = JSON.parse(localStorage.getItem('token'));

function Login() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [token, setToken] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    if (hasToken) return navigate('/');
  }, []);

  async function handleLogin(event) {
    event.preventDefault();

    if (token) return navigate('/');

    if (username && password) {
      try {
        const login = await api.post('/v1/login/', {
          userName: username,
          password: password,
        });

        if (!login) {
          return alert('Usuário ou senha invalidos!');
        }

        if (login.status === 202) {
          localStorage.setItem('token', JSON.stringify(login.data));

          setToken(login.data);

          navigate('/');

          navigate(0);
        }
      } catch (error) {
        alert('Falha na tentativa de login.');
      }
    } else {
      alert('Usuário e Senha obrigatórios!');
    }
  }

  return (
    <Fragment>
      <div
        class="main"
        style={{
          display: 'flex',
          flex: 1,
          maxWidth: 600,
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          paddingTop: 50,
        }}
      >
        <div class="col-md-9 col-sm-12">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Oba Business</h3>
            </div>
            <form onSubmit={handleLogin} className="form-horizontal">
              <div className="card-body">
                <div class="form-group row">
                  <label>Usuário - CHAPA</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Usuário"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </div>
                <div class="form-group row">
                  <label>Senha</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Senha"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" class="btn btn-primary">
                  Login
                </button>
                {/* <button type="submit" class="btn btn-secondary">
                Register
              </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
