import { Fragment, useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import * as xlsx from 'xlsx/xlsx.mjs';
import api from '../../services/api';

function Produto() {
  const [json, setJson] = useState([]);
  const [codUsuario, setCodUsuario] = useState(undefined);
  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    setCodUsuario(parseInt(token.userName));
  }, []);

  async function handleChangefile(event) {
    event.preventDefault();

    if (event.target.files) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const result = xlsx.utils.sheet_to_json(worksheet);

        result.map((item) => (item.status = 'Não processado.'));

        setJson(result);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    if (json.length > 0) {
      let items = json;

      if (items.length > 0) {
        for (let index = 0; index < items.length; index++) {
          if (items[index].max < items[index].min) {
            items[index].status = 'MÁXIMO nao pode ser menos que o MÍNIMO!';
          } else {
            const result = await api.post('/produto/v1/produtominmax/', {
              nroempresa: items[index].empresa,
              seqproduto: items[index].seqproduto,
              codusuario: codUsuario,
              estqminimodv: parseFloat(
                items[index].min.toString().replace(',', '.'),
                3,
              ).toFixed(3),
              estqmaximodv: parseFloat(
                items[index].max.toString().replace(',', '.'),
                3,
              ).toFixed(3),
            });

            items[index].status = result.data.msg;
          }
        }
      }

      setJson([]);

      setJson(items);
    }
  }

  return (
    <Fragment>
      <div className="content-header">
        <h1 style={{ marginLeft: 20 }}>Produto</h1>
      </div>

      <div className="content">
        <Tabs defaultActiveKey="minmax">
          <Tab eventKey="minmax" title="Mínimo e Máximo">
            <div className="card">
              <div className="card-body">
                <h4>Importação Mínimo e Máximo</h4>
                <p>
                  Utilize esta ferramenta para importação de mínimo e máximo em
                  massa.
                </p>
                <form>
                  <div className="form-group">
                    <label>Selecionar Planilha</label>
                    <div className="input-group">
                      <input
                        defaultValue={undefined}
                        onChange={handleChangefile}
                        onClick={() => setJson([])}
                        type="file"
                        id="uploadButton"
                      />
                    </div>
                  </div>
                  {json.length > 0 && (
                    <table className="table table-bordered table-hover table-striped text-nowrap text-sm">
                      <thead>
                        <tr>
                          <th>Empresa</th>
                          <th>Sequencia Produto</th>
                          <th>Mínimo</th>
                          <th>Maximo</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {json.map((item) => (
                          <tr key={`${item.empresa}_${item.seqproduto}`}>
                            <td>{item.empresa}</td>
                            <td>{item.seqproduto}</td>
                            <td>{item.min.toString().replace('.', ',')}</td>
                            <td>{item.max.toString().replace('.', ',')}</td>
                            <td
                              style={
                                item.status ===
                                'Dados atualizados com sucesso...'
                                  ? { fontWeight: 'bold', color: 'green' }
                                  : { fontWeight: 'bold', color: 'red' }
                              }
                            >
                              {item.status}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <a
                    id="btnDownload"
                    href="../apoio/Modelo_minmax.xlsx"
                    target="_blank"
                    download
                    className="btn btn-primary mr-3"
                  >
                    {/* <span className="fa fa-file-download fa-fw"></span> Baixar */}
                    <span></span> Baixar Planilha Modelo
                  </a>
                  <button
                    disabled={json.length === 0}
                    className="btn btn-success"
                    type="submit"
                    onClick={(event) => handleFormSubmit(event)}
                  >
                    Processar
                  </button>
                </form>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </Fragment>
  );
}

export default Produto;
