import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

export function ProductTab({ listCD, users, codigoUsuario }) {
  const [searchProcess, setSearchProcess] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [comprador, setComprador] = useState([]);
  const [produtoList, setProdutoList] = useState([]);
  const [produtoItemList, setProdutoItemList] = useState([]);
  const [product, setProduct] = useState('');
  const [description, setDescription] = useState('');

  const lblSearchTypeSelected = document.querySelector(
    '#lblSearchTypeSelected',
  );
  const selSearchType = document.querySelector('#selSearchType');
  const datalistComprador = document.querySelector('#datalistComprador');
  const inputProduto = document.querySelector('#inputProduto');
  const inputUsuario = document.querySelector('#inputUsuario');
  const inputComprador = document.querySelector('#inputComprador');
  const inputTargetusuario = document.querySelector('#inputTargetusuario');
  const deadline = document.querySelector('#deadline');
  const labelDeadline = document.querySelector('#labelDeadline');
  const itensProdutosTable = document.querySelector('#itensProdutosTable');
  const datalistUsuario = document.querySelector('#datalistUsuario');
  const datalistProduto = document.querySelector('#datalistProduto');
  const labelTemporario = document.querySelector('#labelTemporario');
  const temporario = document.querySelector('#temporario');
  const centroDistribuicaoSelect = document.querySelector(
    '#centroDistribuicaoSelect',
  );
  const blink = document.querySelector('#blink');
  let boolSuccess = false;

  const scrollDiv = {
    overflowY: 'scroll',
    border: '1px solid  #d9d9d9',
    width: '100%',
    float: 'left',
    height: '50vh',
    position: 'relative',
    marginBottom: '5px',
  };

  const styBlink = {
    backgroundColor: '#E9ECEF',
    padding: '5px',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: '5px',
  };

  useEffect(() => {
    try {
      async function fetchData() {
        const res = await api.get('/comprador/comprador/');
        setComprador(res.data.results);
      }

      fetchData();
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }, []);

  function handleSearchcentroDistribuicao(event) {
    setSearchProcess(true);

    if (event.target.selectedIndex !== 0) {
      selSearchType.disabled = false;
      inputTargetusuario.disabled = false;
    } else {
      showHiddenElements('all');
    }

    setSearchProcess(false);
  }

  function handleSearchType(event) {
    setSearchProcess(true);

    showHiddenElements('SearchType');

    if (event.target.value === 'Produto') {
      setSearchType('Produto');
    } else {
      setSearchType('');
    }

    if (event.target.selectedIndex === 0) {
      lblSearchTypeSelected.textContent = '';
      return;
    } else if (event.target.value === 'Comprador') {
      inputComprador.hidden = false;
    } else if (event.target.value === 'Produto') {
    } else {
      inputUsuario.hidden = false;
    }

    lblSearchTypeSelected.textContent =
      event.target.value == 'Produto' ? '' : event.target.value;

    setSearchProcess(false);
  }

  function handlePreencherProdutosTable() {
    setSearchProcess(true);

    setProdutoList([]);

    if (inputUsuario.value !== '') {
      searchProdutoConferente();
    }

    if (inputComprador.value !== '') {
      searchProdutoEmpresa();
    }

    if (inputProduto.value !== '') {
      loadTableByProduto();
    }

    setSearchProcess(false);
  }

  function showHiddenElements(group) {
    setSearchProcess(true);

    setProdutoList([]);

    if (group === 'all') {
      selSearchType.disabled = true;
      inputTargetusuario.disabled = true;
      selSearchType.selectedIndex = 0;
      inputTargetusuario.selectedIndex = 0;

      inputUsuario.hidden = true;
      inputUsuario.value = '';
      datalistUsuario.selectedIndex = 0;
      datalistUsuario.hidden = true;

      inputComprador.hidden = true;
      inputComprador.value = '';
      datalistComprador.hidden = true;
      datalistComprador.selectedIndex = 0;

      inputProduto.hidden = true;
      inputProduto.value = '';
      datalistProduto.hidden = true;
      datalistProduto.selectedIndex = 0;

      lblSearchTypeSelected.textContent = '';
      inputTargetusuario.value = '';

      labelTemporario.hidden = true;
      temporario.hidden = true;
      deadline.hidden = true;
      labelDeadline.hidden = true;
    } else if (group === 'SearchType') {
      inputComprador.hidden = true;
      inputComprador.value = '';
      datalistComprador.selectedIndex = 0;
      datalistComprador.hidden = true;

      inputUsuario.hidden = true;
      inputUsuario.value = '';
      datalistUsuario.selectedIndex = 0;
      datalistUsuario.hidden = true;

      inputProduto.hidden = true;
      inputProduto.value = '';

      lblSearchTypeSelected.textContent = '';
      inputTargetusuario.value = '';

      labelTemporario.hidden = false;
      temporario.hidden = false;

      deadline.hidden = false;
      labelDeadline.hidden = false;
    }

    setSearchProcess(false);
  }

  async function searchProdutoEmpresaToLoadCompradorDataListExtended() {
    try {
      setSearchProcess(true);

      if (product !== '' || description !== '') {
        let cd =
          centroDistribuicaoSelect.options[
            centroDistribuicaoSelect.selectedIndex
          ].value;

        const res = await api.post('/produto/produtoempresa/', {
          sequencia_comprador: null,
          sequencia_produto: product,
          descricao: description,
          status_compra: 'A',
          numero_empresa: cd,
        });

        const resConf = await api.post('/produto/produtoconferente/', {
          numero_empresa: cd,
          codigo_usuario: '0',
          sequencia_produto: product,
          sequencia_comprador: 0,
        });

        let lst = res.data;

        lst.forEach((itemProd) => {
          var itemProdSequenciaProduto = itemProd.sequencia_produto;
          var firstName = '';
          var codigoUsuario = '';

          resConf.data.forEach((itemConf) => {
            var itemConfSequenciaProduto = itemConf.sequencia_produto;

            if (firstName !== '') {
              return;
            }

            if (itemProdSequenciaProduto === itemConfSequenciaProduto) {
              codigoUsuario = itemConf.codigo_usuario;

              users.forEach((itemUsuario) => {
                if (firstName !== '') {
                  return;
                }
                if (itemUsuario.username == codigoUsuario) {
                  firstName = itemUsuario.first_name;
                  return;
                }
              });
            }
          });
          itemProd.codigo_usuario = codigoUsuario;
          itemProd.first_name = firstName;
        });

        setProdutoItemList(lst);
        handlePreencherProdutosTable();
        loadTableByProduto();
        setProdutoList(lst);
        // loadTableByProdutoExtended();
      } else {
        alert('Digite o código de produto ou descrição!');
      }

      setSearchProcess(false);
    } catch (err) {
      setSearchProcess(false);
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function searchProdutoConferente() {
    try {
      setSearchProcess(true);

      let cd =
        centroDistribuicaoSelect.options[centroDistribuicaoSelect.selectedIndex]
          .value;

      let codigoUsuario = inputUsuario.value.split(' ')[0];

      const res = await api.post('/produto/produtoconferente/', {
        numero_empresa: cd,
        codigo_usuario: codigoUsuario,
        sequencia_produto: 0,
        sequencia_comprador: 0,
      });

      let lst = [];

      if (res.data.resposta !== 'Item nao localizado') {
        lst = res.data;
        users.map((usuarioItem) => {
          for (let idx = 0; idx < lst.length; idx++) {
            if (usuarioItem.username === lst[idx].codigo_usuario) {
              lst[idx].first_name = usuarioItem.first_name;
            }
          }
        });
      }

      setProdutoList(lst);

      setSearchProcess(false);
    } catch (err) {
      setSearchProcess(false);
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function searchProdutoEmpresa() {
    try {
      setSearchProcess(true);

      let cd =
        centroDistribuicaoSelect.options[centroDistribuicaoSelect.selectedIndex]
          .value;

      let sequenciaComprador = document.querySelectorAll(
        'option[value="' + inputComprador.value + '"]',
      )[0].id;

      const resProd = await api.post('/produto/produtoempresa/', {
        sequencia_comprador: sequenciaComprador,
        sequencia_produto: '',
        descricao: '',
        status_compra: 'A',
        numero_empresa: cd,
      });

      let lst = resProd.data;
      console.log(lst);

      if (resProd.data.resposta) {
        alert(resProd.data.resposta);
        return;
      }

      const resConf = await api.post('/produto/produtoconferente/', {
        numero_empresa: cd,
        codigo_usuario: '0',
        sequencia_produto: 0,
        sequencia_comprador: sequenciaComprador,
      });

      lst.forEach((itemProd) => {
        var itemProdSequenciaProduto = itemProd.sequencia_produto;
        var firstName = '';
        var codigoUsuario = '';

        resConf.data.forEach((itemConf) => {
          var itemConfSequenciaProduto = itemConf.sequencia_produto;

          if (firstName !== '') {
            return;
          }

          if (itemProdSequenciaProduto === itemConfSequenciaProduto) {
            codigoUsuario = itemConf.codigo_usuario;

            users.forEach((itemUsuario) => {
              if (firstName !== '') {
                return;
              }
              if (itemUsuario.username == codigoUsuario) {
                firstName = itemUsuario.first_name;
                return;
              }
            });
          }
        });
        itemProd.codigo_usuario = codigoUsuario;
        itemProd.first_name = firstName;
      });

      setProdutoList(lst);

      setSearchProcess(false);
    } catch (err) {
      setSearchProcess(false);
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function loadTableByProduto() {
    try {
      setSearchProcess(true);

      if (inputProduto.value.split(' - ').length > 1) {
        let sequenciaProduto = inputProduto.value.split(' - ')[0];
        let descricaoCompleta = inputProduto.value.split(' - ')[1];
        let firstName = '';
        let codigoUsuario = '';
        let cd =
          centroDistribuicaoSelect.options[
            centroDistribuicaoSelect.selectedIndex
          ].value;

        const res = await api.post('/produto/produtoconferente/', {
          numero_empresa: cd,
          codigo_usuario: codigoUsuario,
        });

        if (res.data.length > 0) {
          users.map((usuarioItem) => {
            for (let idx = 0; idx < res.data.length; idx++) {
              if (
                parseInt(sequenciaProduto) === res.data[idx].sequencia_produto
              ) {
                if (usuarioItem.username === res.data[idx].codigo_usuario) {
                  firstName = usuarioItem.first_name;
                  codigoUsuario = res.data[idx].codigo_usuario;
                  break;
                }
              }
            }
          });
        }

        let lst = [
          {
            sequencia_produto: sequenciaProduto,
            descricao_completa: descricaoCompleta,
            codigo_usuario: codigoUsuario,
            first_name: firstName,
          },
        ];

        setProdutoList(lst);
      }

      setSearchProcess(false);
    } catch (err) {
      setSearchProcess(false);
      console.log(err);
      toast.error('Falha no envio dos dados');
    }
  }

  function handleTemporary(event) {
    setSearchProcess(true);

    if (event.target.checked) {
      deadline.disabled = false;
    } else {
      deadline.disabled = true;
      deadline.value = null;
    }

    setSearchProcess(false);
  }

  function handleCheckBoxAll(event) {
    for (var row = 1; row < itensProdutosTable.rows.length; row++) {
      itensProdutosTable.rows[row].cells[0].getElementsByTagName(
        'input',
      )[0].checked = event.target.checked;
    }
  }

  async function handleSaveAppointment() {
    try {
      setSearchProcess(true);

      async function saveData(
        jsonParam,
        totalItensCheckados,
        totalItensExecutados,
        nomeUsuarioDestino,
      ) {
        const res = await api.post('/produto/salvarprodutousuario/', jsonParam);

        if (res.data[0].numero_erro !== 0) {
          boolSuccess = false;
          alert(res.data[0].mensagem);
        }

        if (boolSuccess) {
          if (
            totalItensCheckados === totalItensExecutados ||
            totalItensCheckados === totalItensExecutados + 1
          ) {
            handlePreencherProdutosTable();
            alert(`Produto(s) salvo(s) para o usuário ${nomeUsuarioDestino}`);
            document
              .querySelectorAll('#itensProdutosTable input[type="checkbox"]')
              .forEach((checkbox) => (checkbox.checked = false));
          }
        }
      }

      if (inputTargetusuario.value === '') {
        alert('Selecione um usuário destino');
      } else {
        let codigoUsuarioDestino = inputTargetusuario.value.split(' ')[0];
        let nomeUsuarioDestino = inputTargetusuario.value.split(' ')[2];
        let dataLimite = temporario.checked ? deadline.value : null;
        let totalItensCheckados = document.querySelectorAll(
          '#itensProdutosTable input[type="checkbox"]:checked',
        ).length;
        let contador = 0;
        boolSuccess = true;

        if (totalItensCheckados === 0) {
          alert('Selecione pelo menos um item para ser salvo');
          setSearchProcess(false);
          return;
        }

        for (var row = 1; row < itensProdutosTable.rows.length; row++) {
          let chkbx =
            itensProdutosTable.rows[row].cells[0].getElementsByTagName(
              'input',
            )[0];
          let numeroEmpresa =
            centroDistribuicaoSelect.options[
              centroDistribuicaoSelect.selectedIndex
            ].value;
          let sequenciaProduto =
            itensProdutosTable.rows[row].cells[1].innerText;

          if (chkbx.checked) {
            contador += 1;
            let json = {
              numero_empresa: numeroEmpresa,
              codigo_usuario: codigoUsuarioDestino,
              sequencia_produto: sequenciaProduto,
              data_limite: dataLimite,
              codigo_usuario_temporario: null,
              indice_replicado: 'N',
            };

            await saveData(
              json,
              totalItensCheckados,
              contador,
              nomeUsuarioDestino,
            );
          }
        }
      }
      setSearchProcess(false);
    } catch (err) {
      setSearchProcess(false);
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <label>Centro de Distribuição</label>
            <select
              id="centroDistribuicaoSelect"
              className="form-control"
              onChange={(event) => handleSearchcentroDistribuicao(event)}
              disabled={listCD.length === 0 ? true : false}
            >
              <option defaultValue>Selecione o CD ..</option>
              {listCD.map((item) => (
                <option value={item.numero_empresa} key={item.numero_empresa}>
                  {item.nome_reduzido}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <label>Tipo de Busca </label>
            <select
              className="form-control"
              id="selSearchType"
              disabled
              onChange={(event) => handleSearchType(event)}
            >
              <option defaultValue>Selecione o tipo</option>
              <option>Comprador</option>
              <option>Produto</option>
              <option>Usuário</option>
            </select>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <label>Usuário Destino</label>

            <input
              list="datalistTargetusuario"
              autoComplete="off"
              id="inputTargetusuario"
              className="form-control"
              placeholder="Entre com o nome do usuário"
            />
            <datalist
              id="datalistTargetusuario"
              className="form-control"
              hidden
            >
              {users.map((item) => (
                <option key={item.username}>
                  {item.username} - {item.first_name}
                </option>
              ))}
            </datalist>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <label id="lblSearchTypeSelected" />
            <div id="blink" className="blink" style={styBlink} hidden>
              Carregando, aguarde ...
            </div>
            <input
              list="datalistProduto"
              autoComplete="off"
              id="inputProduto"
              className="form-control"
              hidden
              onChange={(event) => handlePreencherProdutosTable()}
            />
            <datalist id="datalistProduto" className="form-control" hidden>
              {produtoItemList.map((item) => (
                <option key={item.sequencia_produto}>
                  {item.sequencia_produto} - {item.descricao_completa}
                </option>
              ))}
            </datalist>

            <input
              list="datalistUsuario"
              autoComplete="off"
              id="inputUsuario"
              className="form-control"
              hidden
              onChange={(event) => handlePreencherProdutosTable()}
            />
            <datalist id="datalistUsuario" className="form-control" hidden>
              {users.map((user) => (
                <option key={user.username}>
                  {user.username} - {user.first_name}
                </option>
              ))}
            </datalist>

            <input
              list="datalistComprador"
              autoComplete="off"
              id="inputComprador"
              className="form-control"
              hidden
              onChange={(event) => handlePreencherProdutosTable()}
            />
            <datalist id="datalistComprador" className="form-control" hidden>
              {comprador.map((item) => (
                <option
                  id={item.seqcomprador}
                  value={item.comprador}
                  key={item.comprador}
                />
              ))}
            </datalist>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="temporario"
              onChange={(event) => handleTemporary(event)}
              hidden
            />
            <label
              className="form-check-label"
              id="labelTemporario"
              htmlFor="temporario"
              hidden
            >
              Temporário
            </label>
          </div>
        </div>

        <div className="col-sm">
          <div className="form-group">
            <label id="labelDeadline" hidden>
              Data Limite
            </label>
            <input
              type="date"
              className="form-control"
              id="deadline"
              disabled
              hidden
            />
          </div>
        </div>
      </div>

      {searchType == 'Produto' && (
        <>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>Cod. Produto</label>
                <input
                  type="number"
                  className="form-control"
                  value={product}
                  onChange={(event) => setProduct(event.target.value)}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>Desc. Produto</label>
                <input
                  type="text"
                  className="form-control"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
            </div>

            <div
              className="col-sm form-group align-self-end"
              style={{ display: 'flex' }}
            >
              <button
                className="btn btn-primary"
                style={{ flex: 1 }}
                onClick={() =>
                  searchProdutoEmpresaToLoadCompradorDataListExtended()
                }
              >
                Pesquisar
              </button>
            </div>
          </div>
        </>
      )}

      {searchProcess && (
        <div className="d-flex align-items-center mb-2">
          <h5 style={{ margin: 0 }}>Processando....</h5>
          <Spinner
            className="ml-2"
            animation="border"
            variant="primary"
            size="sm"
          />
        </div>
      )}

      <div style={scrollDiv}>
        <table id="itensProdutosTable" className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  value=""
                  onClick={(event) => handleCheckBoxAll(event)}
                />
              </th>
              <th scope="col">Produto</th>
              <th scope="col">Descrição</th>
              <th scope="col">Usuário</th>
            </tr>
          </thead>
          <tbody></tbody>
          {produtoList.map((item) => (
            <tr key={item.sequencia_produto}>
              <td>
                <input type="checkbox" value="" />
              </td>
              <td>{item.sequencia_produto}</td>
              <td>{item.descricao_completa}</td>
              <td hidden>{item.codigo_usuario}</td>
              <td>{item.first_name}</td>
            </tr>
          ))}
        </table>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <button
              className="btn btn-primary float-right mt-2"
              onClick={() => handleSaveAppointment()}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
