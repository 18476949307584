import moment from "moment-timezone";
import { Badge, Spinner } from "react-bootstrap";

export function LojasTab({
  cds,
  users,
  onFilterCompanyByState,
  onFilterCompanies,
  stores,
  onItemStoreList,
  isLoading,
  productItemList,
  onProductItem,
  conferedLotesList,
  isLoading2,
  onBilling,
  onSaveLotes
}) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row bg-gray-light rounded p-3 mb-3">
          <div className="col-sm">
            <div className="form-group">
              <label>Centro de Distribuição</label>
              <select
                id="centroDistribuicaoSelect2"
                className="form-control"
              >
                {cds.map((item, index) => (
                  <option key={index} value={item.numero_empresa}>
                    {item.nome_reduzido}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-sm">
            <div className="form-group">
              <label>Data Inicial</label>
              <input
                type="date"
                className="form-control"
                id="dataInicial2"
              />
            </div>
          </div>

          <div className="col-sm">
            <div className="form-group">
              <label>Data Final</label>
              <input
                type="date"
                className="form-control"
                id="dataFinal2"
              />
            </div>
          </div>

          <div className="col-sm">
            <div className="form-group">
              <label>Status </label>
              <select className="form-control" id="selStatusFixo2">
                <option defaultValue value="P,C,L,F,X">
                  Todos
                </option>
                <option value="F">Finalizado</option>
                <option value="L">Liberado</option>
                <option value="X">Cancelado</option>
                <option value="C">Conferido</option>
                <option value="P">Pendente</option>
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Conferente</label>

              <input
                list="datalistTargetusuario"
                autoComplete="off"
                id="inputTargetusuario"
                className="form-control"
                placeholder="Entre com o nome do usuário"
              />
              <datalist
                id="datalistTargetusuario"
                className="form-control"
                hidden
              >
                {users.map((item) => (
                  <option key={item.username}>
                    {item.username} - {item.first_name}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
        </div>

        <div
          id="table-spinner"
          className="mb-3"
          style={{
            textAlign: 'center',
            position: 'absolute',
            zIndex: '9',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,.5)',
            paddingTop: '110px',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>

        <div className="row">
          <div className="col-sm-2">
            <h5>Empresa</h5>
            <select
              id="ddlUfs"
              className="form-control"
              onChange={onFilterCompanyByState}
            >
              <option value="0">Todos os estados</option>
              <option value="SP">SP</option>
              <option value="GO">GO</option>
              <option value="BA">BA</option>
              <option value="DF">DF</option>
            </select>
            <input
              type="search"
              id="txtFiltraEmpresa"
              className="form-control mt-2 mr-2"
              placeholder="Procurar empresa"
              onChange={onFilterCompanies}
            />
            <div
              className="faturamentoProd"
              style={{ height: '320px' }}
            >
              <table className="table table-striped table-hover">
                <tbody>
                  {stores.map((item, index) => (
                    <tr
                      key={index}
                      className={'btnEmpresa btnEmpresa' + index}
                      data-uf={item.uf}
                    >
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          onItemStoreList(
                            item.seqpessoa,
                            item.nome_reduzido,
                            'btnEmpresa' + index,
                          )
                        }
                      >
                        {item.nome_reduzido}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="d-flex align-items-center gap-2">
              <h5>Produtos</h5>
              {isLoading ? (
                <Spinner
                  className="ml-2"
                  animation="border"
                  variant="primary"
                  size="sm"
                />
              ) : (
                ''
              )}
            </div>
            <div className="faturamentoProd lotes">
              <div
                className="pl-2 pt-2 pb-2 text-bold"
                id="spanNomeEmpresa"
              >
                &nbsp;
              </div>
              <table className="table table-striped  table-hover">
                <thead>
                  <tr>
                    <th>Lista</th>
                    <th>Descrição</th>
                    <th>Qtde</th>
                    <th>Status</th>
                    <th>Conferente</th>
                  </tr>
                </thead>
                <tbody>
                  {productItemList.length === 0 ? (
                    <tr>
                      <td
                        id="txtMsg"
                        className="text-center"
                        colSpan="100"
                      >
                        Selecione uma empresa na lista ao lado
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}

                  {productItemList.map((item, index) => (
                    <tr
                      key={index}
                      className={'btnProduto btnProduto' + index}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        onProductItem(
                          item,
                          'btnProduto' + index,
                        )
                      }
                    >
                      <td>{item.sequencia_lista}</td>
                      <td className="d-flex justify-content-between align-items-center">
                        {item.descricao}
                        {item.bonificado === 'S' ? (
                          <Badge
                            bg="primary"
                            title="Produto Bonificado"
                            className=" fs-4"
                          >
                            B
                          </Badge>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {item.quantidade_atendida != 0
                          ? item.quantidade_atendida
                          : item.quantidade_conferida}
                      </td>
                      <td>{item.status_conferencia}</td>
                      <td>{item.nome_usuario}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-sm-6">
            <h5>Lotes</h5>
            <div className="faturamentoProd lotes">
              <div
                className="pl-2 pt-2 pb-2 text-bold"
                id="spanNomeProdutoConferido"
              >
                &nbsp;
              </div>
              <table
                className="table table-striped table-hover"
                id="lotsTable"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Lote</th>
                    <th>Descrição</th>
                    <th>NF</th>
                    <th>Criação</th>
                    <th>Qtde</th>
                    <th>Tipo Lote</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {conferedLotesList.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="100">
                        Selecione um produto na lista ao lado
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}

                  {conferedLotesList.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox ckbLoteConf"
                          data-status={'input-lote-status-' + index}
                          data-sequenciaProduto={
                            item.sequencia_produto
                          }
                          data-sequenciaPessoa={
                            item.sequencia_pessoa
                          }
                          data-sequenciaLote={'input-lote-' + index}
                          data-indiceConferencia={
                            item.indice_conferencia
                          }
                          data-sequenciaLista={item.sequencia_lista}
                        />
                      </td>
                      <td style={{ width: '15%' }}>
                        <input
                          style={{
                            border: 'none',
                            color: '#DD8500',
                            width: '100%',
                            backgroundColor: '#F5F5F5',
                          }}
                          type="number"
                          className={
                            'input-lote-' + index + ' input-lote'
                          }
                          value={item.sequencia_lote}
                        />
                      </td>
                      <td style={{ width: '30%' }}>
                        {item.descricao}
                      </td>
                      <td style={{ width: '10%' }}>
                        {item.numero_pedido}
                      </td>
                      <td style={{ width: '15%' }}>
                        {item.data_hora_registro != null
                          ? moment(item.data_hora_registro).format(
                            'DD/MM/yyyy',
                          )
                          : ''}
                      </td>
                      <td style={{ width: '10%' }}>
                        {item.quantidade_conferida}
                      </td>
                      <td style={{ width: '12%' }}>
                        {item.tipo_lote}
                      </td>
                      <td style={{ width: '10%' }}>
                        <span
                          className={
                            item.origem == 'N'
                              ? 'badge bg-blue'
                              : 'badge bg-red'
                          }
                          style={{
                            position: 'absolute',
                            'margin-top': '-7px',
                            marginLeft: '-5px',
                          }}
                          title="Origem (A = Avulso / N = Normal)"
                        >
                          {item.origem}
                        </span>
                        <select
                          id="selItemStatus"
                          className={'input-lote-status-' + index}
                          style={{
                            border: 'none',
                            color: '#DD8500',
                            backgroundColor: '#F5F5F5',
                          }}
                        >
                          <option
                            defaultValue
                            value={item.status_conferencia}
                          >
                            {item.status_conferencia}
                          </option>
                          <option value="L">Liberado</option>
                          <option value="X">Cancelado</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <button
              id="btnFaturar"
              className="form-control btn btn-primary float-right"
              style={{ margin: '3px' }}
              onClick={() => onBilling()}
              disabled={isLoading2}
            // data-bs-toggle="modal"
            >
              {isLoading2 ? (
                <Spinner
                  className="ml-2 mr-2"
                  animation="border"
                  variant="light"
                  size="sm"
                />
              ) : (
                'Faturar'
              )}
            </button>
          </div>
          <div className="col-sm-7">
            <button
              className="form-control btn btn-primary float-right"
              style={{ margin: '3px' }}
              onClick={onSaveLotes}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
