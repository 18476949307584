import moment from 'moment';
import './printer.css';

export function PrinterReport({ stocks, reportToPrintRef }) {
  return (
    // <div className="PDFReport px-1 py-4" ref={reportToPrintRef}>
    //   <div
    //     className="ReportHeader row text-center text-bold mx-2 bg-light"
    //     style={{ fontSize: '13px' }}
    //   >
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       PRODUTO
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       DT ENTRADA
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       FORNECEDOR
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       COMPRADOR
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       LOTE
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       UNIT
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       ENTRADA
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       SAIDA
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       ESTOQUE
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       EMBALAGEM
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       UNI/KG
    //     </div>
    //     <div className="col-1 border border-dark px-1 d-flex align-items-center justify-content-center">
    //       TOTAL DE PRODUTOS
    //     </div>
    //   </div>

    //   <div className="ReportBody">
    //     {stocks.map((stock) => (
    //       <div
    //         className=" ReportBodyRow row mx-2"
    //         key={stock.lote}
    //         style={{ fontSize: '12px' }}
    //       >
    //         <div className="col-1 border border-dark p-1 d-flex align-items-center justify-content-center">
    //           {stock.seqproduto} - {stock.produto}
    //         </div>
    //         <div className="col-1 border border-dark d-flex align-items-center justify-content-between">
    //           {moment(stock.dtaentrada).format('DD/MM/YYYY')}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.fornecedor}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.comprador}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.lote}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.unitario}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.entrada}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.saida}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.entrada - stock.saida}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.volume}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {(
    //             (stock.entrada - stock.saida) *
    //             stock.quantidade_embalagem
    //           ).toFixed(2)}
    //         </div>
    //         <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
    //           {stock.total.toFixed(2)}
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="row PDFReport">
      <div className="col-sm-12">
        {stocks.length > 0 && (
          <div
            className="card-body table-responsive"
            style={{ maxHeight: 'calc(100vh - 271px)', overflow: 'auto' }}
          >
            <table
              id="table-export"
              className="table table-bordered table-hover table-striped text-nowrap text-sm"
              style={{ position: 'relative' }}
            >
              <thead className='ReportHeader'>
                <tr style={{ fontSize: '14px' }} className='ReportHeader'>
                  <th>CODIGO</th>
                  <th>PRODUTO</th>
                  <th>DT. ENTRADA</th>
                  <th>FORNECEDOR</th>
                  <th>COMPRADOR</th>
                  <th>LOTE</th>
                  <th>UNIT</th>
                  <th>ENTRADA</th>
                  <th>SAIDA</th>
                  <th>ESTOQUE</th>
                  <th>EMBALAGEM</th>
                  <th>(UNI/KG)</th>
                  <th>TOTAL DE PRODUTOS</th>
                </tr>
              </thead>
              <tbody>
                {stocks.map((item) => (
                  <tr key={`${item.seqproduto}-${item.lote}`} style={{ fontSize: '13px' }} className='ReportBodyRow'>
                    <td>{item.seqproduto}</td>
                    <td>{item.produto}</td>
                    <td>
                      {moment(item.dtaentrada).format('DD/MM/YYYY')}
                    </td>
                    <td>{item.fornecedor}</td>
                    <td>{item.comprador}</td>
                    <td>{item.lote}</td>
                    <td>{item.unitario}</td>
                    <td>{item.entrada}</td>
                    <td>{item.saida}</td>
                    <td>{item.entrada - item.saida}</td>
                    <td>{item.volume}</td>
                    <td>
                      {(
                        (item.entrada - item.saida) *
                        item.quantidade_embalagem
                      ).toFixed(2)}
                    </td>
                    <td
                      style={{
                        background:
                          item.total !== 0 && 'rgb(253, 253, 150)',
                      }}
                    >
                      {/* {stockTotalByItem.map((totalItem) => {
                        if (totalItem.seqproduto === item.seqproduto) {
                          return totalItem.total;
                        }
                      })} */}
                      {item.total.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
