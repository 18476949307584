import pt from 'date-fns/locale/pt';
import $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import * as xlsx from 'xlsx/xlsx.mjs';
import { FormConferenciaAvulsa } from '../../../../components/ConferenciaAvulsa/FormConferenciaAvulsa';
import { ModalLote } from '../../../../components/ConferenciaAvulsa/ModalLote';
import { ProductsBox } from '../../../../components/ConferenciaAvulsa/ProductsBox';
import { ModalAlert } from '../../../../components/ModalAlert';
import { useLoading } from '../../../../hooks/useLoading';
import api from '../../../../services/api';
import { groupByKey } from '../../../../utils/groupByKey';

registerLocale('pt', pt);
xlsx.set_cptable(cpexcel);

var respMsg = '';

var currentEmpresaId = null;
//var currentEmpresa = null;
var currentseqitemlista;
var currentseqlista;
var currentseqitem;

const token = JSON.parse(localStorage.getItem('token'));

function ShowAlert(title, message) {
  $('#modal-alert .modal-title').html(title);
  $('#modal-alert .modal-body').html(
    '<i class="fa fa-info-circle fa-fw text-gray"></i> ' + message,
  );
  window.$('#modal-alert').modal('show');
}

let currentEmpresa = {};

function ConferenciaAvulsa() {
  // const [currentEmpresa, setCurrentEmpresa] = useState({});
  const [currentProduto, setCurrentProduto] = useState({});
  const [currentLote, setCurrentLote] = useState({});
  const [lotes, setLotes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [cd, setcd] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [locais, setLocais] = useState([]);
  const [isBonified, setIsBonified] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const hiddenFileInput = React.useRef(null);
  const loading = useLoading();
  const [produtosConferidos, setProdutosConferidos] = useState([]);

  useEffect(() => {
    $('#txtFiltraEmpresa').focus();
    Buscacd();
    BuscaEmpresas();

    $('#boxProdutos').show();
    $('#boxLotes').hide();
    $('#table-spinner').hide();
    $('.lote-linha-box').hide();
    window.$('#modal-lote').on('hidden.bs.modal', function () {
      $('.lote-linha').removeClass('active-conferencia');
    });
  }, []);

  async function Buscacd() {
    try {
      const res = await api.get('/empresa/centrodistribuicao/');

      if (res.data.results != null) {
        if (res.data.results.length == 0) {
          toast.error('Nenhum Centro de Distribuição foi localizado.');
        }
        res.data.results.sort(dynamicSort('cidade'));
        setLocais(distinct(res.data.results, 'cidade'));
        res.data.results.sort(dynamicSort('nroempresa'));
        setcd(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function BuscaProdutos(event) {
    setProdutos([]);
    setLotes([]);

    if (event.key === 'Enter') {
      if (currentEmpresa.nroempresa != undefined) {
        SetEmpresaSelecionada(
          '#btnEmpresa' + $('.active-conferencia').attr('data-key'),
        );
      } else {
        ShowAlert('Atenção', 'Selecione uma empresa.');
      }
    }
  }

  async function BuscaBlur(event) {
    if (currentEmpresa.nroempresa != undefined) {
      SetEmpresaSelecionada(
        '#btnEmpresa' + $('.active-conferencia').attr('data-key'),
      );
    } else {
      ShowAlert('Atenção', 'Selecione uma empresa.');
    }
  }

  async function GetProdutos() {
    try {
      if ($('#ddlEmpresa').val() != '') {
        const res = await api.post('/produto/produtoempresa/', {
          sequencia_comprador: 0,
          sequencia_produto: Number($('#txtProduto').val()),
          descricao: '',
          status_compra: 'A',
          numero_empresa: $('#ddlEmpresa').val(),
        });
        if (res.data.resposta == null) {
          if (res.data.length == 0) {
            toast.error('Nenhum produto foi localizado.');
          }
          BuscaLote(
            $('#ddlEmpresa').val(),
            $('#txtProduto').val(),
            currentEmpresa.seqpessoa,
            0,
          );

          setProdutos(res.data);
        } else {
          toast.error('Nenhum produto foi localizado.');

          setProdutos([]);
          setLotes([]);
        }
      } else {
        ShowAlert('Atenção', 'Informe o código do produto');
        $('#ddlEmpresa').focus();
        $('.btnEmpresa').removeClass('active-conferencia');
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function BuscaLote(nroempresa, seqproduto, seqpessoa, seqlista) {
    $('#table-spinner').show();

    try {
      const res = await api.get(
        `/listaseparacao/busca_lote_conferencia/${nroempresa}/${seqproduto}/${seqpessoa}`,
      );

      if (res.data.results.length === 0) {
        toast.error('Nenhum lote foi localizado para o produto selecionado.');
        $('#table-spinner').hide();
        return;
      }

      if (res.data.results != null) {
        const res2 = await api.post('/listaseparacao/busca_conferencia_v2/', {
          numero_empresa: nroempresa,
          sequencia_lista: seqlista,
          sequencia_produto: seqproduto,
          sequencia_pessoa: currentEmpresa.seqpessoa,
          data_inicial: null,
          data_final: null,
          status: 'P,C,L',
          codigo_usuario: null,
        });

        if (res2.data != null) {
          if (res.data.results.length > 0 && res2.data.length === 0) {
            setLotes(res.data.results);
            $('#table-spinner').hide();
            return;
          }

          if (res2.data.length > 0) {
            for (var i = 0; i < res.data.results.length; i++) {
              res.data.results[i].quantidade_conferida = 0;
              res.data.results[i].temp = 0;
              res.data.results[i].produtos = {};
              res.data.results[i].conferido_bonificado = 0;
              res.data.results[i].conferido_normal = 0;
              res.data.results[i].bonificado = '';

              for (var a = 0; a < res2.data.length; a++) {
                if (
                  res.data.results[i].sequencia_lote ==
                  res2.data[a].sequencia_lote
                ) {
                  // if (res2.data[a].sequencia_pessoa != currentEmpresa.seqpessoa) {
                  //   res.data.results[i].quantidade_conferida +=
                  //     res2.data[a].quantidade_conferida;
                  //   console.log('tem bonificado');
                  //   res.data.results[i].bonificado = res2.data[a].bonificado;
                  // } else {
                  //   console.log(res.data.results[i].bonificado);
                  //   console.log(res2.data[a].bonificado);
                  //   res.data.results[i].temp += res2.data[a].quantidade_conferida;
                  //   res.data.results[i].quantidade_atendida =
                  //     res2.data[a].quantidade_atendida;
                  //   console.log('não tem bonificado');

                  //   res.data.results[i].bonificado = res2.data[a].bonificado;
                  // }

                  /* Como na req buscar_conferencia recebe o seqpessoa ñ precisa do 
                if (res2.data[a].sequencia_pessoa != currentEmpresa.seqpessoa) */
                  res.data.results[i].quantidade_conferida +=
                    res2.data[a].quantidade_conferida;
                  res.data.results[i].temp += res2.data[a].quantidade_conferida;
                  res.data.results[i].quantidade_atendida =
                    res2.data[a].quantidade_atendida;

                  if (res2.data[a].bonificado === 'S') {
                    res.data.results[i].conferido_bonificado +=
                      res2.data[a].quantidade_conferida;
                  } else {
                    res.data.results[i].conferido_normal +=
                      res2.data[a].quantidade_conferida;
                  }
                }
              }
              //filtrar produtos conferidos por lote
              const filterProductsPerLote = res2.data.filter(
                (product) =>
                  product.sequencia_lote === res.data.results[i].sequencia_lote,
              );

              const grouped = groupByKey(filterProductsPerLote, 'bonificado');
              res.data.results[i].produtos = grouped;

              if (
                res.data.results[i].saldo -
                res.data.results[i].quantidade_conferida <=
                0
              ) {
                delete res.data.results[i];
              }
            }
          }
        }

        setLotes(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }

    $('#table-spinner').hide();
  }

  async function BuscaEmpresas() {
    try {
      const res = await api.get('/empresa/listaempresa/');
      var result = null;

      if (res.data.results != null) {
        res.data.results.sort(dynamicSort('cidade'));
        setLocais(distinct(res.data.results, 'cidade'));
        // console.log('---locais---');
        // console.log(locais);
        res.data.results.sort(dynamicSort('nroempresa'));
        setEmpresas(res.data.results);
        // console.log('---Empresas---');
        // console.log(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function SetEmpresaSelecionada(name) {
    currentEmpresaId = [$(name).val()];
    // setCurrentEmpresa(empresas[$(name).attr('data-key')]);
    currentEmpresa = empresas[$(name).attr('data-key')];

    $('.btnEmpresa').removeClass('active-conferencia');
    $(name).addClass('active-conferencia');
    if ($('#txtProduto').val() !== '') {
      GetProdutos();
    }
  }

  async function Salvar() {
    try {
      loading.setLoading(true);
      if (currentEmpresaId != null) {
        // const res1 = await api.post('/listaseparacao/gerarlistaseparacao/', {
        //   sequencia_usuario: '' + token.userName,
        //   sequencia_comprador: produtos[0].sequencia_comprador,
        //   numero_empresa: Number($('#ddlEmpresa').val()),
        //   data_criacao: moment(new Date()).format('yyyy-MM-DD'),
        // });

        // console.log(res1.data);

        // if (res1.data != null) {
        const res2 = await api.post('/listaseparacao/gerar_lista_avulsa_v3/', {
          seqlista: 0,
          nroempresa: Number($('#ddlEmpresa').val()),
          seqproduto: Number($('#txtProduto').val()),
          descricao: produtos[0].descricao_completa,
          qtdembpedida: currentLote.quantidade_conferida || 0,
          seqpessoa: currentEmpresa.seqpessoa,
          empdestino: currentEmpresa.nroempresa,
          embalagem: currentLote.embalagem,
          codusuario: '' + token.userName,
          bonificado: isBonified ? 'S' : 'N',
        });

        if (res2.data.codResult == 0) {
          currentseqitemlista = res2.data.seqitemlista;
          currentseqlista = res2.data.seqlista;
          currentseqitem = res2.data.seqitem;

          var id_conf =
            currentProduto.codusuario +
            currentProduto.seqlista +
            currentProduto.seqitem +
            moment(new Date()).format('DDMMyyyyHHmmss');

          var qtdValid =
            currentLote.saldo - (currentLote.quantidade_conferida || 0);

          if (qtdValid >= Number($('#txtQtd').val())) {
            const res = await api.post(
              '/listaseparacao/salvarconferencia_v2/',
              {
                codigo_usuario: '' + token.userName,
                sequencia_lista: currentseqlista,
                sequencia_produto: produtos[0].sequencia_produto,
                numero_empresa: produtos[0].numero_empresa,
                sequencia_cliente: currentEmpresa.seqpessoa,
                quantidade_embalagem: currentLote.embalagem,
                quantidade_conferida: Number($('#txtQtd').val()),
                sequencia_item_lista: currentseqitem,
                sequencia_lote: currentLote.sequencia_lote,
                valor_embalagem_produto: currentLote.valor,
                tipo_lote: currentLote.tipo_lote,
                status: 'C',
                data_hora_conferencia: new Date(),
                conferencia:
                  token.userName +
                  '' +
                  currentseqlista +
                  '' +
                  currentseqitem +
                  '' +
                  moment(new Date()).format('DDMMyyyyHHmmss'),
                sequencia_separador: 0,
                origem: 'A',
                bonificado: isBonified ? 'S' : 'N',
              },
            );

            window.$('#modal-lote').modal('hide');
            if (res.data != null) {
              if (res.data[0].numero_erro != 0) {
                ShowAlert('Atenção', res.data[0].mensagem);
              }
              BuscaLote(
                $('#ddlEmpresa').val(),
                produtos[0].sequencia_produto,
                currentEmpresa.seqpessoa,
                0,
              );
            } else {
              ShowAlert('Erro', 'Um erro ocorreu ao fazer a chamada da API');
            }
          } else {
            window.$('#modal-lote').modal('hide');
            ShowAlert(
              'Quantidade inválida',
              'A quantidade deve ser menor ou igual a ' + qtdValid,
            );
          }
        } else {
          alert('Ocorreu um erro ao enviar os dados.');
        }
        // }
        toast.success('Conferência realizada com sucesso.');
        loading.setLoading(false);
      } else {
        alert('Selecione uma empresa.');
        loading.setLoading(false);
      }
    } catch (err) {
      loading.setLoading(false);
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function FiltrarEmpresas() {
    $('.btnEmpresa').hide();
    $(
      ".btnEmpresa:containsi('" + $('#txtFiltraEmpresa').val() + "')",
    ).slideDown('fast');
  }

  function FindEmpresa(id) {
    var toReturn = null;
    cd.forEach(function (item, a) {
      if (parseInt(cd[a].nroempresa) == parseInt(id)) {
        toReturn = cd[a].nome_reduzido;
      }
    });

    return toReturn;
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function BtnEmpresaClicked(btn) {
    //setCurrentEmpresa(cd[$('#' + btn).attr('data-key')]);

    BuscaProdutos($('#' + btn).attr('data-seqpessoa'));

    $('.btnEmpresa').removeClass('active-conferencia');
    $('.produto-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');

    BackToProdutos();
  }

  async function ProdutoLinhaClicked(btn) {
    setCurrentProduto(produtos[$('#' + btn).attr('data-key')]);
    var seqproduto = $('#' + btn).attr('data-id');
    var nroempresa = $('#' + btn).attr('data-empresa');

    BuscaLote(
      nroempresa,
      seqproduto,
      currentEmpresa.seqpessoa,
      currentEmpresa.seqlista,
    );

    $('.produto-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');
    $('#boxProdutos').slideUp('fast');
    $('#boxLotes').fadeIn('slow');
  }

  async function LoteLinhaClicked(btn) {
    setCurrentLote(lotes[$('#' + btn).attr('data-key')]);

    if (lotes[$('#' + btn).attr('data-key')].quantidade_atendida > 0) {
      $('#txtQtd').val(
        lotes[$('#' + btn).attr('data-key')].quantidade_atendida,
      );
    } else {
      $('#txtQtd').val('');
    }

    $('.lote-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');
    window.$('#modal-lote').modal('show');

    setTimeout(function () {
      $('#txtQtd').focus();
      $('#txtQtd').select();
    }, 1000);
  }

  async function BackToProdutos() {
    $('#boxProdutos').slideDown('fast');
    $('#boxLotes').hide();
    $('.produto-linha').removeClass('active-conferencia');
  }

  async function Filtrarcd() {
    $('.btnEmpresa').hide();
    $(
      ".btnEmpresa:containsi('" + $('#txtFiltraEmpresa').val() + "')",
    ).slideDown('fast');
  }

  $.extend($.expr[':'], {
    containsi: function (elem, i, match, array) {
      return (
        (elem.textContent || elem.innerText || '')
          .toLowerCase()
          .indexOf((match[3] || '').toLowerCase()) >= 0
      );
    },
  });

  async function CloseModal() {
    window.$('.modal').modal('hide');
  }

  async function LiberarFaturamento(seqpessoa) {
    const options = {
      title: 'Liberar Farutamento',
      message: 'Deseja realmente liberar o faturamento para esta empresa?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => FinalizarLiberacao(seqpessoa),
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    };

    confirmAlert(options);
  }

  async function FinalizarLiberacao(seqpessoa) {
    try {
      const res = await api.post('/listaseparacao/libera_faturamento', {
        seqpessoa: seqpessoa,
        chapa_usuario: '' + token.userName,
      });
      var result = null;

      if (res.data != null) {
        ShowAlert(
          'Sucesso!',
          'A liberação de faturamento foi concluída com sucesso.',
        );
      } else {
        ShowAlert('Atenção', 'Não foi possível liberar o faturamento.');
      }
    } catch (err) {
      console.log(err);
      ShowAlert('Atenção', 'Ocorreu um erro ao liberar o faturamento.');
    }
  }

  return (
    <Fragment>
      <div className="content-header">
        <h1 style={{ marginLeft: 0 }}>Conferência Avulsa</h1>
      </div>

      <div className="content">
        <div className="row" style={{ height: '84.5vh' }}>
          <div className="col-sm-12 h-100">
            <div className="card mb-0 h-100" style={{ overflow: 'hidden' }}>
              <div className="card-body h-100 p-1" style={{ overflow: 'hidden' }}>
                <div className="row h-100">
                  <FormConferenciaAvulsa
                    cds={cd}
                    onFilterCompanies={FiltrarEmpresas}
                    companies={empresas}
                    onSetSelectedCompany={SetEmpresaSelecionada}
                    onReleaseBilling={LiberarFaturamento}
                    isBonified={isBonified}
                    onSetBonified={setIsBonified}
                    onSearchProcuts={BuscaProdutos}
                  />
                  <ProductsBox
                    products={produtos}
                    currentCompany={currentEmpresa}
                    lotes={lotes}
                    onLoteRowClicked={LoteLinhaClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAlert onCloseModal={CloseModal} />

      <ModalLote
        onCloseModal={CloseModal}
        currentLote={currentLote}
        onSave={Salvar}
        isLoading={loading.isLoading}
      />
    </Fragment>
  );
}

export default ConferenciaAvulsa;
