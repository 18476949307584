import { Fragment } from 'react';

function Dashboard() {
  return (
    <Fragment>
      <div className="content-header">
        <h1>Oba Business</h1>
      </div>
      <div className="content">
        <h1>Dashboard</h1>
      </div>
    </Fragment>
  );
}

export default Dashboard;
