import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { useLoading } from '../hooks/useLoading';

import api from '../services/api';
import { Spinner } from 'react-bootstrap';

function ResumoParticular() {
  const [cnpj, setCnpj] = useState('');
  const [fornecedor, setFornecedor] = useState('');
  const [listCD, setListCD] = useState([]);
  const [searchCD, setSearchCD] = useState(null);
  const [origem, setOrigem] = useState([]);
  const [selectedOrigem, setSelectedOrigem] = useState(null);
  const [destino, setDestino] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const token = JSON.parse(localStorage.getItem('token'));
  const codigo_usuario = token && token.userName ? token.userName : '';

  const [showModal, setShowModal] = useState(false);

  const [alterarSequenciaPessoa, setAlterarSequenciaPessoa] = useState('');
  const [alterarSequenciaProduto, setAlterarSequenciaProduto] = useState('');
  const [alterarCNPJ, setAlterarCNPJ] = useState('');
  const [alterarEmbalagem, setAlterarEmbalagem] = useState('');
  const [alterarQuantidade, setAlterarQuantidade] = useState('');
  const [alterarValor, setAlterarValor] = useState('');
  const [alterarNome, setAlterarNome] = useState('');
  const [alterarLote, setAlterarLote] = useState('');
  const [alterarObservacao, setAlterarObservacao] = useState('');

  const loading = useLoading();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get('/empresa/centrodistribuicao/');

        if (res.data.results.length == 0)
          toast.error('Nenhum centro de distribuição foi localizado');

        setListCD(res.data.results);
      } catch (err) {
        console.log(err);
        toast.error('Falha na requisição dos dados', err.message);
      }
    }

    fetchData();
  }, []);

  function handleCloseModal() {
    setShowModal(false);
  }

  async function CallFornecedor(next = null) {
    try {
      var data = [];
      var resultFornecedor = null;

      var seqpessoa = $('#seqpessoa').val() != '' ? $('#seqpessoa').val() : 0;

      if (next != null) {
        resultFornecedor = await api.get(next);

        const url = new URL(next);

        const page = url.searchParams.get('page');

        page > 2 && loading.setLoading(false);
      } else {
        resultFornecedor = await api.get(
          `pessoa/fornecedor/` + seqpessoa + `/${cnpj == '' ? '0' : cnpj}`,
        );
      }

      if (seqpessoa == 0 && cnpj == '') {
        const seqfornecedor = 0;
        const resultOrigem = await api.get(
          `listaseparacao/v1/busca_lote_particular/${seqfornecedor}/${searchCD.numero_empresa}`,
        );

        for (var a = 0; a < resultOrigem.data.results.length; a++) {
          data.push(resultOrigem.data.results[a]);
        }
      } else {
        for (var b = 0; b < resultFornecedor.data.results.length; b++) {
          const seqfornecedor = resultFornecedor.data.results[b].seqfornecedor;
          setFornecedor(seqfornecedor);

          const resultOrigem = await api.get(
            `listaseparacao/v1/busca_lote_particular/${seqfornecedor}/${searchCD.numero_empresa}`,
          );

          for (var a = 0; a < resultOrigem.data.results.length; a++) {
            data.push(resultOrigem.data.results[a]);
          }
        }
      }
      setOrigem(data);
      forceUpdate();

      if (resultFornecedor.data.next != null) {
        CallFornecedor(resultFornecedor.data.next);
      }

      if (data.length > 0) {
      } else {
        toast.error('Fornecedor não possui entrada particular.');
      }
      loading.setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.', err.message);
    }
  }

  async function handleGetByCNPJ() {
    if (searchCD !== null /*&& cnpj !== ''*/) {
      setOrigem([]);
      setDestino([]);

      loading.setLoading(true);
      CallFornecedor();
      /*
      const resultFornecedor = await api.get(`pessoa/fornecedor/` + seqpessoa+`/${(cnpj==""?"0":cnpj)}`);

      if (resultFornecedor.data.results.length > 0) {
        const seqfornecedor = resultFornecedor.data.results[0].seqfornecedor;

        setFornecedor(seqfornecedor);

        if (resultFornecedor.data.results.length > 0) {
          setFornecedor(resultFornecedor.data.results[0]);

          const resultOrigem = await api.get(
            `listaseparacao/v1/busca_lote_particular/${seqfornecedor}/${searchCD.numero_empresa}`,
          );

          setOrigem(resultOrigem.data.results);

          if (resultOrigem.data.results.length > 0) {
          } else {
            toast.error('Fornecedor não possui entrada particular.');
          }
        } else {
          toast.error('CNPJ inválido.');
        }
      } else {
        toast.error('Nenhum item foi localizado.');
      }

*/
    } else {
      toast.error('Selecione o CD');

      setCnpj('');
    }
  }

  function handleSearchCD(event) {
    if (event.target.value !== 'A DEFINIR') {
      setSearchCD(
        listCD.find(
          (item) => item.numero_empresa === parseInt(event.target.value),
        ),
      );
    } else {
      setSearchCD(null);
    }
  }

  async function handleSelectedOrigem(item) {
    try {
      setSelectedOrigem(item);
      setDestino([]);
      const resultDestino = await api.post(
        `listaseparacao/v1/estoque_lote_total/`,
        {
          numero_empresa: searchCD.numero_empresa,
          sequencia_comprador: 0,
          sequencia_produto: item.sequencia_produto,
          sequencia_fornecedor: item.sequencia_fornecedor,
          tipo_lote: 'N',
          status: 'P',
        },
      );

      if (resultDestino.data.length > 0) {
        setDestino(resultDestino.data);
      } else {
        toast.error('Fornecedor não possui entrada particular.');
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.', err.message);
    }
  }

  async function handleSelectedDestino(item) {
    setSelectedDestino(item);
  }

  async function handleCasarLote() {
    try {
      if (
        selectedOrigem.sequencia_fornecedor ===
        selectedDestino.sequencia_fornecedor
      ) {
        const resultCasaLote = await api.post(
          'listaseparacao/casa_lote_particular',
          {
            sequencia_lote_origem: selectedOrigem.sequencia_lote,
            sequencia_lote_destino: selectedDestino.lote,
            codigo_usuario: codigo_usuario,
          },
        );

        if (resultCasaLote.data.codResult === 1) {
          toast.error(resultCasaLote.data.msg);
        } else {
          toast.success(resultCasaLote.data.msg);
        }

        setDestino([]);
      } else {
        toast.error('Fornecedor de origem e destino devem ser iguais');
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.', err.message);
    }
  }

  async function handleLiberaLoteParticular() {
    try {
      const response = await api.post(
        'listaseparacao/v1/libera_lote_particular',
        {
          codigo_usuario: codigo_usuario,
          sequencia_lote: selectedDestino.lote,
          numero_empresa: searchCD.numero_empresa,
        },
      );

      if (response.data.msg === 'Lote liberado com sucesso.') {
        toast.success(response.data.msg);
      } else {
        toast.error(response.data.msg);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.', err.message);
    }
  }

  function handleAlterarEntradaParticular(itemOrigem) {
    setAlterarSequenciaPessoa(itemOrigem.sequencia_fornecedor);
    setAlterarSequenciaProduto(itemOrigem.sequencia_produto);
    setAlterarCNPJ(cnpj);
    setAlterarEmbalagem(itemOrigem.embalagem);
    setAlterarQuantidade(itemOrigem.quantidade);
    setAlterarValor(itemOrigem.valor);
    setAlterarNome(itemOrigem.descricao_completa);
    setAlterarLote(itemOrigem.sequencia_lote);
  }

  async function handleAlterarLote() {
    try {
      const response = await api.post(
        '/listaseparacao/gerar_lote_particular/',
        {
          numeronf: null,
          serienf: null,
          seqlote: alterarLote,
          nroempresa: searchCD.numero_empresa,
          seqproduto: alterarSequenciaProduto,
          // seqfornecedor: seqfornecedor,
          quantidade: alterarQuantidade,
          valor: alterarValor,
          observacao: alterarObservacao,
          embalagem: alterarEmbalagem,
          codusuario: codigo_usuario,
          seqfornecedor: alterarSequenciaPessoa,
        },
      );

      if (response.data.msg === 'Processo terminado com sucesso') {
        toast.success(response.data.msg);

        setShowModal(false);
      } else {
        toast.error(response.data.msg);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.', err.message);
    }
  }

  return (
    <Fragment>
      <div className="content-header">
        <h1>Resumo Particular</h1>
      </div>
      <div className="content">
        <div className="card-body card">
          <div className="row">
            <div className="form-horizontal col-sm-12">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className="col-form-label">CD</label>
                  <select
                    className="form-control"
                    onChange={(event) => handleSearchCD(event)}
                    disabled={listCD.length === 0 ? true : false}
                  >
                    <option defaultValue>A DEFINIR</option>
                    {listCD.map((item) => (
                      <option
                        value={item.numero_empresa}
                        key={item.numero_empresa}
                      >
                        {item.nome_reduzido}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-3">
                  <label className="col-form-label">Seq. Pessoa</label>
                  <input
                    id="seqpessoa"
                    className="form-control"
                    placeholder="Digite o seq. pessoa"
                  ></input>
                </div>

                <div className="col-sm-4">
                  <label className="col-form-label">Fornecedor</label>
                  <input
                    id="fornecedor"
                    className="form-control"
                    placeholder="Digite o CNPJ"
                    value={cnpj}
                    onChange={(event) => setCnpj(event.target.value)}
                  ></input>
                </div>

                <div className="col-sm-2">
                  <label className="col-form-label">&nbsp;</label>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={handleGetByCNPJ}
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading.isLoading && (
            <div className="d-flex align-items-center mb-2">
              <h5 style={{ margin: 0 }}>Processando....</h5>
              <Spinner
                className="ml-2"
                animation="border"
                variant="primary"
                size="sm"
              />
            </div>
          )}

          <div className="row table-responsive">
            <div
              className={origem.length == 0 ? 'd-none' : ''}
              style={{ overflow: 'auto', height: 'calc(100vh - 463px)' }}
            >
              <label>Origem</label>
              <table className="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Lote</th>
                    <th>Cod. Fornecedor</th>
                    <th>Nome Fornecedor</th>
                    <th>Descrição</th>
                    <th>Data</th>
                    <th>Embalagem</th>
                    <th>QTDE</th>
                    <th>Valor</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {origem.map((itemOrigem) => (
                    <tr key={`${itemOrigem.sequencia_lote}`}>
                      <td>
                        <input
                          type="radio"
                          name="group-origem"
                          onChange={() => handleSelectedOrigem(itemOrigem)}
                        />
                      </td>
                      <td>{itemOrigem.sequencia_lote}</td>
                      <td>{itemOrigem.sequencia_fornecedor}</td>
                      <td>{itemOrigem.nome_fornecedor}</td>
                      <td>{itemOrigem.descricao_completa}</td>
                      <td>{itemOrigem.data_entrada}</td>
                      <td>{itemOrigem.embalagem}</td>
                      <td>{itemOrigem.quantidade}</td>
                      <td>{itemOrigem.valor}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                          onClick={() => {
                            setShowModal(true);
                            handleAlterarEntradaParticular(itemOrigem);
                          }}
                        >
                          Editar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={destino.length == 0 ? 'd-none' : ''}>
              <label>Destino</label>
              <table className="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Lote</th>
                    <th>Cod. Fornecedor</th>
                    <th>Nome Fornecedor</th>
                    <th>NF</th>
                    <th>Descrição</th>
                    <th>Data</th>
                    <th>Embalagem</th>
                    <th>QTDE</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {destino.map((itemDestino) => (
                    <tr key={itemDestino.sequencia_lote}>
                      <td>
                        <input
                          type="radio"
                          name="group-destino"
                          onChange={() => handleSelectedDestino(itemDestino)}
                        />
                      </td>
                      <td>{itemDestino.lote}</td>
                      <td>{itemDestino.sequencia_fornecedor}</td>
                      <td>{itemDestino.fornecedor}</td>
                      <td>{itemDestino.numero_nota_fiscal}</td>
                      <td>{itemDestino.produto}</td>
                      <td>{itemDestino.data_de_entrada}</td>
                      <td>{itemDestino.volume}</td>
                      <td>{itemDestino.entrada - itemDestino.saida}</td>
                      <td>{itemDestino.unitario}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={'row ' + (destino.length == 0 ? 'd-none' : '')}>
            <div className="form-horizontal col-sm-12">
              <div
                className="form-group"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => handleLiberaLoteParticular()}
                >
                  Liberar Lote
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => handleCasarLote()}
                >
                  Casar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className="modal fade show"
        id="modal-default"
        aria-modal="true"
        role="dialog"
        style={{ padding: '17px', display: showModal ? 'block' : 'none' }}
      >
        <div className="modal-dialog" style={{ minWidth: '800px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Alterar Entrada Particular - {alterarNome} - Lote {alterarLote}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleCloseModal()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="class">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Seq. Pessoa:</label>
                        <input
                          className="form-control"
                          value={alterarSequenciaPessoa}
                          onChange={(event) =>
                            setAlterarSequenciaPessoa(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>CNPJ:</label>
                        <input
                          className="form-control"
                          value={alterarCNPJ}
                          onChange={(event) =>
                            setAlterarCNPJ(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Embalagem:</label>
                        <input
                          className="form-control"
                          value={alterarEmbalagem}
                          onChange={(event) =>
                            setAlterarEmbalagem(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Quantidade:</label>
                        <input
                          className="form-control"
                          value={alterarQuantidade}
                          onChange={(event) =>
                            setAlterarQuantidade(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Valor:</label>
                        <input
                          className="form-control"
                          value={alterarValor}
                          onChange={(event) =>
                            setAlterarValor(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => handleCloseModal()}
              >
                Fechar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleAlterarLote()}
              >
                Alterar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ResumoParticular;
