import { toast } from "react-toastify"
import { useLoading } from "../../hooks/useLoading"
import api from "../../services/api"
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min"
import { Spinner } from "react-bootstrap"

export function InactivateSupplierModal({ supplierRegistered }) {
  const loading = useLoading()

  async function handleInactivateUser() {
    try {
      if (!supplierRegistered) return

      loading.setLoading(true)
      const data = {
        cpf_cnpj: supplierRegistered.cpfcnpj
      }

      const response = await api.post('/v1/inativa_fornecedor_api/', data)

      if (response.request.status === 400) {
        const jsonResponse = JSON.parse(response.request.response)
        const errorMessage = jsonResponse.error
        toast.error(errorMessage)
        loading.setLoading(false)
        return
      }

      const successMessage = response.data.success
      toast.success(successMessage)
      loading.setLoading(false)

      const modal = document.getElementById('inactiveUser')
      const modalInstance = bootstrapBundleMin.Modal.getInstance(modal);
      modalInstance.hide();

    } catch (err) {
      loading.setLoading(false)

      if (err.response) {
        console.log(err.response.data.error);
        toast.error(err.response.data.error)
        return
      }

      console.log(err.message);
      toast.error(err.message)
    }
  }

  return (
    <div
      className="modal fade"
      id="inactiveUser"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Inativar Cadastro</h1>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
              X
            </button>
          </div>
          <div className="modal-body">
            <p>Deseja inativar o cadastro para o fornecedor: {' '}
              <em>{supplierRegistered?.empresa}</em> e usuário: <em>{supplierRegistered?.usuario}</em>
              ?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleInactivateUser}
              disabled={loading.isLoading}
            >
              {loading.isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'Salvar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
