import axios from 'axios';

import { toast } from 'react-toastify';

const notify = (message) => toast.error(message);

const data = JSON.parse(localStorage.getItem('token'));

let api = null;

if (data && data.token) {
  api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Token ${data.token}`,
    },
  });
} else {
  api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();

      window.location = '/login';
    }
    if (error.response.status === 403) {
      notify('Usuário sem permissão de acesso a este recurso!');
    }
    if (error.response.status === 429) {
      notify('Limite de login excedido, aguarde um minuto e tente novamente!');
    }

    return error
  },
);

export default api;
