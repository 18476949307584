import { Fragment, useEffect, useState } from 'react';
import React from 'react';
import api from '../../../services/api';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import $ from 'jquery';
import CurrencyInput from 'react-currency-masked-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import Moment from 'react-moment';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as xlsx from 'xlsx/xlsx.mjs';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { toast } from 'react-toastify';

registerLocale('pt', pt);
xlsx.set_cptable(cpexcel);

var currentProduto = null;
var currentCluster = null;
var respMsg = '';
var jsonPlanilha = {};

const token = JSON.parse(localStorage.getItem('token'));

function ShowAlert(title, message) {
  $('#modal-alert .modal-title').html(title);
  $('#modal-alert .modal-body').html(message);
  window.$('#modal-alert').modal('show');
}

function AtivarProduto() {
  const [produtosRelacionados, setProdutosRelacionados] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [selectedProdutos, setSelectedProdutos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [precosRelatorio, setPrecosRelatorio] = useState([]);
  const [importacaoData, setImportacaoData] = useState([]);
  const [locais, setLocais] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [embalagens, setEmbalagens] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const hiddenFileInput = React.useRef(null);
  const [dtaAgendamento, setDtaAgendamento] = useState(new Date());

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/empresa/v1/segmento');
      if (res.data != null) {
        setSegmentos(res.data.results);
        console.log('---Segmentos---');
        console.log(res.data);
      }
    }

    $('.card-embalagem').hide();
    $('.card-relacionados').hide();
    $('#boxEmpresas3').hide();
    $('#boxSegmentos2').hide();
    $('.produto-item').hide();
    fetchData();
    BuscaEmpresas();
    BuscaClusters();

    $('.segmento-inverter').change(function () {
      $('.segmento-todos').prop('checked', false);
      for (var i = 0; i < $('.segmento-preco').length; i++) {
        if ($('.segmento-preco').eq(i).prop('checked') == true) {
          $('.segmento-preco').eq(i).prop('checked', false);
        } else {
          $('.segmento-preco').eq(i).prop('checked', true);
        }
      }
    });

    $('.empresa-inverter').change(function () {
      for (var i = 0; i < $('.empresas').length; i++) {
        if ($('.empresas').eq(i).prop('checked') == true) {
          $('.empresas').eq(i).prop('checked', false);
        } else {
          $('.empresas').eq(i).prop('checked', true);
        }
      }
    });

    $('.empresas-filtro-inverter').change(function () {
      for (var i = 0; i < $('.empresas-filtro').length; i++) {
        if ($('.empresas-filtro').eq(i).prop('checked') == true) {
          $('.empresas-filtro').eq(i).prop('checked', false);
        } else {
          $('.empresas-filtro').eq(i).prop('checked', true);
        }
      }
    });

    $('.segmentos-filtro-inverter').change(function () {
      for (var i = 0; i < $('.segmentos-filtro').length; i++) {
        if ($('.segmentos-filtro').eq(i).prop('checked') == true) {
          $('.segmentos-filtro').eq(i).prop('checked', false);
        } else {
          $('.segmentos-filtro').eq(i).prop('checked', true);
        }
      }
    });
  }, []);

  $(document).ready(function () {
    $('.segmento-todos').change(function () {
      $('.segmento-inverter').prop('checked', false);
      if (this.checked) {
        $('.segmento-preco').prop('checked', true);
      } else {
        $('.segmento-preco').prop('checked', false);
      }
    });

    $('.local-todos').prop('checked', true);
    $('.local').prop('checked', true);
    $('.local').change(function () {
      $('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).show();
        $(
          $(
            ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
          ).find('input'),
        ).prop('checked', true);
      } else {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).hide();
        $(
          $(
            ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
          ).find('input'),
        ).prop('checked', false);
      }
    });

    $('.local-todos').change(function () {
      $('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $('.local').prop('checked', true);
        $('.empresas').prop('checked', true);
        $('.box-empresa').show();
      } else {
        $('.local').prop('checked', false);
        $('.empresas').prop('checked', false);
        $('.box-empresa').hide();
      }
    });

    $('.local-todos-cluster').prop('checked', true);
    $('.local-cluster').prop('checked', true);
    $('.local-cluster').change(function () {
      //$('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $(
          ".box-empresa-cluster[data-cidade='" +
            $(this).attr('data-cidade') +
            "']",
        ).show();
        $(
          $(
            ".box-empresa-cluster[data-cidade='" +
              $(this).attr('data-cidade') +
              "']",
          ).find('input'),
        ).prop('checked', false);
      } else {
        $(
          ".box-empresa-cluster[data-cidade='" +
            $(this).attr('data-cidade') +
            "']",
        ).hide();
        $(
          $(
            ".box-empresa-cluster[data-cidade='" +
              $(this).attr('data-cidade') +
              "']",
          ).find('input'),
        ).prop('checked', false);
      }
    });

    $('.local-todos-cluster').change(function () {
      //$('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $('.local-cluster').prop('checked', true);
        $('.empresas-cluster').prop('checked', false);
        $('.box-empresa-cluster').show();
      } else {
        $('.local-cluster').prop('checked', false);
        $('.empresas-cluster').prop('checked', false);
        $('.box-empresa-cluster').hide();
      }
    });

    $('.empresas-filtro-todos').change(function () {
      if (this.checked) {
        $('.empresas-filtro').prop('checked', true);
      } else {
        $('.empresas-filtro').prop('checked', false);
      }
    });

    $('.segmentos-filtro-todos').change(function () {
      if (this.checked) {
        $('.segmentos-filtro').prop('checked', true);
      } else {
        $('.segmentos-filtro').prop('checked', false);
      }
    });

    $('#table-line-todos').change(function () {
      if (this.checked) {
        $('.table-line').prop('checked', true);
      } else {
        $('.table-line').prop('checked', false);
      }
    });
    $('#table-spinner').hide();

    if (importacaoData.length == 0) {
      $('#tblImportacao').hide();
      $('#tblConfig').show();
    } else {
      $('#tblImportacao').show();
      $('#tblConfig').hide();
    }
    if (precosRelatorio.length == 0) {
      $('#tblRelatorio').hide();
    }

    $(document).mousedown(function (event) {
      var $target = $(event.target);

      if (
        !$target.closest('.dropdown-dialog').length &&
        $('.dropdown-dialog').is(':visible')
      ) {
        $('.dropdown-dialog').fadeOut('fast');
      }
    });
  });

  async function BuscaClusters(reset = true) {
    const res = await api.get('/empresa/v1/cluster');

    if (res.data.results != null) {
      res.data.results = res.data.results.filter((obj) => obj.status == 'A');
      for (var a = 0; a < res.data.results.length; a++) {
        res.data.results[a].childs = [];
        const res2 = await api.get(
          '/empresa/v1/clusterEmpresa/' + res.data.results[a].seqcluster,
        );

        if (res2.data.results != null) {
          res.data.results[a].childs = res2.data.results.filter(
            (obj) => obj.status == 'A',
          );
        }

        if (reset) {
          setTimeout(function () {
            $('.box-cluster-empresa').hide();
          }, 1);
        }
      }

      setClusters(res.data.results);
      console.log('---Clusters---');
      console.log(res.data.results);
    }
  }

  async function AddCluster() {
    const res = await api.post('/empresa/v1/cluster_create', {
      seqcluster: currentCluster,
      descricao: $('#txtClusterName').val(),
      status: 'A',
      usuario: '' + token.userName,
    });
    if (res.data.codResult != 1) {
      window.$('#modal-add-cluster').modal('hide');
      ShowAlert('Cluster', res.data.msg);
    }

    BuscaClusters();
  }

  async function AddEmpresaCluster() {
    var empresas = $('input[name=ckbEmpresa2]:checked');

    empresas.each(async function (item) {
      const res = await api.post('/empresa/v1/clusterEmpresa_create', {
        seqcluster: currentCluster,
        nroempresa: $(this).val(),
        status: 'A',
        usuario: '' + token.userName,
      });
      if (res.data.codResult != 1) {
        ShowAlert('Cluster', res.data.msg);
      }
      window.$('#modal-add').modal('hide');
    });

    BuscaClusters(false);
  }

  async function SetClusterSelected() {
    var seqcluster = $('#ddlCluster').val();
    if (seqcluster != 0) {
      var currentClusterEmpresas = clusters.filter(
        (obj) => obj.seqcluster == seqcluster,
      );

      $('.empresas').parent().parent().parent().hide();
      $('.empresas').prop('checked', false);
      $('.local').prop('checked', false);
      $('.local-todos').prop('checked', false);
      for (var i = 0; i < currentClusterEmpresas[0].childs.length; i++) {
        $(
          'input[data-empresaid=' +
            currentClusterEmpresas[0].childs[i].nroempresa +
            ']',
        )
          .parent()
          .parent()
          .parent()
          .show();
        $(
          'input[data-empresaid=' +
            currentClusterEmpresas[0].childs[i].nroempresa +
            ']',
        ).prop('checked', true);
      }
    } else {
      $('.empresas').parent().parent().parent().show();
      $('.empresas').prop('checked', true);
      $('.local').prop('checked', true);
      $('.local-todos').prop('checked', true);
    }
  }

  async function BuscaEmpresas() {
    const res = await api.get('/empresa/listaempresa/');
    var result = null;

    if (res.data.results != null) {
      res.data.results.sort(dynamicSort('cidade'));
      setLocais(distinct(res.data.results, 'cidade'));
      res.data.results.sort(dynamicSort('nroempresa'));
      setEmpresas(res.data.results);
      console.log('---Empresas---');
      console.log(res.data.results);
    }
  }

  async function BuscaEmbalagens() {
    const res = await api.get(
      '/produto/v1/embalagem/' + selectedProdutos[0].seqfamilia,
    );
    var result = null;

    if (res.data.results != null) {
      setEmbalagens(res.data.results);
      console.log('---Embalagens---');
      console.log(res.data.results);
      $('.card-embalagem').show();
      $('.card-relacionados').show();
    }
  }

  async function RemoveProduto(seqproduto) {
    for (var i = selectedProdutos.length - 1; i >= 0; --i) {
      if (selectedProdutos[i].seqproduto == seqproduto) {
        selectedProdutos.splice(i, 1);
      }
    }

    forceUpdate();
    checkComponents();
  }

  async function BuscaProduto(event) {
    var msg = '';
    if (event.key === 'Enter' || event.key == undefined) {
      $('.produto-item').show();
      setImportacaoData([]);
      try {
        var cods = $('#txtCodigoProduto').val().split(',');

        for (var i = 0; i < cods.length; i++) {
          const res = await api.get('/produto/v1/produto/' + cods[i]);

          if (res.data.results.length > 0) {
            await SetSelectedProduto(res.data.results[0]);
            //BuscaEmbalagens();
            //BuscaProdutosRelacionados();
            //setTimeout(function () { $('.embalagens').val('') }, 100)
          } else {
            msg +=
              '<div>Nenhum produto foi localizado para o código: ' +
              cods[i] +
              '</div>';
          }
        }
      } catch (ex) {
        alert('Nenhum produto foi localizado. ');
      }
    }

    if (msg != '') {
      ShowAlert('Atenção', msg);
    }

    BuscaEmbalagens();
    //BuscaProdutosRelacionados();
  }

  async function SetSelectedProduto(p) {
    /*currentProduto = p;
    document.getElementById('txtDescricaoProduto').value = currentProduto.descreduzida;
    console.log(currentProduto);*/

    if (
      selectedProdutos.filter((e) => e.seqproduto === p.seqproduto).length <= 0
    ) {
      const res = await api.get('/produto/v1/embalagem/' + p.seqfamilia);
      var result = null;

      if (res.data.results != null) {
        p.embalagens = res.data.results;
      }

      var c = selectedProdutos;
      c.push(p);
      setSelectedProdutos(c);
      forceUpdate();
    }

    checkComponents();
  }

  function checkComponents() {
    /*if (selectedProdutos.length > 1) {
      $('#ckbTodosSegmentos').prop("checked", true)
      $('#ckbTodosSegmentos').attr('disabled', 'disabled')

      $('#ckbTodasEmbalagens').prop("checked", true)
      $('#ckbTodasEmbalagens').attr('disabled', 'disabled')
    } else {
      $('#ckbTodosSegmentos').prop("checked", false)
      $('#ckbTodosSegmentos').removeAttr('disabled')

      $('#ckbTodasEmbalagens').prop("checked", false)
      $('#ckbTodasEmbalagens').removeAttr('disabled')

      
    }*/
  }

  async function BuscaProdutosRelacionados() {
    const res = await api.get(
      '/produto/v1/produtorelacionado/' +
        document.getElementById('txtCodigoProduto').value,
    );

    if (res.data.results != null) {
      setProdutosRelacionados(res.data.results);
    }
  }

  function FindEmpresa(id) {
    var toReturn = null;
    empresas.forEach(function (item, a) {
      if (parseInt(empresas[a].nroempresa) == parseInt(id)) {
        toReturn = empresas[a].nome_reduzido;
      }
    });

    return toReturn;
  }

  const handleClickUpload = (event) => {
    hiddenFileInput.current.click();
  };

  async function readUploadFile(e) {
    setImportacaoData([]);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        for (var i = 0; i < json.length; i++) {
          json[i].status =
            "<span class='text-orange'><i class='fa fa-clock'></i> Aguardando processamento</span>";
        }
        console.log(json);

        $('#tblImportacao').show();
        $('#btnProcessar').show();

        $('#tblConfig').hide();

        setImportacaoData(json);
        jsonPlanilha = json;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  async function ProcessarPlanilha(method, status) {
    var json = jsonPlanilha;
    var errorCount = 0;
    var successCount = 0;
    var scrollInitial = 0;
    var todosSegmentos = '';
    var todasEmbalagens = '';

    var segmentos = $('input[name=ckbSegmento]');
    segmentos.each(function (i) {
      todosSegmentos += $(segmentos[i]).val() + ',';
    });
    todosSegmentos = todosSegmentos.slice(0, -1);

    $('#btnUpload, #btnDownload').hide();

    for (var i = 0; i < json.length; i++) {
      try {
        if (method == 'Compra') {
          const res = await api.post('/produto/v1/statuscompra/', {
            nroempresa: json[i].nroempresas.toString(),
            status: status,
            seqproduto: json[i].codproduto,
            chapa_usuario: token.userName,
          });

          if (res.data.msg != null) {
            if (res.data.codResult == 1) {
              json[i].status =
                "<span class='text-red'><i class='fa fa-times'></i> " +
                res.data.msg +
                '</span>';
              errorCount += 1;
            } else {
              json[i].status =
                "<span class='text-green'><i class='fa fa-check'></i> " +
                res.data.msg +
                '</span>';
            }
          } else {
            json[i].status =
              "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento.</span>";
            errorCount += 1;
          }
        }

        if (method == 'Venda') {
          if (
            json[i].nrosegmentos.toUpperCase() == 'TODOS' ||
            json[i].nrosegmentos.toUpperCase() == 'TODAS' ||
            json[i].nrosegmentos.toUpperCase() == 'TOTAL'
          ) {
            json[i].nrosegmentos = todosSegmentos;
          }

          if (
            json[i].qtdembalagens.toUpperCase() == 'TODOS' ||
            json[i].qtdembalagens.toUpperCase() == 'TODAS' ||
            json[i].qtdembalagens.toUpperCase() == 'TOTAL'
          ) {
            json[i].qtdembalagens = 'TOTAL';
          }

          const res = await api.post('/produto/v1/statusvenda/', {
            nroempresa: json[i].nroempresas.toString(),
            status: status,
            seqproduto: json[i].codproduto,
            chapa_usuario: token.userName,
            nrosegmento: json[i].nrosegmentos,
            qtdembalagem: json[i].qtdembalagens,
          });

          if (res.data.msg != null) {
            if (res.data.codResult == 1) {
              json[i].status =
                "<span class='text-red'><i class='fa fa-times'></i> " +
                res.data.msg +
                '</span>';
              errorCount += 1;
            } else {
              json[i].status =
                "<span class='text-green'><i class='fa fa-check'></i> " +
                res.data.msg +
                '</span>';
            }
          } else {
            json[i].status =
              "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento.</span>";
            errorCount += 1;
          }
        }
      } catch (ex) {
        json[i].status =
          "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento. (" +
          ex +
          ')</span>';
        errorCount += 1;
      }

      setImportacaoData(json);
      forceUpdate();

      if (
        $('.import-row').eq(i).offset().top >
        $(window).scrollTop() + $(window).height()
      ) {
        $('html, body').animate(
          { scrollTop: $(window).scrollTop() + $(window).height() },
          'slow',
          function () {},
        );
      }

      scrollInitial += 110;
    }
    $('#btnUpload, #btnDownload').show();
    hiddenFileInput.current.value = null;
    $('html, body').animate({ scrollTop: 0 }, 'slow', function () {
      ShowAlert(
        "<i class='fa fa-check'></i> Importação Finalizada",
        'A importação da planilha em excel foi finalizada. <br/><br/><b>Total de registros:</b> ' +
          json.length +
          " <br/><span class='text-success'><b>Processados com sucesso:</b> " +
          successCount +
          "</span> <br/><span class='text-danger'><b>Processados com erro:</b> " +
          errorCount +
          '</span>',
      );
    });
  }

  function AtivarCompra() {
    if (selectedProdutos.length > 0) {
      CallCompra('A');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function InativarCompra() {
    if (selectedProdutos.length > 0) {
      CallCompra('I');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function SuspenderCompra() {
    if (selectedProdutos.length > 0) {
      CallCompra('S');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  async function CallCompra(status) {
    try {
      $('#table-spinner').show();
      var empresasStr = '';
      var empresas = $('input[name=ckbEmpresa]:checked');

      empresas.each(function (i) {
        empresasStr += $(empresas[i]).val() + ',';
      });
      empresasStr = empresasStr.slice(0, -1);

      if (empresasStr == '') {
        alert('Selecione ao menos uma empresa antes de prosseguir.');
        $('#table-spinner').hide();
        return;
      }

      var msg = '';

      for (var i = 0; i < selectedProdutos.length; i++) {
        const res = await api.post('/produto/v1/statuscompra/', {
          nroempresa: empresasStr,
          status: status,
          seqproduto: selectedProdutos[i].seqproduto,
          chapa_usuario: token.userName,
        });

        if (res.data.msg != null) {
          if (res.data.codResult == 0)
            msg +=
              '<div><small><b>' +
              selectedProdutos[i].seqproduto +
              ' - ' +
              selectedProdutos[i].descreduzida +
              "</b>: <span class='text-green'> <i class='fa fa-check mr-1 ml-2'></i>" +
              res.data.msg +
              '</span></small></div>';
          else
            msg +=
              '<div><small><b>' +
              selectedProdutos[i].seqproduto +
              ' - ' +
              selectedProdutos[i].descreduzida +
              "</b>: <span class='text-red'> <i class='fa fa-times mr-1 ml-2'></i>" +
              res.data.msg +
              '</span></small></div>';
        }

        ShowAlert('Atenção', msg);
      }

      ShowAlert('Atenção', msg);

      $('#table-spinner').hide();
    } catch (ex) {
      ShowAlert('Erro', 'Não foi possível completar a ação.');
      $('#table-spinner').hide();
    }
  }

  var currentVendaStatus = '';
  function AtivarVenda() {
    if (selectedProdutos.length == 1) {
      currentVendaStatus = 'A';
      window.$('#modal-embalagens').modal('show');
    } else if (selectedProdutos.length > 1) {
      if (
        window.confirm(
          // 'Ao selecionar múltiplos produtos, todas as embalagens e todos os segmentos serão ativados. Deseja continuar?',
          // 'Ao selecionar múltiplos produtos, todos os segmentos serão ativados e a embalagem padrão será 1 KG. Deseja continuar?',
          'Ao inserir múltiplos produtos, a menor embalagem padrão (1) será ativada em todos os segmentos. Deseja continuar?',
        )
      ) {
        currentVendaStatus = 'A';
        CallVendaAtiva();
      }
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function InativarVenda() {
    if (selectedProdutos.length == 1) {
      currentVendaStatus = 'I';
      window.$('#modal-embalagens').modal('show');
    } else if (selectedProdutos.length > 1) {
      if (
        window.confirm(
          'Ao selecionar múltiplos produtos, todas as embalagens e todos os segmentos serão inativados. Deseja continuar?',
        )
      ) {
        currentVendaStatus = 'I';
        CallVenda();
      }
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  function SuspenderVenda() {
    if (currentProduto != null) {
      currentVendaStatus = 'S';
      window.$('#modal-embalagens').modal('show');
    } else {
      ShowAlert('Atenção', 'Informe o código do produto');
    }
  }

  async function CallVenda() {
    try {
      $('#table-spinner').show();
      var empresasStr = '';
      var empresas = $('input[name=ckbEmpresa]:checked');
      empresas.each(function (i) {
        empresasStr += $(empresas[i]).val() + ',';
      });
      empresasStr = empresasStr.slice(0, -1);
      if (empresasStr == '') {
        alert('Selecione ao menos uma empresa antes de prosseguir.');
        $('#table-spinner').hide();
        return;
      }

      var segmentoStr = '';
      var segmentos = $('input[name=ckbSegmento]:checked');
      segmentos.each(function (i) {
        segmentoStr += $(segmentos[i]).val() + ',';
      });
      segmentoStr = segmentoStr.slice(0, -1);

      var embalagemStr = '';
      var embalagens = $('input[name=ckbEmbalagem]:checked');
      embalagens.each(function (i) {
        embalagemStr += $(embalagens[i]).val() + ',';
      });
      embalagemStr = embalagemStr.slice(0, -1);

      var msg = '';
      for (var i = 0; i < selectedProdutos.length; i++) {
        if (selectedProdutos.length > 1) {
          embalagemStr = '';
          segmentoStr = '';
          for (var b = 0; b < selectedProdutos[i].embalagens.length; b++) {
            embalagemStr +=
              selectedProdutos[i].embalagens[b].qtdembalagem + ',';
          }
          embalagemStr = embalagemStr.slice(0, -1);

          segmentos = $('input[name=ckbSegmento]');
          segmentos.each(function (i) {
            segmentoStr += $(segmentos[i]).val() + ',';
          });
          segmentoStr = segmentoStr.slice(0, -1);
        }

        if (embalagemStr == '') {
          alert('Selecione ao menos uma embalagem antes de prosseguir.');
          $('#table-spinner').hide();
          return;
        }

        if (segmentoStr == '') {
          alert('Selecione ao menos um segmento antes de prosseguir.');
          $('#table-spinner').hide();
          return;
        }

        // if(selectedProdutos.length > 1){
        //   embalagemStr = embalagemStr.split(',')[0];
        // }

        if(selectedProdutos.length > 1 && currentVendaStatus === 'I'){
          embalagemStr = 'TOTAL';
        }
        
        const res = await api.post('/produto/v1/statusvenda/', {
          nroempresa: empresasStr,
          status: currentVendaStatus,
          seqproduto: selectedProdutos[i].seqproduto,
          chapa_usuario: token.userName,
          nrosegmento: segmentoStr,
          qtdembalagem: embalagemStr,
        });

        if (res.data.msg != null) {
          if (res.data.codResult == 0)
            msg +=
              '<div><small><b>' +
              selectedProdutos[i].seqproduto +
              ' - ' +
              selectedProdutos[i].descreduzida +
              "</b>: <span class='text-green'> <i class='fa fa-check mr-1 ml-2'></i>" +
              res.data.msg +
              '</span></small></div>';
          else
            msg +=
              '<div><small><b>' +
              selectedProdutos[i].seqproduto +
              ' - ' +
              selectedProdutos[i].descreduzida +
              "</b>: <span class='text-red'> <i class='fa fa-times mr-1 ml-2'></i>" +
              res.data.msg +
              '</span></small></div>';
        }

        window.$('#modal-embalagens').modal('hide');
        ShowAlert('Atenção', msg);
      }

      $('#table-spinner').hide();
    } catch (ex) {
      window.$('#modal-embalagens').modal('hide');
      ShowAlert('Erro', 'Não foi possível completar a ação.');
      $('#table-spinner').hide();
    }
  }

  async function CallVendaAtiva() {
    try {
      $('#table-spinner').show();
      var empresasStr = '';
      var empresas = $('input[name=ckbEmpresa]:checked');
      empresas.each(function (i) {
        empresasStr += $(empresas[i]).val() + ',';
      });
      empresasStr = empresasStr.slice(0, -1);
      if (empresasStr == '') {
        alert('Selecione ao menos uma empresa antes de prosseguir.');
        $('#table-spinner').hide();
        return;
      }

      var segmentoStr = '';
      var segmentos = $('input[name=ckbSegmento]:checked');
      segmentos.each(function (i) {
        segmentoStr += $(segmentos[i]).val() + ',';
      });
      segmentoStr = segmentoStr.slice(0, -1);

      var embalagemStr = '';
      var embalagens = $('input[name=ckbEmbalagem]:checked');
      embalagens.each(function (i) {
        embalagemStr += $(embalagens[i]).val() + ',';
      });
      embalagemStr = embalagemStr.slice(0, -1);

      var msg = '';
      for (var i = 0; i < selectedProdutos.length; i++) {
        if (selectedProdutos.length > 1) {
          embalagemStr = '';
          segmentoStr = '';
          for (var b = 0; b < selectedProdutos[i].embalagens.length; b++) {
            embalagemStr +=
              selectedProdutos[i].embalagens[b].qtdembalagem + ',';
          }
          embalagemStr = embalagemStr.slice(0, -1);

          segmentos = $('input[name=ckbSegmento]');
          segmentos.each(function (i) {
            segmentoStr += $(segmentos[i]).val() + ',';
          });
          segmentoStr = segmentoStr.slice(0, -1);
        }

        if (embalagemStr == '') {
          alert('Selecione ao menos uma embalagem antes de prosseguir.');
          $('#table-spinner').hide();
          return;
        }

        if (segmentoStr == '') {
          alert('Selecione ao menos um segmento antes de prosseguir.');
          $('#table-spinner').hide();
          return;
        }

        // Ao selecionar multiplos produtos, a primeira embalagens sera selecionada em lote.
        if(selectedProdutos.length > 1){
          embalagemStr = embalagemStr.split(',')[0];
        }
        
        const res = await api.post('/produto/v1/statusvenda/', {
          nroempresa: empresasStr,
          status: currentVendaStatus,
          seqproduto: selectedProdutos[i].seqproduto,
          chapa_usuario: token.userName,
          nrosegmento: segmentoStr,
          qtdembalagem: embalagemStr,
        });

        if (res.data.msg != null) {
          if (res.data.codResult == 0)
            msg +=
              '<div><small><b>' +
              selectedProdutos[i].seqproduto +
              ' - ' +
              selectedProdutos[i].descreduzida +
              "</b>: <span class='text-green'> <i class='fa fa-check mr-1 ml-2'></i>" +
              res.data.msg +
              '</span></small></div>';
          else
            msg +=
              '<div><small><b>' +
              selectedProdutos[i].seqproduto +
              ' - ' +
              selectedProdutos[i].descreduzida +
              "</b>: <span class='text-red'> <i class='fa fa-times mr-1 ml-2'></i>" +
              res.data.msg +
              '</span></small></div>';
        }

        window.$('#modal-embalagens').modal('hide');
        ShowAlert('Atenção', msg);
      }

      $('#table-spinner').hide();
    } catch (ex) {
      window.$('#modal-embalagens').modal('hide');
      ShowAlert('Erro', 'Não foi possível completar a ação.');
      $('#table-spinner').hide();
    }
  }

  function ClearFields() {
    document.getElementById('txtCodigoProduto').value = '';
    document.getElementById('txtDescricaoProduto').value = '';
    setEmbalagens([]);
    $('.card-embalagem').hide();
    $('.card-relacionados').hide();
    currentProduto = null;
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function TodosSegmentosChange() {
    if ($('#ckbTodosSegmentos').prop('checked') == true) {
      $('.segmento-preco').prop('checked', true);
    } else {
      $('.segmento-preco').prop('checked', false);
    }
  }

  async function TodasEmbalagensChange() {
    if ($('#ckbTodasEmbalagens').prop('checked') == true) {
      $('.embalagem').prop('checked', true);
    } else {
      $('.embalagem').prop('checked', false);
    }
  }

  async function Closemodal() {
    window.$('.modal').modal('hide');
  }
  return (
    <Fragment>
      <div className="content-header">
        <h1 style={{ marginLeft: 20 }}>Ativação de Produtos</h1>
      </div>

      <div className="content">
        <div id="box-preco-manual">
          <div className="car d">
            <div className="card-bo dy">
              <div className="row">
                <div className="col-sm-12 col-md-4"></div>

                <div className="col-sm-12 col-md-8"></div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-gr oup">
                            <label>Código do Produto [Enter]</label>
                            <div>
                              <i>
                                <small>
                                  Informe os códigos separados por vírgula para
                                  buscar múltiplos produtos
                                </small>
                              </i>
                            </div>

                            <div className="input-group mt-2 mb-3">
                              <input
                                id="txtCodigoProduto"
                                className="form-control m t-2"
                                onKeyUp={(event) => BuscaProduto(event)}
                                onBlur={(event) => BuscaProduto(event)}
                                placeholder="Entre com o código do produto"
                              />

                              <div className="input-group-append">
                                <div className="input-group-text btn">
                                  <i className="fa fa-search"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <ul
                            id="listaDeProdutos"
                            className="list-group produto-item"
                          >
                            <li className="list-group-item d-flex justify-content-between align-items-cente text-bold">
                              <div>Produtos Selecionados</div>
                            </li>

                            <li className="list-group-item">
                              <div className="row mb-0">
                                <div className="col-md-6 text-sm">
                                  <label className="font-weight-normal">
                                    <input
                                      id="ckbTodosSegmentos"
                                      type="checkbox"
                                      onChange={() => {
                                        TodosSegmentosChange();
                                      }}
                                    />{' '}
                                    Todos Segmentos
                                  </label>
                                </div>

                                <div className="col-md-6 text-sm">
                                  <label className="font-weight-normal">
                                    <input
                                      id="ckbTodasEmbalagens"
                                      type="checkbox"
                                      onChange={() => {
                                        TodasEmbalagensChange();
                                      }}
                                    />{' '}
                                    Todas Embalagens
                                  </label>
                                </div>
                              </div>
                            </li>
                            {selectedProdutos.map((item, index) => (
                              <li
                                id={'prod' + item.seqproduto}
                                key={index}
                                className="list-group-item d-flex justify-content-between align-items-center"
                              >
                                <div>
                                  <i className="fa fa-check mr-3 text-green"></i>{' '}
                                  {item.seqproduto + ' - ' + item.descreduzida}G
                                </div>
                                <button
                                  className="badge badge-danger badge-pill btn-danger btn"
                                  onClick={() => {
                                    RemoveProduto(item.seqproduto);
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Importação</label>
                            <div className="mb-3">
                              <i>
                                <small>
                                  Use esta ferramenta para fazer o envio de
                                  múltiplos produtos, empresas, segmentos e
                                  embalagens via planilha.
                                </small>
                              </i>
                            </div>
                            <form>
                              <a
                                id="btnDownload"
                                href="../../../apoio/Modelo_Ativacao_Produto.xlsx"
                                target="_blank"
                                download
                                className="btn btn-primary mr-3"
                              >
                                <span className="fa fa-file-download fa-fw"></span>{' '}
                                Baixar Modelo
                              </a>
                              <div
                                id="btnUpload"
                                className="btn btn-primary"
                                onClick={handleClickUpload}
                              >
                                <span className="fa fa-file-upload fa-fw"></span>{' '}
                                Enviar Planilha
                              </div>
                              <input
                                type="file"
                                name="upload"
                                id="upload"
                                ref={hiddenFileInput}
                                onChange={readUploadFile}
                                style={{ display: 'none' }}
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="card">
                    <div className="card-body">
                      <div
                        id="table-spinner"
                        className="mb-3"
                        style={{
                          textAlign: 'center',
                          textAlign: 'center',
                          position: 'absolute',
                          zIndex: '9',
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(255,255,255,.5)',
                          paddingTop: '110px',
                        }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>

                      <div id="tblImportacao" className="mt-3">
                        <div className="alert alert-dark">
                          <div className="row">
                            <div className="col-1 text-center">
                              <i className="fa fa-clock fa-5x"></i>
                            </div>
                            <div className="col-10">
                              <h4 className="alert-heading">Atenção</h4>
                              <p className="mb-0">
                                Não feche esta janela até que todos os registros
                                tenham sido concluídos.
                              </p>
                              <p>
                                <small>
                                  Acompanhe a evolução do processamento na
                                  coluna <b>Status</b>.
                                </small>
                              </p>
                              <input
                                type="button"
                                value="Ativar Compra"
                                className="btn btn-primary mt-3 mr-3"
                                onClick={() => {
                                  ProcessarPlanilha('Compra', 'A');
                                }}
                              />
                              <input
                                type="button"
                                value="Inativar Compra"
                                className="btn btn-danger mt-3 mr-3"
                                onClick={() => {
                                  ProcessarPlanilha('Compra', 'I');
                                }}
                              />
                              <input
                                type="button"
                                value="Suspender Compra"
                                className="btn btn-info mt-3 mr-3"
                                onClick={() => {
                                  ProcessarPlanilha('Compra', 'S');
                                }}
                              />
                              <input
                                type="button"
                                value="Ativar Venda"
                                className="btn btn-primary mt-3 mr-3"
                                onClick={() => {
                                  ProcessarPlanilha('Venda', 'A');
                                }}
                              />
                              <input
                                type="button"
                                value="Inativar Venda"
                                className="btn btn-danger mt-3 mr-3"
                                onClick={() => {
                                  ProcessarPlanilha('Venda', 'I');
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <table className="table table-striped table-hover tabl e-sm table-bordered">
                          <thead className="">
                            <tr>
                              <th scope="col">Produto</th>
                              <th scope="col">Empresas</th>
                              <th scope="col">Segmentos</th>
                              <th scope="col">Embalagens</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>

                          <tbody id="table-body">
                            {importacaoData.map((item, index) => (
                              <tr className="import-row" key={index}>
                                <td>{item.codproduto}</td>
                                <td>{item.nroempresas}</td>
                                <td>{item.nrosegmentos}</td>
                                <td>{item.qtdembalagens}</td>

                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: item.status,
                                  }}
                                ></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div id="tblConfig">
                        <h4 className="text-md">Empresas</h4>

                        <hr />

                        <div className="row">
                          <div className="col-sm-4 col-md-3">
                            <select
                              id="ddlCluster"
                              className="form-control mb-3"
                              onChange={() => {
                                SetClusterSelected();
                              }}
                            >
                              <option value="0">Selecione um cluster</option>
                              {clusters.map((item, index) => (
                                <option key={index} value={item.seqcluster}>
                                  {item.descricao}
                                </option>
                              ))}
                            </select>
                            <div id="boxLocais" className="row">
                              <div className="col-sm-12">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      className="local-todos"
                                      type="checkbox"
                                    />
                                    &nbsp; Marcar Todos
                                  </label>
                                </div>
                              </div>
                              {locais.map((item, index) => (
                                <div className="col-sm-12" key={index}>
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        className="local"
                                        data-cidade={item}
                                        value={item}
                                        type="checkbox"
                                      />
                                      &nbsp;&nbsp;
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="col-sm-8 col-md-9">
                            <div
                              id="boxEmpresas"
                              className="row bg-gray-light p-3 rounded"
                            >
                              <div
                                style={{
                                  display: 'block',
                                  width: '100%',
                                  'margin-left': '7px',
                                  marginBottom: '10px',
                                }}
                              >
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      name="ckbEmpInvert"
                                      className="form-check-input empresa-inverter"
                                      type="checkbox"
                                    />
                                    Inverter Seleção
                                  </label>
                                </div>
                              </div>

                              {empresas.map((item, index) => (
                                <div
                                  className="col-sm-6 col-md-4 box-empresa"
                                  data-cidade={item.cidade}
                                  key={index}
                                >
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        name="ckbEmpresa"
                                        className="form-check-input empresas"
                                        data-cidade={item.cidade}
                                        data-empresaid={item.nroempresa}
                                        value={item.nroempresa}
                                        type="checkbox"
                                      />
                                      {item.nome_reduzido}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 text-center">
                            <input
                              type="button"
                              value="Ativar Compra"
                              className="btn btn-primary mt-3 mr-3"
                              onClick={() => {
                                AtivarCompra();
                              }}
                            />
                            <input
                              type="button"
                              value="Inativar Compra"
                              className="btn btn-danger mt-3 mr-3"
                              onClick={() => {
                                InativarCompra();
                              }}
                            />
                            <input
                              type="button"
                              value="Suspender Compra"
                              className="btn btn-info mt-3 mr-3"
                              onClick={() => {
                                SuspenderCompra();
                              }}
                            />
                            <input
                              type="button"
                              value="Ativar Venda"
                              className="btn btn-primary mt-3 mr-3"
                              onClick={() => {
                                AtivarVenda();
                              }}
                            />
                            <input
                              type="button"
                              value="Inativar Venda"
                              className="btn btn-danger mt-3 mr-3"
                              onClick={() => {
                                InativarVenda();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-alert" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-embalagens" className="modal " role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content " style={{ width: 900 }}>
            <div className="modal-header">
              <h5 className="modal-title">
                Selecione os segmentos e as embalagens
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="text-md">Segmentos</h4>
                        <hr />
                        <div id="boxSegmentos" className="row">
                          <div className="col-sm-12">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  name="ckbSegTodos"
                                  className="form-check-input segmento-todos"
                                  type="checkbox"
                                />
                                Marcar Todos
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-3">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  name="ckbSegInvert"
                                  className="form-check-input segmento-inverter"
                                  type="checkbox"
                                />
                                Inverter Seleção
                              </label>
                            </div>
                          </div>
                          {segmentos.map((item, index) => (
                            <div className="col-sm-12" key={index}>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="ckbSegmento"
                                    className="form-check-input segmento-preco"
                                    value={item.nrosegmento}
                                    type="checkbox"
                                  />
                                  {item.descsegmento}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-9">
                    <h4 className="text-md">Embalagens</h4>
                    <div
                      id="boxEmbalagem"
                      className="row bg-gray-light p-3 rounded"
                    >
                      {embalagens.map((item, index) => (
                        <div
                          className="col-sm-3 box-empresa-cluster"
                          key={index}
                        >
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                name="ckbEmbalagem"
                                className="form-check-input embalagem"
                                value={item.qtdembalagem}
                                type="checkbox"
                              />
                              {item.embalagem + ' - ' + item.qtdembalagem}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  CallVenda();
                }}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-add-cluster" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Adicionar novo cluster</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <input
                  type="text"
                  id="txtClusterName"
                  className="form-control"
                  placeholder="Digite o nome do cluster"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  AddCluster();
                }}
              >
                Incluir
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AtivarProduto;
