import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";

export function RefaturamentoLoteTab({ cds, onSearchRebilling, leavingLotesRebilling, onRebillingLote }) {
  return (
    <div className="card">
      <div
        id="spinner-nf"
        className="mb-3"
        style={{
          textAlign: 'center',
          position: 'absolute',
          zIndex: '9',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,.7)',
          paddingTop: '70px',
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
      <div className="card-body">
        <div className="row bg-gray-light rounded p-3 mb-3">
          <div className="col-sm-12 col-md-3">
            <div className="form-group">
              <label>Centro de Distribuição</label>
              <select id="cdRefaturamento" className="form-control">
                {cds.map((item, index) => (
                  <option key={index} value={item.numero_empresa}>
                    {item.nome_reduzido}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-sm-12 col-md-3">
            <div className="form-group">
              <label>Lote</label>
              <input
                id="txtLoteRefaturamento"
                type="text"
                className="form-control"
                placeholder="Digite o número do lote"
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-3">
            <div className="form-group">
              <label>&nbsp;</label>
              <div>
                <button
                  id="btnBuscarRefaturamento"
                  className="btn btn-primary"
                  onClick={onSearchRebilling}
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            'row bg-gr ay-light rounded p-3 mb-3 ' +
            (leavingLotesRebilling.length == 0 ? '' : 'd-none')
          }
        >
          <div className="col-sm-12 col-md-12 text-center">
            <h4>
              <i className="fa fa-info-circle fa-fw"></i> Nenhum
              lote selecionado
            </h4>
            Informe o número do lote e clique em buscar para
            visualizar os registros.
          </div>
        </div>
        <div
          className={
            'row bg-gray-light rounded p-3 mb-3 ' +
            (leavingLotesRebilling.length == 0 ? 'd-none' : '')
          }
        >
          <div
            className="col-sm-12 col-md-12"
            style={{
              maxHeight: 'calc(100vh - 670px)',
              overflow: 'auto',
              minHeight: '200px',
            }}
          >
            <label>Informações do Lote</label>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Sequência Lote</th>
                  <th>Número NF</th>
                  <th>Fornecedor</th>
                  <th>Produto</th>
                  <th>Quantidade</th>
                  <th>Embalagem</th>
                  <th>Valor</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {leavingLotesRebilling.map((item, index) => (
                  <tr key={index}>
                    <td>{item.sequencia_lote}</td>
                    <td>
                      {item.numero_nota_fiscal +
                        '-' +
                        item.serie_nota_fiscal}
                    </td>
                    <td>
                      {item.sequencia_fornecedor +
                        ' - ' +
                        item.descricao_fornecedor}
                    </td>
                    <td>
                      {item.sequencia_produto +
                        ' - ' +
                        item.descricao_produto}
                    </td>
                    <td>{item.quantidade}</td>
                    <td>{item.embalagem}</td>
                    <td>
                      R$
                      {parseFloat(
                        item.valor.toString().replace(',', '.'),
                      )
                        .toFixed(2)
                        .toString()
                        .replace('.', ',')}
                    </td>
                    <td>
                      {moment(
                        item.data_entrada,
                        'MM/DD/yyyy',
                      ).format('DD/MM/yyyy')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={
            'row bg-gray-light rounded p-3 mb-3 ' +
            (leavingLotesRebilling.length == 0 ? 'd-none' : '')
          }
        >
          <div
            className="col-sm-12 col-md-12"
            style={{
              maxHeight: 'calc(100vh - 670px)',
              overflow: 'auto',
              minHeight: '250px',
            }}
          >
            <label>Informações de saída</label>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Sequência Cliente</th>
                  <th>Número do Pedido</th>
                  <th>Quantidade</th>
                  <th>Embalagem</th>
                  <th>Valor</th>
                  <th>Data Movimentação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {leavingLotesRebilling.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox ckbRefaturamento"
                        value={item.sequencia_lista}
                        data-index={index}
                        data-select={'ddlEstoqueLote' + index}
                      />
                    </td>
                    <td>{item.sequencia_cliente}</td>
                    <td>{item.numero_pedido}</td>
                    <td>{item.quantidade}</td>
                    <td>{item.embalagem}</td>
                    <td>
                      R$
                      {parseFloat(
                        item.valor.toString().replace(',', '.'),
                      )
                        .toFixed(2)
                        .toString()
                        .replace('.', ',')}
                    </td>
                    <td>
                      {moment(
                        item.data_movimentacao,
                        'MM/DD/yyyy',
                      ).format('DD/MM/yyyy')}
                    </td>
                    <td>
                      <select
                        id={'ddlEstoqueLote' + index}
                        data-pedido={item.numero_pedido}
                        className={
                          'form-control form-control-sm ' +
                          (leavingLotesRebilling[index].estoqueLote
                            .length == 0
                            ? 'd-none'
                            : '')
                        }
                      >
                        <option value="-1">Estoque Lote</option>
                        {leavingLotesRebilling[
                          index
                        ].estoqueLote.map((item2, index2) => (
                          <option key={index2} value={item2.lote}>
                            {item2.numeronf +
                              ' - ' +
                              moment(
                                item2.dtaentrada,
                                'yyyy-MM-DD',
                              ).format('DD/MM/yyyy') +
                              ' - ' +
                              item2.lote}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-primary"
              onClick={onRebillingLote}
            >
              Refaturar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
