import { useRef, useCallback, useState } from 'react';
import $ from 'jquery';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Fragment } from 'react';

import obaLogo from '../../assets/oba.png';

var currentEmpresaId = null;
function ShowAlert(title, message) {
  $('#modal-alert .modal-title').html(title);
  $('#modal-alert .modal-body').html(
    '<i className="fa fa-info-circle fa-fw text-gray"></i> ' + message,
  );
  window.$('#modal-alert').modal('show');
}

export function UserTab({ cdList, empresas, locais }) {
  const [currentEmpresaNome, setCurrentEmpresaNome] = useState(String);
  const [currentUsuario, setCurrentUsuario] = useState(String);
  const [currentEmpresa, setCurrentEmpresa] = useState({});
  const [currentProduto, setCurrentProduto] = useState({});
  const [currentLote, setCurrentLote] = useState({});
  const [lotes, setLotes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [listas, setListas] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [pdfFormatado, setPdfFormatado] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const hiddenFileInput = useRef(null);

  async function SetEmpresaSelecionada(name) {
    currentEmpresaId = [$(name).val()];
    setCurrentEmpresa(empresas[$(name).attr('data-key')]);
    $('.btnEmpresa').removeClass('active-conferencia');
    $(name).addClass('active-conferencia');
  }

  async function FiltrarEmpresas() {
    $('.btnEmpresa').hide();
    $(
      ".btnEmpresa:containsi('" + $('#txtFiltraEmpresa').val() + "')",
    ).slideDown('fast');
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function LoteLinhaClicked(btn) {
    setCurrentLote(lotes[$('#' + btn).attr('data-key')]);

    if (lotes[$('#' + btn).attr('data-key')].quantidade_atendida > 0) {
      $('#txtQtd').val(
        lotes[$('#' + btn).attr('data-key')].quantidade_atendida,
      );
    } else {
      $('#txtQtd').val('');
    }

    $('.lote-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');
    window.$('#modal-lote').modal('show');

    setTimeout(function () {
      $('#txtQtd').focus();
      $('#txtQtd').select();
    }, 1000);
  }

  $.extend($.expr[':'], {
    containsi: function (elem, i, match, array) {
      return (
        (elem.textContent || elem.innerText || '')
          .toLowerCase()
          .indexOf((match[3] || '').toLowerCase()) >= 0
      );
    },
  });

  // async function CloseModal() {
  //   window.$('.modal').modal('hide');
  // }

  async function BuscaLote(nroempresa, seqproduto, seqpessoa, seqlista) {
    try {
      const res = await api.get(
        '/listaseparacao/busca_lote_conferencia/' +
          nroempresa +
          '/' +
          seqproduto +
          '/' +
          seqpessoa,
      );

      if (res.data.results != null) {
        const res2 = await api.post('/listaseparacao/produtoconferido/', {
          numero_empresa: nroempresa,
          sequencia_lista: seqlista,
          sequencia_produto: seqproduto,
          sequencia_pessoa: seqpessoa,
          status: 'P,C,L',
        });

        if (res2.data != null) {
          for (var i = 0; i < res.data.results.length; i++) {
            res.data.results[i].quantidade_conferida = 0;
            res.data.results[i].temp = 0;
            for (var a = 0; a < res2.data.length; a++) {
              if (
                res.data.results[i].sequencia_lote ==
                res2.data[a].sequencia_lote
              ) {
                if (res2.data[a].sequencia_pessoa != currentEmpresa.seqpessoa) {
                  res.data.results[i].quantidade_conferida +=
                    res2.data[a].quantidade_conferida;
                } else {
                  res.data.results[i].temp += res2.data[a].quantidade_conferida;
                  res.data.results[i].quantidade_atendida =
                    res2.data[a].quantidade_atendida;
                }
              }
            }
            if (
              res.data.results[i].saldo -
                res.data.results[i].quantidade_conferida <=
              0
            ) {
              delete res.data.results[i];
            }
          }
        }

        setLotes(res.data.results);
        console.log('---Lotes---');
        console.log(res.data.results);
        console.log('---Produtos Conferidos---');
        console.log(res2.data);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function GerarPDF() {
    try {
      var tempLoteSize = 0;
      if ($('#txtUsuario').val() != '') {
        setCurrentUsuario($('#txtUsuario').val());
        if (currentEmpresa.nroempresa != null) {
          const res = await api.get(
            '/listaseparacao/v1/contingencia_pdf/' +
              $('#ddlEmpresa').val() +
              '/' +
              $('#txtUsuario').val() +
              '/' +
              currentEmpresa.seqpessoa,
          );

          if (res.data.results != null) {
            if (res.data.results.length > 0) {
              setPdf(res.data.results);
              setListas(distinct(res.data.results, 'seqlista'));
              var obj = [];
              for (var i = 0; i < res.data.results.length; i++) {
                setCurrentEmpresaNome(res.data.results[i].fantasia);
                if (
                  obj.filter(
                    (e) => e.seqproduto === res.data.results[i].seqproduto,
                  ).length <= 0
                ) {
                  res.data.results[i].lotes = [];
                  obj.push(res.data.results[i]);
                }
                for (var a = 0; a < obj.length; a++) {
                  if (obj[a].seqproduto == res.data.results[i].seqproduto) {
                    obj[a].lotes.push(res.data.results[i]);
                  }
                  if (obj[a].lotes.length > tempLoteSize) {
                    tempLoteSize = obj[a].lotes.length;
                  }
                }
              }
              for (var a = 0; a < obj.length; a++) {
                for (var b = obj[a].lotes.length; b < tempLoteSize; b++) {
                  obj[a].lotes.push({});
                }
              }

              setPdfFormatado(obj);
            } else {
              toast.error('Nenhum resultado foi localizado.');
            }
          }
        } else {
          ShowAlert('Atenção', 'Selecione uma empresa antes de gerar o PDF.');
        }
      } else {
        ShowAlert('Atenção', 'Informe o código do usuário.');
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function printDiv() {
    var divToPrint = document.getElementById('PDF');

    var mywindow = window.open('', 'Print-Window');
    mywindow.document.open();
    mywindow.document.write('<html ><head><title></title>');
    mywindow.document.write(
      '<link rel="stylesheet" href="/dist/css/adminlte.min.css" type="text/css" />',
    );
    mywindow.document.write(
      '<style type="text/css" media="print"> @page { size: landscape;} body { writing mode: tb-rl; min-height: auto !important;}</style >',
    );
    mywindow.document.write('</head><body onload="window.print()">');
    mywindow.document.write(divToPrint.innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close();
    setTimeout(function () {
      mywindow.close();
    }, 100);
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card" style={{ overflow: 'hidden' }}>
          <div
            className="card-body py-2"
            style={{ height: '80vh', overflow: 'hidden' }}
          >
            <div className="row">
              <div className="col-3">
                <div className="mb-2">
                  <label>Código do Usuário</label>
                  <input
                    id="txtUsuario"
                    type="text"
                    className="form-control"
                    placeholder="Código"
                  />
                </div>

                <div>
                  <label>Centro de Distribuição</label>
                  <select id="ddlEmpresa" className="form-control">
                    {cdList.map((item, index) => (
                      <option key={index} value={item.numero_empresa}>
                        {item.nome_reduzido}
                      </option>
                    ))}
                  </select>
                </div>

                <div id="boxEmpresaDestino" className="card mt-2">
                  <div className="card-body pt-1" style={{ height: '350px' }}>
                    <label className="mt-0 pb-0">Empresas</label>
                    <input
                      type="search"
                      id="txtFiltraEmpresa"
                      className="form-control  ml-0 mb-2"
                      placeholder="Procurar empresa"
                      onChange={() => {
                        FiltrarEmpresas();
                      }}
                    />

                    <div className="row">
                      <div className="col-sm-12">
                        <div
                          id="boxcd"
                          className="row bg-gray-light "
                          style={{
                            overflow: 'auto',
                            height: '270px',
                            border: '1px solid #f1f1f1',
                            alignContent: 'baseline',
                          }}
                        >
                          {empresas.map((item, index) => (
                            <div
                              id={'btnEmpresa' + index}
                              className="btnEmpresa"
                              data-seqpessoa={item.seqpessoa}
                              data-cidade={item.cidade}
                              data-key={index}
                              data-id={item.nroempresa}
                              key={index}
                              onClick={() => {
                                SetEmpresaSelecionada('#btnEmpresa' + index);
                              }}
                            >
                              <div>{item.nome_reduzido}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2 mb-2">
                  <button
                    className="btn btn-success btn-block"
                    onClick={() => GerarPDF()}
                  >
                    <i className="fa fa-file-pdf mr-1"></i> Gerar PDF
                  </button>
                </div>
              </div>

              <div className="col-sm-9">
                <div id="boxProdutos">
                  <div className="card" style={{ overflow: 'hidden' }}>
                    <div
                      className="card-body p-0"
                      style={{ height: '706px', overflow: 'auto' }}
                    >
                      <div
                        id="no-products"
                        className={
                          'text-center pt-5 ' +
                          (pdfFormatado.length == 0 ? 'd-block' : 'd-none')
                        }
                        style={{ marginTop: '25vh' }}
                      >
                        <h4>
                          <i className="fa fa-info-circle fa-fw"></i> Mapa de
                          Separação
                        </h4>
                        Selecione um CD e uma empresa para gerar o PDF de Mapa
                        de Separação.
                      </div>
                      <div
                        id="tblProdutos"
                        className={
                          pdfFormatado.length == 0 ? 'd-none' : 'd-block'
                        }
                      >
                        <div
                          style={{
                            height: 'calc(100% - 55px)',
                            overflow: 'auto',
                          }}
                        >
                          <nav className="navbar navbar-expand-lg navbar-light bg-gray">
                            <a className="navbar-brand ml-3" href="#">
                              <i className="fa fa-file-pdf mr-3"></i> PDF - Mapa
                              Separação
                            </a>
                            <button
                              className="navbar-toggler"
                              type="button"
                              data-toggle="collapse"
                              data-target="#navbarSupportedContent"
                              aria-controls="navbarSupportedContent"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              <span className="navbar-toggler-icon"></span>
                            </button>

                            <div
                              className="collapse navbar-collapse"
                              id="navbarSupportedContent"
                              style={{
                                position: 'absolute',
                                right: 0,
                              }}
                            >
                              <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                  <a
                                    className="nav-link mr-3 text-white"
                                    href="#"
                                    onClick={() => {
                                      printDiv();
                                    }}
                                  >
                                    <i className="fa fa-print mr-1"></i>{' '}
                                    Imprimir
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </nav>

                          <div id="PDF" style={{ border: '1px solid #ccc' }}>
                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              className="table table-border table-sm"
                              style={{ border: '1px solid #ccc' }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="100"
                                    height="50px"
                                    valign="middle"
                                    className="text-bold"
                                  >
                                    <div className="pt-2">
                                      <img
                                        height="30px"
                                        src={obaLogo}
                                        alt="Logo Oba Hortifruti"
                                      />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Listas:
                                      {listas.map((item, index) => (
                                        <span key={index}>
                                          {' '}
                                          {item}
                                          {index < listas.length - 1
                                            ? ','
                                            : ''}{' '}
                                        </span>
                                      ))}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      Cliente: {currentEmpresaNome}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      Conferente: {currentUsuario}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="100"
                                    style={{
                                      height: '20px',
                                      overflow: 'hidden',
                                      padding: '0px',
                                      borderTop: 'none',
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: '100%',
                                        height: '20px',
                                      }}
                                      src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP88h8AAu0B9XNPCQQAAAAASUVORK5CYII="
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td width="80px" className="text-bold">
                                    Código
                                  </td>
                                  <td width="250px" className="text-bold">
                                    Descrição
                                  </td>
                                  <td colSpan="100" className="text-bold">
                                    Lote - Quantidade
                                  </td>
                                </tr>
                                {pdfFormatado.map((item, index) => (
                                  <Fragment key={index}>
                                    <tr>
                                      <td className="">{item.seqproduto}</td>
                                      <td>{item.descricao}</td>
                                      {pdfFormatado[index].lotes.map(
                                        (item2, index2) => (
                                          <td
                                            width="120px"
                                            key={index2}
                                            style={{ padding: '0' }}
                                          >
                                            <div
                                              style={{
                                                border: '1px solid #000000',
                                                padding: '4px 3px 3px 3px',
                                                height: '33px',
                                                marginBottom: '-1px',
                                                marginLeft: '-1px',
                                              }}
                                            >
                                              {item2.loteQuantidade}
                                              &nbsp;
                                            </div>
                                          </td>
                                        ),
                                      )}
                                      <td
                                        colSpan="100"
                                        style={{
                                          border: '1px solid #f1f1f1',
                                        }}
                                      ></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>Conferido</td>
                                      {pdfFormatado[index].lotes.map(
                                        (item2, index2) => (
                                          <td
                                            width="120px"
                                            style={{ padding: '0' }}
                                            key={index2}
                                          >
                                            <div
                                              style={{
                                                border: '1px solid #000000',
                                                padding: '4px 3px 3px 3px',
                                                height: '33px',
                                                marginBottom: '1px',
                                                marginLeft: '-1px',
                                              }}
                                            >
                                              {item2.qtdconferida}
                                              &nbsp;
                                            </div>
                                          </td>
                                        ),
                                      )}
                                      <td
                                        colSpan="100"
                                        style={{
                                          border: '1px solid #f1f1f1',
                                        }}
                                      ></td>
                                    </tr>

                                    <tr style={{ padding: '0px' }}>
                                      <td
                                        colSpan="100"
                                        border="0"
                                        style={{
                                          height: '20px',
                                          overflow: 'hidden',
                                          padding: '0px',
                                          borderTop: 'none',
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: '100%',
                                            height: '20px',
                                          }}
                                          src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP88h8AAu0B9XNPCQQAAAAASUVORK5CYII="
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
