import { useState } from "react"
import { useLoading } from "../../hooks/useLoading"
import { toast } from "react-toastify"
import api from "../../services/api"
import { Spinner } from "react-bootstrap"
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min"

export function RegisterSupplierModal({ supplier }) {
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')

  const loading = useLoading()

  async function handleRegisterSupplier() {
    try {
      if (!userName || !email) return toast.error('Usuário e E-mail são obrigatórios.')

      loading.setLoading(true)

      const data = {
        email,
        cpf_cnpj: supplier.cpf_cnpj,
        usuario: userName,
        empresa: supplier.fantasia,
        seqfornecedor: supplier.seqfornecedor,
      }

      const response = await api.post(`/v1/cadastra_fornecedor_api/`, data)

      if (response.request.status === 400) {
        const jsonResponse = JSON.parse(response.request.response)
        const errorMessage = jsonResponse.error
        toast.error(errorMessage)
        loading.setLoading(false)
        return
      }

      const successMessage = response.data.success
      await toast.success(successMessage)

      setUserName('')
      setEmail('')
      loading.setLoading(false)

      const modal = document.getElementById('registerUser')
      const modalInstance = bootstrapBundleMin.Modal.getInstance(modal);
      modalInstance.hide();

    } catch (err) {
      loading.setLoading(false)

      if (err.response) {
        console.log(err.response.data.error);
        toast.error(err.response.data.error)
        return
      }

      console.log(err.message);
      toast.error(err.message)
    }
  }

  return (
    <div
      className="modal fade"
      id="registerUser"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Novo Cadastro</h1>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
              X
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Usuário:</label>
                  <input
                    className="form-control"
                    placeholder="Usuário para acesso"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                  />
                </div>
              </div>

              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">E-mail:</label>
                  <input
                    id="txtSeqPessoa"
                    className="form-control"
                    placeholder="E-mail do fornecedor"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleRegisterSupplier}
              disabled={loading.isLoading}
            >
              {loading.isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'Salvar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
