import { Fragment, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-masked-input';

import $ from 'jquery';
import { Input } from '@material-ui/core';
import { toast } from 'react-toastify';

import { useLoading } from '../hooks/useLoading';
import api from '../services/api';
import { Spinner } from 'react-bootstrap';

const token = JSON.parse(localStorage.getItem('token'));

function Entrada() {
  const [listCD, setListCD] = useState([]);
  const [valor, setValor] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [observacao, setObservacao] = useState('');
  const [embalagens, setEmbalagens] = useState([]);
  const [searchCD, setSearchCD] = useState(null);
  const [searchQtd, setSearchQtd] = useState(null);
  const [seqfornecedor, setSeqfornecedor] = useState('');
  const [notnullCnpj, setNotnullcnpj] = useState('');
  const [seqproduto, setSeqproduto] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const loading = useLoading();

  var currentProduto = null;
  var currentCnpj = null;

  useEffect(() => {
    try {
      async function fetchData() {
        const res = await api.get('/empresa/centrodistribuicao/');
        setListCD(res.data.results);

        $('#txtDescricaoProduto').hide();
        $('#txtDescricaoCNPJ').hide();
      }

      fetchData();
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }, []);

  function SetProduto(p) {
    currentProduto = p;

    $('#txtDescricaoProduto').html(
      "<i class='fa fa-check fa-fw text-green'></i> " +
        currentProduto.descreduzida,
    );
    $('#txtDescricaoProduto').show();
    // console.log(currentProduto);
  }

  function SetCnpj(j) {
    currentCnpj = j;
    $('#txtDescricaoCNPJ').html(
      "<i class='fa fa-check fa-fw  text-green'></i> " + currentCnpj.fantasia,
    );
    $('#txtDescricaoCNPJ').show();
    // console.log(currentCnpj);

    setNotnullcnpj(currentCnpj);

    var b = null;
    b = currentCnpj.seqfornecedor;
    setSeqfornecedor(b);
  }

  async function BuscaEmbalagens() {
    try {
      const res = await api.get(
        '/produto/v1/embalagem/' + currentProduto.seqfamilia,
      );

      if (res.data.results != null) {
        setEmbalagens(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function BuscaProduto(event) {
    var temp = null;
    try {
      if (event.key === 'Enter' || event === '') {
        const produto = await api.get(
          '/produto/v1/produto/' +
            document.getElementById('txtCodigoProduto').value,
        );
        temp = document.getElementById('txtCodigoProduto').value;
        setSeqproduto(temp);

        if (produto.data.results != null) {
          SetProduto(produto.data.results[0]);
          BuscaEmbalagens();
          setTimeout(function () {
            $('.embalagens').val('');
          }, 100);
        }
      }
    } catch (err) {
      console.log(err);
      //ClearFields();
      $('#txtDescricaoProduto').show();

      $('#txtDescricaoProduto').html(
        "<i class='fa fa-times fa-fw text-red'></i> Nenhum produto foi localizado",
      );
      //alert('Nenhum produto foi localizado. ');
    }
  }

  async function BuscaCnpj(event) {
    //event.preventDefault();
    try {
      if (event.key === 'Enter') {
        var seqpessoa = 0;
        if ($('#txtSeqPessoa').val() !== '')
          seqpessoa = $('#txtSeqPessoa').val();

        console.log($('#txtSeqPessoa').val());

        const cnpjInput = document.getElementById('txtCodigoCNPJ');
        if (cnpjInput.value === '') {
          setSeqfornecedor('');
          return toast.warning('CNPJ / CPF obrigatório');
        }
        console.log(cnpjInput.value);

        const cnpj = await api.get(
          '/pessoa/fornecedor/' +
            seqpessoa +
            '/' +
            document.getElementById('txtCodigoCNPJ').value,
        );
        console.log(cnpj.data.results[0]);

        if (!cnpj.data.result) {
          setSeqfornecedor('');
        }

        if (cnpj.data.results != null) {
          SetCnpj(cnpj.data.results[0]);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error('Nenhum CNPJ foi localizado');
      //ClearFields();
      //$('#txtDescricaoCNPJ').hide()
      //alert('Nenhum CNPJ foi localizado. ');
      $('#txtDescricaoCNPJ').show();

      $('#txtDescricaoCNPJ').html(
        "<i class='fa fa-times fa-fw text-red'></i> Nenhum CNPJ foi localizado",
      );
    }
  }

  async function buscaSeqFornecedor(event) {
    //event.preventDefault();

    try {
      if (event.key === 'Enter' || event === '') {
        const result = await api.get(
          `/pessoa/fornecedor/${event.target.value}/0`,
        );

        console.log(result);

        if (result.data.results.length > 0) {
          const resultFornecedor = result.data.results.filter(
            (item) => (item.seqfornecedor = event.target.value),
          );

          // console.log(resultFornecedor);

          // setSeqfornecedor(resultFornecedor);

          const cnpj = await api.get(
            `/pessoa/fornecedor/0/${resultFornecedor[0].cpf_cnpj}`,
          );
          console.log(cnpj);

          if (cnpj.data.results != null) {
            SetCnpj(cnpj.data.results[0]);

            setCpfCnpj(resultFornecedor[0].cpf_cnpj);
          }
        }
      }
    } catch (err) {
      console.log(err);
      toast.error('Nenhum Fornecedor foi localizado');
    }
  }

  function ClearFields() {
    document.getElementById('txtCodigoProduto').value = '';

    document.getElementById('txtCodigoCNPJ').value = '';

    $('#txtDescricaoProduto').html('');

    $('#txtDescricaoCNPJ').html('');
    $('#txtDescricaoCNPJ').hide();
    $('#txtDescricaoProduto').hide();

    $('#txtQuantidade').val('');
    $('#txtObservacao').val('');
    $('#txtValor').val('');
    $('#txtSeqPessoa').val('');

    currentProduto = null;
    currentCnpj = null;
  }

  async function Closemodal() {
    window.$('.modal').modal('hide');
  }

  async function Salvar() {
    // console.log(searchQtd);
    try {
      if (searchCD === null) {
        return toast.warning('Selecione um CD.');
      }

      loading.setLoading(true);
      if (
        searchCD.numero_empresa &&
        notnullCnpj &&
        seqproduto &&
        quantidade &&
        valor &&
        searchQtd.qtdembalagem !== null
      ) {
        const res = await api.post('/listaseparacao/gerar_lote_particular/', {
          numeronf: null,
          serienf: null,
          seqlote: null,
          nroempresa: searchCD.numero_empresa,
          seqproduto: seqproduto,
          // seqfornecedor: seqfornecedor,
          quantidade: quantidade,
          valor: valor,
          observacao: observacao,
          embalagem: searchQtd.qtdembalagem,
          codusuario: '' + token.userName,
          seqfornecedor: seqfornecedor,
        });

        if (res.data.codResult === 0) {
          ShowAlert('Sucesso', res.data.msg);

          setEmbalagens([]);

          ClearFields();
        } else {
          ShowAlert('Erro', res.data.msg);
        }
      } else {
        ShowAlert(
          'Atenção',
          'Por favor, verifique as informações antes de continuar.',
        );
      }
      loading.setLoading(false);
    } catch (err) {
      loading.setLoading(false);
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  function ShowAlert(title, message) {
    $('#modal-alert .modal-title').html(title);
    $('#modal-alert .modal-body').html(message);
    window.$('#modal-alert').modal('show');
  }

  function handleSearchCD(event) {
    if (event.target.value !== 'A DEFINIR') {
      setSearchCD(
        listCD.find(
          (item) => item.numero_empresa === parseInt(event.target.value),
        ),
      );
    } else {
      setSearchCD(null);
    }
  }

  function handleSearchQtd(event) {
    if (event.target.value !== 'A DEFINIR') {
      const result = embalagens.find(
        (item) => item.qtdembalagem === event.target.value,
      );
      setSearchQtd(result);
    } else {
      setSearchQtd(null);
    }
  }

  return (
    <Fragment>
      <div className="content-header">
        <h1>Entrada Particular</h1>
      </div>

      <div className="content">
        <div className="card">
          <div className="card-body">
            <div className="row col">
              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Centro de Distribuição :</label>
                  <div>
                    <select
                      className="form-control"
                      id="distributionCenterSelect"
                      onChange={(event) => handleSearchCD(event)}
                      disabled={listCD.length === 0}
                    >
                      <option defaultValue>A DEFINIR</option>
                      {listCD.map((item) => (
                        <option
                          value={item.numero_empresa}
                          key={item.numero_empresa}
                        >
                          {item.nome_reduzido}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Seq. Pessoa:</label>
                  <div>
                    <Input
                      id="txtSeqPessoa"
                      className="form-control"
                      placeholder="Seq. Pessoa"
                      value={seqfornecedor}
                      onKeyUp={(event) => buscaSeqFornecedor(event)}
                      onChange={(event) => setSeqfornecedor(event.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">CNPJ [Enter]:</label>
                  <div>
                    <input
                      id="txtCodigoCNPJ"
                      className="form-control"
                      onKeyUp={(event) => BuscaCnpj(event)}
                      // onBlur={() => BuscaCnpj('')}
                      placeholder="Entre com o CNPJ / CPF"
                      value={cpfCnpj}
                      onChange={(event) => setCpfCnpj(event.target.value)}
                    />
                    <div
                      id="txtDescricaoCNPJ"
                      style={{
                        paddingTop: '6px',
                        marginTop: '-4px',
                        borderTop: 'none',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Produto [Enter]:</label>
                  <div>
                    <input
                      id="txtCodigoProduto"
                      className="form-control"
                      name="test_name"
                      min="0"
                      type="search"
                      // onInput="validity.valid||(value='');"
                      onKeyUp={(event) => BuscaProduto(event)}
                      onBlur={() => BuscaProduto('')}
                      placeholder="Entre com o código do produto .."
                    />
                    <datalist
                      id="datalistProduto"
                      className="form-control"
                      hidden
                    >
                      <option key="123">606 - Abacate KG</option>
                      <option key="321">902 - Banana KG</option>
                      {/*usuario.map((item) => (
                        <option key={item.username}>
                          {item.username} - {item.first_name}
                        </option>
                      ))*/}
                    </datalist>
                    <div
                      id="txtDescricaoProduto"
                      style={{
                        paddingTop: '6px',
                        marginTop: '-4px',
                        borderTop: 'none',
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Embalagem :</label>
                  <div>
                    <select
                      id="embalagemSelect"
                      className="form-control"
                      onChange={(event) => handleSearchQtd(event)}
                      disabled={embalagens.length === 0 ? true : false}
                    >
                      <option defaultValue>A DEFINIR</option>
                      {embalagens.map((item) => (
                        <option
                          value={item.qtdembalagem}
                          key={`${item.embalagem} - ${item.qtdembalagem}`}
                        >
                          {item.embalagem} - {item.qtdembalagem}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col">
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Quantidade :</label>
                  <div>
                    <CurrencyInput
                      id="txtQuantidade"
                      allowempty="true"
                      allowleadingzeroes="false"
                      className="form-control"
                      value={quantidade}
                      type="number"
                      name="test_name"
                      min="0"
                      // onInput="validity.valid||(value='');"
                      onChange={(event) =>
                        setQuantidade(
                          event.target.value.replace(/[^.0-9]/g, ''),
                        )
                      }
                      placeholder="0,00"
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Observação :</label>
                  <div>
                    <Input
                      id="txtObservacao"
                      allowempty="true"
                      allowleadingzeroes="false"
                      className="form-control"
                      value={observacao}
                      onChange={(event) => setObservacao(event.target.value)}
                      placeholder="Observação"
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Valor :</label>
                  <div>
                    <CurrencyInput
                      id="txtValor"
                      allowempty="true"
                      allowleadingzeroes="false"
                      className="form-control"
                      value={valor}
                      type="number"
                      name="test_name"
                      min="0"
                      // oninput="validity.valid||(value='');"
                      onChange={(event) =>
                        setValor(event.target.value.replace(/[^.0-9]/g, ''))
                      }
                      placeholder="R$ 0,00"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary float-right mr-4"
              onClick={Salvar}
              disabled={loading.isLoading}
            >
              {loading.isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'Salvar'
              )}
            </button>
          </div>
        </div>
      </div>

      <div id="modal-alert" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Entrada;
