import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Tabs, Tab, Spinner, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-masked-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import $ from 'jquery';
import pt from 'date-fns/locale/pt';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import * as xlsx from 'xlsx/xlsx.mjs';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import api from '../../../../services/api';
import { useLoading } from '../../../../hooks/useLoading';
import { useReactToPrint } from 'react-to-print';
import { CompanyTab } from '../../../../components/MapaSeparacao/CompanyTab';

import css from './MapaDeSeparacao.css';
import { UserTab } from '../../../../components/MapaSeparacao/UserTab';

registerLocale('pt', pt);
xlsx.set_cptable(cpexcel);
const token = JSON.parse(localStorage.getItem('token'));
var respMsg = '';

var currentEmpresaId = null;
let selectedTab = 'user';

function MapaDeSeparacao() {
  const [currentEmpresa, setCurrentEmpresa] = useState({});
  const [currentProduto, setCurrentProduto] = useState({});
  const [currentLote, setCurrentLote] = useState({});
  const [lotes, setLotes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [cdList, setCdList] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [locais, setLocais] = useState([]);

  useEffect(() => {
    $('#txtFiltraEmpresa').focus();
    Buscacd();
    BuscaEmpresas();

    $('#boxProdutos').show();
    $('#boxLotes').hide();
    $('.lote-linha-box').hide();
    window.$('#modal-lote').on('hidden.bs.modal', function () {
      $('.lote-linha').removeClass('active-conferencia');
    });
  }, []);

  async function Buscacd() {
    try {
      const res = await api.get('/empresa/centrodistribuicao/');

      if (res.data.results != null) {
        res.data.results.sort(dynamicSort('cidade'));
        setLocais(distinct(res.data.results, 'cidade'));
        res.data.results.sort(dynamicSort('nroempresa'));
        setCdList(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function BuscaEmpresas() {
    try {
      const res = await api.get('/empresa/listaempresa/');
      var result = null;

      if (res.data.results != null) {
        res.data.results.sort(dynamicSort('cidade'));
        setLocais(distinct(res.data.results, 'cidade'));

        res.data.results.sort(dynamicSort('nroempresa'));
        setEmpresas(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function CloseModal() {
    window.$('.modal').modal('hide');
  }

  return (
    <>
      <div className="content-header">
        <h1 style={{ marginLeft: 0 }}>Mapa de Separação</h1>
      </div>

      <div className="content">
        <Tabs
          defaultActiveKey="user"
          onSelect={(firstTab, secondTab) => {
            selectedTab = firstTab;
          }}
        >
          <Tab eventKey="user" title="Conferente" tab={selectedTab}>
            <UserTab cdList={cdList} empresas={empresas} locais={locais} />
          </Tab>

          <Tab eventKey="company" title="Empresa">
            <CompanyTab cdList={cdList} empresas={empresas} />
          </Tab>
        </Tabs>
      </div>

      <div id="modal-alert" className="modal" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MapaDeSeparacao;
