import React, { Fragment, useEffect, useState } from 'react';

function Header() {
  const [usuario, setUsuario] = useState(null);
  const [displayName, setDisplayName] = useState(null);

  useEffect(() => {
    async function main() {
      const token = JSON.parse(localStorage.getItem('token'));

      if (token && token.userName) {
        setUsuario(token.userName);
        setDisplayName(token.displayName);
      }
    }

    main();
  }, []);

  function Sair() {
    if (window.confirm('Deseja realmente desconectar de sua conta?')) {
      localStorage.clear();
      window.location = '/login';
    }
  }

  return (
    <Fragment>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="index3.html" className="nav-link">
              DASHBOARD
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item" style={{ marginRight: 20, marginTop: 3 }}>
            {`${usuario} - ${displayName}`}
          </li>

          <li className="nav-item" style={{ marginRight: 10 }}>
            <button
              className="btn btn-danger btn-sm "
              onClick={() => {
                Sair();
              }}
            >
              Sair
            </button>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
}

export default Header;
