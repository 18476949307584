import React from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react/cjs/react.production.min';

// import { Container } from './styles';

function Menu() {
  return (
    <Fragment>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <p>Dashboard</p>
                </Link>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <p>
                    Admin
                    <i className="fas fa-angle-left right" />
                  </p>
                </div>
                <ul className="nav-treeview">
                  <li className="nav-item ml-2">
                    <Link to="/Admin/ObaAdmPermission" className="nav-link">
                      <p>Permissão Oba Adm</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <p>
                    Logistica
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </div>
                <ul className="nav nav-treeview">
                  <li className="nav-item ml-2">
                    <div className="nav-link">
                      <p>
                        Expedição FLV
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </div>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/separation-list" className="nav-link">
                          <p>Lista Separação</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/Estoque/EstoqueLote" className="nav-link">
                          <p>Estoque Lote</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/appointment" className="nav-link">
                          <p>Apontamento</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/product-billing" className="nav-link">
                          <p>Faturamento de Produtos </p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link
                          to="/Logistica/ExpedicaoFLV/Conferencia/conferencia"
                          className="nav-link"
                        >
                          <p>Conferência</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link
                          to="/Logistica/ExpedicaoFLV/ConferenciaAvulsa/ConferenciaAvulsa"
                          className="nav-link"
                        >
                          <p>Conferência Avulsa</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link
                          to="/Logistica/ExpedicaoFLV/MapaDeSeparacao/MapaDeSeparacao"
                          className="nav-link"
                        >
                          <p>Mapa de Separação</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/pages/entrada" className="nav-link">
                          <p>Entrada Particular</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/pages/resumoparticular" className="nav-link">
                          <p>Resumo Particular</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview">
                      <li className="nav-item ml-2">
                        <Link to="/Logistica/register-supplier" className="nav-link">
                          <p>Cadastro de Fornecedor</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item">
                <div className="nav-link">
                  <p>
                    {' '}
                    Configuração <i className="fas fa-angle-left right"></i>
                  </p>
                </div>

                <ul className="nav nav-treeview">
                  <li className="nav-item ml-2">
                    <Link to="/base" className="nav-link">
                      <p>Base</p>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item">
                <div className="nav-link">
                  <p>
                    {' '}
                    Comercial <i className="fas fa-angle-left right"></i>
                  </p>
                </div>

                <ul className="nav nav-treeview">
                  <li className="nav-item ml-2">
                    <Link to="/Comercial/AlteraPreco" className="nav-link">
                      <p>Alteração de Preço</p>
                    </Link>
                  </li>
                </ul>

                <ul className="nav nav-treeview">
                  <li className="nav-item ml-2">
                    <Link to="/Comercial/Produto" className="nav-link">
                      <p>Produto</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item ml-2">
                    <Link
                      to="/Comercial/AtivarProduto/AtivarProduto"
                      className="nav-link"
                    >
                      <p>Ativação de Produto</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <div className="nav-link">
                  <p>
                    {' '}
                    Financeiro <i className="fas fa-angle-left right"></i>
                  </p>
                </div>

                <ul className="nav nav-treeview">
                  <li className="nav-item ml-2">
                    <Link to="/Financeiro/EstornoDeTitulo" className="nav-link">
                      <p>Estorno de Título</p>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* <aside className="control-sidebar control-sidebar-dark"> */}
      {/* Control sidebar content goes here */}
      {/* <a href="#" data-widget="control-sidebar">
          Toggle Control Sidebar
        </a> */}
      {/* </aside> */}
    </Fragment>
  );
}

export default Menu;
