import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";

export function Lots({
  stocks,
  currentStock,
  onGenerateLabelPDFBySelectedStock,
  loading2,
  onGenerateMapPDFBySelectedStock,
  loading
}) {
  return (
    <div className="row">
      <div className="col-sm-12">
        {stocks.length > 0 && (
          <div
            className="card-body table-responsive p-0 "
            style={{ maxHeight: 'calc(100vh - 271px)', overflow: 'auto' }}
          >
            <table
              // id="table-export"
              className="table table-bord ered table-hover table-striped text-nowrap text-sm"
              style={{ position: 'relative' }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>COD. PRODUTO</th>
                  <th>PRODUTO</th>
                  <th>DATA ENTRADA</th>
                  <th>FORNECEDOR</th>
                  <th>COMPRADOR</th>
                  <th>LOTE</th>
                  <th>UNIT</th>
                  <th>ENTRADA</th>
                  <th>SAIDA</th>
                  <th>ESTOQUE</th>
                  <th>EMBALAGEM</th>
                  <th>(UNI/KG)</th>
                  <th>TOTAL DE PRODUTOS</th>
                </tr>
              </thead>
              <tbody>
                {stocks.map((item) => {
                  return (
                    <tr key={`${item.seqproduto}-${item.lote}`}>
                      <td>
                        <button
                          type="button"
                          className="btn btn-info btn-sm"
                          title="Imprimir Etiqueta"
                          onClick={() =>
                            onGenerateLabelPDFBySelectedStock(
                              item.lote
                            )
                          }
                          style={{ width: 32, height: 32 }}
                        >
                          {loading2.isLoading && item.lote === currentStock.lote ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : (
                            'E'
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn btn-info btn-sm ml-2"
                          title="Imprimir Mapa"
                          onClick={() =>
                            onGenerateMapPDFBySelectedStock(item.lote)
                          }
                          style={{ width: 32, height: 32 }}
                        >
                          {loading.isLoading && item.lote === currentStock.lote ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : (
                            'M'
                          )}
                        </button>
                      </td>
                      <td>{item.seqproduto}</td>
                      <td>{item.produto}</td>
                      <td>
                        {moment(item.dtaentrada).format('DD/MM/YYYY')}
                      </td>
                      <td>{item.fornecedor}</td>
                      <td>{item.comprador}</td>
                      <td>{item.lote}</td>
                      <td>{item.unitario}</td>
                      <td>{item.entrada}</td>
                      <td>{item.saida}</td>
                      <td>{item.entrada - item.saida}</td>
                      <td>{item.volume}</td>
                      <td>
                        {(
                          (item.entrada - item.saida) *
                          item.quantidade_embalagem
                        ).toFixed(2)}
                      </td>
                      <td
                        style={{
                          background:
                            item.total !== 0 && 'rgb(253, 253, 150)',
                        }}
                      >
                        {item.total.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
