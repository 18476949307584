import $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import * as xlsx from 'xlsx/xlsx.mjs';
import api from '../services/api';
const bootstrap = require('../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js');


var currentBonificado = '';
var currentSequencia_item_lista;
var separationProducts = [];
let lstEmpresa = [];
let lstEmbalagens = [];
let currentProduto = null;
let apiNoError = false;
var searchBuyer = null;
var rowsDisable = [];
var currentProduct = null;
var jsonPlanilha = {};
var keys = [];
function SeparationList() {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const boolHideItemDetails = true;
  const [listCD, setListCD] = useState([]);
  //const [separationProducts, setSeparationProducts] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [searchCD, setSearchCD] = useState(null);
  //const [searchBuyer, setSearchBuyer] = useState(null);
  const [productSeparationList, setProductSeparationList] = useState([]);
  const [productSeparationList2, setProductSeparationList2] = useState([]);
  const [
    productSeparationTableListFiltered,
    setProductSeparationTableListFiltered,
  ] = useState([]);
  const [generateSeparationDate, setGenerationSeparationDate] = useState(null);
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [listCompany, setListCompany] = useState([]);
  const [productSeparationAppointments, setProductSeparationAppointments] =
    useState([]);
  const [productItems, setProductItems] = useState([]);
  const [productDescription, setProductDescription] = useState('');
  const [listUF, setListUF] = useState(['DF', 'GO', 'SP']);
  const [listFilterUF, setListFilterUF] = useState([]);
  const [totalItemsSum, setTotalItemsSum] = useState([]);
  const [locais, setLocais] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [produtosRelacionados, setProdutosRelacionados] = useState([]);
  const [usuario, setUsuario] = useState(null);
  const [importacaoData, setImportacaoData] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const token = JSON.parse(localStorage.getItem('token'));
  const codigo_usuario = token && token.userName ? token.userName : '';

  const [todasEmpresas, setTodasEmpresas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/empresa/centrodistribuicao/');

      if (res.data.results.length == 0)
        toast.error('Nenhum centro de distribuição foi localizado');

      setListCD(res.data.results);
    }

    fetchData();

    if (token && token.userName) {
      setUsuario(token.userName);
    }
    $('#spinner, #grid-spinner').hide();
    $('#tblImportacao').hide();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/comprador/comprador/');

      if (res.data.results.length == 0)
        toast.error('Nenhum comprador foi localizado');

      setBuyer(res.data.results);
    }

    fetchData();
  }, []);

  useEffect(() => {
    //searchListaseparacaoProduto();
  }, [searchCD, searchBuyer]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/empresa/listaempresa/');

      if (res.data.results != null) {
        if (res.data.results.length == 0)
          toast.error('Nenhuma empresa foi localizada');

        //document.getElementById('SeparateOrderBtn').disabled = true;

        res.data.results.sort(dynamicSort('cidade'));

        var r = [];
        for (var a = 0; a < res.data.results.length; a++) {
          r.push({
            cidade: res.data.results[a].cidade + '-' + res.data.results[a].uf,
          });
        }

        setLocais(distinct(r, 'cidade'));
        setTodasEmpresas(res.data.results)
        res.data.results.sort(dynamicSort('nroempresa'));
        lstEmpresa = res.data.results;

        lstEmpresa.forEach((item) => (item.show = true));

        lstEmpresa.sort(function (a, b) {
          if (a.nroempresa > b.nroempresa) {
            return 1;
          }
          if (a.nroempresa < b.nroempresa) {
            return -1;
          }
          return 0;
        });

        setEmpresas(lstEmpresa);
        setTimeout(() => {
          document.getElementById('localTodosChk').checked = true;
          handleLocationsCheckBoxAll(true);
          totalsQuantities(null);
        }, 1000);
      }
    }

    fetchData();
  }, []);

  function OnCloseModal() {
    setProductSeparationAppointments([]);
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function BuscaProduto(event) {
    try {
      const res = await api.get(
        '/produto/v1/produto/' +
        document.getElementById('txtCodigoProduto').value,
      );

      if (res.data.results != null) {
        if (res.data.results.length == 0)
          toast.error('Nenhum produto foi localizado');

        currentProduto = res.data.results[0];
        document.getElementById('txtDescricaoProduto').value =
          currentProduto.descreduzida;
        BuscaEmbalagens();
        //BuscaProdutosRelacionados();

        ValidarEmpresarProduto(currentProduto);
      }
    } catch (ex) {
      ClearFields();
      alert('Nenhum produto foi localizado.');
      //console.log(ex);
    }
  }
  ///
  async function ValidarEmpresarProduto(prod) {
    var bonificado = '';

    if ($('#ckbBonificado').prop('checked') == true) {
      bonificado = 'S';
    } else {
      bonificado = 'N';
    }

    var itens = separationProducts.filter(
      (item) =>
        item.sequencia_produto == prod.seqproduto &&
        item.bonificado == bonificado,
    );

    $('.empr-item').show();
    for (var a = 0; a < itens.length; a++) {
      $('.empr-item-' + itens[a].sequencia_pessoa).hide();
    }

    $('.chkUF').each(function () {
      if ($(this).prop('checked') == false) {
        $('.' + $(this).val()).hide();
        $('.ckb-item-' + $(this).val()).change();
      }
    });
  }

  async function somatoria() {
    var total = 0;
    $('.tocount').each(function () {
      total += Number($(this).val());
    });

    $('#totalizador').html(total);
  }

  async function BuscaProdutosComprador(event) {
    if (event.key === 'Enter' || event.key === undefined) {
      const distributionCenterSelect = document.getElementById(
        'distributionCenterSelect',
      );
      const cd =
        distributionCenterSelect.options[distributionCenterSelect.selectedIndex]
          .value;
      const txtCodigoProduto = document.getElementById('txtCodigoProduto');
      const seqcomprador = searchBuyer.seqcomprador;

      $('.tocount').val('');
      $('#totalizador').html(0);

      const res = await api.post('/produto/produtoempresa/', {
        sequencia_comprador: seqcomprador,
        sequencia_produto: txtCodigoProduto.value,
        descricao: '',
        status_compra: null,
        numero_empresa: cd,
      });

      if (res.data.resposta) {
        alert(`${res.data.resposta} para o comprador ${searchBuyer.comprador}`);
        return;
      }

      if (res.data) {
        BuscaProduto();
      }
    }
  }

  async function BuscaEmbalagens() {
    const res = await api.get(
      '/produto/v1/embalagem/' + currentProduto.seqfamilia,
    );

    if (res.data.results != null) {
      if (res.data.results.length == 0)
        toast.error('Nenhuma embalagem foi localizada');

      lstEmbalagens = res.data.results;
    }
  }

  function ClearFields() {
    document.getElementById('txtCodigoProduto').value = '';
    document.getElementById('txtDescricaoProduto').value = '';
    currentProduto = null;
  }

  async function BuscaProdutosRelacionados() {
    const res = await api.get(
      '/produto/v1/produtorelacionado/' +
      document.getElementById('txtCodigoProduto').value,
    );

    if (res.data.results != null) {
      if (res.data.results.length == 0)
        toast.error('Nenhum produto relacionado foi localizado');

      setProdutosRelacionados(res.data.results);
    }
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }
  function groupBy(arrayObjects, key) {
    return arrayObjects.reduce(function (result, currentObject) {
      const val = currentObject[key];
      result[val] = result[val] || [];
      result[val].push(currentObject);
      return result;
    }, {});
  }
  async function searchListaseparacaoProduto(updateUfs = true) {
    async function fetchData() {
      const seq_list = await getList();

      if (seq_list == null) {
        toast.error('Lista de separação não encontrada!');
        setListCompany([]);
        setProductSeparationList([]);
        setProductSeparationList2([]);
      } else {
        const res = await api.post('/listaseparacao/listaseparacaoproduto/', {
          sequencia_lista: seq_list.sequencia_lista,
          numero_empresa: searchCD.numero_empresa,
          // situacao: 'F',
          sequencia_comprador: searchBuyer.seqcomprador,
          sequencia_produto: null,
          sequencia_pessoa: null,
          uf: null,
        });

        if (res.data.resposta) {
          alert(res.data.resposta);
          setListCompany([]);
          return;
        }
        if (updateUfs == true) {
          let cjkElements = document.getElementsByClassName('chkUF');
          for (let idx = 0; idx < cjkElements.length; idx++) {
            cjkElements[idx].checked = true;
          }
        }

        separationProducts = res.data;

        forceUpdate();
        let count = {};

        separationProducts.forEach((element) => {
          count[element.sequencia_produto] =
            (count[element.sequencia_produto] || 0) + 1;
        });

        count = Object.entries(count);
        count.map((listItem) => {
          if (listItem[1] > 1) {
            for (let index = 0; index < separationProducts.length; index++) {
              if (
                separationProducts[index].sequencia_produto ===
                parseInt(listItem[0]) &&
                separationProducts[index].embalagem !== ''
              ) {
                //separationProducts[index].quantidade_embalagem = listItem[1] * separationProducts[index].quantidade_embalagem;
              }
            }
          }
        });

        // TODO  Verificar com o Carlos quando possivel, o que fazer quando a embalagem é nula ? - Nélio Junior - Maio/22
        separationProducts.forEach((item) => {
          if (item.embalagem === null) {
            item.embalagem = '';
          }
        });

        const arr = separationProducts.filter(
          (item) => item.embalagem !== null,
        );

        const ids2 = arr.map((o) => o.sequencia_produto);
        const filtered2 = arr.filter(
          ({ sequencia_produto }, index) =>
            !ids2.includes(sequencia_produto, index + 1),
        );
        setProductSeparationList2(filtered2);

        const ids = arr.map((o) => o.quantidade_embalagem);
        //const filtered = arr;/*.filter(({ quantidade_embalagem, quantidade_embalagem }, index) => !ids.includes(quantidade_embalagem, index + 1) && !ids2.includes(sequencia_produto, index + 1));*/
        const filtered = [];

        ///
        arr.forEach(function (a) {
          if (
            filtered.filter(
              (e) =>
                e.quantidade_embalagem == a.quantidade_embalagem &&
                e.sequencia_produto == a.sequencia_produto &&
                //e.sequencia_item_lista == a.sequencia_item_lista &&
                e.bonificado == a.bonificado,
            ).length == 0
          ) {
            filtered.push(a);
          }
        }, Object.create(null));

        console.log(filtered);

        const error = {
          resposta: 'Item nao localizado',
        };

        if (filtered !== error) {
          const totalItemsData = await api.post(
            '/listaseparacao/listaseparacaoitem/',
            {
              sequencia_lista: seq_list.sequencia_lista,
              numero_empresa: `${searchCD.numero_empresa}`,
              situacao: 'C,P',
              sequencia_comprador: null,
              sequencia_produto: null,
              sequencia_pessoa: null,
            },
          );

          let totalItems = totalItemsData.data;

          if (totalItems.resposta !== 'Item nao localizado') {
            setProductItems(totalItems);
          }

          const separationList = res.data;

          totalItems = [];

          if (totalItems.resposta !== 'Item nao localizado') {
            separationList.map((separation) => {
              separation.total_quantidade_solicitada = 0;
              separation.total_quantidade_atendida = 0;
              totalItems.map((item) => {
                if (separation.sequencia_produto === item.sequencia_produto) {
                  separation.total_quantidade_solicitada +=
                    item.quantidade_solicitada;
                  separation.total_quantidade_atendida +=
                    item.quantidade_atendida;
                }
              });
            });
          }

          filtered.sort(function (a, b) {
            if (a.bonificado == "N" && a.sequencia_produto == b.sequencia_produto) {
              return 1;
            }
            if (a.bonificado == "S" && a.sequencia_produto == b.sequencia_produto) {
              return -1;
            }
            return 0;
          });

          setProductSeparationList(filtered);

          setProductSeparationTableListFiltered(filtered);
          //console.log(productSeparationTableListFiltered);
          const totalItemsSum = totalItems;

          for (let i = 0; i < totalItemsSum.length; i++) {
            totalItemsSum[i].quantidade_total_solicitada = 0;
            totalItemsSum[i].quantidade_total_atendida = 0;
            for (let j = 0; j < totalItems.length; j++) {
              if (
                totalItemsSum[i].sequencia_produto ===
                totalItems[j].sequencia_produto
              ) {
                totalItemsSum[i].quantidade_total_solicitada +=
                  totalItems[j].quantidade_solicitada;
                totalItemsSum[i].quantidade_total_atendida +=
                  totalItems[j].quantidade_atendida;
              }
            }
          }

          const arr2 = totalItemsSum;

          const ids2 = arr2.map((o) => o.sequencia_produto);
          const filtered2 = arr2.filter(
            ({ sequencia_produto }, index) =>
              !ids2.includes(sequencia_produto, index + 1),
          );

          setTotalItemsSum(filtered2);

          const companies = await api.get('/empresa/listaempresa/');

          companies.data.results.sort(function (a, b) {
            if (a.numero_empresa > b.numero_empresa) {
              return 1;
            }
            if (a.numero_empresa < b.numero_empresa) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          if (companies.data.results.length == 0)
            toast.error('Nenhuma empresa foi localizada');

          var temp = [];
          var cc = 0;

          for (var i = 0; i < companies.data.results.length; i++) {
            if (companies.data.results[i].sigla != null) {
              temp[cc] = companies.data.results[i];
              cc++;
            }
          }

          setListCompany(temp);

          document.getElementById('SeparateOrderBtn').disabled = false;
          //document.querySelector('.separatioList').scrollTo(0, 0);

          forceUpdate();

          handleFilterProductList();

          fixarColunas();
        }
      }
      SetUFSelected();

      forceUpdate();
    }

    async function getList() {
      const list = await api.post('/listaseparacao/listaseparacao', {
        sequencia_lista: null,
        numero_empresa: `${searchCD.numero_empresa}`,
        data_criacao_inicio: null,
        data_criacao_fim: null,
        situacao: 'P',
        sequencia_comprador: `${searchBuyer.seqcomprador}`,
      });

      const seq_list = await list.data;

      if (seq_list.resposta === 'Item nao localizado') {
        return null;
      } else {
        const seq_list = await list.data[0];
        return seq_list;
      }
    }

    if (searchCD && searchBuyer) {
      fetchData();
    }
  }

  function fixarColunas() {
    //////////////////
    ///fixed-position

    $('.table-responsive').scrollLeft(0);

    $('.table-excel1 .fixed-position').each(function () {
      if ($(this).attr('style') == undefined) {
        $(this).css(
          'background-color',
          $(this).parent().css('background-color'),
        );
        $(this).css('border', $(this).parent().css('border'));
      }
      $(this).css('left', $(this).position().left);
    });

    $('.table-excel2 .fixed-position').each(function () {
      //if ($(this).attr('style') == undefined) {
      $(this).removeAttr('style');

      $(this).css('background-color', $(this).parent().css('background-color'));
      $(this).css('border', $(this).parent().css('border'));

      //}

      $(this).css('left', $(this).position().left);
    });
  }

  function handleSearchCD(event) {
    if (event.target.value !== 'A DEFINIR') {
      searchBuyer = null;
      setProductSeparationTableListFiltered([]);
      setSearchCD(
        listCD.find(
          (item) => item.numero_empresa === parseInt(event.target.value),
        ),
      );
    } else {
      setSearchCD(null);
      setProductSeparationTableListFiltered([]);
    }
  }

  function handleSearchBuyer(updateUfs = true) {

    if (searchBuyer != null) {
      updateUfs = false;
    }
    if ($('#ddlComprador').val() !== 'A DEFINIR') {
      searchBuyer = null;
      setProductSeparationTableListFiltered([]);
      searchBuyer = buyer.find(
        (item) => item.comprador === $('#ddlComprador').val(),
      );
      $('#inputTargetusuario').val('');
      forceUpdate();
      setTimeout(function () {
        searchListaseparacaoProduto(updateUfs);
      }, 100);
    } else {
      searchBuyer = null;
      setProductSeparationTableListFiltered([]);
    }
  }

  async function handleSearchProductAppointments(
    sequencia_produto,
    sequencia_lista,
    sequencia_item_lista,
  ) {
    for (var r = 0; r < listCompany.length; r++) {
      listCompany[r].enabled = 'true';
    }
    const res = await api.post('/listaseparacao/lote/', {
      numero_empresa: searchCD.numero_empresa,
      sequencia_pessoa: null,
      sequencia_lista: sequencia_lista,
      sequencia_produto: sequencia_produto,
    });

    if (res.data.length > 0) {

      const produtoconferido = await api.post(
        '/listaseparacao/busca_conferencia_v2/',
        {
          numero_empresa: searchCD.numero_empresa,
          sequencia_lista: sequencia_lista,
          sequencia_produto: sequencia_produto,
          sequencia_pessoa: 0,
          data_inicial: null,
          data_final: null,
          status: 'C,L,P',
          codigo_usuario: null,
        },
      );

      if (produtoconferido.data != null) {

        for (var b = 0; b < res.data.length; b++) {
          for (var a = 0; a < produtoconferido.data.length; a++) {
            if (
              res.data[b].sequencia_lote == produtoconferido.data[a].sequencia_lote &&
              res.data[b].origem == 'N' &&
              (produtoconferido.data[a].status_conferencia == 'C' || produtoconferido.data[a].status_conferencia == 'L')
            ) {
              var c = listCompany.find(
                (r) => r.seqpessoa == produtoconferido.data[a].sequencia_pessoa,
              );
              c.enabled = 'false';

              ///////////////////////////////////////////////////////////////////////
              //Correção do saldo do lote 31/08/2022 14:07
              //res.data[b].saldo -= produtoconferido.data[a].quantidade_conferida;
              ///////////////////////////////////////////////////////////////////////

              if (res.data[b].saldo < 0) res.data[b].saldo = 0;
            }
            // }
          }
        }
      }
      console.log('separationProducts');
      console.log(separationProducts);

      console.log('produtoconferido');
      console.log(produtoconferido.data);

      console.log('productSeparationAppointments');
      console.log(productSeparationAppointments);

      setProductSeparationAppointments(res.data);
    } else {
      //toast.error("Nenhum lote foi localizado");
      setProductSeparationAppointments([]);
    }

    setTimeout(function () {
      disableColumns();
    }, 100);
    setTimeout(function () {
      disableColumns();
    }, 500);
    setTimeout(function () {
      disableColumns();
    }, 1000);
    setTimeout(function () {
      disableColumns();
    }, 1500);
    setTimeout(function () {
      disableColumns();
    }, 2000);

    $('#modalTable').on('mouseenter mouseleave', function () {
      disableColumns();
      //fixarColunas();
    });

    forceUpdate();
  }

  function enableColumns() {
    //$(".inp").removeAttr('disabled');
  }
  function disableColumns() {
    const modalTable = document.querySelector('#modalTable');

    for (var i = 0; i < modalTable.rows[0].cells.length; i++) {
      var h5 = $('<div></div>').append(modalTable.rows[0].cells[i].innerHTML);

      if ($(h5).find('.total_embalagem_pedida').html() == undefined) {
        for (var a = 1; a < modalTable.rows.length; a++) {
          var r = $('<div></div>').append(modalTable.rows[a].cells[i].innerHTML);
          $('#' + $(r).find('input').attr('id')).attr('disabled', 'disabled');
        }
      }
      else {
        for (var a = 1; a < modalTable.rows.length; a++) {
          var r = $('<div></div>').append(modalTable.rows[a].cells[i].innerHTML);

          if ($('#' + $(r).find('input').attr('id')).attr('data-otiginal-enabled') == 'true') {
            $('#' + $(r).find('input').attr('id')).removeAttr('disabled');
          }
        }
      }
    }
  }

  async function handleFilterProductList(event) {
    if (
      $('#inputTargetusuario').val() !== 'A DEFINIR' &&
      $('#inputTargetusuario').val() !== ''
    ) {
      setProductSeparationTableListFiltered(
        productSeparationList.filter((item) => {
          if (item.descricao === $('#inputTargetusuario').val()) {
            return item;
          }
        }),
      );
    } else {
      if (event != undefined) {
        setProductSeparationTableListFiltered([]);
        setProductSeparationTableListFiltered(productSeparationList);
      }
    }
  }

  function handleFilterProduct(
    sequencia_produto,
    product_description,
    sequencia_lista,
    sequencia_item_lista,
    bonificado,
    index,
  ) {
    ///roda

    currentBonificado = bonificado;
    currentSequencia_item_lista = sequencia_item_lista;

    $('#modalTable').hide();

    $('.table-excel2 .fixed-position').each(function () {
      $(this).removeAttr('style');
    });
    $('.table-responsive').scrollLeft(0);
    setFilteredProduct([]);

    const product = productSeparationTableListFiltered.find(
      (item) =>
        item.sequencia_produto === sequencia_produto &&
        item.bonificado === bonificado,
    );
    currentProduct = product;
    $('#grid-spinner').show();

    //window.$('#addItemsByLotModal').modal('hide')

    setFilteredProduct(product);

    handleSearchProductAppointments(
      sequencia_produto,
      sequencia_lista,
      sequencia_item_lista,
    );

    setProductDescription(`${sequencia_produto} - ${product_description}`);
    //fixarColunas();
    setTimeout(() => {
      showsQuantidadeEmbalagemPedidaInModalTableHeader();
      getQuantidadeAtendidaSaved();

      //document.getElementById('addItemsByLotModal').hidden = false;
      //window.$('#addItemsByLotModal').modal('show');
      initialPositionModalTable();
      SetUFSelected();

      totalsQuantities(null);
      //fixarColunas();
    }, 300);

    setTimeout(function () {
      totalsQuantities(null);

      //fixarColunas();
    }, 2000);
  }

  function initialPositionModalTable() {
    document.querySelector('.modal-body .card-body').scrollTo(0, 0);
  }

  function getQuantidadeAtendidaSaved() {
    let previousElementInnerText = '';
    document.querySelectorAll('#modalTable td').forEach((element) => {
      if (element.children.length !== 0) {
        if (element.children[0].tagName === 'INPUT') {
          let quantidade_atendida = JSON.parse(
            previousElementInnerText,
          ).quantidade_atendida;
          if (quantidade_atendida > 0) {
            element.children[0].value = quantidade_atendida;
          } else {
            element.children[0].value = '';
          }
        }
      } else {
        previousElementInnerText = element.innerText;
      }
    });
  }

  function showsQuantidadeEmbalagemPedidaInModalTableHeader() {
    const modalTable = document.querySelector('#modalTable');
    const totalColumns = modalTable.rows[1].cells.length;

    const elements = document.getElementsByClassName('total_embalagem_pedida');

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }

    for (let idxCol = 6; idxCol < totalColumns; idxCol += 2) {
      let total_embalagem_pedida = 0;
      let seq_info = JSON.parse(modalTable.rows[1].cells[idxCol].innerText);
      var sequencia_pessoa = 0;

      separationProducts.forEach((itemProd) => {
        if (
          itemProd.sequencia_produto === seq_info.sequencia_produto &&
          itemProd.empresa_destino === seq_info.numero_empresa &&
          itemProd.bonificado === currentBonificado
        ) {
          total_embalagem_pedida += itemProd.quantidade_embalagem_pedida;

          sequencia_pessoa = itemProd.sequencia_pessoa;
        }
      });

      if (total_embalagem_pedida === 0) {
        total_embalagem_pedida = '';
      }

      if (total_embalagem_pedida > 0) {
        modalTable.rows[0].cells[
          idxCol + 1
        ].innerHTML += `<h5 class="total_embalagem_pedida">P-${total_embalagem_pedida.toFixed(
          2,
        )}</h5>`;
      } else {
        rowsDisable.push(idxCol + 1);
      }
    }
  }

  function modalSeparateOrderGetShops() {
    const shops = document.querySelectorAll('.shops');
    const seqlista = 0;
    if (separationProducts.length > 0) {
      try {
        seqlista = separationProducts[0].sequencia_lista;
      } catch (ex) { }
    }

    const txtDescricaoProduto = document.getElementById('txtDescricaoProduto');

    const distributionCenterSelect = document.getElementById(
      'distributionCenterSelect',
    );
    const nroempresa =
      distributionCenterSelect.options[distributionCenterSelect.selectedIndex]
        .value;

    let embalagem = lstEmbalagens.find(
      (item) => item.seqfamilia === currentProduto.seqfamilia,
    ).qtdembalagem;
    embalagem = parseInt(embalagem);

    if (!txtDescricaoProduto.value) {
      alert('Entre com o codigo do produto');
      return;
    }

    let jsonCollection = [];
    var bonificado = 'N';

    if ($('#ckbBonificado').prop('checked') == true) {
      bonificado = 'S';
    }

    shops.forEach((item) => {
      let qtdembpedida = parseInt(item.getElementsByTagName('input')[0].value);

      if (qtdembpedida) {
        let empdestino = parseInt(item.getElementsByTagName('input')[0].id);
        let seqpessoa = lstEmpresa.find(
          (item) => item.nroempresa === parseInt(empdestino),
        ).seqpessoa;

        let json = {
          seqlista: seqlista,
          nroempresa: nroempresa,
          seqproduto: currentProduto.seqproduto,
          descricao: currentProduto.desccompleta,
          qtdembpedida: qtdembpedida,
          seqpessoa: seqpessoa,
          empdestino: empdestino,
          embalagem: embalagem,
          codusuario: usuario,
          bonificado: bonificado,
        };

        jsonCollection.push(json);
      }
    });

    if (jsonCollection.length > 0) {
      apiNoError = true;
      jsonCollection.forEach((json) => {
        if (apiNoError) {
          modalSeparateOrderSave(json);
        }
      });
      ///rod
      setTimeout(() => {
        if (apiNoError) {
          SeparateOrderModalClose();
          //searchListaseparacaoProduto(false);
          handleSearchBuyer(false);
        }
      }, 1000);
    } else {
      SeparateOrderModalClose();
    }
  }

  async function modalSeparateOrderSave(json) {
    const res = await api.post('/listaseparacao/gerar_lista_avulsa_v3/', json);
    if (res.data.resposta) {
      apiNoError = false;
      alert(res.data.resposta);
    }
  }

  function modalSave() {
    let modalTable = document.querySelector('#modalTable');
    let jsonCollection = [];
    let warningTxt = '';
    var empresasSemPedido = '';
    var count = 0;

    for (let idxRow = 1; idxRow < modalTable.rows.length; idxRow++) {
      let sequencia_lote =
        modalTable.rows[idxRow].cells[0].innerText === ''
          ? 0
          : modalTable.rows[idxRow].cells[0].innerText;

      let list = productItems.filter((item) => {
        return (
          item.sequencia_lista === filteredProduct.sequencia_lista &&
          item.sequencia_produto === filteredProduct.sequencia_produto &&
          item.sequencia_lote === parseInt(sequencia_lote)
        );
      });

      //saldo negativo
      if (Number(modalTable.rows[idxRow].cells[4].innerText) < 0) {
        ShowAlert('Atenção', 'Verifique os saldos negativos antes de salvar.');
        return;
      }

      for (
        let idxCol = 7;
        idxCol < modalTable.rows[idxRow].cells.length;
        idxCol += 2
      ) {
        let quantidade_atendida = parseFloat(
          modalTable.rows[idxRow].cells[idxCol].getElementsByTagName('input')[0]
            .value,
        );

        var pedido = $(
          $(modalTable.rows[0].cells[idxCol]).find('.total_embalagem_pedida'),
        ).html();
        var empresa = $(modalTable.rows[0].cells[idxCol]).text();

        /*console.log('-----------------')
        console.log(quantidade_atendida)
        console.log(pedido)*/

        if (isNaN(quantidade_atendida) === false) {
          if (pedido == undefined) {
            empresasSemPedido += empresa + ', ';
            count++;
          }
          let seq_info = JSON.parse(
            modalTable.rows[idxRow].cells[idxCol - 1].innerText,
          );

          var currentItem = separationProducts.filter(
            (r) =>
              r.sequencia_produto === filteredProduct.sequencia_produto &&
              r.sequencia_pessoa == seq_info.sequencia_pessoa &&
              r.bonificado === filteredProduct.bonificado,
          );

          //console.log('9999999999999999999999999999999999999999999999999999')
          //alert(currentItem[0].sequencia_item_lista)

          let json = {
            sequencia_lista: filteredProduct.sequencia_lista,
            sequencia_produto: filteredProduct.sequencia_produto,
            descricao: filteredProduct.descricao,
            sequencia_lote: sequencia_lote,
            sequencia_pessoa: seq_info.sequencia_pessoa,
            embalagem: filteredProduct.quantidade_embalagem,
            quantidade_solicitada: seq_info.quantidade_embalagem_pedida,
            quantidade_atendida: quantidade_atendida,
            sequencia_item: currentItem[0].sequencia_item_lista,
            sequencia_usuario: usuario,
            observacao: null,
          };

          jsonCollection.push(json);
        }
      }
    }

    empresasSemPedido = empresasSemPedido.slice(0, -2);

    //jsonCollection = verifyThereAreSequenciaItemListaEqualsZero(jsonCollection);
    if (count > 1)
      warningTxt = `As lojas ${empresasSemPedido} não possuem pedidos para este produto, deseja continuar?`;
    else
      warningTxt = `A loja ${empresasSemPedido} não possui pedido para este produto, deseja continuar?`;

    if (empresasSemPedido != '') {
      if (window.confirm(warningTxt) === false) {
        return;
      }
    }

    if (jsonCollection.length > 0) {
      saveItemsByLote(jsonCollection);
      window.$('#addItemsByLotModal .close').trigger('click');
      OnCloseModal();
      //window.$('#addItemsByLotModal').modal('hide');
    } else {
      //document.querySelector('#addItemsByLotModal .close').click();
    }
  }

  function verifyThereAreSequenciaItemListaEqualsZero(jsonCollection) {
    let shop = '';
    let previousShops = [];
    let warningTxt = '';
    let count = 0;

    console.log(jsonCollection);
    jsonCollection.map((item) => {
      //if (item.sequencia_item === 0) {
      if (item.quantidade_solicitada == 0) {
        let sigla = listCompany.find(
          (companyItem) => companyItem.seqpessoa === item.sequencia_pessoa,
        ).sigla;

        if (previousShops.find((prvItem) => prvItem === sigla) === undefined) {
          count += 1;
          previousShops.push(sigla);

          shop = shop ? (shop += ', ') : shop;
          shop += listCompany.find(
            (companyItem) => companyItem.seqpessoa === item.sequencia_pessoa,
          ).sigla;
        }
      }
    });

    if (shop) {
      if (count === 1) {
        warningTxt = `A loja ${shop} não possui pedido para este produto, deseja continuar?`;
      } else {
        warningTxt = `As lojas ${shop} não possuem pedidos para este produto, deseja continuar?`;
      }

      if (warningTxt) {
        if (window.confirm(warningTxt) === false) {
          jsonCollection = jsonCollection.filter(
            (item) => item.sequencia_item > 0,
          );
        }
      }
    }
    return jsonCollection;
  }

  async function saveItemsByLote(jsonCollection) {
    const res = await api.post(
      '/listaseparacao/salvarapontamento/',
      jsonCollection,
    );

    if (res.data.length) {
      res.data = res.data[0];
    }

    if (res.data.numero_erro === 0) {
      //document.querySelector('#addItemsByLotModal .close').click();
      window.$('#addItemsByLotModal').modal('hide');
      searchListaseparacaoProduto(false);
    } else {
      alert(res.data.mensagem);
    }
  }
  function ShowAlert(title, message) {
    $('#modal-alert .modal-title').html(title);
    $('#modal-alert .modal-body').html(message);
    window.$('#modal-alert').modal('show');
  }

  async function totalsQuantitiesLote0(event = '', itemName = '', linha = 0) {
    var rTotal = 0;
    var countItem = 0;

    $('.input-item-lote-0').each(function () {
      if ($(this).attr('id') != itemName) {
        var valor = Number($(this).val());
        if (valor > 0) countItem++;

        rTotal += Number($(this).val());
      }
    });

    $('.saldo-lote-0').html("<div class='table-box'>" + rTotal + '</div>');
  }

  async function totalsQuantities(event, itemName = '', linha = 0) {
    let modalTable = document.querySelector('#modalTable');

    if (linha == 0) {
      //Todas as linhas
      for (var i = 1; i < modalTable.rows.length; i++) {
        await GenerateCount(itemName, i);
      }
    } else {
      await GenerateCount(itemName, linha); //Somente uma linha
    }

    $('#grid-spinner').hide();

    //fixarColunas()
    totalsQuantitiesLote0();
  }

  async function GenerateCount(itemName, linha) {
    let modalTable = document.querySelector('#modalTable');
    var rPreTotal = 0;
    var rTotal = 0;
    var rSaldoInicial = modalTable.rows[linha].cells[5].innerText;
    var saldoAtual = modalTable.rows[linha].cells[4].innerText;
    var countItem = 0;

    $('.input-item' + linha).each(function () {
      if ($(this).attr('id') != itemName) {
        var valor = Number($(this).val());
        if (valor > 0) countItem++;

        rPreTotal += Number($(this).val());
      }
    });

    //if (itemName != "") {
    try {
      if (
        Number($('#' + itemName).val()) !=
        Number($('#' + itemName).attr('data-beforeValue'))
      ) {
        if (Number($('#' + itemName).val()) > saldoAtual) {
          $('#' + itemName).val('0');
          ShowAlert(
            'Atenção',
            'A quantidade digitada é maior que o saldo disponível.',
          );
        }
      }

      rTotal = Number($('#' + itemName).val()) + rPreTotal;
      $('#' + itemName).attr('data-beforeValue', $('#' + itemName).val());
    } catch (ex) {
      rTotal = rPreTotal;
    }
    //}

    //rTotal = rPreTotal;

    modalTable.rows[linha].cells[3].innerHTML =
      "<div class='table-box'>" + rTotal + '</div>'; //Qtd Atendida

    var saldo = rSaldoInicial - rTotal;
    if (saldo < 0) saldo = 0;

    modalTable.rows[linha].cells[4].innerHTML =
      "<div class='table-box'>" + saldo + '</div>'; //Saldo

    if (
      rTotal == 0 &&
      saldo == 0 &&
      countItem == 0 &&
      linha < modalTable.rows.length - 1
    ) {
      modalTable.rows[linha].hidden = true;
    }

    //fixarColunas();
  }

  function handleSeparateOrder() {
    $('#ckbBonificado').prop('checked', false);
    document.getElementById('txtCodigoProduto').value = '';
    document.getElementById('txtDescricaoProduto').value = '';
    const localTodosChk = document.getElementById('localTodosChk');

    const shops = document.querySelectorAll('.shops');
    shops.forEach((item) => {
      item.getElementsByTagName('input')[0].value = '';
    });

    localTodosChk.checked = false;
    localTodosChk.click();

    const SeparateOrderModal = new bootstrap.Modal(
      document.getElementById('SeparateOrderModal'),
    );
    SeparateOrderModal.show();

    SetUFSelected();
  }

  function SeparateOrderModalClose() {
    let modalEl = document.getElementById('SeparateOrderModal');
    let modal = bootstrap.Modal.getInstance(modalEl);
    modal.hide();
  }

  async function ReplicarLista() {
    var empresa_origem = $('#distributionCenterSelect').val();
    var empresa_destino = $('#cdDeDestino').val();
    var lista_origem = 0;
    if (productSeparationList.length > 0) {
      lista_origem = productSeparationList[0].sequencia_lista;

      if (empresa_destino != 'A DEFINIR') {
        const res = await api.post('/listaseparacao/replica_lista', {
          lista_origem: lista_origem,
          empresa_origem: empresa_origem,
          empresa_destino: empresa_destino,
          codigo_usuario: codigo_usuario,
          data_criacao: moment(new Date()).format('DD-MM-yyyy'),
        });

        if (res.data.msg != undefined) {
          if (res.data.codResult == 0) {
            ShowAlert('Sucesso', res.data.msg);
          } else {
            ShowAlert('Erro', res.data.msg);
          }
        }
        window.$('#modal-replicar').modal('hide');
      } else {
        alert('Selecione o CD de destino.');
      }
    } else {
      alert('Selecione uma lista antes de replicar.');
    }
  }

  async function handleGenerateList() {
    if (searchCD === null) {
      alert('Entre com um Centro de Distribuição');
      return;
    }

    if (searchBuyer === null) {
      alert('Entre com um Comprador');
      return;
    }

    if (generateSeparationDate === null) {
      alert('Entre com uma data');
      return;
    }

    $('#spinner').show();
    try {
      const res = await api.post('/listaseparacao/gerarlistaseparacao/', {
        sequencia_usuario: usuario,
        sequencia_comprador: searchBuyer.seqcomprador,
        numero_empresa: searchCD.numero_empresa,
        data_criacao: generateSeparationDate,
      });

      alert(res.data[0].mensagem);

      searchListaseparacaoProduto(false);
    } catch (ex) {
      alert('Não foi possível completar a requisição.');
    }
    $('#spinner').hide();
  }

  async function Closemodal() {
    window.$('.modal').modal('hide');
  }
  var ccc = 0;
  function returnInfoItems(
    itemCompany,
    sequencia_lote,
    info = null,
    linha = 0,
    companyIdx = -1,
    enabledC = '',
    sequencia_item_listaPR = null,
  ) {
    let sequencia_pessoa = itemCompany.seqpessoa;
    let sequencia_item_lista = 0;
    let quantidade_atendida = '';
    let sequencia_produto = filteredProduct.sequencia_produto;
    let quantidade_embalagem_pedida = 0;
    let respSeparacaoProduto = [];
    let respSeparacaoProdutoItem = [];
    let numero_empresa = itemCompany.nroempresa;
    var enabled = '';

    if (ccc == 0) {
      console.log('___________roda_______________');
      console.log(listCompany);
      console.log(productItems);
      ccc++;
    }

    ///roda

    respSeparacaoProduto = separationProducts.find(
      (item) =>
        itemCompany.seqpessoa === item.sequencia_pessoa &&
        filteredProduct.sequencia_produto === item.sequencia_produto &&
        sequencia_lote == item.lote &&
        item.sequencia_item_lista === filteredProduct.sequencia_item_lista &&
        item.bonificado === currentBonificado,
    );

    respSeparacaoProdutoItem = productItems.find(
      (item) =>
        itemCompany.seqpessoa === item.sequencia_pessoa &&
        filteredProduct.sequencia_produto === item.sequencia_produto &&
        sequencia_lote === item.sequencia_lote &&
        item.sequencia_pessoa === itemCompany.seqpessoa &&
        item.bonificado === currentBonificado,
    );

    if (respSeparacaoProduto !== undefined) {
      quantidade_embalagem_pedida =
        respSeparacaoProduto.quantidade_embalagem_pedida;
      sequencia_item_lista = respSeparacaoProduto.sequencia_item_lista;

      if (!respSeparacaoProduto.enabled) enabled = 'disabled';
    }

    if (respSeparacaoProdutoItem !== undefined) {
      if (respSeparacaoProdutoItem.quantidade_atendida > 0)
        quantidade_atendida = respSeparacaoProdutoItem.quantidade_atendida;
    }

    if (enabledC == 'false') {
      //$('.itemC_' + companyIdx).attr('disabled', 'disabled');
    } else if (enabledC == 'true') {
      //$('.itemC_' + companyIdx).removeAttr("disabled")
    }

    /*
    if (info != null) {
      if (enabled == "disabled")
        $(info).attr("disabled", "disabled")
      else
        $(info).removeAttr("disabled")
    }
    */

    return {
      sequencia_lote: sequencia_lote,
      numero_empresa: numero_empresa,
      sequencia_pessoa: sequencia_pessoa,
      sequencia_item_lista: sequencia_item_lista,
      quantidade_atendida: quantidade_atendida,
      sequencia_produto: sequencia_produto,
      quantidade_embalagem_pedida: quantidade_embalagem_pedida,
      enabled: enabled,
    };
  }

  function SetUFSelected() {
    $('.chkUF').each(function () {
      if ($(this).prop('checked') == true) {
        $('.' + $(this).val()).show();

        $('.ckb-item-' + $(this).val()).prop('checked', true);
      } else {
        $('.' + $(this).val()).hide();
        $('.ckb-item-' + $(this).val()).prop('checked', false);
      }

      $('.ckb-item-' + $(this).val()).change();
    });

    $('.local').change(function () {
      if (this.checked) {
        //console.log($(this).attr('data-cidade'));
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).show();
      } else {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).hide();
      }
    });
    $('.local-todos').change(function () {
      if (this.checked) {
        $('.local').prop('checked', true);
        $('.empresas').prop('checked', true);
        $('.box-empresa').show();
      } else {
        $('.local').prop('checked', false);
        $('.empresas').prop('checked', false);
        $('.box-empresa').hide();
      }
    });
  }

  function handleFilterUF(UF) {
    const array = [];
    const cjkElements = document.getElementsByClassName('chkUF');
    for (let idx = 0; idx < cjkElements.length; idx++) {
      if (cjkElements[idx].checked) {
        array.push(cjkElements[idx].parentElement.textContent);
      }
    }

    const filtred = [];
    lstEmpresa.filter((empItem) => {
      array.map((itemUF) => {
        if (empItem.uf === itemUF) {
          filtred.push(empItem);
        }
      });
    });

    const mainTable = document.getElementById('mainTable');
    const totalRows = mainTable.rows.length;
    const totalColumns = mainTable.rows[0].cells.length;

    for (let idxCol = 6; idxCol < totalColumns; idxCol++) {
      for (let idxRow = 0; idxRow < totalRows; idxRow++) {
        mainTable.rows[idxRow].cells[idxCol].hidden = true;
      }
    }

    for (let idxCol = 6; idxCol < totalColumns; idxCol++) {
      const storeTitle = mainTable.rows[0].cells[idxCol].innerText;

      const scope = lstEmpresa.find((item) => item.sigla === storeTitle);

      if (filtred.find((item) => item.uf === scope.uf)) {
        for (let idxRow = 0; idxRow < totalRows; idxRow++) {
          mainTable.rows[idxRow].cells[idxCol].hidden = false;
        }
      }
    }

    forceUpdate();

    // setListCompany(filtred);
  }

  function RedirectFocus(e, idx, linha) {
    /*e.preventDefault();
    var largura = 78;
    var distancia = 0;
    var total = (idx * largura) + distancia

    console.log(total)
    $('.scroll').stop().animate({ scrollLeft: total }, 1000);*/

    if (linha == -1) {
      linha = $('#modalTable tbody tr').length - 1;
    }
    var seqlote = $('#modalTable tbody tr').eq(linha).find('td').eq(0).text();
    var lote = $('#modalTable tbody tr').eq(linha).find('td').eq(1).text();
    var emb = $('#modalTable tbody tr').eq(linha).find('td').eq(2).text();
    var qtdAtendida = $('#modalTable tbody tr')
      .eq(linha)
      .find('td')
      .eq(3)
      .text();
    var Saldo = $('#modalTable tbody tr').eq(linha).find('td').eq(4).text();

    setTimeout(function () {
      $('#rSeqLote').html(seqlote);
      $('#rLote').html(lote);
      $('#rEmb').html(emb);
      $('#rQtdAtendida').html(qtdAtendida);
      $('#rSaldo').html(Saldo);
      $('#resumo').show();

    }, 0)

  }

  function ResetResumo() {
    setTimeout(function () {
      $('#rSeqLote').html("");
      $('#rLote').html("");
      $('#rEmb').html("");
      $('#rQtdAtendida').html("");
      $('#rSaldo').html("");

    }, 0)

  }

  function setColorScheme(ordered, served) {
    let backColor = 'green';
    let lightGreen = '#37fe37';
    let pastelYellow = '#FDFD96';

    if (ordered === '') {
      ordered = 0;
    }

    if (ordered === 0 && served === 0) {
      backColor = '';
    } else if (ordered > 0 && served <= 0) {
      backColor = '';
    } else if (ordered < served) {
      backColor = lightGreen;
    } else if (ordered > served) {
      backColor = pastelYellow;
    }

    return { backgroundColor: backColor };
  }

  function getTotalQuantityOrdered(item) {
    let TotalQuantityOrdered = 0;

    separationProducts.forEach((itemProd) => {
      if (itemProd.sequencia_produto === item.sequencia_produto && itemProd.bonificado == item.bonificado) {
        TotalQuantityOrdered += itemProd.quantidade_embalagem_pedida;
      }
    });

    return TotalQuantityOrdered;
  }

  function showTotalQuantityOrdered(item) {
    return (
      <td className="fixed-position">
        <div className="table-box">{getTotalQuantityOrdered(item)}</div>
      </td>
    );
  }

  function showTotalQuantityServed(item) {
    let TotalQuantityServed = 0;

    separationProducts.forEach((itemProd) => {
      if (itemProd.sequencia_produto === item.sequencia_produto && itemProd.bonificado == item.bonificado) {
        TotalQuantityServed += itemProd.quantidade_atendida;
      }
    });

    let TotalQuantityOrdered = getTotalQuantityOrdered(item);

    return (
      <td
        className="fixed-position fixed-last"
        style={setColorScheme(TotalQuantityOrdered, TotalQuantityServed)}
      >
        <div className="table-box">{TotalQuantityServed}</div>
      </td>
    );
  }

  function showTotalByProduct(item) {
    const buffer = [];
    let totalPackagingOrdered = 0;
    let TotalQuantityServed = 0;

    listCompany.forEach((itemCompany) => {
      totalPackagingOrdered = 0;
      TotalQuantityServed = 0;

      separationProducts.forEach((itemProd) => {
        if (
          itemProd.sequencia_produto === item.sequencia_produto &&
          itemProd.empresa_destino === itemCompany.nroempresa &&
          itemProd.bonificado === item.bonificado
        ) {
          totalPackagingOrdered += itemProd.quantidade_embalagem_pedida;
          TotalQuantityServed += itemProd.quantidade_atendida;
        }
      });

      if (totalPackagingOrdered === 0) {
        totalPackagingOrdered = '';
      }

      buffer.push(
        <td
          className={itemCompany.uf}
          style={setColorScheme(totalPackagingOrdered, TotalQuantityServed)}
        >
          <div className="table-box">{totalPackagingOrdered}</div>
        </td>,
      );
    });

    return buffer;
  }

  function handleLocationsCheckBoxAll(trueOrFalse) {
    let lstCidades = document.querySelectorAll('.cidade');

    lstCidades.forEach((element) => {
      element.checked = trueOrFalse;
    });

    lstEmpresa.forEach((item) => {
      item.show = trueOrFalse;
    });

    const lst = lstEmpresa.filter((item) => item.show);
    setEmpresas(lst);

    document.getElementById('divShops').hidden = !trueOrFalse;
  }

  function download_table_as_csv(table_id, separator = ',') {
    var rows = document.querySelectorAll('table#' + table_id + ' tr');
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (var j = 0; j < cols.length; j++) {
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/(\s\s)/gm, ' ');
        data = data.replace(/"/g, '""');
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    var filename =
      'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string),
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /*
  function filterStoresByCity(event) {
    if (event.target.checked) {
      document.getElementById('divShops').hidden = false;
    }

    lstEmpresa.forEach((item) => {
      if (item.cidade === event.target.value) {
        item.show = event.target.checked;
      }
    });

    const lst = lstEmpresa.filter((item) => item.show);
    setEmpresas(lst);
    lst.length === 0
      ? (document.getElementById('divShops').hidden = true)
      : (document.getElementById('divShops').hidden = false);
  }*/



  const handleClickUpload = (event) => {
    if ($('#distributionCenterSelect').val() == "A DEFINIR") {
      alert('Selecione o CD antes de prosseguir.')
      return;
    }

    if ($('#ddlComprador').val() == "A DEFINIR") {
      alert('Selecione o comprador antes de prosseguir.')
      return;
    }
    hiddenFileInput.current.click();
  };


  async function readUploadFile(e) {
    
    setImportacaoData([]);
    e.preventDefault();
    if (e.target.files) {
      
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: 'array',
          cellDates: true,
          dateNF: 'dd/mm/yyyy',
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        keys = Object.keys(json[0])
        
        for (var i = 0; i < json.length; i++) {
          //console.log(new Date(moment(json[i].dtahoraagenda, "DD/MM/YYYY").format('MM/DD/yyyy')) +"###"+ new Date(moment(new Date()).format('MM/DD/yyyy')));
          
          json[i].status = "<span class='text-orange'><i class='fa fa-clock'></i> Aguardando processamento</span>";

          /*if (json[i].bonificado != "S" && json[i].bonificado != "N")
          {
            json[i].status = "<span class='text-red'><i class='fa fa-times'></i> O campo 'Bonificado' deve ser igual a 'S' ou 'N'</span>";
          }*/


          /*var checkUnique = json.filter((r) => r.seqproduto == json[i].seqproduto && r.bonificado == json[i].bonificado && r.embalagem == json[i].embalagem);

          if (checkUnique.length > 1) {
            json[i].status = "<span class='text-red'><i class='fa fa-times'></i> Existem linhas duplicadas para o produto " + json[i].seqproduto +"</span>";
          }*/
        }

        
        $('#btnProcessar').show();
        
        setImportacaoData(json);
        jsonPlanilha = json;


        window.$('#tblImportacao').modal('show');
        
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  async function ProcessarPlanilha() {
    var json = jsonPlanilha;
    var errorCount = 0;
    var successCount = 0;
    var scrollInitial = 0;
    $('#btnUpload, #btnDownload').hide();

    

    console.log(json[3])

    
    for (var i = 0; i < json.length; i++) {

      for (var a = 2; a < keys.length; a++) {

        try {

          if (json[i][keys[a]] != null) {

            var seqpessoa = todasEmpresas.filter((item) => item.nroempresa == parseInt([keys[a]]))
            const res = await api.post('/listaseparacao/gerar_lista_avulsa_v3/', {
              nroempresa: $('#distributionCenterSelect').val(),
              seqproduto: parseInt(json[i][keys[0]]),
              qtdembpedida: parseFloat(json[i][keys[a]]),
              empdestino: parseInt(keys[a]),
              embalagem: parseFloat(json[i][keys[a]]),
              bonificado: 'N',
              codusuario: token.userName,
              seqlista: 0,
              descricao: '',
              seqpessoa: seqpessoa[0].seqpessoa,
            });
            if (res.data.codResult == 0) {
              json[i].status =
                "<span class='text-success'><i class='fa fa-check'></i> Processado com sucesso</span>";
              successCount += 1;
            } else {
              json[i].status =
                "<span class='text-red'><i class='fa fa-times'></i> " +
                res.data.msg +
                '</span>';
              errorCount += 1;
            }
          }

        } catch (ex) {
          json[i].status =
            "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento. (" +
          ex + parseInt(json[keys[a]]) +
            ')</span>';
          errorCount += 1;
        }

        setImportacaoData(json);
        forceUpdate();

        if (
          $('.import-row').eq(i).offset().top >
          $(window).scrollTop() + $(window).height()
        ) {
          $('html, body').animate(
            { scrollTop: $(window).scrollTop() + $(window).height() },
            'slow',
            function () { },
          );
        }

        scrollInitial += 110;
      }
    }
    $('#btnUpload, #btnDownload').show();
    hiddenFileInput.current.value = null;
    $('html, body').animate({ scrollTop: 0 }, 'slow', function () {
      ShowAlert(
        "<i class='fa fa-check'></i> Importação Finalizada",
        'A importação da planilha em excel foi finalizada. <br/><br/><b>Total de registros:</b> ' +
        json.length +
        " <br/><span class='text-success'><b>Processados com sucesso:</b> " +
        successCount +
        "</span> <br/><span class='text-danger'><b>Processados com erro:</b> " +
        errorCount +
        '</span>',
      );
    });

    handleSearchBuyer();
  }

  return (
    <Fragment>
      <div className="content-header">
        <h1>Lista de separação</h1>
      </div>

      <div className="content">
        <div className="card card-body">
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>CD</label>
                <select
                  className="form-control"
                  id="distributionCenterSelect"
                  onChange={(event) => handleSearchCD(event)}
                  disabled={listCD.length === 0 ? true : false}
                >
                  <option defaultValue>A DEFINIR</option>
                  {listCD.map((item) => (
                    <option value={item.numero_empresa}>
                      {item.nome_reduzido}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>Comprador</label>
                <select
                  id="ddlComprador"
                  className="form-control"
                  onChange={(event) => handleSearchBuyer()}
                  disabled={buyer.length === 0 ? true : false}
                >
                  {buyer.map((item) => (
                    <option key={item.seqcomprador}>{item.comprador}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>Produto</label>

                <input
                  list="datalistTargetusuario"
                  autocomplete="off"
                  type="search"
                  id="inputTargetusuario"
                  className="form-control"
                  placeholder="Buscar por código ou nome do produto"
                  disabled={productSeparationList2.length === 0 ? true : false}
                  onChange={(event) => handleFilterProductList(event)}
                />
                <datalist
                  id="datalistTargetusuario"
                  className="form-control"
                  hidden
                >
                  <option defaultValue>A DEFINIR</option>
                  {productSeparationList2.map((item) => (
                    <option
                      key={`${item.sequencia_produto}_${item.numero_pedido_venda}`}
                      value={item.descricao}
                    >
                      {item.sequencia_produto + '-' + item.descricao}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <div className="col-sm-2" style={{ display: 'flex' }}>
              <div
                className="form-group"
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {listUF.map((UF) => (
                  <div
                    className="form-check sm-1"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    key={UF}
                  >
                    <input
                      className="form-check-input chkUF"
                      type="checkbox"
                      onChange={() => SetUFSelected()}
                      value={UF}
                    />
                    <label className="form-check-label">{UF}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <input
                  value={generateSeparationDate}
                  onChange={(event) =>
                    setGenerationSeparationDate(event.target.value)
                  }
                  className="datetimepicker-input form-control"
                  type="date"
                />
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <button
                  onClick={() => handleGenerateList()}
                  className="btn btn-primary"
                >
                  Gerar lista
                </button>
                <div
                  id="spinner"
                  className="mb-3"
                  style={{
                    textAlign: 'center',
                    position: 'absolute',
                    zIndex: '9',
                    width: '100%',
                    height: '100%',
                    paddingTop: '110px',
                  }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
                <button
                  onClick={() => handleSeparateOrder()}
                  id="SeparateOrderBtn"
                  className="btn btn-success ml-2"
                >
                  Inserir Produto
                </button>

                <button
                  onClick={() => handleSearchBuyer()}
                  className="btn btn-primary ml-2"
                >
                  Recarregar
                </button>

                <div
                  id="btnUpload"
                  className="btn btn-primary ml-2"
                  onClick={handleClickUpload}
                >
                  <span className="fa fa-file-upload fa-fw"></span> Enviar
                  Planilha
                </div>
                <a
                  id="btnModelo"
                  className="btn btn-primary ml-2"
                  href="../apoio/ModeloLista.xlsx"
                  target="_blank"
                >
                  <span className="fa fa-file-download fa-fw"></span> Baixar
                  Modelo
                </a>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  ref={hiddenFileInput}
                  onChange={readUploadFile}
                  style={{ display: 'none' }}
                />
                <button
                  className={
                    'btn btn-secondary ml-2 ' +
                    (productSeparationTableListFiltered.length > 0
                      ? ''
                      : 'd-none')
                  }
                  onClick={() => {
                    window.$('#modal-replicar').modal('show');
                  }}
                >
                  {' '}
                  Transferir{' '}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              {listCompany.length > 0 &&
                productSeparationTableListFiltered.length > 0 ? (
                <div
                  className="card-body table-sm table-responsive p-0"
                  style={{ maxHeight: 'calc(100vh - 330px)', overflow: 'auto' }}
                >
                  <table
                    className="table table-borde red table-hover table-striped2 text-nowrap text-sm table-excel table-excel1"
                    id="mainTable"
                  >
                    <thead>
                      <tr>
                        <th className="fixed-position">
                          <div className="text-right">
                            <button
                              title="Baixar CSV"
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                download_table_as_csv('mainTable');
                              }}
                            >
                              <i className="fa fa-download"></i>
                            </button>
                          </div>
                        </th>
                        <th className="fixed-position">Prod.</th>
                        <th className="fixed-position text-xm">Produto</th>
                        <th className="fixed-position">Emb.</th>
                        <th className="fixed-position">T. Ped.</th>
                        <th className="fixed-position fixed-last">T. Atend.</th>
                        {listCompany.map((company) => (
                          <>
                            <th
                              className={company.uf}
                              key={company.nroempresa}
                              hidden={
                                listFilterUF.length > 0 &&
                                !listFilterUF.includes(company.uf)
                              }
                            >
                              {company.sigla}
                            </th>
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {productSeparationTableListFiltered.length > 0
                        ? productSeparationTableListFiltered.map(
                          (item, index) => (
                            <tr key={item.sequencia_produto}>
                              <td className="px-2 fixed-position">
                                <div className="table-box">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm"
                                    data-toggle="modal"
                                    data-target="#addItemsByLotModal"
                                    data-backdrop="static"
                                    data-keyboard="false"
                                    onClick={() => {
                                      handleFilterProduct(
                                        item.sequencia_produto,
                                        item.descricao,
                                        item.sequencia_lista,
                                        item.sequencia_item_lista,
                                        item.bonificado,
                                        index,
                                      );
                                    }}
                                  >
                                    <i className="fas fa-plus-circle"></i>
                                  </button>
                                </div>
                              </td>
                              <td className="fixed-position">
                                <div className="table-box">
                                  {item.sequencia_produto}
                                </div>
                              </td>
                              <td className="fixed-position">
                                <div className="table-box">
                                  {item.descricao.toUpperCase()}
                                  {item.bonificado == 'S' ? (
                                    <span
                                      class="badge badge-primary"
                                      style={{
                                        position: 'absolute',
                                        right: '5px',
                                      }}
                                    >
                                      B
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </td>
                              <td className="fixed-position">
                                <div className="table-box">
                                  {(item.embalagem == ''
                                    ? ''
                                    : item.embalagem + ' - ') +
                                    item.quantidade_embalagem}
                                </div>
                              </td>

                              {showTotalQuantityOrdered(item)}
                              {showTotalQuantityServed(item)}
                              {showTotalByProduct(item)}
                            </tr>
                          ),
                        )
                        : null}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Fragment></Fragment>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="addItemsByLotModal"
          aria-hidden="true"
          role="dialog"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog" style={{ marginLeft: '5vw' }}>
            <div className="modal-content" style={{ width: '90vw' }}>
              <div className="modal-header">
                <div className="modal-title">
                  {' '}
                  Adicionar item por lote - {productDescription}{' '}
                  {currentProduct != null ? (
                    currentProduct.bonificado == 'S' ? (
                      <span class="badge badge-primary">B</span>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => OnCloseModal()}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  class="row"
                  id="resumo"
                  style={{
                    border: '1px solid #f3eebd',
                    margin: '0 0px 15px',
                    backgroundColor: '#fbffe3',
                    padding: '7px',

                  }}
                >
                  <div class="col-sm-2">
                    <b>Seq. Lote:</b> <span id="rSeqLote"></span>
                  </div>
                  <div class="col-sm-4">
                    <b>Lote:</b> <span id="rLote"></span>
                  </div>
                  <div class="col-sm-2">
                    <b>Emb.:</b> <span id="rEmb"></span>
                  </div>
                  <div class="col-sm-2">
                    <b>Qtd. Atend.:</b> <span id="rQtdAtendida"></span>
                  </div>
                  <div class="col-sm-2">
                    <b>Saldo:</b> <span id="rSaldo"></span>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="scroll card-body table-responsive p-0"
                    style={{ 'max-height': '75vh', overflo: 'auto !important' }}
                  >
                    <div
                      id="grid-spinner"
                      className="mb-3"
                      style={{
                        textAlign: 'center',
                        position: 'absolute',
                        zIndex: '9',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255,255,255,.5)',
                        paddingTop: '50px',
                      }}
                    >
                      <Spinner animation="border" variant="primary" />
                    </div>
                    <table
                      className="table table-border ed table-hover table-striped2 text-nowrap text-sm table-excel table-excel2"
                      id="modalTable"
                    >
                      <thead>
                        <tr>
                          <th style={{ left: '0px' }} className="">
                            Seq.lote
                          </th>
                          <th style={{ left: '63.3906px' }} className="">
                            Lote
                          </th>
                          <th style={{ left: '206.375px' }} className="">
                            Emb.
                          </th>
                          <th style={{ left: '249.141px' }} className="">
                            Qtd.Atendida
                          </th>
                          <th style={{ left: '347.469px' }} className="">
                            Saldo
                          </th>
                          <th hidden={boolHideItemDetails}>Saldo Inicial</th>
                          {listCompany.map((company) => (
                            <>
                              <th
                                hidden={boolHideItemDetails}
                                key={company.nroempresa}
                              >
                                Detalhes id - {company.nroempresa}
                              </th>
                              <th className={company.uf}>
                                <span>{company.sigla}</span>
                              </th>
                            </>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {productSeparationAppointments !== null ? (
                          <Fragment>
                            {productSeparationAppointments.map((item, index) =>
                              item.sequencia_produto ===
                                filteredProduct.sequencia_produto ? (
                                <>
                                  <tr>
                                    <td style={{ left: '0px' }} className="">
                                      <div className="table-box">
                                        {item.sequencia_lote}
                                      </div>
                                    </td>
                                    <td
                                      style={{ left: '63.3906px' }}
                                      className=""
                                    >
                                      <div className="table-box">
                                        {item.sequencia_fornecedor} -{' '}
                                        {item.fantasia} - R${' '}
                                        {item.valor_unitario.toFixed(2)}
                                      </div>
                                    </td>
                                    <td
                                      style={{ left: '206.375px' }}
                                      className=""
                                    >
                                      <div className="table-box">
                                        {(filteredProduct.embalagem == ''
                                          ? ''
                                          : filteredProduct.embalagem + ' - ') +
                                          item.embalagem}
                                      </div>
                                    </td>
                                    <td
                                      style={{ left: '249.141px' }}
                                      className=" text-bold"
                                    >
                                      <div className="table-box">&nbsp;</div>
                                    </td>
                                    <td
                                      style={{ left: '347.469px' }}
                                      className=""
                                    >
                                      <div className="table-box">
                                        {item.saldo}
                                      </div>
                                    </td>
                                    <td hidden={boolHideItemDetails}>
                                      <div className="table-box">
                                        {item.saldo}
                                      </div>
                                    </td>
                                    {listCompany.map((itemCompany, index2) => (
                                      <>
                                        <td hidden={boolHideItemDetails}>
                                          <div className="table-box">
                                            {JSON.stringify(
                                              returnInfoItems(
                                                itemCompany,
                                                item.sequencia_lote,
                                              ),
                                            )}
                                          </div>
                                        </td>
                                        <td
                                          className={itemCompany.uf}
                                          key={itemCompany.nroempresa}
                                          hidden={
                                            listFilterUF.length > 0 &&
                                            !listFilterUF.includes(
                                              itemCompany.uf,
                                            )
                                          }
                                        >
                                          <div className="table-box">
                                            <input
                                              type="number"
                                              min="0"
                                              style={{ width: 70 }}
                                              id={
                                                'item_' + index + '_' + index2
                                              }
                                              className={
                                                'input-item' +
                                                (index + 1) +
                                                ' itemC_' +
                                                index2 +
                                                ' inp'
                                              }
                                              disabled={
                                                itemCompany.enabled == 'true'
                                                  ? false
                                                  : true
                                              }
                                              data-otiginal-enabled={
                                                itemCompany.enabled
                                              }
                                              data-beforeValue={
                                                returnInfoItems(
                                                  itemCompany,
                                                  item.sequencia_lote,
                                                  '#item_' +
                                                  index +
                                                  '_' +
                                                  index2,
                                                  index2,
                                                  itemCompany.enabled,
                                                ).quantidade_atendida
                                              }
                                              onFocus={(event) => {
                                                RedirectFocus(
                                                  event,
                                                  index2,
                                                  index,
                                                );
                                              }}
                                              onBlur={(event) => {
                                                totalsQuantities(
                                                  event,
                                                  'item_' +
                                                  index +
                                                  '_' +
                                                  index2,
                                                  index + 1,
                                                );

                                                ResetResumo();
                                                //$('#resumo').hide();
                                              }}
                                              defaultValue={
                                                returnInfoItems(
                                                  itemCompany,
                                                  item.sequencia_lote,
                                                  '#item_' +
                                                  index +
                                                  '_' +
                                                  index2,
                                                  index + 1,
                                                  item.sequencia_item,
                                                ).quantidade_atendida
                                              }
                                            />

                                            <span style={{ display: 'none' }}>
                                              {setTimeout(function () {
                                                totalsQuantities(null);
                                              }, 1000)}
                                            </span>
                                          </div>
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                </>
                              ) : (
                                <></>
                              ),
                            )}
                          </Fragment>
                        ) : (
                          <></>
                        )}

                        {filteredProduct !== null ? (
                          <Fragment>
                            <tr>
                              <td style={{ left: '0px' }} className="">
                                <div className="table-box">0</div>
                              </td>
                              <td style={{ left: '63.3906px' }} className="">
                                <div className="table-box">
                                  Apontamento sem Lote
                                </div>
                              </td>
                              <td style={{ left: '206.375px' }} className="">
                                <div className="table-box"></div>
                              </td>
                              <td
                                style={{ left: '249.141px' }}
                                className=" text-bold saldo-lote-0"
                              >
                                <div className="table-box saldo-lote-0"></div>
                              </td>
                              <td
                                style={{ left: '0px' }}
                                className=""
                                hidden={boolHideItemDetails}
                              >
                                <div className="table-box saldo-lote-0"></div>
                              </td>
                              <td style={{ left: '347.469px' }} className="">
                                <div className="table-box"></div>
                              </td>
                              {listCompany.map((itemCompany, index2) => (
                                <>
                                  <td hidden={boolHideItemDetails}>
                                    <div className="table-box">
                                      {JSON.stringify(
                                        returnInfoItems(itemCompany, 0),
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    className={itemCompany.uf}
                                    key={itemCompany.nroempresa}
                                    hidden={
                                      listFilterUF.length > 0 &&
                                      !listFilterUF.includes(itemCompany.uf)
                                    }
                                  >
                                    <div className="table-box">
                                      <input
                                        id={'qtdSemLote' + index2}
                                        type="number"
                                        min="0"
                                        style={{ width: 70 }}
                                        className={
                                          'itemC_' +
                                          index2 +
                                          ' inp input-item-lote-0'
                                        }
                                        onChange={(event) =>
                                          totalsQuantitiesLote0(event)
                                        }
                                        onFocus={(event) => {
                                          RedirectFocus(event, index2, -1);
                                        }}
                                        onBlur={(event) => {

                                          ResetResumo();
                                          //$('#resumo').hide();
                                        }}
                                        disabled={
                                          itemCompany.enabled == 'true'
                                            ? false
                                            : true
                                        }
                                        data-otiginal-enabled={
                                          itemCompany.enabled
                                        }
                                        data-initial-value={
                                          returnInfoItems(
                                            itemCompany,
                                            0,
                                            '#item_' + 0 + '_' + index2,
                                            index2,
                                            itemCompany.enabled,
                                          ).quantidade_atendida
                                        }
                                        defaultValue={
                                          returnInfoItems(
                                            itemCompany,
                                            0,
                                            '#item_' + 0 + '_' + index2,
                                            index2,
                                            itemCompany.enabled,
                                          ).quantidade_atendida
                                        }
                                      />
                                    </div>
                                  </td>
                                </>
                              ))}
                            </tr>
                          </Fragment>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    <span style={{ display: 'none' }}>
                      {setTimeout(function () {
                        fixarColunas();
                        SetUFSelected();
                        $('#modalTable tr:last input').each(function () {
                          $(this).val($(this).attr('data-initial-value'));
                        });
                        $('#modalTable').show();
                      }, 500)}
                      ]{' '}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      modalSave();
                    }}
                  >
                    Salvar
                  </button>
                  <button
                    type="button"
                    className="btn text-red ml-2"
                    data-dismiss="modal"
                    onClick={() => {
                      ResetResumo();
                      //$('#resumo').hide();
                      OnCloseModal();
                      $('#addItemsByLotModal').modal('hide');
                    }}
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="SeparateOrderModal"
          className="modal fade"
          role="dialog"
          style={{ overflowY: 'hidden' }}
        >
          <div className="modal-dialog" style={{ marginLeft: '5vw' }}>
            <div
              className="modal-content"
              style={{ width: '90vw', height: '90vh' }}
            >
              <div className="modal-header">
                <h4 className="modal-title">Pedidos Avulsos</h4>

                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    SeparateOrderModalClose();
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body row p-2">
                <div className="col-3">
                  <div
                    className="card"
                    style={{ height: '100%', width: '100%' }}
                  >
                    <div
                      className="card-body row-col"
                      style={{
                        // display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <h4>Produto</h4>
                      <hr />
                      <div
                        className=""
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div
                          className=""
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className="form-group">
                            <label>Código</label>
                            <input
                              id="txtCodigoProduto"
                              className="form-control"
                              onKeyUp={(event) => BuscaProdutosComprador(event)}
                              placeholder="Código do produto"
                            />
                          </div>

                          <div className="form-group">
                            <label>&nbsp;</label>
                            <button
                              type="button"
                              className="form-control btn-info"
                              onClick={(event) => BuscaProdutosComprador(event)}
                            >
                              🔎
                            </button>
                          </div>
                        </div>

                        {/* <div className="col-1">
                          <div className="form-group">
                            <label>&nbsp;</label>
                            <button
                              type="button"
                              className="form-control btn-info"
                              onClick={(event) => BuscaProdutosComprador(event)}
                            >
                              🔎
                            </button>
                          </div>
                        </div> */}

                        <div
                          className=""
                          style={{
                            width: '100%',
                          }}
                        >
                          <div className="form-group">
                            <label>Descrição</label>
                            <input
                              id="txtDescricaoProduto"
                              disabled
                              className="form-control"
                              placeholder="Descrição do produto"
                            />
                          </div>
                        </div>

                        <div className="" style={{ width: '100%' }}>
                          <div className="form-group">
                            <label>
                              <input
                                id="ckbBonificado"
                                className="checkbox"
                                type="checkbox"
                                onChange={(event) =>
                                  BuscaProdutosComprador(event)
                                }
                              />
                              &nbsp; Bonificado
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-7"
                  style={{
                    height: '100%',
                  }}
                >
                  <div
                    className="card"
                    style={{ height: '100%', marginBottom: 0 }}
                  >
                    <div
                      className="card-body"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div className="row align-items-center justify-content-between">
                        <h4>Empresas</h4>

                        <span
                          className="badge badge-primary"
                          style={{
                            fontSize: '16px',
                          }}
                        >
                          Total: <span id="totalizador">0</span>
                        </span>
                      </div>
                      <hr />
                      <div
                        className="row"
                        style={{
                          // position: 'relative',
                          height: '100%',
                        }}
                      >
                        <div className="col-sm-4" style={{ flex: 2 }}>
                          <div id="boxLocais" className="row">
                            <div className="col-sm-12">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input local-todos"
                                    id="localTodosChk"
                                    type="checkbox"
                                  />
                                  &nbsp; Marcar Todos
                                </label>
                              </div>
                            </div>
                            {locais.map((item, index) => (
                              <div
                                className="col-sm-12"
                                key={index}
                                style={{ fontSize: '12px' }}
                              >
                                <div className="form-check">
                                  <label className="form-check-label ">
                                    <input
                                      data-cidade={item.split('-')[0]}
                                      className={
                                        'form-check-input local cidade ckb-item-' +
                                        item.split('-')[1]
                                      }
                                      value={item}
                                      type="checkbox"
                                    />
                                    &ensp;{item.split('-')[0]}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          className="col-sm-8"
                          style={{ flex: 4, position: 'relative' }}
                        >
                          <div
                            className="row bg-gray-light p-3 rounded align-content-start"
                            id="divShops"
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              overflowY: 'auto',
                            }}
                          >
                            {empresas.map((item) => (
                              <div
                                data-cidade={item.cidade}
                                className={
                                  'col-6 box-empresa empr-item empr-item-' +
                                  item.seqpessoa
                                }
                                style={{
                                  marginBottom: '4px',
                                }}
                              >
                                <div
                                  className={'row shops'}
                                  style={{ fontSize: '14px' }}
                                >
                                  <div className="col-4">
                                    <input
                                      type="number"
                                      min="0"
                                      max="99999"
                                      className="form-control tocount"
                                      id={item.nroempresa}
                                      style={{
                                        // marginBottom: 3,
                                        width: '60px',
                                        height: '25px',
                                        padding: 0,
                                        paddingLeft: '4px',
                                        fontSize: '14px'
                                      }}
                                      onBlur={() => somatoria()}
                                      onChange={() => somatoria()}
                                    />
                                  </div>
                                  <div className="col-8">
                                    <span style={{ marginLeft: 12 }}>
                                      {item.nome_reduzido}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-2"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      modalSeparateOrderGetShops();
                      //ReplicarLista();
                    }}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Salvar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      Closemodal('show');
                    }}
                  >
                    Fechar
                  </button>
                </div>
              </div>
              {/* <div className="modal-footer justify-content-between">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      modalSeparateOrderGetShops();
                    }}
                    className="btn btn-primary"
                  >
                    Salvar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      SeparateOrderModalClose();
                    }}
                    className="btn text-red ml-2"
                  >
                    Fechar
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      

      <div id="modal-replicar" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Replicar lista de separação</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <small>
                <i>
                  Selecione o cd de destino e clique em "Salvar" para replicar a
                  lista de separação.
                </i>
              </small>

              <div className="form-group mt-3">
                <label>CD de destino</label>
                <select
                  className="form-control"
                  id="cdDeDestino"
                  disabled={listCD.length === 0 ? true : false}
                >
                  <option defaultValue>A DEFINIR</option>
                  {listCD.map((item) => (
                    <option value={item.numero_empresa}>
                      {item.nome_reduzido}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  ReplicarLista();
                }}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn text-red"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>




      <div id="tblImportacao" className="modal fade" role="dialog">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content" style={{ width: '1100px' }}>
            <div className="modal-header">
              <h5 className="modal-title">Importar lista de separação</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{width:'1100px'}}>
              <div id="" className="mt-3 ml-3 mr-3">
                <div className="alert alert-dark">
                  <div className="row">
                    <div className="col-2 text-center">
                      <i className="fa fa-clock fa-5x"></i>
                    </div>
                    <div className="col-9">
                      <h4 className="alert-heading">Atenção</h4>
                      <p className="mb-0">
                        Não feche esta janela até que todos os registros
                        tenham sido concluídos.
                      </p>
                      <p>
                        <small>
                          Acompanhe a evolução do processamento na coluna{' '}
                          <b>Status</b>.
                        </small>
                      </p>
                      <button
                        id="btnProcessar"
                        className="btn btn-success "
                        onClick={() => {
                          ProcessarPlanilha();
                        }}
                      >
                        <span className="fa fa-play fa-fw"></span> Iniciar
                        processamento da planilha
                      </button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive" style={{ height: '300' }}>
                  <table className="table table-striped table-hover tabl e-sm ">

                  <thead>
                    <tr>

                      {keys.map((item2, index2) => (
                        <th key={index2}>{item2}</th>
                      ))}
                      <th>Status</th>
                    </tr>
                  </thead>


                  <tbody id="table-body">
                    {importacaoData.map((item, index) => (
                      <tr className="import-row" key={index}>

                        {keys.map((item2, index2) => (
                            <td key={index2}>{item[item2]}</td>
                        ))}

                        <td
                          dangerouslySetInnerHTML={{ __html: item.status }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                </div>
              </div>





            </div>
            <div className="modal-footer ">
              
              <button
                type="button"
                className="btn text-red"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>




      <div id="modal-alert" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SeparationList;
