import { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CompanyTab } from '../components/Apontamento/CompanyTab';
import { ProductTab } from '../components/Apontamento/ProductTab';

import api from '../services/api';

const token = JSON.parse(localStorage.getItem('token'));
const codigo_usuario = token && token.userName ? token.userName : '';
let selectedTab = 'produto';

export default function Appointment() {
  const [listCD, setListCD] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    try {
      async function fetchData() {
        const res = await api.get('/usuario/listausuario/');
        setUsers(res.data.results);
      }

      fetchData();
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }, []);

  useEffect(() => {
    try {
      async function fetchData() {
        const res = await api.get('/empresa/centrodistribuicao/');
        setListCD(res.data.results);
      }

      fetchData();
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }, []);

  function closeModal() {
    const modal = document.querySelector('.modal');
    modal.style.display = 'none';
  }

  return (
    <>
      <div className="content-header d-flex align-items-center justify-content-between">
        <h1>Apontamento</h1>
      </div>

      <div className="content">
        <Tabs
          defaultActiveKey="product"
          onSelect={(firstTab, secondTab) => {
            selectedTab = firstTab;
          }}
        >
          <Tab eventKey="product" title="Produto" tab={selectedTab}>
            <ProductTab
              listCD={listCD}
              users={users}
              codigoUsuario={codigo_usuario}
            />
          </Tab>

          <Tab eventKey="company" title="Empresa">
            <CompanyTab
              listCD={listCD}
              users={users}
              codigoUsuario={codigo_usuario}
              onCloseModal={closeModal}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
