import React, { useEffect } from 'react';

import AppRoutes from './routes';

import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

function App() {
  return (
    <div className="wrapper">
      <AppRoutes />
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}

export default App;
