import { useEffect, useRef, useState } from 'react';
import React from 'react';
import api from '../../../../services/api';
import $ from 'jquery';
import css from './Conferencia.css';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as xlsx from 'xlsx/xlsx.mjs';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import { toast } from 'react-toastify';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";

registerLocale('pt', pt);
xlsx.set_cptable(cpexcel);

var respMsg = '';
const token = JSON.parse(localStorage.getItem('token'));

const codigo_usuario = token && token.userName ? token.userName : '';
const nome_usuario = token && token.displayName ? token.displayName : '';
const email_usuario = token && token.email ? token.email : '';

var todosProdutos = [];
var totalAtendido = 0;
var totalConferido = 0;

function ShowAlert(title, message) {
  $('#modal-alert .modal-title').html(title);
  $('#modal-alert .modal-body').html(
    '<i class="fa fa-info-circle fa-fw text-gray"></i> ' + message,
  );
  window.$('#modal-alert').modal('show');
}

let selectedTab = 'company';

function Conferencia() {
  /* Company Tab */
  const [currentEmpresa, setCurrentEmpresa] = useState({});
  const [currentProduto, setCurrentProduto] = useState({});
  const [currentLote, setCurrentLote] = useState({});
  const [lotes, setLotes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [locais, setLocais] = useState([]);
  const [prodInfos, setProdInfos] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const hiddenFileInput = React.useRef(null);
  const [cutReason, setCutReason] = useState('');
  /* Product Tab */
  const [products, setProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [lots, setLots] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [currentCompany, setCurrentCompany] = useState({});
  const [currentLot, setCurrentLot] = useState([]);
  const [conferenceValue, setConferValue] = useState(0);
  const checkboxCompaniesRef = useRef(null);
  const [companyInfos, setCompanyInfo] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const ref_potato_table = useRef(null);
  

  useEffect(() => {
    $('#txtFiltraEmpresa').focus();
    BuscaEmpresas();
    $('#boxProdutos').show();
    $('#boxLotes').hide();
    $('.lote-linha-box').hide();

    window.$('#modal-lote').on('hidden.bs.modal', function () {
      $('.lote-linha').removeClass('active-conferencia');
    });

    $('#table-spinner').hide();
    $('#table-spinner2').hide();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);


  const exportTableToPDF = useReactToPrint({
    content: () => ref_potato_table.current,
  });

  var utc = new Date().toLocaleDateString();


  async function BuscaEmpresas() {
    try {
      const res = await api.get('/empresa/listaempresa/');

      if (res.data.results != null) {
        if (res.data.results.length == 0) {
          toast.error('Nenhuma empresa foi localizada.');
        }

        res.data.results.sort(dynamicSort('cidade'));
        setLocais(distinct(res.data.results, 'cidade'));
        res.data.results.sort(dynamicSort('nroempresa'));
        setEmpresas(res.data.results);
        $('.btnEmpresaSearch').hide();
        ValidarEmpresas();
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function ValidarEmpresas(next = '') {
    $('#table-spinner').show();

    try {
      if (next == '') {
        todosProdutos = [];

        const res = await api.get(
          '/listaseparacao/v1/prodconferencia_v2/0/0/' + token.userName,
        );

        if (res.data.results != null) {
          $('.btnEmpresaSearch').hide();
          $('.btnEmpresaSearch').removeClass('btnEmpresa');
          for (var i = 0; i < res.data.results.length; i++) {
            $('.empNro' + res.data.results[i].seqpessoa).show();
            $('.empNro' + res.data.results[i].seqpessoa).addClass('btnEmpresa');

            res.data.results[i].empresaNome = $(
              '.empNro' + res.data.results[i].seqpessoa,
            )
              .find('.nome-empresa')
              .text();
            todosProdutos.push(res.data.results[i]);
          }
        }

        if (res.data.next != null) {
          $('#table-spinner').hide();
          ValidarEmpresas(res.data.next);
        } else {
          DestacarEmpresas();
        }
      } else {
        next = next.replace('http', 'https');
        const res = await api.get(next);

        if (res.data.results != null) {
          for (var i = 0; i < res.data.results.length; i++) {
            $('.empNro' + res.data.results[i].seqpessoa).show();
            $('.empNro' + res.data.results[i].seqpessoa).addClass('btnEmpresa');

            todosProdutos.push(res.data.results[i]);
          }
        }

        if (res.data.next != null) {
          ValidarEmpresas(res.data.next);
        } else {
          DestacarEmpresas();
        }
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }

    $('#table-spinner').hide();
  }

  function DestacarEmpresas() {
    $('.btnEmpresaSearch').removeClass('highlight');

    $('.btnEmpresa').each(function () {
      var c = todosProdutos.filter(
        (item) => item.seqpessoa == $(this).attr('data-seqpessoa'),
      );
      var d = c.filter((item) => item.qtdconferida > 0);

      if (c.length == d.length) {
        $(this).addClass('highlight');
      }
    });
    for (var a = 0; a < todosProdutos.length; a++) {}
  }

  async function BuscaProdutos(seqpessoa) {
    $('#table-spinner2').show();

    try {
      const res = await api.get(
        '/listaseparacao/v1/prodconferencia_v2/0/' +
          seqpessoa +
          '/' +
          token.userName,
      );

      if (res.data.results != null) {
        if (res.data.results.length == 0) {
          toast.error(
            'Nenhum produto foi localizado para a empresa selecionada.',
          );
        }

        for (var i = 0; i < res.data.results.length; i++) {
          res.data.results[i].lineColor = '';

          if (
            res.data.results[i].qtdeatendida >
              res.data.results[i].qtdconferida &&
            res.data.results[i].qtdconferida > 0
          ) {
            res.data.results[i].lineColor = '#fff7cf'; //Amarelo
          }
          if (res.data.results[i].qtdconferida == 0) {
          }
          if (
            res.data.results[i].qtdeatendida <= res.data.results[i].qtdconferida
          ) {
            res.data.results[i].lineColor = '#c3e3cc'; //Verde
          }
        }
        res.data.results.sort(dynamicSort('descricao'));
        setProdutos(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }

    $('#table-spinner2').hide();
  }

  async function BuscaLote(
    nroempresa,
    seqproduto,
    seqpessoa,
    seqlista,
    seqitem,
  ) {
    $('#table-spinner2').show();

    try {
      const res = await api.get(
        '/listaseparacao/busca_lote_conferencia/' +
          nroempresa +
          '/' +
          seqproduto +
          '/' +
          seqpessoa,
      );
      if (res.data.results != null) {
        //const res2 = await api.post('/listaseparacao/produtoconferido/', {
        const res2 = await api.post('/listaseparacao/busca_conferencia_v2/', {
          numero_empresa: nroempresa,
          sequencia_lista: seqlista,
          sequencia_produto: seqproduto,
          sequencia_pessoa: 0,
          data_inicial: null,
          data_final: null,
          status: 'P,C,L',
          codigo_usuario: null,
        });

        const prodFilter = res2.data.filter(
          (prod) => prod.sequencia_produto == seqproduto,
        );

        if (res2.data != null) {
          for (var i = 0; i < res.data.results.length; i++) {
            if (res.data.results.length == 0) {
              toast.error(
                'Nenhum lote foi localizado para o produto selecionado.',
              );
            }

            res.data.results[i].quantidade_conferida = 0;
            res.data.results[i].temp = 0;
            for (var a = 0; a < prodFilter.length; a++) {
              if (
                res.data.results[i].sequencia_lote ==
                  prodFilter[a].sequencia_lote &&
                prodFilter[a].sequencia_item_lista == seqitem
              ) {
                if (
                  prodFilter[a].sequencia_pessoa != currentEmpresa.seqpessoa
                ) {
                  res.data.results[i].quantidade_conferida +=
                    prodFilter[a].quantidade_conferida;

                  res.data.results[i].bonificado = prodFilter[a].bonificado;
                } else {
                  res.data.results[i].temp +=
                    prodFilter[a].quantidade_conferida;
                  res.data.results[i].quantidade_atendida =
                    prodFilter[a].quantidade_atendida;

                  res.data.results[i].bonificado = prodFilter[a].bonificado;
                }
              }

              if (
                res.data.results[i].sequencia_lote ==
                  prodFilter[a].sequencia_lote &&
                prodFilter[a].sequencia_produto == seqproduto
              ) {
                if (
                  prodFilter[a].sequencia_pessoa != currentEmpresa.seqpessoa
                ) {
                  res.data.results[i].quantidade_conferida +=
                    prodFilter[a].quantidade_conferida;

                  res.data.results[i].bonificado = prodFilter[a].bonificado;
                } else {
                  // res.data.results[i].temp +=
                  //   prodFilter[a].quantidade_conferida;
                  // res.data.results[i].quantidade_atendida =
                  //   prodFilter[a].quantidade_atendida;
                  // res.data.results[i].bonificado = prodFilter[a].bonificado;
                }
              }
            }
          }
        }
        setLotes(res.data.results);
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }

    $('#table-spinner2').hide();
  }

  async function Salvar() {
    try {
      var id_conf =
        currentProduto.codusuario +
        currentProduto.seqlista +
        currentProduto.seqitem +
        moment(new Date()).format('DDMMyyyyHHmmss');
      var qtdValid = currentLote.saldo - currentLote.quantidade_conferida;

      if (qtdValid >= Number($('#txtQtd').val())) {
        const res = await api.post('/listaseparacao/salvarconferencia_v2/', {
          codigo_usuario: currentProduto.codusuario,
          sequencia_lista: currentProduto.seqlista,
          sequencia_produto: currentProduto.seqproduto,
          numero_empresa: currentProduto.nroempresa,
          sequencia_cliente: currentEmpresa.seqpessoa,
          quantidade_embalagem: currentLote.embalagem,
          quantidade_conferida: Number($('#txtQtd').val()),
          sequencia_item_lista: currentProduto.seqitem,
          sequencia_lote: currentLote.sequencia_lote,
          valor_embalagem_produto: currentLote.valor,
          tipo_lote: currentLote.tipo_lote,
          status: 'C',
          data_hora_conferencia: new Date(),
          conferencia:
            currentProduto.codusuario +
            '' +
            currentProduto.seqlista +
            '' +
            currentProduto.seqitem +
            '' +
            moment(new Date()).format('DDMMyyyyHHmmss'),
          sequencia_separador: 0,
          origem: 'N',
          bonificado: currentProduto.bonificado,
        });

        window.$('#modal-lote').modal('hide');

        if (res.data != null) {
          //ShowAlert("Atenção", res.data[0].mensagem);
          //BuscaLote(currentProduto.nroempresa, currentProduto.seqproduto, currentEmpresa.seqpessoa)

          if (res.data[0].numero_erro == '0') {
            BuscaProdutos(currentEmpresa.seqpessoa);
            BackToProdutos();
          } else {
            ShowAlert('Atenção', res.data[0].mensagem);
          }
        } else {
          ShowAlert('Erro', 'Um erro ocorreu ao fazer a chamada da API');
        }
      } else {
        window.$('#modal-lote').modal('hide');
        ShowAlert(
          'Quantidade inválida',
          'A quantidade deve ser menor ou igual a ' + qtdValid,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  function FindEmpresa(id) {
    var toReturn = null;
    empresas.forEach(function (item, a) {
      if (parseInt(empresas[a].nroempresa) == parseInt(id)) {
        toReturn = empresas[a].nome_reduzido;
      }
    });

    return toReturn;
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  async function BtnEmpresaClicked(btn) {
    setCurrentEmpresa(empresas[$('#' + btn).attr('data-key')]);

    BuscaProdutos($('#' + btn).attr('data-seqpessoa'));

    $('.btnEmpresa').removeClass('active-conferencia');
    $('.produto-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');

    BackToProdutos();

    ValidarEmpresas();
  }

  async function ProdutoLinhaClicked(btn) {
    setCurrentProduto(produtos[$('#' + btn).attr('data-key')]);
    var seqproduto = $('#' + btn).attr('data-id');
    var nroempresa = $('#' + btn).attr('data-empresa');
    var seqitem = $('#' + btn).attr('data-seqitem');

    todosProdutos.sort(dynamicSort('empresaNome'));
    totalAtendido = 0;
    totalConferido = 0;
    const itens = todosProdutos.filter((item) => item.seqproduto == seqproduto);

    setProdInfos(itens);
    forceUpdate();

    for (var a = 0; a < itens.length; a++) {
      totalAtendido += Number(itens[a].qtdeatendida);
      totalConferido += Number(itens[a].qtdconferida);
    }

    $('#totalAtendido').html(totalAtendido);
    $('#totalConferido').html(totalConferido);

    forceUpdate();
    BuscaLote(
      nroempresa,
      seqproduto,
      currentEmpresa.seqpessoa,
      produtos[$('#' + btn).attr('data-key')].seqlista,
      seqitem,
    );

    $('.produto-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');
    $('#boxProdutos').slideUp('fast');
    $('#boxLotes').fadeIn('slow');
  }

  async function LoteLinhaClicked(btn) {
    setCurrentLote(lotes[$('#' + btn).attr('data-key')]);

    if (lotes[$('#' + btn).attr('data-key')].quantidade_atendida > 0) {
      $('#txtQtd').val(
        lotes[$('#' + btn).attr('data-key')].quantidade_atendida,
      );
    } else {
      $('#txtQtd').val('');
    }

    $('.lote-linha').removeClass('active-conferencia');
    $('#' + btn).addClass('active-conferencia');
    window.$('#modal-lote').modal('show');

    setTimeout(function () {
      $('#txtQtd').focus();
      $('#txtQtd').select();
    }, 1000);
  }

  async function BackToProdutos() {
    $('#boxProdutos').slideDown('fast');
    $('#boxLotes').hide();
    $('.produto-linha').removeClass('active-conferencia');
  }

  async function FiltrarEmpresas() {
    $('.btnEmpresa').hide();
    $(
      ".btnEmpresa:containsi('" + $('#txtFiltraEmpresa').val() + "')",
    ).slideDown('fast');
  }

  $.extend($.expr[':'], {
    containsi: function (elem, i, match, array) {
      return (
        (elem.textContent || elem.innerText || '')
          .toLowerCase()
          .indexOf((match[3] || '').toLowerCase()) >= 0
      );
    },
  });

  async function CloseModal() {
    window.$('.modal').modal('hide');
  }

  async function LiberarFaturamento(seqpessoa) {
    const options = {
      title: 'Liberar Farutamento',
      message: 'Deseja realmente liberar o faturamento para esta empresa?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => FinalizarLiberacao(seqpessoa),
        },
        {
          label: 'Não',
          onClick: () => {},
        },
      ],
    };

    confirmAlert(options);
  }

  async function FinalizarLiberacao(seqpessoa) {
    try {
      const res = await api.post('/listaseparacao/libera_faturamento', {
        seqpessoa: seqpessoa,
        chapa_usuario: '' + token.userName,
      });
      var result = null;

      if (res.data != null) {
        ShowAlert(
          'Sucesso!',
          'A liberação de faturamento foi concluída com sucesso.',
        );
        ValidarEmpresas();
        setProdutos([]);
      } else {
        ShowAlert('Atenção', 'Não foi possível liberar o faturamento.');
      }
    } catch (err) {
      console.log(err);
      ShowAlert('Atenção', 'Ocorreu um erro ao liberar o faturamento.');
    }
  }

  async function handleCutProductWithoutStorage(e, product) {
    e.preventDefault();

    try {
      if (cutReason === '') return toast.error('Insira um motivo.');

      if (cutReason.replace(/ +/g, ' ').trim().length < 20)
        return toast.error(
          'Digite um motivo com pelo menos 20 caracteres válidos.',
        );

      const data = {
        codigo_usuario: product.codusuario,
        sequencia_item: product.seqitem,
        sequencia_lista: product.seqlista,
        motivo: cutReason,
      };

      const response = await api.post('listaseparacao/v1/corte_produto', data);

      if (response.data.codResult === 1) {
        return toast.error(`${response.data.msg}`);
      }

      setCutReason('');
      CloseModal();
      toast.success('Produto cortado com sucesso.');
      $('#boxLotes').hide();
      $('#boxProdutos').show();
      setProdutos([]);
      BuscaEmpresas();
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function getProducts() {
    try {
      const spinLoader = document.querySelector('.table-spinner');
      spinLoader.classList.add('loading-active');

      const response = await api.get(
        `/listaseparacao/v3/prodconferencia/0/0/${token.userName}`,
      );

      const { results: productsResults } = response.data;

      if (productsResults.length === 0) {
        spinLoader.classList.remove('loading-active');
        toast.warning('Nenhum produto foi encontrado.');
        return;
      }

      setProducts(productsResults);

      const distinctProducts = Object.values(
        productsResults.reduce((acc, product) => {
          const key = product.seqproduto + '_' + product.bonificado;
          acc[key] = acc[key] || product;
          acc[key].seqproduto = product.seqproduto;
          return acc;
        }, {}),
      );

      const sortProductsDistincted = distinctProducts.sort(
        (a, b) => a.seqproduto - b.seqproduto,
      );

      setProductsList(sortProductsDistincted);
      spinLoader.classList.remove('loading-active');
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
      const spinLoader = document.querySelector('.table-spinner');
      spinLoader.classList.remove('loading-active');
    }
  }

  function searchProductsFilter() {
    const filterElement = document.querySelector('#textFilterProduct');
    const products = document.querySelectorAll('.btnProduct');

    if (filterElement.value !== '') {
      for (const product of products) {
        let productTitle = product.querySelector('span');
        productTitle = productTitle.textContent.toLowerCase();

        let filterText = filterElement.value.toLowerCase();

        if (!productTitle.includes(filterText)) {
          product.style.display = 'none';
        } else {
          product.style.display = 'block';
        }
      }
    } else {
      for (const product of products) {
        product.style.display = 'block';
      }
    }
  }

  async function productRowClicked(product, index) {
    getProducts();
    setCurrentProduct(product);

    //Search companies
    setCompaniesList([]);
    setCompanies([]);
    setLots([]);
    const spinLoader = document.querySelector('.table-spinner2');
    spinLoader.classList.add('loading-active');

    getCompanyByProductClicked(product.seqproduto, product.bonificado);

    //Change product line styles
    const productRows = document.querySelectorAll('.btnProduct');
    const productIndexRow = productRows[index];
    productRows.forEach((item) => item.classList.remove('active-conferencia'));
    productIndexRow.classList.add('active-conferencia');

    //Remove company line styles
    /*const companyTableRow = document.querySelector('.company-row');
    companyTableRow.classList.remove('active-conferencia');*/

    //Remove lot box styles
    const lotsBox = document.getElementById('boxLot');
    lotsBox.classList.remove('active-lot-box');

    document
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => (checkbox.checked = false));
    spinLoader.classList.remove('loading-active');
  }

  async function getCompanyByProductClicked(seqproduto, bonificado) {
    try {
      const filteredProducts = products.filter(
        (product) =>
          product.seqproduto === seqproduto &&
          product.bonificado === bonificado,
      );

      const filteredCompanies = [];

      filteredProducts.forEach((product) => {
        let newCompany = {
          nrocd: 0,
          qtdesolicitada: 0,
          qtdeatendida: 0,
          qtdconferida: 0,
          bonificado: '',
          lineColor: '',
        };

        empresas.forEach((company) => {
          const isSameCompany = product.seqpessoa === company.seqpessoa;

          if (isSameCompany) {
            newCompany = {
              ...newCompany,
              ...company,
              nrocd: product.nroempresa,
              qtdesolicitada:
                newCompany.qtdesolicitada + product.qtdesolicitada,
              qtdeatendida: newCompany.qtdeatendida + product.qtdeatendida,
              qtdconferida: newCompany.qtdconferida + product.qtdconferida,
              bonificado: product.bonificado,
            };

            if (
              newCompany.qtdeatendida > newCompany.qtdconferida &&
              newCompany.qtdconferida > 0
            ) {
              newCompany.lineColor = '#fff7cf';
            } else if (newCompany.qtdeatendida <= newCompany.qtdconferida) {
              newCompany.lineColor = '#c3e3cc';
            } else {
              newCompany.lineColor = '';
            }

            filteredCompanies.push(newCompany);
          }
        });
      });

      let totalRequested = 0;
      let totalGenerated = 0;
      let totalConfered = 0;

      for (const company of filteredCompanies) {
        totalRequested += company.qtdesolicitada;
        totalGenerated += company.qtdeatendida;
        totalConfered += company.qtdconferida;
      }

      const totalRequestedElement = document.getElementById('totalRequested');
      const totalAttendedElement = document.getElementById('totalGenerated');
      const totalConferenceElement = document.getElementById('totalConfered');

      totalRequestedElement.textContent = totalRequested;
      totalAttendedElement.textContent = totalGenerated;
      totalConferenceElement.textContent = totalConfered;

      const sortedFilteredCompanies = filteredCompanies.sort(
        (a, b) => a.nroempresa - b.nroempresa,
      );

      setCompanies(sortedFilteredCompanies);
    } catch (err) {
      console.log(err);
      toast.error('Falha na requisição dos dados.');
    }
  }

  async function companyRowClicked(companyClicked, e) {
    if (e.target.id === 'selectCompany') {
      return;
    }

    setCurrentCompany(companyClicked);

    getLotsByCompanyClicked(companyClicked);

    filterProductsByCompany(companyClicked);

    const lotsBox = document.getElementById('boxLot');
    lotsBox.classList.add('active-lot-box');
  }

  async function getLotsByCompanyClicked(company) {
    const filteredProductsByCompany = products.filter(
      (product) =>
        product.seqproduto === currentProduct.seqproduto &&
        product.bonificado === currentProduct.bonificado &&
        product.seqpessoa === company.seqpessoa,
    );

    const lotsresponse = await api.get(
      `/listaseparacao/busca_lote_conferencia/${company.nrocd}/${currentProduct.seqproduto}/0`,
    );
    const { results: lotsResults } = lotsresponse.data;

    const filteredLotsByProduct = [];
    lotsResults.forEach((lot) => {
      lot.quantidade_gerada = 0;
      filteredProductsByCompany.forEach((product) => {
        const isSameLot = Number(product.sequencia_lote) === lot.sequencia_lote;
        const isSameProduct = product.seqproduto === lot.sequencia_produto;

        if (isSameLot && isSameProduct) {
          lot.quantidade_gerada += product.qtdeatendida;
        }
        filteredLotsByProduct.push(lot);
      });
    });

    const groupedLots = [];
    filteredLotsByProduct.forEach((lot) => {
      if (!groupedLots.includes(lot.sequencia_lote)) {
        groupedLots.push(lot);
      }
    });

    const data = {
      numero_empresa: company.nrocd,
      sequencia_lista: null,
      sequencia_produto: currentProduct.seqproduto,
      sequencia_pessoa: 0,
      data_inicial: null,
      data_final: null,
      status: 'P,C,L',
      codigo_usuario: null,
    };

    const productsResponse = await api.post(
      '/listaseparacao/busca_conferencia_v2/',
      data,
    );

    const productsConfered = productsResponse.data;

    if (productsConfered.length !== 0) {
      for (const lot of groupedLots) {
        lot.quantidade_conferida = 0;
        lot.qtdConferidaEmp = 0;

        for (const product of productsConfered) {
          if (
            lot.sequencia_lote === product.sequencia_lote &&
            currentProduct.bonificado === product.bonificado
          ) {
            if (product.sequencia_pessoa !== company.seqpessoa) {
              lot.quantidade_conferida += product.quantidade_conferida;
            } else {
              lot.qtdConferidaEmp += product.quantidade_conferida;
              lot.quantidade_atendida = product.quantidade_atendida;
            }
          }
        }
      }
    }

    setLots(groupedLots);
  }

  function returnToCompanyBox() {
    const lotsBox = document.getElementById('boxLot');
    lotsBox.classList.remove('active-lot-box');
    setLots([]);
  }

  function filterProductsByCompany(company) {
    const filteredProductsByCompany = products.filter(
      (product) => product.seqpessoa === company.seqpessoa,
    );

    let totalProductAttended = 0;
    let totalProductConfered = 0;

    for (const product of filteredProductsByCompany) {
      totalProductAttended += product.qtdeatendida;
      totalProductConfered += product.qtdconferida;
    }

    const totalAttendedElement = document.getElementById(
      'totalProductAttended',
    );
    const totalConferenceElement = document.getElementById(
      'totalProductConfered',
    );

    totalAttendedElement.textContent = totalProductAttended;
    totalConferenceElement.textContent = totalProductConfered;

    setCompanyInfo(filteredProductsByCompany);
  }

  async function handleConfer() {
    try {
      if (Number(conferenceValue) === 0) {
        return toast.warning('Informe um valor válido');
      }

      const foundProductByCompany = products.find(
        (product) =>
          product.seqproduto === currentProduct.seqproduto &&
          product.bonificado === currentProduct.bonificado &&
          product.seqpessoa === currentCompany.seqpessoa,
      );

      const validQuantity = currentLot.saldo - currentLot.quantidade_conferida;

      if (validQuantity >= Number(conferenceValue)) {
        const data = {
          codigo_usuario: foundProductByCompany.codusuario,
          sequencia_lista: foundProductByCompany.seqlista,
          sequencia_produto: foundProductByCompany.seqproduto,
          numero_empresa: foundProductByCompany.nroempresa,
          sequencia_cliente: currentCompany.seqpessoa,
          quantidade_embalagem: currentLot.embalagem,
          quantidade_conferida: Number(conferenceValue),
          sequencia_item_lista: foundProductByCompany.seqitem,
          sequencia_lote: currentLot.sequencia_lote,
          valor_embalagem_produto: currentLot.valor,
          tipo_lote: currentLot.tipo_lote,
          status: 'C',
          data_hora_conferencia: new Date(),
          conferencia:
            foundProductByCompany.codusuario +
            '' +
            foundProductByCompany.seqitem +
            '' +
            foundProductByCompany.seqlista +
            '' +
            moment(new Date()).format('DDMMyyyyHHmmss'),
          sequencia_separador: 0,
          origem: 'N',
          bonificado: foundProductByCompany.bonificado,
        };

        const response = await api.post(
          'listaseparacao/salvarconferencia_v2/',
          data,
        );

        if (response.data !== null) {
          if (response.data[0].numero_erro === 0) {
            CloseModal();
            const lotsBox = document.getElementById('boxLot');
            lotsBox.classList.remove('active-lot-box');
            setLots([]);
            setConferValue(0);

            await getProducts();

            setTimeout(() => {
              window.$('.active-conferencia').click();
            }, 500);
          } else {
            toast.error(response.data[0].mensagem);
          }
        }
      } else {
        if (validQuantity === 0) return toast.warning('Saldo insuficiente.');
      }
    } catch (err) {
      console.log(err.message);
      toast.error('Falha no envio dos dados.');
    }
  }

  function handleAddCheckedCompany(id) {
    const row = checkboxCompaniesRef.current.querySelector(`[id='${id}']`);

    const input = row.querySelector('input');

    let companiesArray = [];

    if (input.checked) {
      const company = companies.find((company) => company.seqpessoa === id);

      companiesArray = [...companiesList, company];
    } else {
      companiesArray = companiesList.filter(
        (company) => company.seqpessoa !== id,
      );
    }

    companiesArray.sort((a, b) => a.seqpessoa - b.seqpessoa);

    setCompaniesList(companiesArray);
  }

  function handleCheckAllCompanies(checked) {
    const rows = checkboxCompaniesRef.current.querySelectorAll('tr');
    const companiesArray = [];

    for (const row of rows) {
      const input = row.querySelector('input');
      input.checked = checked;

      if (checked) {
        const company = companies.find(
          (item) => item.seqpessoa === Number(row.id),
        );
        companiesArray.push(company);
      }
    }

    setCompaniesList(companiesArray);
  }

  async function releaseBilling() {
    const options = {
      title: 'Liberar Faturamento',
      message:
        'Dejesa realmente liberar o faturamento para esta(s) empresa(s)?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            executeReleasing();
          },
        },
        { label: 'Nâo', onClick: () => {} },
      ],
    };
    confirmAlert(options);
  }

  async function executeReleasing() {
    try {
      if (companiesList.length === 0)
        return toast.warning('Selecione ao menos uma empresa para faturar.');

      const results = await Promise.all(
        companiesList.map(async (company) => {
          return await api.post('/listaseparacao/libera_faturamento', {
            seqpessoa: company.seqpessoa,
            chapa_usuario: token.userName,
          });
        }),
      );

      let codigoResult = 0;
      let resultMessage = '';

      results.map((result) => {
        if (result.data.codResult === 1) {
          codigoResult = 1;
          resultMessage = result.data.msg;
          return { codigoResult, resultMessage };
        }
        resultMessage = result.data.msg;
        return { codigoResult, resultMessage };
      });

      if (codigoResult === 1) return toast.error(`${resultMessage}`);

      document
        .querySelectorAll("input[type='checkbox']")
        .forEach((checkbox) => (checkbox.checked = false));
      setCompaniesList([]);
      toast.success(`Empresa(s) faturadas com sucesso.`);
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function handleCutProductByCompany(e) {
    e.preventDefault();

    try {
      if (cutReason === '') return toast.error('Insira um motivo.');

      if (cutReason.replace(/ +/g, ' ').trim().length < 20)
        return toast.error(
          'Digite um motivo com pelo menos 20 caracteres válidos.',
        );

      const foundProductByCompany = products.find(
        (product) =>
          product.seqproduto === currentProduct.seqproduto &&
          product.bonificado === currentProduct.bonificado &&
          product.seqpessoa === currentCompany.seqpessoa,
      );

      const data = {
        codigo_usuario: foundProductByCompany.codusuario,
        sequencia_item: foundProductByCompany.seqitem,
        sequencia_lista: foundProductByCompany.seqlista,
        motivo: cutReason,
      };

      const response = await api.post('listaseparacao/v1/corte_produto', data);

      if (response.data.codResult === 1) {
        return toast.error(`${response.data.msg}`);
      }

      CloseModal();
      const lotsBox = document.getElementById('boxLot');
      lotsBox.classList.remove('active-lot-box');
      const productRows = document.querySelectorAll('.btnProduct');
      productRows.forEach((item) =>
        item.classList.remove('active-conferencia'),
      );
      setCutReason('');
      setLots([]);
      setCompanies([]);
      setCurrentProduct({});
      getProducts();
      toast.success('Produto cortado com sucesso.');
    } catch (err) {
      console.log(err);
      toast.error('Falha no envio dos dados.');
    }
  }

  window.$('#modal-lot').on('shown.bs.modal', function () {
    window.$('#conferQuantity').focus();
    window.$('#conferQuantity').val(0);
  });

  return (
    <>
      <div className="content-header">
        <h1>Conferência</h1>
      </div>

      <div className="content">
        <Tabs
          defaultActiveKey="company"
          onSelect={(firstTab, lastTab) => {
            selectedTab = firstTab;
          }}
        >
          <Tab eventKey="company" title="Empresa">
            <div className="row">
              <div className="col-sm-4">
                <div className="card" style={{ overflow: 'hidden' }}>
                  <div
                    className="card-body p-0"
                    style={{ height: '75vh', overflow: 'hidden' }}
                  >
                    <button
                      className="btn btn-secondary btn-sm ml-2"
                      style={{ position: 'absolute', marginTop: '12px' }}
                      title="Atualizar lista de empresas"
                      onClick={() => {
                        ValidarEmpresas();
                      }}
                    >
                      <i className="fa fa-retweet"></i>
                    </button>

                    <input
                      type="search"
                      id="txtFiltraEmpresa"
                      className="form-control mt-2 mr-2"
                      style={{ position: 'absolute', right: 0, width: '50%' }}
                      placeholder="Procurar empresa"
                      onChange={() => {
                        FiltrarEmpresas();
                      }}
                    />
                    <h4
                      className="pl-3 pt-3 pb-3 mb-0 bg-light"
                      style={{
                        borderBottom: '1px solid #dfdfdf',
                        fontSize: 18,
                        boxShadow: '1px 1px 4px 0px rgb(0 0 0 / 10%)',
                      }}
                    >
                      {' '}
                      <i className="fa fa-tasks mr-2 ml-1"></i> Empresas
                    </h4>
                    <div
                      id="table-spinner"
                      className="mb-3"
                      style={{
                        textAlign: 'center',
                        position: 'absolute',
                        zIndex: '9',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255,255,255,.5)',
                        paddingTop: '110px',
                      }}
                    >
                      <Spinner animation="border" variant="primary" />
                    </div>
                    <div
                      className="lista-empresas"
                      style={{ height: 'calc(100% - 55px)', overflow: 'auto' }}
                    >
                      {empresas.map((item, index) => (
                        <div
                          id={'btnEmpresa' + index}
                          className={
                            'btnEmpresa btnEmpresaSearch empNro' +
                            item.seqpessoa
                          }
                          data-seqpessoa={item.seqpessoa}
                          data-cidade={item.cidade}
                          data-key={index}
                          data-id={item.nroempresa}
                          key={index}
                          onClick={() => {
                            BtnEmpresaClicked('btnEmpresa' + index);
                          }}
                        >
                          <div className="btnLiberarFaturamento">
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                LiberarFaturamento(item.seqpessoa);
                              }}
                            >
                              Liberar Faturamento
                            </button>
                          </div>
                          <div>
                            <i className="fa fa-tasks mr-3"></i>
                            <span className="nome-empresa">
                              {item.nome_reduzido}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-8">
                <div
                  id="table-spinner2"
                  className="mb-3"
                  style={{
                    textAlign: 'center',
                    position: 'absolute',
                    zIndex: '9',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,.5)',
                    paddingTop: '110px',
                  }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>

                <div id="boxProdutos">
                  <div className="card" style={{ overflow: 'hidden' }}>
                    <div
                      className="card-body p-0"
                      style={{ height: '75vh', overflow: 'auto' }}
                    >
                      <div
                        id="no-products"
                        className={
                          'text-center pt-5 ' +
                          (produtos.length == 0 ? 'd-block' : 'd-none')
                        }
                      >
                        <h4>
                          <i className="fa fa-info-circle fa-fw"></i> Nenhum
                          produto
                        </h4>
                        Selecione uma empresa na lista ao lado para visualizar o
                        produtos.
                      </div>
                      <div
                        id="tblProdutos"
                        className={produtos.length === 0 ? 'd-none' : 'd-block'}
                      >
                        <h4
                          className="pl-3 pt-3 pb-3 mb-0 bg-light"
                          style={{
                            borderBottom: '1px solid #dfdfdf',
                            fontSize: 18,
                            boxShadow: '1px 1px 4px 0px rgb(0 0 0 / 10%)',
                          }}
                        >
                          {' '}
                          <i className="fa fa-list mr-2 ml-1"></i> Produtos
                        </h4>
                        <div
                          style={{
                            height: 'calc(100% - 55px)',
                            overflow: 'auto',
                          }}
                        >
                          <table 
                            className={
                              'table table-striped table-hover table-bordered '
                            } 
                          >
                            <thead>
                              <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Produto</th>
                                <th scope="col">Pedido</th>
                                <th scope="col">Gerado</th>
                                <th scope="col">Conferido</th>
                                <th scope="col">Saldo</th>
                              </tr>
                            </thead>

                            <tbody id="table-body">
                              {produtos.map((item, index) => (
                                <tr
                                  id={'produto-linha' + index}
                                  key={index}
                                  className="produto-linha"
                                  data-key={index}
                                  data-empresa={item.nroempresa}
                                  data-id={item.seqproduto}
                                  data-seqitem={item.seqitem}
                                  onClick={() => {
                                    ProdutoLinhaClicked(
                                      'produto-linha' + index,
                                    );
                                  }}
                                  style={{ backgroundColor: item.lineColor }}
                                >
                                  <td>{item.seqproduto}</td>
                                  <td className="d-flex justify-content-between align-items-center">
                                    {item.descricao}
                                    {item.bonificado === 'S' ? (
                                      <Badge
                                        bg="primary"
                                        title="Produto Bonificado"
                                        className=" fs-4"
                                      >
                                        B
                                      </Badge>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>{item.qtdesolicitada}</td>
                                  <td>{item.qtdeatendida}</td>
                                  <td>{Number(item.qtdconferida)}</td>
                                  <td>
                                    {item.qtdeatendida -
                                      Number(item.qtdconferida)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div class="text-right">
                            <button type="button"
                             class="btn btn-primary"
                              onClick={() => exportTableToPDF()}
                              >Imprimir</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="boxLotes">
                  <div className="card" style={{ overflow: 'hidden' }}>
                    <div
                      className="card-body p-0"
                      style={{ height: '75vh', overflow: 'auto' }}
                    >
                      <nav
                        className="navbar navbar-light bg-dark"
                        style={{ justifyContent: 'flex-start' }}
                      >
                        <form className="form-inline">
                          <button
                            className="btn btn-sm btn-secondary"
                            type="button"
                            onClick={() => {
                              BackToProdutos();
                            }}
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                        </form>
                        <div className="ml-3">
                          {currentProduto.descricao} | QTD_PED:{' '}
                          {currentProduto.qtdesolicitada} | QTD_CONF:{' '}
                          {Number(currentProduto.qtdconferida)} | SALDO:{' '}
                          {currentProduto.qtdeatendida -
                            Number(currentProduto.qtdconferida)}
                        </div>
                        <div
                          className="d-flex"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            padding: '4px 11px',
                          }}
                        >
                          <button
                            className="btn btn-sm btn-secondary pull-right"
                            type="button"
                            onClick={() => {
                              window.$('#modal-prod-info').modal('show');
                            }}
                          >
                            <i className="fa fa-info"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-secondary pull-right ml-3 bg-orange"
                            type="button"
                            onClick={() =>
                              window.$('#modal-prod-cut').modal('show')
                            }
                          >
                            <i className="fa fa-trash white"></i>
                          </button>
                        </div>
                      </nav>
                      <table className="table table-striped table-hover table-bordered">
                        <thead className="">
                          <tr>
                            <th scope="col" width="40%">
                              Lote
                            </th>
                            <th scope="col">Data</th>
                            <th scope="col">Emb.</th>
                            <th scope="col">Disponível</th>
                            <th scope="col">Gerado</th>
                            <th scope="col">Conferido</th>
                          </tr>
                        </thead>
                        <tbody id="table-body">
                          {lotes.map((item, index) => (
                            <tr
                              id={'lote-linha' + index}
                              key={index}
                              data-key={index}
                              className="lote-linha"
                              onClick={() => {
                                LoteLinhaClicked('lote-linha' + index);
                              }}
                            >
                              <td>{item.descricao}</td>
                              <td>
                                {moment(
                                  new Date(item.data_hora_entrada),
                                ).format('DD/MM/yyyy')}
                              </td>
                              <td>{item.embalagem}</td>
                              <td>
                                {Number(item.saldo) -
                                  Number(item.quantidade_conferida)}
                              </td>
                              <td>{item.quantidade_atendida}</td>
                              <td>{item.temp}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="product" title="Produto">
            <div className="row" style={{ height: '75vh' }}>
              <div className="col-sm-4" style={{ height: '75vh' }}>
                <div className="card h-100" style={{ overflow: 'hidden' }}>
                  <div className="card-header bg-light py-2 px-1 d-flex align-items-center justify-content-between">
                    <button
                      className="btn btn-secondary btn-sm ml-1"
                      title="Atualizar lista de produtos"
                      onClick={() => getProducts()}
                    >
                      <i className="fa fa-retweet" />
                    </button>
                    <h4 className="ml-2 my-0 mr-5" style={{ fontSize: 18 }}>
                      Produtos
                    </h4>
                    <input
                      type="search"
                      id="textFilterProduct"
                      className="form-control mr-1"
                      placeholder="Procurar produto"
                      onChange={() => searchProductsFilter()}
                    />
                  </div>
                  <div
                    className="card-body container p-0"
                    style={{ overflow: 'hidden' }}
                  >
                    <div id="table-spinner" className="mb-3 table-spinner">
                      <Spinner animation="border" variant="primary" />
                    </div>
                    <div
                      className="product-list"
                      style={{ height: 'calc(100% - 25px)', overflow: 'auto' }}
                    >
                      {productsList.map((product, index) => (
                        <>
                          <div
                            id={'Product ' + product.seqproduto}
                            className={
                              'btnProduct btnProductSearch productNumber' +
                              product.seqproduto
                            }
                            key={product.seqproduto}
                            data-key={index}
                            onClick={() => {
                              productRowClicked(product, index);
                            }}
                          >
                            <div>
                              <i className="fa fa-tasks mr-3"></i>
                              <span className="product-description">
                                {product.seqproduto} - {product.descricao}
                                {product.bonificado === 'S' && (
                                  <Badge
                                    className="fs-4 ml-2"
                                    bg="primary"
                                    title="Produto Bonificado"
                                  >
                                    B
                                  </Badge>
                                )}
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-8" style={{ height: '75vh' }}>
                <div id="table-spinner2" className="mb-3 table-spinner2">
                  <Spinner animation="border" variant="primary" />
                </div>
                <div id="boxCompany" style={{ height: '75vh' }}>
                  <div className="card h-100" style={{ overflow: 'hidden' }}>
                    <div
                      className={
                        companies.length === 0
                          ? 'd-none'
                          : 'd-block card-header bg-light py-3 px-3 d-flex align-items-center justify-content-between'
                      }
                      style={{ overflow: 'hidden' }}
                    >
                      <div className="d-flex align-items-center">
                        <h4 className="my-0 mr-4" style={{ fontSize: 18 }}>
                          <i className="fa fa-list mr-2 ml-1" />
                          Empresas
                        </h4>
                        <span>
                          QTD PED.:{' '}
                          <strong id="totalRequested" className="mr-1"></strong>
                        </span>
                        <span>
                          | QTD GER.:{' '}
                          <strong id="totalGenerated" className="mr-1"></strong>
                        </span>
                        <span>
                          | QTD CONF.:{' '}
                          <strong id="totalConfered" className="mr-1"></strong>
                        </span>
                      </div>
                      <div
                        className="btnRealeaseBilling position-absolute pt-0"
                        style={{ right: 0 }}
                      >
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            releaseBilling();
                          }}
                        >
                          Liberar Faturamento
                        </button>
                      </div>
                    </div>
                    <div
                      className="card-body p-0"
                      style={{
                        overflow: 'auto',
                      }}
                    >
                      <div
                        id="no-companies"
                        className={
                          'text-center pt-5 ' +
                          (companies.length === 0
                            ? 'd-block h-100 d-flex flex-column align-items-center justify-content-center'
                            : 'd-none')
                        }
                      >
                        <h4>
                          <i className="fa fa-info-circle fa-fw"></i> Nenhuma
                          empresa
                        </h4>
                        Selecione um produto na lista ao lado para visualizar as
                        empresas.
                      </div>
                      <div
                        id="tblCompanies"
                        className={
                          companies.length === 0 ? 'd-none' : 'd-block'
                        }
                      >
                        <table className="table table-striped table-hover table-bordered">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  name="selectAllCompanies"
                                  id="selectCompanies"
                                  onChange={(e) =>
                                    handleCheckAllCompanies(e.target.checked)
                                  }
                                />
                              </th>
                              <th scope="col">Loja</th>
                              <th scope="col">Gerado</th>
                              <th scope="col">Conferido</th>
                              <th scope="col">Saldo</th>
                            </tr>
                          </thead>
                          <tbody
                            id="company-table-body"
                            ref={checkboxCompaniesRef}
                          >
                            {companies.map((company, index) => (
                              <tr
                                key={index}
                                data-key={index}
                                data-nroempresa={company.nroempresa}
                                id={company.seqpessoa}
                                className="companyLine"
                                style={{ backgroundColor: company.lineColor }}
                                onClick={(e) => companyRowClicked(company, e)}
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    id="selectCompany"
                                    onChange={() => {
                                      handleAddCheckedCompany(
                                        company.seqpessoa,
                                      );
                                    }}
                                  />
                                </td>
                                <td>{company.nome_reduzido}</td>
                                <td>{company.qtdeatendida}</td>
                                <td>{company.qtdconferida}</td>
                                <td>
                                  {company.qtdeatendida - company.qtdconferida}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="boxLot" className="boxLot" style={{ height: '75vh' }}>
                  <div className="card h-100">
                    <div
                      className="card-header bg-dark py-3 px-3 d-flex align-items-center justify-content-between"
                      style={{ overflow: 'hidden' }}
                    >
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-sm btn-secondary"
                          type="button"
                          onClick={() => returnToCompanyBox()}
                        >
                          <i className="fa fa-arrow-left"></i>
                        </button>
                        {currentCompany && (
                          <div className="ml-3">
                            {currentCompany.nome_reduzido} | QTD_PED:{' '}
                            {currentCompany.qtdesolicitada} | QTD_CONF:{' '}
                            {currentCompany.qtdconferida} | SALDO:{' '}
                            {currentCompany.qtdeatendida -
                              currentCompany.qtdconferida}
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center ml-auto">
                        <button
                          className="btn btn-sm btn-secondary pull-right"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-company-info"
                          // onClick={() => window.$('#modal-prod-info').modal('show')}
                        >
                          <i className="fa fa-info"></i>
                        </button>

                        <button
                          className="btn btn-sm btn-secondary pull-right ml-3 bg-orange"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-productByCompany-cut"
                          // onClick={() => window.$('#modal-prod-cut').modal('show')}
                        >
                          <i className="fa fa-trash white"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      className="card-body p-0"
                      style={{
                        overflow: 'auto',
                      }}
                    >
                      {lots.length === 0 ? (
                        <div
                          id="no-lots"
                          className="text-center h-100 w-100 d-flex flex-column align-items-center justify-content-center"
                        >
                          <h4>
                            <i className="fa fa-info-circle fa-fw"></i> Nenhum
                            lote encontrado
                          </h4>
                        </div>
                      ) : (
                        <table
                          id="tblLots"
                          className="table table-striped table-hover table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>Lote</th>
                              <th>Data</th>
                              <th>Emb.</th>
                              <th>Disponível</th>
                              <th>Gerado</th>
                              <th>Conferido</th>
                            </tr>
                          </thead>
                          <tbody id="lots-table-body">
                            {lots.map((lot, index) => (
                              <tr
                                onClick={() => setCurrentLot(lot)}
                                data-bs-toggle="modal"
                                data-bs-target="#modal-lot"
                              >
                                <td>{lot.descricao}</td>
                                <td>
                                  {moment(lot.data_hora_entrada).format(
                                    'DD/MM/yyyy',
                                  )}
                                </td>
                                <td>{lot.embalagem}</td>
                                <td>{lot.saldo - lot.quantidade_conferida}</td>
                                <td>{lot.quantidade_gerada}</td>
                                <td>
                                  {lot.qtdConferidaEmp > 0
                                    ? lot.qtdConferidaEmp
                                    : ''}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      <div id="modal-alert" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-lote" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{currentLote.descricao}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-6 border rounded pl-3 pr-3 pt-2 pb-2">
                  <div className="">
                    <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Data: </b>
                      </div>
                      <div className="col-7">
                        {moment(new Date(currentLote.data_hora_entrada)).format(
                          'DD/MM/yyyy',
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5 text-right">
                        <b>Emb.: </b>
                      </div>
                      <div className="col-7">{currentLote.embalagem}</div>
                    </div>

                    <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Disponível: </b>
                      </div>
                      <div className="col-7">
                        {Number(currentLote.saldo) -
                          Number(currentLote.quantidade_conferida)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5 text-right">
                        <b>Gerado: </b>
                      </div>
                      <div className="col-7">
                        {currentLote.quantidade_atendida}
                      </div>
                    </div>

                    <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Conferido: </b>
                      </div>
                      <div className="col-7">{currentLote.temp}</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 pt-3">
                  <label>Quantidade</label>
                  <input
                    type="number"
                    id="txtQtd"
                    className="form-control"
                    placeholder="Digite a quantidade"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  Salvar();
                }}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-prod-info" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{currentProduto.descricao}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: '75vh', overflow: 'auto' }}
            >
              <table className="table table-striped table-hover tabl e-sm table-bordered">
                <thead className="">
                  <tr>
                    <th scope="col" width="40%">
                      Empresa
                    </th>
                    <th scope="col">
                      Atendido (<span id="totalAtendido"></span>)
                    </th>
                    <th scope="col">
                      Conferido (<span id="totalConferido"></span>)
                    </th>
                  </tr>
                </thead>
                <tbody id="table-body">
                  {prodInfos.map((item, index) => (
                    <tr>
                      <td>
                        {$('.empNro' + item.seqpessoa)
                          .find('.nome-empresa')
                          .text()}
                        {item.bonificado === 'S' && (
                          <Badge
                            className="fs-4 ml-2"
                            bg="primary"
                            title="Produto Bonificado"
                          >
                            B
                          </Badge>
                        )}
                      </td>
                      <td>{item.qtdeatendida}</td>
                      <td>{Number(item.qtdconferida)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-prod-cut" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Cortar Produto - {currentProduto.descricao}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: '75vh', overflow: 'auto' }}
            >
              <textarea
                id="reason"
                className="w-100"
                placeholder="Informe um motivo para o corte."
                rows="6"
                required
                minLength={20}
                value={cutReason}
                onChange={(e) => setCutReason(e.target.value)}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  handleCutProductWithoutStorage(e, currentProduto);
                }}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Product Tab */}
      <div
        id="modal-lot"
        className="modal-lot modal fade"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{currentLot.descricao}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-6 border rounded pl-3 pr-3 pt-2 pb-2">
                  <div className="">
                    <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Data: </b>
                      </div>
                      <div className="col-7">
                        {moment(new Date(currentLot.data_hora_entrada)).format(
                          'DD/MM/yyyy',
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5 text-right">
                        <b>Emb.: </b>
                      </div>
                      <div className="col-7">{currentLot.embalagem}</div>
                    </div>

                    <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Disponível: </b>
                      </div>
                      <div className="col-7">
                        {Number(currentLot.saldo) -
                          Number(currentLot.quantidade_conferida)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5 text-right">
                        <b>Gerado: </b>
                      </div>
                      <div className="col-7">
                        {currentLot.quantidade_gerada}
                      </div>
                    </div>

                    <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Conferido: </b>
                      </div>
                      <div className="col-7">{currentLot.qtdConferidaEmp}</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 pt-3">
                  <label>Quantidade</label>
                  <input
                    type="number"
                    id="conferQuantity"
                    className="form-control"
                    placeholder="Digite a quantidade"
                    onChange={(event) => setConferValue(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleConfer}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-company-info" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{currentCompany.nome_reduzido}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: '75vh', overflow: 'auto' }}
            >
              <table className="table table-striped table-hover tabl e-sm table-bordered">
                <thead className="">
                  <tr>
                    <th scope="col" width="40%">
                      Produto
                    </th>
                    <th scope="col">
                      Atendido (<span id="totalProductAttended"></span>)
                    </th>
                    <th scope="col">
                      Conferido (<span id="totalProductConfered"></span>)
                    </th>
                  </tr>
                </thead>
                <tbody id="table-body">
                  {companyInfos.map((item, index) => (
                    <tr>
                      <td>
                        {item.descricao}
                        {item.bonificado === 'S' && (
                          <Badge
                            className="fs-4 ml-2"
                            bg="primary"
                            title="Produto Bonificado"
                          >
                            B
                          </Badge>
                        )}
                      </td>
                      <td>{item.qtdeatendida}</td>
                      <td>{item.qtdconferida}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-productByCompany-cut" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Cortar Produto - {currentProduct.descricao}
                {currentProduct.bonificado === 'S' && (
                  <Badge
                    className="fs-4 ml-2"
                    bg="primary"
                    title="Produto Bonificado"
                  >
                    B
                  </Badge>
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  CloseModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: '75vh', overflow: 'auto' }}
            >
              <textarea
                id="reason"
                className="w-100"
                placeholder="Informe um motivo para o corte."
                rows="6"
                required
                minLength={20}
                value={cutReason}
                onChange={(e) => setCutReason(e.target.value)}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  handleCutProductByCompany(e);
                }}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  CloseModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='d-none'>
      <div className="PDFBody overflow-hidden p-2"  ref={ref_potato_table} id="page-container">
      <div id="content-wrap">
      <h4 className="ml-2 my-0 mr-6  p-2 d-flex align-items-center justify-content-center" style={{ fontSize: 18 }} >
        EMPRESA: {currentEmpresa.nome_reduzido}</h4>
        <div 
          className="row text-center mx-2 bg-light"
          style={{ fontSize: '14px' }}
        >
          <div className="col-1 border border-dark p-1">Código</div>
          <div className="col-8 border border-dark p-1">Produto</div>
          {/* <div className="col-1 border border-dark p-1">Pedido</div> */}
          <div className="col-1 border border-dark p-1">Gerado</div>
          <div className="col-1 border border-dark p-1">Conferido</div>
          <div className="col-1 border border-dark p-1">Saldo</div>
        </div>
        {produtos.map((item, index) => (
          <div
            className="row mx-2"
            key={item.nroempresa}
            style={{ fontSize: '14px' }}
          >
            <div className="col-1 border border-dark p-1 d-flex align-items-center justify-content-center">
              {item.seqproduto}
            </div>
            <div className="col-8 border border-dark p-1 d-flex align-items-center justify-content-center">
              {item.descricao}
            </div>
            {/* <div className="col-1 border border-dark d-flex align-items-center justify-content-between">
              {item.qtdesolicitada}
            </div> */}
            <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
              {item.qtdeatendida}
            </div>
            <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
              {Number(item.qtdconferida)}
            </div>
            <div className="col-1 p-1 border border-dark d-flex align-items-center justify-content-end">
              {item.qtdeatendida - Number(item.qtdconferida)}
            </div>
          </div>
        ))}
        <footer id="footer">
          <div>DATA: {utc} </div>
          <div>CÓDIGO: {codigo_usuario}</div>
          <div>NOME: {nome_usuario}</div>
          <div>EMAIL: {email_usuario}</div>
          <div>ASSINATURA DO CONFERENTE: ________________________________________________________________________________________________________________</div>
        </footer>
      </div>
      </div>
      </div>
    </>
  );
}

export default Conferencia;
