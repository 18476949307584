import { useRef } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useLoading } from '../../hooks/useLoading';

import api from '../../services/api';

export function CompanyTab({ listCD, users, codigoUsuario, onCloseModal }) {
  const [companies, setCompanies] = useState([]);
  const [priority, setPriority] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const checkboxCompaniesRef = useRef(null);
  const loading = useLoading();

  async function handleFillCompaniesTable() {
    const selectedCd = document.querySelector('#selectCd').options;
    const cd = Number(selectedCd[selectedCd.selectedIndex].value);

    if (cd === 0) {
      setCompanies([]);
      return;
    }

    const response = await api.get(
      `/listaseparacao/get_empresa_conferente/${cd}`,
    );

    const { results: companiesResponse } = response.data;

    for (let i = 0; i < companiesResponse.length; i++) {
      const company = companiesResponse[i];

      for (let j = 0; j < users.length; j++) {
        const user = users[j];

        if (company.codigo_conferente === user.username) {
          company.codigo_conferente = `${user.username} - ${user.first_name}`;
        }
      }
    }

    setCompanies(companiesResponse);
    document
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => (checkbox.checked = false));
  }

  function handleAddCheckedCompany(id) {
    const row = checkboxCompaniesRef.current.querySelector(`[id='${id}']`);

    let appointmentArray = [];

    const input = row.querySelector('input');

    if (input.checked) {
      const company = companies.find(
        (company) => company.numero_empresa === id,
      );

      appointmentArray = [...appointmentList, company];
    } else {
      appointmentArray = appointmentList.filter(
        (item) => item.numero_empresa !== id,
      );
    }

    appointmentArray.sort((a, b) => a.numero_empresa - b.numero_empresa);
    setAppointmentList(appointmentArray);
  }

  function handleCheckAllCompanies(checked) {
    const rows = checkboxCompaniesRef.current.querySelectorAll('tr');
    const array = [];

    for (const row of rows) {
      const input = row.querySelector('input');
      input.checked = checked;

      if (checked) {
        const company = companies.find(
          (item) => item.numero_empresa === row.id,
        );
        array.push(company);
      }
    }
    setAppointmentList(array);
  }

  async function handleAppointment(active) {
    try {
      loading.setLoading(true);
      const cd = document.querySelector('#selectCd').value;
      const targetUser = document.querySelector('#inputTargetUser').value;
      const modalAppointment = document.getElementById(
        'modal-appointment-unactive',
      );

      if (!cd) {
        loading.setLoading(false);
        return toast.warning('Selecione um centro de distribuição');
      }

      if (targetUser === '') {
        loading.setLoading(false);
        return toast.warning('Selecione um usuário de destino');
      }

      if (Number(priority) === 0) {
        loading.setLoading(false);
        return toast.warning('Informe um grau de prioridade');
      }

      if (!appointmentList.length) {
        loading.setLoading(false);
        return toast.warning('Selecione ao menos um item da listagem.');
      }

      if (!isActive && !active) {
        modalAppointment.style.display = 'block';
        loading.setLoading(false);
        return;
      }

      const appointments = appointmentList.map((appointment) => {
        return {
          numero_centro_distribuicao: Number(cd),
          ativo: isActive ? 'S' : 'N',
          codigo_conferente: targetUser.split(' ')[0],
          numero_empresa: Number(appointment.numero_empresa),
          usuario_alteracao: codigoUsuario,
          prioridade: Number(priority),
        };
      });

      const results = await Promise.all(
        appointments.map(async (data) => {
          return await api.post('/lista_separacao/empresa_conferente', data);
        }),
      );

      let codigoResult = 0;
      let resultMessage = '';

      results.map((result) => {
        if (result.data.codResult === 1) {
          codigoResult = 1;
          resultMessage = result.data.msg;
          return { codigoResult, resultMessage };
        }
        resultMessage = result.data.msg;
        return { codigoResult, resultMessage };
      });

      if (codigoResult === 1) return toast.error(`${resultMessage}`);

      modalAppointment.style.display = 'none';
      toast.success(`Empresa(s) salva(s) para o usuário ${targetUser}`);
      setAppointmentList([]);
      handleFillCompaniesTable();
      document
        .querySelectorAll("input[type='checkbox']")
        .forEach((checkbox) => (checkbox.checked = false));
      loading.setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error('Erro inesperado');
      loading.setLoading(false);
    }
  }

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-4">
            <label>Centro de Distribuição</label>
            <select
              id="selectCd"
              className="form-control"
              onChange={(event) => handleFillCompaniesTable(event)}
              disabled={listCD.length === 0 ? true : false}
            >
              <option defaultValue value="">
                Selecione o CD...
              </option>
              {listCD.map((item) => (
                <option value={item.numero_empresa} key={item.numero_empresa}>
                  {item.nome_reduzido}
                </option>
              ))}
            </select>
          </div>

          <div className="col-sm-4">
            <label>Usuário Destino</label>
            <input
              id="inputTargetUser"
              className="form-control"
              placeholder="Digita o nome do usuário"
              list="datalistTargetusuario"
              autoComplete="off"
            />
            <datalist
              id="datalistTargetusuario"
              className="form-control"
              hidden
            >
              {users.map((user) => (
                <option key={user.username}>
                  {user.username} - {user.first_name}
                </option>
              ))}
            </datalist>
          </div>

          <div className="col-sm-2 ">
            <label htmlFor="priority">Prioridade</label>
            <input
              className=" form-control"
              type="number"
              name="priority"
              id="priority"
              placeholder="Insira o grau de prioridade"
              min={1}
              onChange={(e) => setPriority(e.target.value)}
            />
          </div>

          <div className="col-sm-2 d-flex align-items-center mt-4">
            <label htmlFor="isActive">Ativo</label>
            <input
              className="h-50 w-50 ml-[-2]"
              type="checkbox"
              name="isActive"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          </div>
        </div>

        <div
          style={{
            overflowY: 'scroll',
            border: '1px solid  #d9d9d9',
            width: '100%',
            float: 'left',
            height: '50vh',
            position: 'relative',
            marginBottom: '5px',
            marginTop: '16px',
          }}
        >
          <table id="companiesTable" className="table table-striped">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="companyCheckboxAll"
                    onChange={(e) => handleCheckAllCompanies(e.target.checked)}
                    disabled={companies.length === 0}
                  />
                </th>
                <th>Nº Empresa</th>
                <th>Empresa</th>
                <th>Conferente</th>
                <th>Prioridade</th>
                <th>Ativo</th>
              </tr>
            </thead>
            <tbody ref={checkboxCompaniesRef}>
              {companies.map((company) => (
                <tr key={company.numero_empresa} id={company.numero_empresa}>
                  <td>
                    <input
                      type="checkbox"
                      className="companyCheckboxItem"
                      value=""
                      onChange={() => {
                        handleAddCheckedCompany(company.numero_empresa);
                      }}
                    />
                  </td>
                  <td>{company.numero_empresa}</td>
                  <td>{company.nome_reduzido}</td>
                  <td>{company.codigo_conferente}</td>
                  <td>{company.prioridade}</td>
                  <td>{company.ativo === 'S' ? 'Sim' : 'Não'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row ">
          <div className="col-sm-12">
            <div className="form-group">
              <button
                className="btn btn-primary float-right mt-2"
                onClick={() => handleAppointment()}
                disabled={loading.isLoading}
              >
                {loading.isLoading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Salvar'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="modal-appointment-unactive"
        className="modal"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Atenção</h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onCloseModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                O modo separação por empresa não foi ativado, as empresas
                selecionadas serão inativadas. Deseja continuar?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleAppointment(true)}
                disabled={loading.isLoading}
              >
                {loading.isLoading ? (
                  <Spinner
                    className="ml-2 mr-2"
                    animation="border"
                    variant="light"
                    size="sm"
                  />
                ) : (
                  'Salvar'
                )}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  onCloseModal();
                  // $('#table-spinner').hide();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
