import pt from 'date-fns/locale/pt';
import $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import { toast } from 'react-toastify';
import { FaturamentoNFTab } from '../components/FaturamentoProdutos/FatutramentoNFTab';
import { ListasTab } from '../components/FaturamentoProdutos/ListasTab';
import { LojasTab } from '../components/FaturamentoProdutos/LojasTab';
import { ModalAlert } from '../components/FaturamentoProdutos/ModalAlert';
import { ModalFaturarSuccess } from '../components/FaturamentoProdutos/ModalFaturarSuccess';
import { ModalFaturarTipo } from '../components/FaturamentoProdutos/ModalFaturarTipo';
import { ModalOrders } from '../components/FaturamentoProdutos/ModalOrders';
import { ModalRefaturamento } from '../components/FaturamentoProdutos/ModalRefaturamento';
import { RefaturamentoLoteTab } from '../components/FaturamentoProdutos/RefaturamentoLoteTab';
import { useLoading } from '../hooks/useLoading';
import api from '../services/api';

registerLocale('pt', pt);

const token = JSON.parse(localStorage.getItem('token'));
const codigo_usuario = token && token.userName ? token.userName : '';
let lstAux = [];
let sequencia_pessoa;
let statusList = [];
let messageFaturaLista_v2;
let selectedTab = 'lista'

export default function Monitoring() {
  const [refaturamentoLoteTotal, setRefaturamentoLoteTotal] = useState([]);
  const [refaturamentoLoteSaida, setRefaturamentoLoteSaida] = useState([]);
  const [listCD, setListCD] = useState([]);
  const [usuario, setusuario] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [listLoja, setListLoja] = useState([]);
  const [listItemProduto, setlistItemProduto] = useState([]);
  const [seqItemListaDistinct, setSeqItemListaDistinct] = useState([]);
  const [listLotesConferidos, setListLotesConferidos] = useState([]);
  const [enableElement, setEnableElement] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [selectedLists, setSelectedLists] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [billingSuccessResponse, setBillingSuccessResponse] = useState({});
  const loading1 = useLoading();
  const loading2 = useLoading();
  const loading3 = useLoading();

  const checkboxListsRef = useRef(null);
  const checkboxOrdersRef = useRef(null);

  const centroDistribuicaoSelect = document.querySelector(
    '#centroDistribuicaoSelect',
  );
  let spanNomeEmpresa = document.getElementById('spanNomeEmpresa');

  const divTabs = {
    display: 'block',
  };

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/usuario/listausuario/');
      setusuario(res.data.results);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/empresa/centrodistribuicao/');
      setListCD(res.data.results);

      const dataInicial = document.getElementById('dataInicial');
      const dataFinal = document.getElementById('dataFinal');
      const currentDate = new Date();
      const currentDayMinusOne = new Date();
      currentDayMinusOne.setDate(currentDayMinusOne.getDate() - 1);
      dataInicial.value = formatDate(currentDayMinusOne);
      dataFinal.value = formatDate(currentDate);

      $('#dataInicial2').val(formatDate(currentDayMinusOne));
      $('#dataFinal2').val(formatDate(currentDate));

      statusList['F'] = 'Finalizado';
      statusList['L'] = 'Liberado';
      statusList['X'] = 'Cancelado';
      statusList['C'] = 'Conferido';
      statusList['P'] = 'Pendente';

      const res2 = await api.get('/empresa/listaempresa/');
      setListLoja(res2.data.results);
      $('#table-spinner, #spinner-nf').hide();
    }

    fetchData();
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  var msg = '';

  async function handleFinalizar() {
    msg = '';

    $('.ckbLotes:checked').each(async function () {
      await listLotesConferidosSave($(this).val());
    });

    setSelectedLists([])
  }



  async function listLotesConferidosSave(sequencia_lista) {
    const res = await api.post('/listaseparacao/finalizarlista/', {
      codigo_usuario: codigo_usuario,
      sequencia_lista: sequencia_lista,
    });

    if (res !== undefined) {
      handlePesquisar();
      msg += '<div>' + res.data[0].mensagem + '</div>';
      ShowAlert('Atenção', msg);
    }
  }

  async function handleReopenList() {
    if (!selectedLists.length) {
      return toast.warning('Selecione pelo menos uma lista.');
    }

    if (selectedLists.length > 1) {
      return toast.warning('Selecione apenas a lista mais recente.');
    }

    if (selectedLists[0].situacao !== "F") {
      return toast.warning(`Lista ${selectedLists[0].sequencia_lista} já está aberta.`)
    }

    const data = {
      sequencia_lista: String(selectedLists[0].sequencia_lista),
      sequencia_comprador: String(selectedLists[0].sequencia_comprador),
      chapa: codigo_usuario,
    }

    const responseReopenList = await await api.post('/listaseparacao/reabre_lista', data);

    if (responseReopenList.data.codResult === 1) {
      return toast.error(`${responseReopenList.data.msg}.`);
    }

    toast.success(`Lista ${responseReopenList.data.codvalidador} reaberta com sucesso.`);

    setSelectedLists([])
    handlePesquisar()
  }

  function handleSearchcentroDistribuicao(event) {
    let spanNomeProdutoConferido = document.querySelector(
      '#spanNomeProdutoConferido',
    );
    spanNomeEmpresa.innerHTML = '&nbsp;';
    spanNomeProdutoConferido.innerHTML = '&nbsp;';

    if (event.target.selectedIndex !== 0) {
      setEnableElement(false);
    } else {
      setEnableElement(true);
      setListLoja([]);
      setlistItemProduto([]);
      setListLotesConferidos([]);
    }
  }

  async function handlePesquisar() {
    setlistItemProduto([]);
    setListLotesConferidos([]);
    setLotes([]);

    if (centroDistribuicaoSelect.selectedIndex !== 0) {
      const dataInicial = document.getElementById('dataInicial');
      const dataFinal = document.getElementById('dataFinal');

      if (centroDistribuicaoSelect.selectedIndex === 0) {
        alert('Entre com um centro de distribuição');
        return;
      }

      if (!dataInicial.value) {
        alert('Entre com a data inicial');
        return;
      }

      if (!dataFinal.value) {
        alert('Entre com a data final');
        return;
      }

      loading1.setLoading(true);
      const res = await api.post('/listaseparacao/listaseparacao', {
        sequencia_lista: null,
        numero_empresa: Number($('#centroDistribuicaoSelect').val()),
        data_criacao_inicio: moment(dataInicial.value).format('DD-MM-yyyy'),
        data_criacao_fim: moment(dataFinal.value).format('DD-MM-yyyy'),
        situacao: $('#selStatusFixo').val(),
        sequencia_comprador: 0,
      });

      if (res.data.resposta == null) {
        setLotes(res.data);
      } else {
        setLotes([]);
        toast.error('Nenhum lote foi localizado');
      }
      loading1.setLoading(false);

      /*
      async function fetchData() {
        const res = await api.get('/empresa/listaempresa/');
        setListLoja(res.data.results);
      }

      fetchData();*/
    } else {
      setListLoja([]);
    }
  }

  function handleItemListLoja(sequenciaPessoa, nomeEmpresa, info = '') {
    setlistItemProduto([]);
    setListLotesConferidos([]);
    loading2.setLoading(true);

    $('.btnEmpresa').removeClass('active-conferencia');
    $('.' + info).addClass('active-conferencia');

    let status = document.querySelector('#selStatusFixo2');
    let statusVal = status.value !== 'T' ? status.value : 'P,C,L,F,X';
    let centroDistibuicao = $('#centroDistribuicaoSelect2').val();
    let spanNomeProdutoConferido = document.querySelector(
      '#spanNomeProdutoConferido',
    );

    sequencia_pessoa = sequenciaPessoa;
    spanNomeEmpresa.innerHTML = '&nbsp;';
    spanNomeProdutoConferido.innerHTML = '&nbsp;';

    if ($('#centroDistribuicaoSelect2').val() == '') {
      alert('Selecione um centro de distribuição');
      return;
    }

    async function fetchData() {
      loading2.setLoading(true);
      const res = await api.post('/listaseparacao/produtoconferido_v2/', {
        numero_empresa: centroDistibuicao,
        sequencia_lista: null,
        sequencia_produto: null,
        sequencia_pessoa: sequenciaPessoa,
        data_inicial: moment($('#dataInicial2').val()).format('DD-MM-yyyy'),
        data_final: moment($('#dataFinal2').val()).format('DD-MM-yyyy'),
        status: statusVal,
        codigo_usuario:
          $('#inputTargetusuario').val() != ''
            ? $('#inputTargetusuario').val().split(' - ')[0]
            : null,
      });

      if (
        res.data.resposta === 'Item nao localizado' ||
        res.data === undefined
      ) {
        //alert("Item nao localizado");
        loading2.setLoading(false);
        $('#txtMsg').text('Nenhum item localizado');
        toast.error('Nenhum item localizado');
      } else {
        lstAux = res.data.sort(function (a, b) {
          var x = a.sequencia_lista < b.sequencia_lista ? -1 : 1;
          return x;
        });

        let lst = [];
        let seq_lista_anterior = 0;
        lstAux.map((item) => {
          if (item.sequencia_lista !== seq_lista_anterior) {
            seq_lista_anterior = item.sequencia_lista;
            lst.push(item);
          }
        });

        lstAux.forEach((item) => {
          item.prod_status = `${item.sequencia_produto}-${item.status_conferencia}`;
          item.descricao =
            item.sequencia_produto + ' - ' + firstUpperCase(item.descricao);

          switch (item.status_conferencia) {
            case 'F':
              item.status_conferencia = 'Finalizado';
              break;
            case 'L':
              item.status_conferencia = 'Liberado';
              break;
            case 'X':
              item.status_conferencia = 'Cancelado';
              break;
            case 'C':
              item.status_conferencia = 'Conferido';
              break;
            default:
              item.status_conferencia = 'Pendente';
          }
        });

        setSeqItemListaDistinct(
          lst.filter(
            (item) =>
              item.status_conferencia === 'Pendente' ||
              item.status_conferencia === 'Liberado' ||
              item.status_conferencia === 'Conferido',
          ),
        );

        lstAux = lstAux.filter(
          (item) => item.sequencia_pessoa === sequenciaPessoa,
        );

        spanNomeEmpresa.innerText = firstUpperCase(nomeEmpresa);

        lstAux = lstAux.sort(function (a, b) {
          var x =
            firstUpperCase(a.descricao) < firstUpperCase(b.descricao) ? -1 : 1;
          return x;
        });

        lstAux = lstAux.filter(
          (item) => item.sequencia_lote !== sequenciaPessoa,
        );

        var listaAuxiliar2 = [...lstAux];
        var findLista = [];
        var novaLista = [];
        /*listaAuxiliar2 => lista de lotes por lista de produtos.*/

        for (var i = 0; i < listaAuxiliar2.length; i++) {
          var currentItem = listaAuxiliar2[i];

          /*findLista => qtd de lotes em cada item de produtos*/
          findLista = lstAux.filter(
            (item) =>
              item.sequencia_lista == currentItem.sequencia_lista &&
              item.sequencia_produto == currentItem.sequencia_produto &&
              item.status_conferencia == currentItem.status_conferencia &&
              item.codigo_usuario == currentItem.codigo_usuario &&
              item.bonificado == currentItem.bonificado,
          );

          if (findLista.length > 0) {
            var novoValor = 0;
            currentItem.quantidade_atendida = 0;

            for (var b = 0; b < findLista.length; b++) {
              novoValor += findLista[b].quantidade_atendida;
              var c = lstAux.indexOf(findLista[b]);
              lstAux.splice(c, 1);
            }
            currentItem.quantidade_atendida = novoValor;
            novaLista.push(currentItem);
          }
        }

        lstAux = [...listaAuxiliar2];
        /* lstAux => se torna a listagem final de produtos
          novaLista => é a lista de produtos agrupados*/
        console.log('---Listagem final de produtos---');
        console.log(lstAux);
        console.log('---Listagem de produtos agrupados---');
        console.log(novaLista);
        setlistItemProduto(novaLista);
        loading2.setLoading(false);
      }
    }
    loading2.setLoading(false);

    fetchData();
  }

  function handleItemProduto(item, info = '') {
    $('.btnProduto').removeClass('active-conferencia');
    $('.' + info).addClass('active-conferencia');

    let spanNomeProdutoConferido = document.querySelector(
      '#spanNomeProdutoConferido',
    );
    spanNomeProdutoConferido.innerText = item.descricao;

    var a = lstAux.filter(
      (itemAux) =>
        item.sequencia_item_lista === itemAux.sequencia_item_lista &&
        itemAux.sequencia_lote !== 0 &&
        itemAux.status_conferencia === item.status_conferencia,
    );

    $('.input-lote').val('');
    setListLotesConferidos(a);

    if (a.length == 0) {
      //toast.error("Nenhum lote foi localizado.")
    }
  }

  function firstUpperCase(phrase) {
    const str = phrase.toLowerCase();
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(' ');
    return str2;
  }

  async function handleFaturar(isSubsidized = false) {
    // $('#table-spinner').show();
    console.log(isSubsidized);
    try {
      let listas = '';
      let sequencia_pessoa = 0;
      var isConferido = false;

      if (listItemProduto.length === 0) {
        return toast.error('Não há produtos para serem faturados.');
      }

      let lst = listItemProduto.sort(function (a, b) {
        var x = a.sequencia_lista < b.sequencia_lista ? -1 : 1;
        return x;
      });

      let seq_lista_anterior = 0;
      lst.map(async (item) => {
        if (item.sequencia_lista !== seq_lista_anterior) {
          seq_lista_anterior = item.sequencia_lista;
          sequencia_pessoa = item.sequencia_pessoa;
          listas += item.sequencia_lista + ',';

          if (item.status_conferencia === 'Conferido') {
            isConferido = true;
          }
        }
      });

      const hasProdutoBonificado = listItemProduto.find(
        (produto) => produto.bonificado === 'S',
      );
      /* isSubsidized falso por padrão e com bonificado 'S' abre o modal */
      if (hasProdutoBonificado && !isSubsidized) {
        window.$('#modal-faturar-tipo').modal('show');
        return;
      }

      listas = listas ? listas.slice(0, -1) : '';

      if (listas.length > 200) {
        return toast.warning('Lista muito grande. Selecione um período menor.');
      }

      if (isConferido) {
        if (
          !window.confirm(
            'Existem produtos conferidos não liberados, deseja prosseguir?',
          )
        ) {
          // $('#table-spinner').hide();
          return;
        }
      }

      if (listas) {
        loading3.setLoading(true);
        const res = await api.post('/listaseparacao/faturaLista_v2', {
          seqCliente: sequencia_pessoa,
          listas: listas,
          chapaUsuario: codigo_usuario,
          bonificado: $('input[name="faturar-tipo"]:checked').val(),
        });

        if (res.data !== undefined) {
          // messageFaturaLista_v2 = res.data;
          if (res.data.codResult == 0) {
            var toreturn =
              '<div><b>Número Pedido Vasilhame:</b> ' +
              (res.data.numero_pedido_vasilhame != null
                ? res.data.numero_pedido_vasilhame
                : '') +
              '</div>';
            toreturn +=
              '<div><b>Número Df Vasilhame:</b> ' +
              (res.data.Numero_Df_vasilhame != null
                ? res.data.Numero_Df_vasilhame
                : '') +
              '</div>';
            toreturn +=
              '<div><b>Número Pedido Produto:</b> ' +
              res.data.numero_pedido_produto +
              '</div>';
            toreturn +=
              '<div><b>Número Df Produto:</b> ' +
              res.data.Numero_DF_produto +
              '</div>';

            ShowAlert('Faturado com sucesso!', toreturn);
          } else if (res.data.resposta !== undefined) {
            toast.success(res.data.resposta);
          } else {
            // toast.success(res.data.msg);
            setBillingSuccessResponse(res.data);
          }
        }
        loading3.setLoading(false);
      } else {
        loading3.setLoading(false);
        toast.error('Selecione uma empresa para faturar.');
      }

      $('input[name="faturar-tipo"]')[1].checked = true;
      loading3.setLoading(false);
      CloseModal();
      window.$('#modal-faturar-success').modal('show');
    } catch (err) {
      console.log(err);
      ShowAlert('Erro', 'Não foi possível concluir a operação.');
    }
    // $('#table-spinner').hide();
  }

  async function CloseModal() {
    window.$('.modal').modal('hide');
  }

  function ShowAlert(title, message) {
    $('#modal-alert .modal-title').html(title);
    $('#modal-alert .modal-body').html(
      '<i className="fa fa-info-circle fa-fw text-gray"></i> ' + message,
    );
    window.$('#modal-alert').modal('show');
  }

  async function FiltrarEmpresas() {
    $('#ddlUfs option').removeAttr('selected');
    $('#ddlUfs option').eq(0).attr('selected', 'selected');
    $('.btnEmpresa').hide();
    $(
      ".btnEmpresa:containsi('" + $('#txtFiltraEmpresa').val() + "')",
    ).slideDown('fast');
  }

  async function FiltrarEmpresasPorEstado() {
    $('#txtFiltraEmpresa').val('');
    $('.btnEmpresa').hide();
    $(".btnEmpresa[data-uf='" + $('#ddlUfs').val() + "']").slideDown('fast');

    if ($('#ddlUfs').val() == '0') {
      $('.btnEmpresa').show();
    }
  }

  $.extend($.expr[':'], {
    containsi: function (elem, i, match, array) {
      return (
        (elem.textContent || elem.innerText || '')
          .toLowerCase()
          .indexOf((match[3] || '').toLowerCase()) >= 0
      );
    },
  });

  async function FaturarNf() {
    $('#spinner-nf').show();

    try {
      const res = await api.post('/listaseparacao/faturamento_nota_origem', {
        numero_da_nota: $('#txtNumeroNF').val(),
        centro_de_distribuicao_de_origem: $('#cdOrigem').val(),
        chapa_do_usuario: codigo_usuario,
      });

      if (res !== undefined) {
        if (res.data.msg !== undefined) {
          if (res.data.codResult != 0) {
            ShowAlert('Erro', res.data.msg);
          } else {
            var cMsg =
              '<div>Número DF Produto: <b>' +
              (res.data.Numero_DF_produto == null
                ? '-'
                : res.data.Numero_DF_produto) +
              '</b></div>';
            cMsg +=
              '<div>Número DF Vasilhame: <b>' +
              (res.data.Numero_Df_vasilhame == null
                ? '-'
                : res.data.Numero_Df_vasilhame) +
              '</b></div>';
            cMsg +=
              '<div>Número Pedido Produto: <b>' +
              (res.data.numero_pedido_produto == null
                ? '-'
                : res.data.numero_pedido_produto) +
              '</b></div>';
            cMsg +=
              '<div>Número Pedido Vasilhame: <b>' +
              (res.data.numero_pedido_vasilhame == null
                ? '-'
                : res.data.numero_pedido_vasilhame) +
              '</b></div>';

            ShowAlert(res.data.msg, cMsg);
          }
        }
      } else {
        ShowAlert('Erro', 'Não foi possível concluir a operação.');
      }
    } catch (ex) {
      ShowAlert('Erro', 'Não foi possível concluir a operação.');
    }

    $('#spinner-nf').hide();
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  async function BuscarRefaturamento() {
    $('#spinner-nf').show();
    var estoqueLote = [];
    const res = await api.get(
      '/listaseparacao/v1/busca_lote_total/' +
      $('#txtLoteRefaturamento').val() +
      '/N',
    );

    if (res.data.results.length > 0) {
      for (var i = 0; i < res.data.results.length; i++) {
        const res3 = await api.get(
          '/listaseparacao/v1/estoquelote/' +
          $('#cdRefaturamento').val() +
          '/0/' +
          res.data.results[i].sequencia_produto +
          '/N',
        );
        if (res3.data.results.length > 0) {
          estoqueLote = res3.data.results;
        }
      }

      setRefaturamentoLoteTotal(res.data.results);

      const res2 = await api.get(
        '/listaseparacao/v1/busca_saida_lote/' +
        $('#txtLoteRefaturamento').val() +
        '/' +
        $('#cdRefaturamento').val(),
      );
      if (res2.data.results.length > 0) {
        for (var i = 0; i < res2.data.results.length; i++) {
          res2.data.results[i].estoqueLote = estoqueLote.filter(
            (item) =>
              item.seqfornecedor == res2.data.results[i].sequencia_cliente,
          );
        }
        res2.data.results.sort(dynamicSort('sequencia_cliente'));
        setRefaturamentoLoteSaida(res2.data.results);
      } else {
        setRefaturamentoLoteTotal([]);
        setRefaturamentoLoteSaida([]);
      }
    } else {
      ShowAlert('Erro', 'Não foi possível concluir a operação.');
    }

    $('#spinner-nf').hide();
  }

  async function RefaturarEmLote() {
    var ckbs = $('.ckbRefaturamento:checked');

    setSelectedRows([]);

    var tempSelectedRows = [];
    var msg = '';

    ckbs.each(async function () {
      var currentItem = refaturamentoLoteSaida[$(this).attr('data-index')];
      tempSelectedRows.push(refaturamentoLoteSaida[$(this).attr('data-index')]);
      tempSelectedRows[tempSelectedRows.length - 1].selectedEstoqueLote = $(
        '#' + $(this).attr('data-select'),
      ).val();

      if ($('#' + $(this).attr('data-select')).val() != '-1') {
        const res = await api.post('/listaseparacao/v1/refaturamento_lote', {
          numero_empresa: $('#cdRefaturamento').val(),
          identificador: currentItem.id_linha,
          lote_destino: $('#' + $(this).attr('data-select')).val(),
          codigo_usuario: codigo_usuario,
        });

        if (res.data.codResult == 0) {
          msg +=
            "<div class='text-green'><i class='fa fa-check'></i> Pedido " +
            res.data.numero_pedido_produto +
            ': ' +
            res.data.msg +
            '</div>';
        } else {
          msg +=
            "<div class='text-red'><i class='fa fa-times'></i> Pedido " +
            res.data.numero_pedido_produto +
            ': ' +
            res.data.msg +
            '</div>';
        }
      } else {
        msg +=
          "<div class='text-orange'><i class='fa fa-exclamation'></i> Pedido " +
          $('#' + $(this).attr('data-select')).attr('data-pedido') +
          ': O lote selecionado é inválido.</div>';
      }

      ShowAlert('Atenção', msg);
    });

    /*
    for (var r = 0; r < tempSelectedRows.length; r++) {
      var tempList = tempSelectedRows.filter(item => item.sequencia_cliente == tempSelectedRows[r].sequencia_cliente);

      var finalQuantidade = 0;
      var finalEmbalagem = 0;
      var finalValor = 0;
      for (var i = 0; i < tempList.length; i++) {
        finalQuantidade += tempList[i].quantidade;
        finalEmbalagem += tempList[i].embalagem;
        finalValor += tempList[i].valor;

      }


      tempSelectedRows[r].finalQuantidade = finalQuantidade;
      tempSelectedRows[r].finalEmbalagem = finalEmbalagem;
      tempSelectedRows[r].finalValor = finalValor;
    }

    setSelectedRows(tempSelectedRows);
    forceUpdate();
    
    */
    //window.$('#modal-refaturamento').modal('show')
  }

  async function handleSalvarLotes() {
    try {
      $('.ckbLoteConf:checked').each(async function () {
        const sequencia_lote = $(
          '.' + $(this).attr('data-sequenciaLote'),
        ).val();
        const sequencia_produto = $(this).attr('data-sequenciaProduto');
        const sequencia_lista = $(this).attr('data-sequenciaLista');
        const sequencia_pessoa = $(this).attr('data-sequenciaPessoa');
        const id_conferencia = $(this).attr('data-indiceConferencia');
        var status = $('.' + $(this).attr('data-status')).val();

        switch (status) {
          case 'Finalizado':
            status = 'F';
            break;
          case 'Liberado':
            status = 'L';
            break;
          case 'Cancelado':
            status = 'X';
            break;
          case 'Conferido':
            status = 'C';
            break;
          case 'Pendente':
            status = 'P';
            break;
        }

        const res = await api.post('/listaseparacao/alterarconferencia/', {
          sequencia_lista: sequencia_lista,
          status: status,
          codigo_usuario: codigo_usuario,
          sequencia_produto: sequencia_produto,
          sequencia_lote: sequencia_lote,
          id_conferencia: id_conferencia,
          sequencia_pessoa: sequencia_pessoa,
        });

        if (res !== undefined) {
          if (res.data.resposta !== undefined) {
            toast.error(res.data.resposta);
          }
          if (res.data[0].mensagem !== undefined) {
            if (res.data[0].numero_erro) {
              toast.error(res.data[0].mensagem);
            } else {
              toast.success(res.data[0].mensagem);
            }
          }
        } else {
          toast.error('Item não conferido.');
        }
      });
    } catch (ex) {
      toast.error('Não foi possível salvar os itens selecionados.');
    }

    /*

    const lotsTable = document.getElementById('lotsTable');
    const totalRows = lotsTable.rows.length;
    let success = true;

    for (let row = 0; row < totalRows; row++) {
      const chkbx = lotsTable.rows[row].cells[0].getElementsByTagName("input")[0];
      const regex = /\d+/g;

      if (chkbx.checked) {

        const sequencia_lote = lotsTable.rows[row].cells[1].getElementsByTagName("input")[0].value;
        const seqProd = lotsTable.rows[row].cells[2].innerText;
        const sequencia_produto = seqProd.match(regex)[0];
        const scope = lstAux.find(item => item.sequencia_produto == sequencia_produto);
        const sequencia_lista = scope.sequencia_lista;
        const sequencia_pessoa = scope.sequencia_pessoa
        const id_conferencia = scope.indice_conferencia;

        let status = lotsTable.rows[row].cells[7].getElementsByTagName("select")[0].value;
        switch (status) {
          case 'Finalizado':
            status = 'F';
            break;
          case 'Liberado':
            status = 'L';
            break;
          case 'Cancelado':
            status = 'X';
            break;
          case 'Conferido':
            status = 'C';
            break;
          case "Pendente":
            status = "P"
        }

        const res = await api.post("/listaseparacao/alterarconferencia/", {
          "sequencia_lista": sequencia_lista,
          "status": status,
          "codigo_usuario": codigo_usuario,
          "sequencia_produto": sequencia_produto,
          "sequencia_lote": sequencia_lote,
          "id_conferencia": id_conferencia,
          "sequencia_pessoa": sequencia_pessoa
        });

        if (res !== undefined) {
          if (res.data.resposta !== undefined) {
            alert(res.data.resposta);
          }
          if (res.data[0].mensagem !== undefined) {
            if (res.data[0].numero_erro) {
              success = false;
              alert(res.data[0].mensagem);
              break;
            }
          }
        }
      }
    }

    if (success) {
      alert("Operação realizada com sucesso")
    }

*/
  }

  /* Toggle Checkbox Lists */
  function handleAddCheckedLists(id) {
    //dentro do tbody, pegar todo elemento que pertence ao 'id'
    const row = checkboxListsRef.current.querySelector(`[id='${id}']`);

    let filteredLists = [];

    //Pegar o input checkbbox da linha que encontrou com 'id'
    const input = row.querySelector('input');

    if (input.checked) {
      //Pegar o objeto do array de "lotes" que bate com o 'id' do input que foi clicado.
      const foundLote = lotes.find(
        (item) => item.sequencia_lista === Number(id),
      );

      filteredLists = [...selectedLists, foundLote];
    } else {
      //Caso desmarcar o input depois de marcar, filtra o array de inputs marcados p/ remover oq foi desmarcado.
      filteredLists = selectedLists.filter(
        (lote) => lote.sequencia_lista !== id,
      );
    }

    //Colocar o array em ordem crescente da seq_lista
    filteredLists.sort((a, b) => a.sequencia_lista - b.sequencia_lista);
    setSelectedLists(filteredLists);
  }

  function handleToggleAndAddAllLists(checked) {
    const rows = checkboxListsRef.current.querySelectorAll('tr');
    const formattedLotes = [];

    for (const row of rows) {
      const input = row.querySelector('input');
      input.checked = checked;

      if (checked) {
        const foundLote = lotes.find(
          (item) => item.sequencia_lista === Number(row.id),
        );

        formattedLotes.push(foundLote);
      }
    }

    setSelectedLists(formattedLotes);
  }

  /* Toggle Checkbox Orders */
  function handleAddCheckedOrders(id) {
    //dentro do tbody do modal, pegar todo elemento que pertence ao 'id'
    const row = checkboxOrdersRef.current.querySelector(`[id='${id}']`);

    let filteredOrders = [];

    //Pegar o input checkbbox da linha que encontrou com 'id'
    const input = row.querySelector('input');

    if (input.checked) {
      //Pegar o objeto do array de "orders" que bate com o 'id' do input que foi clicado.
      const foundOrder = ordersList.find(
        (item) => item.numero_pedido_venda === Number(id),
      );

      filteredOrders = [...selectedOrders, foundOrder];
    } else {
      //Caso desmarcar o input depois de marcar, filtra o array de inputs marcados p/ remover oq foi desmarcado.
      filteredOrders = selectedOrders.filter(
        (lote) => lote.numero_pedido_venda !== id,
      );
    }

    //Colocar o array em ordem crescente da seq_lista
    filteredOrders.sort(
      (a, b) => a.numero_pedido_venda - b.numero_pedido_venda,
    );
    setSelectedOrders(filteredOrders);
  }

  function handleToggleAndAddAllOrders(checked) {
    const rows = checkboxOrdersRef.current.querySelectorAll('tr');
    const formattedOrders = [];

    for (const row of rows) {
      const input = row.querySelector('input');
      input.checked = checked;

      if (checked) {
        const foundOrder = ordersList.find(
          (item) => item.numero_pedido_venda === Number(row.id),
        );

        formattedOrders.push(foundOrder);
      }
    }

    setSelectedOrders(formattedOrders);
  }

  /* When MODAL opens, check if truncate 'compradores' */
  const getCompradores = (string) => {
    const MAX_LENGTH = 20;

    const stringWithSpaces = string.split(',').join(', ');

    if (stringWithSpaces.length > MAX_LENGTH) {
      const truncatedString = `${stringWithSpaces.substring(0, MAX_LENGTH)}...`;

      return {
        compradores: stringWithSpaces,
        compradoresTruncated: truncatedString,
      };
    }

    return {
      compradores: stringWithSpaces,
    };
  };

  async function handleOrderListModalOpen() {
    setOrdersList([]);

    if (!selectedLists.length) {
      return toast.warning('Selecione pelo menos uma lista.');
    }

    const lists = selectedLists
      .map((list) => list.sequencia_lista.toString())
      .join();

    const empresa = $('#centroDistribuicaoSelect').val();

    const data = {
      listas: lists,
      numero_empresa: empresa,
    };

    const response = await api.post('/listaseparacao/v1/busca_pedido', data);

    const results = response.data;

    if (!results.length)
      return toast.error('Não existem pedidos na lista selecionada.');

    window.$('#modal-list-prod').modal('show');

    const formattedOrderList = results.map((result) => ({
      ...result,
      ...getCompradores(result.compradores),
    }));

    setOrdersList(formattedOrderList);
  }

  async function handleCancelOrders() {
    try {
      if (!selectedOrders.length) {
        return toast.warning('Selecione pelo menos um pedido.');
      }

      const orders = selectedOrders.map((order) => {
        return {
          codigo_usuario: codigo_usuario,
          numero_venda: String(order.numero_pedido_venda),
          numero_empresa: String(order.numero_empresa),
        };
      });

      const results = await Promise.all(
        orders.map(async (orderToCancel) => {
          return await api.post(
            '/listaseparacao/v1/cancela_pedido',
            orderToCancel,
          );
        }),
      );

      results.map((result) => {
        if (result.data.codResult === 1) {
          return toast.error(`${result.data.msg}`);
        }

        return toast.success(
          `Pedido ${result.data.codvalidador} cancelado com sucesso.`,
        );
      });

      CloseModal();
      setSelectedLists([]);
      setSelectedOrders([]);
      setOrdersList([]);
      document
        .querySelectorAll("input[type='checkbox']")
        .forEach((checkbox) => (checkbox.checked = false));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  function closeModalAndclearBillingSuccessResponse() {
    CloseModal();
    setBillingSuccessResponse({});
  }

  function closeModalAndHideSpinner() {
    CloseModal();
    $('#table-spinner').hide();
  }

  return (
    <>
      <div className="content-header">
        <h1>Faturamento de Produtos</h1>
      </div>
      <div className="content">
        <div className="card-body p-1">
          <div style={divTabs}>
            <Tabs defaultActiveKey="lista"
              onSelect={(firstTab, secondTab) => {
                selectedTab = firstTab;
              }}>
              <Tab eventKey="lista" title="Listas" tab={selectedTab}>
                <ListasTab
                  onSearchCd={handleSearchcentroDistribuicao}
                  cds={listCD}
                  enableElement={enableElement}
                  onSearchProducts={handlePesquisar}
                  isLoading={loading1.isLoading}
                  onToggleAllLists={handleToggleAndAddAllLists}
                  checkboxListsRef={checkboxListsRef}
                  lotes={lotes}
                  onAddCheckedList={handleAddCheckedLists}
                  statusList={statusList}
                  onFinish={handleFinalizar}
                  onOrderListModalOpen={handleOrderListModalOpen}
                  onReopenList={handleReopenList}
                />
              </Tab>

              <Tab
                eventKey="loja"
                title="Lojas"
                style={{ background: 'white' }}
              >
                <LojasTab
                  cds={listCD}
                  users={usuario}
                  onFilterCompanyByState={FiltrarEmpresasPorEstado}
                  stores={listLoja}
                  onFilterCompanies={FiltrarEmpresas}
                  onItemStoreList={handleItemListLoja}
                  isLoading={loading2.isLoading}
                  productItemList={listItemProduto}
                  onProductItem={handleItemProduto}
                  conferedLotesList={listLotesConferidos}
                  isLoading2={loading3.isLoading}
                  onBilling={handleFaturar}
                  onSaveLotes={handleSalvarLotes}
                />
              </Tab>

              <Tab
                eventKey="faturar"
                title="Faturamento por NF"
                style={{ background: 'white' }}
              >
                <FaturamentoNFTab cds={listCD} onBillingNF={FaturarNf} />
              </Tab>

              <Tab
                eventKey="refaturamento"
                title="Refaturamento em lote"
                style={{ background: 'white' }}
              >
                <RefaturamentoLoteTab
                  cds={listCD}
                  onSearchRebilling={BuscarRefaturamento}
                  leavingLotesRebilling={refaturamentoLoteSaida}
                  onRebillingLote={RefaturarEmLote}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      <ModalAlert onCloseModal={CloseModal} />
      <ModalRefaturamento onCloseModal={CloseModal} selectedRows={selectedRows} />
      <ModalOrders
        onCloseModal={CloseModal}
        onToggleAllOrders={handleToggleAndAddAllOrders}
        checkboxOrdersRef={checkboxOrdersRef}
        orders={ordersList}
        onAddCheckedOrders={handleAddCheckedOrders}
        onCancelOrders={handleCancelOrders}
      />
      <ModalFaturarTipo
        onCloseModal={CloseModal}
        onBilling={handleFaturar}
        isLoading={loading3.isLoading}
        onCloseModalAndHideSpinner={closeModalAndHideSpinner}
      />

      <ModalFaturarSuccess
        onCloseModal={CloseModal}
        billingResponse={billingSuccessResponse}
        onCloseModalAndClearBillingSuccessResponse={closeModalAndclearBillingSuccessResponse}
      />
    </>
  );
}
