export function FormConferenciaAvulsa({
  cds,
  onFilterCompanies,
  companies,
  onSetSelectedCompany,
  onReleaseBilling,
  isBonified,
  onSetBonified,
  onSearchProcuts
}) {
  return (
    <div className="col-sm-3">
      <div className="card-body mt-2 px-2 py-0">
        <label>Centro de Distribuição</label>
        <select id="ddlEmpresa" className="form-control">
          {cds.map((item, index) => (
            <option key={index} value={item.numero_empresa}>
              {item.nome_reduzido}
            </option>
          ))}
        </select>
      </div>

      <div id="boxEmpresaDestino" className="card-body px-2 py-0 mt-3 mb-0">
        {/* <div className="card-body px-2 py-0"> */}
        <label className="pb-0">Empresas</label>
        <input
          type="search"
          id="txtFiltraEmpresa"
          className="form-control ml-0 mb-3"
          placeholder="Procurar empresa"
          onChange={onFilterCompanies}
        />

        <div className="row">
          <div className="col-sm-12">
            <div
              id="boxcd"
              className="row bg-gray-light "
              style={{
                overflow: 'auto',
                height: '320px',
                border: '1px solid #f1f1f1',
                alignContent: 'baseline',
              }}
            >
              {companies.map((item, index) => (
                <div
                  id={'btnEmpresa' + index}
                  className="btnEmpresa"
                  data-seqpessoa={item.seqpessoa}
                  data-cidade={item.cidade}
                  data-key={index}
                  data-id={item.nroempresa}
                  key={index}
                  onClick={() => {
                    onSetSelectedCompany(
                      '#btnEmpresa' + index,
                    );
                  }}
                >
                  <div className="btnLiberarFaturamento">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        onReleaseBilling(item.seqpessoa);
                      }}
                    >
                      Liberar Faturamento
                    </button>
                  </div>
                  <div>{item.nome_reduzido}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className=" card-body px-2 py-0 mt-3 mb-0">
        <div className="d-flex align-items-center justify-content-between mb-1">
          <label htmlFor="txtProduto" className='mb-0'>Produto [Enter]</label>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              checked={isBonified}
              onChange={(e) =>
                onSetBonified(e.target.checked)
              }
            />
            <p className="m-0 ml-1 fw-bold">Bonificado</p>
          </div>
        </div>
        <input
          id="txtProduto"
          type="search"
          className="form-control"
          placeholder="Código do produto"
          // onBlur={(event) => BuscaBlur(event)}
          onKeyUp={(event) => onSearchProcuts(event)}
        />
      </div>
    </div>
  )
}
