import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";

export function ModalLote({
  onCloseModal,
  currentLote,
  onSave,
  isLoading
}) {
  return (
    <div
      id="modal-lote"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{currentLote.descricao}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-7 border rounded px-3 py-2">
                <div className="row bg-gray-light">
                  <div className="col-5 text-left">
                    <b>Data: </b>
                  </div>
                  <div className="col-7 text-right">
                    {moment(new Date(currentLote.data_hora_entrada)).format(
                      'DD/MM/yyyy',
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 text-left">
                    <b>Emb.: </b>
                  </div>
                  <div className="col-7 text-right">
                    {currentLote.embalagem}
                  </div>
                </div>

                <div className="row bg-gray-light">
                  <div className="col-5 text-left">
                    <b>Disponível: </b>
                  </div>
                  <div className="col-7 text-right">
                    {Number(currentLote.saldo) -
                      Number(currentLote.quantidade_conferida) ||
                      Number(currentLote.saldo)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 text-left">
                    <b>Gerado: </b>
                  </div>
                  <div className="col-7 text-right">
                    {currentLote.quantidade_atendida || 0}
                  </div>
                </div>

                {/* <div className="row bg-gray-light">
                      <div className="col-5 text-right">
                        <b>Conferido: </b>
                      </div>
                      <div className="col-7">{currentLote.temp}</div>
                    </div> */}
                <div className="row bg-gray-light">
                  <div className="col-7 text-left">
                    <b>Conf. Normal: </b>
                  </div>
                  <div className="col-5 text-right">
                    {currentLote.conferido_normal}
                  </div>
                </div>

                <div className="row">
                  <div className="col-7 text-left">
                    <b>Conf. Bonificado: </b>
                  </div>
                  <div className="col-5 text-right">
                    {currentLote.conferido_bonificado}
                  </div>
                </div>
              </div>
              <div className="col-5 pt-3">
                <label htmlFor="txtQtd">Quantidade</label>
                <input
                  type="number"
                  id="txtQtd"
                  className="form-control"
                  placeholder="Digite a quantidade"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onSave}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'Salvar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onCloseModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
