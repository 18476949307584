import moment from 'moment-timezone';
import { Badge } from 'react-bootstrap';

import obaLogo from '../../assets/oba.png';

export function TablePrinter({
  separationMapHeader,
  products,
  companySeparationMapPrinterRef,
}) {
  return (
    <div className="TableContainer p-4" ref={companySeparationMapPrinterRef}>
      <div className="TableHeader border-bottom p-2">
        <img height="32px" src={obaLogo} alt="Logo Oba Hortifruti" />
        <div
          className="mt-2 d-flex align-items-center border-bottom border-dark-subtle "
          style={{ fontSize: '24px' }}
        >
          <p className="mr-4">
            <strong>CD:</strong>{' '}
            {separationMapHeader.numero_centro_distribuicao}
          </p>
          <p className="mr-4">
            <strong>Listas:</strong> {separationMapHeader.listas}
          </p>
          <p className="mr-4">
            <strong> Data hora:</strong>{' '}
            {moment(new Date()).format('DD/MM/YYYY - hh:mm a')}
          </p>
        </div>
        <div
          className="border-bottom border-dark-subtle"
          style={{ fontSize: '24px' }}
        >
          <p>
            <strong>Loja:</strong> {separationMapHeader.fantasia}
          </p>
        </div>
        <div
          className="border-bottom border-dark-subtle"
          style={{ fontSize: '24px' }}
        >
          <p>
            <strong>Conferente:</strong> {separationMapHeader.codigo_conferente}{' '}
            - {separationMapHeader.nome}
          </p>
        </div>
        <div
          className="border-bottom border-dark-subtle"
          style={{ fontSize: '24px' }}
        >
          <p>
            <strong>Separador:</strong>
          </p>
        </div>
      </div>

      <div className="PDFBody overflow-hidden p-2">
        {products.map((product) => (
          <>
            <div
              className="row text-center bg-light border border-dark"
              style={{ fontSize: '18px' }}
            >
              <div className="col-2 border border-dark p-1">
                {product.sequencia_produto}
              </div>
              <div className="col-6 border border-dark p-1 px-2 d-flex align-items-center justify-content-between">
                {product.descricao}
                {product.bonificado === 'S' && (
                  <Badge
                    bg="primary"
                    title="Produto Bonificado"
                    className=" fs-4 ml-3"
                  >
                    B
                  </Badge>
                )}
              </div>
              <div className="col-2 border border-dark p-1">
                {product.embalagem}
              </div>
              <div className="col-2 border border-dark p-1">
                ATENDIDO: {product.quantidade_atendida}
              </div>
            </div>

            {!!product.lotes.length && (
              <div className="px-4">
                <div className="row" style={{ fontSize: '18px' }}>
                  <div className="col-6 border border-secondary">LOTE</div>
                  <div className="col-2 border border-secondary">EMBALAGEM</div>
                  <div className="col-2 border border-secondary">ATENDIDO</div>
                  <div className="col-2 border border-secondary">CONFERIDO</div>
                </div>
                {product.lotes.map((lot) => (
                  <div className="row" style={{ fontSize: '18px' }}>
                    <div className="col-6 border border-secondary">
                      {lot.sequencia_lote}
                    </div>
                    <div className="col-2 border border-secondary">
                      {lot.embalagem}
                    </div>
                    <div className="col-2 border border-secondary">
                      {lot.quantidade_atendida}
                    </div>
                    <div className="col-2 border border-secondary">
                      {lot.quantidade_conferida > 0
                        ? lot.quantidade_conferida
                        : ''}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
