import { Fragment, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import api from '../../services/api';

import $, { map } from 'jquery';

import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { PrinterLabel } from '../../components/EstoqueLote/PrinterLabel';
import { PrinterMap } from '../../components/EstoqueLote/PrinterMap';
import { useLoading } from '../../hooks/useLoading';
import { PrinterReport } from '../../components/EstoqueLote/PrinterReport';
import { Lots } from '../../components/EstoqueLote/Lots';

function EstoqueLote() {
  const [listCd, setListCd] = useState([]);
  const [listBuyer, setListBuyer] = useState([]);
  const [cd, setCd] = useState(0);
  const [buyer, setBuyer] = useState(0);
  const [product, setProduct] = useState(0);
  const [lot, setLot] = useState('T');
  const [stock, setStock] = useState([]);
  const [searchStatus, setSearchStatus] = useState(true);
  const [searchProcess, setSearchProcess] = useState(false);
  const [stockTotalByItem, setStockTotalByItem] = useState([]);
  const [stockTotalByItemTrigger, setStockTotalByItemTrigger] = useState([]);
  const [blockedStock, setBlockedStock] = useState([]);
  const [mergeBlockedStock, setMergeBlockedStock] = useState([]);
  const [itemToRelease, setItemToRelease] = useState(null);
  const [itemToConnect, setItemToConnect] = useState(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentStock, setCurrentStock] = useState({});
  const [productList, setProductList] = useState([]);
  const labelToPrintRef = useRef(null);
  const mapToPrintRef = useRef(null);
  const reportToPrintRef = useRef(null);
  const loading = useLoading();
  const loading2 = useLoading();

  useEffect(() => {
    getListCd();
    getListBuyer();

    const token = JSON.parse(localStorage.getItem('token'));

    if (token && token.userName) {
      setUser(token.userName);
    }
  }, []);

  useEffect(() => {
    if (product === '') {
      setProduct(0);
    }
  }, [product]);

  async function getListCd() {
    try {
      const result = await api.get('/empresa/centrodistribuicao/');

      setListCd(result.data.results);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  async function getListBuyer() {
    try {
      const result = await api.get('/comprador/comprador/');

      setListBuyer(result.data.results);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  async function getListStock() {
    try {
      const itensBlocked = await handleSearchBlockedStock();

      if (cd !== 0 && cd !== 'Selecione a Empresa ..') {
        if (itensBlocked.results.length === 0) {
          setStock([]);

          setSearchStatus(false);
          setSearchProcess(true);

          let list = [];

          const result = await api.get(
            `/listaseparacao/v1/estoquelote/${cd}/${buyer}/${product}/${lot}`,
          );

          if (result.status === 200) {
            if (result.data.results.length === 0)
              toast.error('Nenhum resultado foi localizado');

            list = result.data.results;

            let status = result.data.next;

            while (status) {
              const response = await api.get(`${status}`);

              const results = response.data.results;

              const newList = [...list, ...results];

              list = newList;

              status = response.data.next;
            }
          }

          setSearchProcess(false);

          setSearchStatus(true);

          for (let index = 0; index < list.length; index++) {
            list[index].total = 0;
          }

          const totals = [];

          list.forEach((x) => {
            const obj = totals.find((o) => o.seqproduto === x.seqproduto);
            if (obj) {
              obj.total =
                obj.total + (x.entrada - x.saida) * x.quantidade_embalagem;

              totals.push(obj);
            } else {
              x.total = (x.entrada - x.saida) * x.quantidade_embalagem;

              totals.push(x);
            }
          });

          for (let index = 0; index < list.length; index++) {
            if (index > 0) {
              if (list[index].seqproduto === list[index - 1].seqproduto) {
                list[index].total = list[index - 1].total;
                list[index - 1].total = 0;
              }
            }
          }

          setStock(list);
        } else {
          itensBlocked.results.map((item) => (item.selected = false));

          setBlockedStock(itensBlocked.results);

          setShowModal(true);
        }
      } else {
        alert('Empresa é obrigatorio!');
      }
    } catch (err) {
      console.log(err);
      setSearchProcess(false);
      toast.error(
        'Não foi possível completar a requisição. Tente novamente mais tarde.',
      );
    }
  }

  function handlePreviousExportCSV() {
    var $table = $('#table-export'),
      rows = [],
      header = [];

    $table.find('thead th').each(function () {
      header.push($(this).html());
    });

    $table.find('tbody tr').each(function () {
      var row = {};

      $(this)
        .find('td')
        .each(function (i) {
          var key = header[i],
            value = $(this).html();

          row[key] = value;
        });

      rows.push(row);
    });

    const ws = XLSX.utils.json_to_sheet(rows);

    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });

    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });

    saveAs(blob, `export.csv`);
  }

  function handleExportCSV() {
    let headers = [];
    let lines = [];

    const ReportHeaderColumns = document.querySelectorAll('.ReportHeader div');
    const ReportBodyRows = document.getElementsByClassName('ReportBodyRow');


    ReportHeaderColumns.forEach((column) => {
      headers.push(column.innerHTML);
    });

    for (const row of ReportBodyRows) {
      let line = {};
      let index = 0;
      const rowValues = row.getElementsByTagName('div');

      for (const rowValue of rowValues) {
        const key = headers[index];
        const value = rowValue.innerText;
        line[key] = value;
        index++;
      }
      lines.push(line);
    }

    const ws = XLSX.utils.json_to_sheet(lines);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
    saveAs(blob, `export.csv`);
  }

  function exportTableToPDF() {
    const tableToPrint = document.getElementById('table-export');

    const newWin = window.open('');
    newWin.document.write(tableToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  const handlePrintReport = useReactToPrint({
    content: () => reportToPrintRef.current,
  });

  function handleGenerateLabelPDFBySelectedStock(lote) {
    loading2.setLoading(true);
    const findStock = stock.find((item) => item.lote === lote);
    setCurrentStock(findStock);

    setTimeout(() => {
      handlePrintLabel();
      loading2.setLoading(false);
    }, 300);
  }

  const handlePrintLabel = useReactToPrint({
    content: () => labelToPrintRef.current,
  });

  async function getLotMapping(lote, product) {
    try {
      const data = {
        numero_centro_distribuicao: cd,
        sequencia_lote: String(lote),
        sequencia_produto: String(product),
      };

      const mappingResponse = await api.post(
        '/listaseparacao/mapa_separacao_produto_lote',
        data,
      );

      if (mappingResponse.data.resposta) {
        setProductList([])
        toast.warning('Não existe empresas para separar.')
        return
      }

      const mappings = mappingResponse.data.filter(
        (map) => map.quantidade_atendida > 0,
      );

      if (mappings.length === 0)
        return toast.warning('Não existe empresas para separar.');

      const productConferedResponse = await await api.post(
        '/listaseparacao/busca_conferencia_v2/',
        {
          numero_empresa: cd,
          sequencia_lista: 0,
          sequencia_produto: product,
          sequencia_pessoa: 0,
          status: 'P,C,L',
        },
      );
      const { data: productsConfered } = productConferedResponse;

      for (const mapping of mappings) {
        mapping.quantidade_conferida = 0;

        for (const product of productsConfered) {
          if (
            product.sequencia_lote === lote &&
            mapping.bonificado === product.bonificado &&
            mapping.sequencia_pessoa === product.sequencia_pessoa
          ) {
            mapping.quantidade_conferida += product.quantidade_conferida;
          }
        }
      }

      setProductList(mappings);
    } catch (err) {
      console.log(err.message);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function handleGenerateMapPDFBySelectedStock(lote) {
    loading.setLoading(true);

    const findStock = stock.find((item) => item.lote === lote);
    setCurrentStock(findStock);

    await getLotMapping(findStock.lote, findStock.seqproduto);

    setTimeout(() => {
      handlePrintMapping();
    }, 300);

    loading.setLoading(false);
  }

  const handlePrintMapping = useReactToPrint({
    content: () => mapToPrintRef.current,
  });

  function handleCloseModal() {
    setShowModal(false);
  }

  async function handleSearchBlockedStock() {
    try {
      setBlockedStock([]);

      setMergeBlockedStock([]);

      const result = await api.get(
        `/listaseparacao/verifica_lote_bloqueado/${buyer}/${cd}`,
      );

      const data = result.data;

      return data;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  async function handleReleaseStock() {
    try {
      if (itemToRelease.saida <= itemToConnect.entrada - itemToConnect.saida) {
        const result = await api.post('/listaseparacao/libera_lote_bloqueado', {
          lote_cancelado: itemToRelease.lote,
          novo_lote: itemToConnect.lote,
          usuario: user,
        });

        alert(result.data.msg);

        handleCloseModal();
      } else {
        alert(
          'Quantidade de saida na origem deve ser menor do que o saldo de destino.',
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  async function handleSelectedReleaseStock(item) {
    try {
      setSearchProcess(true);
      const result = await api.get(
        `/listaseparacao/v1/estoquelote/${cd}/${buyer}/${item.sequencia_produto}/${lot}`,
      );

      const blocked = blockedStock.find(
        (blockedItem) => blockedItem.lote === item.lote,
      );

      // setBlockedStock([blocked]);

      setItemToRelease(blocked);

      setSearchProcess(false);

      if (result.data.results.length > 0) {
        setMergeBlockedStock(result.data.results);
      } else {
        alert('Lote de destino não disponível.');
      }
    } catch (err) {
      console.log(err);
      setSearchProcess(false);
      toast.error(err.message);
    }
  }

  async function handleSelectedConnectStock(item) {
    setItemToConnect(item);
  }

  return (
    <Fragment>
      <div className="content-header">
        <h1>Estoque Lote</h1>
      </div>
      <div className="content">
        <div className="card-body card">
          <div className="row">
            <div className="col-sm-2">
              <div className="form-group">
                <label>Empresa</label>
                <select
                  className="form-control"
                  onChange={(event) => setCd(event.target.value)}
                  disabled={listCd.length === 0 ? true : false}
                >
                  <option defaultValue key={0}>
                    Selecione a Empresa ..
                  </option>
                  {listCd.map((item) => (
                    <option
                      value={item.numero_empresa}
                      key={`${item.numero_empresa}-${item.nome_reduzido}`}
                    >
                      {item.nome_reduzido}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-2">
              <div className="form-group">
                <label>Comprador</label>
                <select
                  className="form-control"
                  onChange={(event) => setBuyer(event.target.value)}
                  disabled={listBuyer.length === 0 ? true : false}
                >
                  <option defaultValue key={0} value={0}>
                    Selecione o Comprador ..
                  </option>
                  {listBuyer.map((item) => (
                    <option value={item.seqcomprador} key={item.seqcomprador}>
                      {item.comprador}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-2">
              <div className="form-group">
                <label>Produto</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(event) => setProduct(event.target.value)}
                  placeholder="Cod. Produto"
                />
              </div>
            </div>

            <div className="col-sm-2">
              <div className="form-group">
                <label>Tipo Lote</label>
                <select
                  className="form-control"
                  onChange={(event) => setLot(event.target.value)}
                >
                  <option value="T" defaultValue="T">
                    Todos
                  </option>
                  <option value="N">Nota Fiscal</option>
                  <option value="P">Particular</option>
                </select>
              </div>
            </div>

            <div
              className="col-sm-4"
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <div className="form-group">
                <label>&nbsp;</label>
                <button
                  onClick={() => getListStock()}
                  className="btn btn-primary"
                  style={{}}
                >
                  Pesquisar
                </button>
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <button
                  className="btn btn-success"
                  style={{}}
                  disabled={stock.length === 0}
                  // onClick={() => handlePrintReport()}
                  onClick={() => exportTableToPDF()}
                >
                  Imprimir
                </button>
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <button
                  className="btn btn-success"
                  style={{}}
                  disabled={stock.length === 0}
                  // onClick={() => handleExportCSV()}
                  onClick={() => handlePreviousExportCSV()}
                >
                  Exportar CSV
                </button>
              </div>
            </div>
          </div>

          {searchProcess && (
            <div className="d-flex align-items-center">
              <h5 style={{ margin: 0 }}>Processando....</h5>
              <Spinner
                className="ml-2"
                animation="border"
                variant="primary"
                size="sm"
              />
            </div>
          )}

          <Lots
            stocks={stock}
            currentStock={currentStock}
            onGenerateLabelPDFBySelectedStock={handleGenerateLabelPDFBySelectedStock}
            loading2={loading2}
            onGenerateMapPDFBySelectedStock={handleGenerateMapPDFBySelectedStock}
            loading={loading}
          />
        </div>
      </div>

      {stock.length > 0 && (
        <div className="d-none">
          <PrinterReport stocks={stock} reportToPrintRef={reportToPrintRef} />
        </div>
      )}

      {currentStock && (
        <div className="d-none">
          <PrinterLabel
            currentStock={currentStock}
            labelToPrintRef={labelToPrintRef}
          />
        </div>
      )}

      {productList.length > 0 && (
        <div className="d-none">
          <PrinterMap
            cd={cd}
            currentStock={currentStock}
            productList={productList}
            mapToPrintRef={mapToPrintRef}
          />
        </div>
      )}

      {/* modal */}
      <div
        className="modal fade show"
        id="modal-default"
        aria-modal="true"
        role="dialog"
        style={{ padding: '17px', display: showModal ? 'block' : 'none' }}
      >
        <div className="modal-dialog" style={{ marginLeft: '5vw' }}>
          <div className="modal-content" style={{ minWidth: '90vw' }}>
            <div className="modal-header">
              <h4 className="modal-title">Estoque Bloqueado</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleCloseModal()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body table-responsive separatioList">
              {searchProcess && (
                <h5 style={{ color: 'red' }}>Processando....</h5>
              )}
              <table
                className="table table-bordered table-hover table-striped text-nowrap text-sm"
                id="modalTable"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>LOTE</th>
                    <th>COMPRADOR</th>
                    <th>COD. PRODUTO</th>
                    <th>PRODUTO</th>
                    <th>NOTA FISCAL</th>
                    <th>FORNECEDOR</th>
                    <th>DATA ENTRADA</th>
                    <th>QUANTIDADE</th>
                    <th>SAIDA</th>
                  </tr>
                </thead>
                <tbody>
                  {blockedStock.map((item) => (
                    <tr key={`${item.sequencia_produto}-${item.lote}`}>
                      <td>
                        <input
                          type="radio"
                          name="group-blocked"
                          onChange={() => handleSelectedReleaseStock(item)}
                        />
                      </td>
                      <td>{item.lote}</td>
                      <td>{item.comprador}</td>
                      <td>{item.sequencia_produto}</td>
                      <td>{item.produto}</td>
                      <td>{item.numero_nota_fiscal}</td>
                      <td>{item.fornecedor}</td>
                      <td>{item.data_entrada}</td>
                      <td>{item.quantidade}</td>
                      <td>{item.saida}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {mergeBlockedStock.length > 0 && (
                <Fragment>
                  <h4>Associar estoque bloqueado</h4>
                  <table
                    className="table table-bordered table-hover table-striped text-nowrap text-sm"
                    id="modalTable"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>LOTE</th>
                        <th>COMPRADOR</th>
                        <th>COD. PRODUTO</th>
                        <th>PRODUTO</th>
                        <th>NOTA FISCAL</th>
                        <th>FORNECEDOR</th>
                        <th>DATA ENTRADA</th>
                        <th>QTDE_ENTRADA</th>
                        <th>SAIDA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mergeBlockedStock.map((item) => (
                        <tr key={item.lote}>
                          <td>
                            <input
                              type="radio"
                              name="group-release"
                              onChange={() => handleSelectedConnectStock(item)}
                            />
                          </td>
                          <td>{item.lote}</td>
                          <td>{item.comprador}</td>
                          <td>{item.seqproduto}</td>
                          <td>{item.produto}</td>
                          <td>{item.numeronf + '-' + item.serienf}</td>
                          <td>{item.fornecedor}</td>
                          <td>
                            {moment(item.dtaentrada).format('DD/MM/yyyy')}
                          </td>
                          <td>{item.entrada}</td>
                          <td>{item.saida}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Fragment>
              )}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => handleCloseModal()}
              >
                Fechar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleReleaseStock()}
              >
                Associar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EstoqueLote;
