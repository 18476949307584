import { Fragment, useEffect, useState } from 'react';
import React from 'react';
import api from '../../services/api';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import $ from 'jquery';
import CurrencyInput from 'react-currency-masked-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import Moment from 'react-moment';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as xlsx from 'xlsx/xlsx.mjs';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { toast } from 'react-toastify';

registerLocale('pt', pt);
xlsx.set_cptable(cpexcel);

var currentProduto = null;
var currentCluster = null;
var respMsg = '';
var jsonPlanilha = {};

const token = JSON.parse(localStorage.getItem('token'));

function ShowAlert(title, message) {
  $('#modal-alert .modal-title').html(title);
  $('#modal-alert .modal-body').html(message);
  window.$('#modal-alert').modal('show');
}

function AlteraPreco() {
  const [produtosRelacionados, setProdutosRelacionados] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [precosRelatorio, setPrecosRelatorio] = useState([]);
  const [importacaoData, setImportacaoData] = useState([]);
  const [locais, setLocais] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [embalagens, setEmbalagens] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const hiddenFileInput = React.useRef(null);
  const [dtaAgendamento, setDtaAgendamento] = useState(new Date());

  useEffect(() => {
    async function fetchData() {
      const res = await api.get('/empresa/v1/segmento');
      if (res.data != null) {
        setSegmentos(res.data.results);
        console.log('---Segmentos---');
        console.log(res.data);
      }
    }

    $('.card-embalagem').hide();
    $('.card-relacionados').hide();
    $('#boxEmpresas3').hide();
    $('#boxSegmentos2').hide();
    fetchData();
    BuscaEmpresas();
    BuscaClusters();

    $('.segmento-inverter').change(function () {
      $('.segmento-todos').prop('checked', false);
      for (var i = 0; i < $('.segmento-preco').length; i++) {
        if ($('.segmento-preco').eq(i).prop('checked') == true) {
          $('.segmento-preco').eq(i).prop('checked', false);
        } else {
          $('.segmento-preco').eq(i).prop('checked', true);
        }
      }
    });

    $('.empresas-filtro-inverter').change(function () {
      for (var i = 0; i < $('.empresas-filtro').length; i++) {
        if ($('.empresas-filtro').eq(i).prop('checked') == true) {
          $('.empresas-filtro').eq(i).prop('checked', false);
        } else {
          $('.empresas-filtro').eq(i).prop('checked', true);
        }
      }
    });

    $('.segmentos-filtro-inverter').change(function () {
      for (var i = 0; i < $('.segmentos-filtro').length; i++) {
        if ($('.segmentos-filtro').eq(i).prop('checked') == true) {
          $('.segmentos-filtro').eq(i).prop('checked', false);
        } else {
          $('.segmentos-filtro').eq(i).prop('checked', true);
        }
      }
    });
  }, []);

  $(document).ready(function () {
    $('.segmento-todos').change(function () {
      $('.segmento-inverter').prop('checked', false);
      if (this.checked) {
        $('.segmento-preco').prop('checked', true);
      } else {
        $('.segmento-preco').prop('checked', false);
      }
    });

    $('.local-todos').prop('checked', true);
    $('.local').prop('checked', true);
    $('.local').change(function () {
      $('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).show();
        $(
          $(
            ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
          ).find('input'),
        ).prop('checked', true);
      } else {
        $(
          ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
        ).hide();
        $(
          $(
            ".box-empresa[data-cidade='" + $(this).attr('data-cidade') + "']",
          ).find('input'),
        ).prop('checked', false);
      }
    });

    $('.local-todos').change(function () {
      $('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $('.local').prop('checked', true);
        $('.empresas').prop('checked', true);
        $('.box-empresa').show();
      } else {
        $('.local').prop('checked', false);
        $('.empresas').prop('checked', false);
        $('.box-empresa').hide();
      }
    });

    $('.local-todos-cluster').prop('checked', true);
    $('.local-cluster').prop('checked', true);
    $('.local-cluster').change(function () {
      //$('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $(
          ".box-empresa-cluster[data-cidade='" +
            $(this).attr('data-cidade') +
            "']",
        ).show();
        $(
          $(
            ".box-empresa-cluster[data-cidade='" +
              $(this).attr('data-cidade') +
              "']",
          ).find('input'),
        ).prop('checked', false);
      } else {
        $(
          ".box-empresa-cluster[data-cidade='" +
            $(this).attr('data-cidade') +
            "']",
        ).hide();
        $(
          $(
            ".box-empresa-cluster[data-cidade='" +
              $(this).attr('data-cidade') +
              "']",
          ).find('input'),
        ).prop('checked', false);
      }
    });

    $('.local-todos-cluster').change(function () {
      //$('#ddlCluster option').eq(0).prop('selected', true);
      if (this.checked) {
        $('.local-cluster').prop('checked', true);
        $('.empresas-cluster').prop('checked', false);
        $('.box-empresa-cluster').show();
      } else {
        $('.local-cluster').prop('checked', false);
        $('.empresas-cluster').prop('checked', false);
        $('.box-empresa-cluster').hide();
      }
    });

    $('.empresas-filtro-todos').change(function () {
      if (this.checked) {
        $('.empresas-filtro').prop('checked', true);
      } else {
        $('.empresas-filtro').prop('checked', false);
      }
    });

    $('.segmentos-filtro-todos').change(function () {
      if (this.checked) {
        $('.segmentos-filtro').prop('checked', true);
      } else {
        $('.segmentos-filtro').prop('checked', false);
      }
    });

    $('#table-line-todos').change(function () {
      if (this.checked) {
        $('.table-line').prop('checked', true);
      } else {
        $('.table-line').prop('checked', false);
      }
    });
    $('#table-spinner').hide();

    if (importacaoData.length == 0) {
      $('#tblImportacao').hide();
    } else {
      $('#tblImportacao').show();
    }
    if (precosRelatorio.length == 0) {
      $('#tblRelatorio').hide();
    }

    $(document).mousedown(function (event) {
      var $target = $(event.target);

      if (
        !$target.closest('.dropdown-dialog').length &&
        $('.dropdown-dialog').is(':visible')
      ) {
        $('.dropdown-dialog').fadeOut('fast');
      }
    });
  });

  async function SelectedTab(name) {
    if (name == 'cluster') {
      $('.local-todos').prop('checked', false);
      $('.local-todos').click();
      BuscaClusters();
    }
  }

  async function BuscaClusters(reset = true) {
    const res = await api.get('/empresa/v1/cluster');

    if (res.data.results != null) {
      res.data.results = res.data.results.filter((obj) => obj.status == 'A');
      for (var a = 0; a < res.data.results.length; a++) {
        res.data.results[a].childs = [];
        const res2 = await api.get(
          '/empresa/v1/clusterEmpresa/' + res.data.results[a].seqcluster,
        );

        if (res2.data.results != null) {
          res.data.results[a].childs = res2.data.results.filter(
            (obj) => obj.status == 'A',
          );
        }

        if (reset) {
          setTimeout(function () {
            $('.box-cluster-empresa').hide();
          }, 1);
        }
      }

      setClusters(res.data.results);
      console.log('---Clusters---');
      console.log(res.data.results);
    }
  }

  async function ShowClusterEmpresas(id) {
    $('[data-id="' + id + '"]').slideToggle();
  }

  async function OpenModalAddEmpresa(id) {
    currentCluster = id;
    window.$('#modal-add').modal('show');
  }

  async function OpenModalAddCluster() {
    $('#txtClusterName').val('');
    currentCluster = 0;
    window.$('#modal-add-cluster').modal('show');
  }

  async function AddCluster() {
    const res = await api.post('/empresa/v1/cluster_create', {
      seqcluster: currentCluster,
      descricao: $('#txtClusterName').val(),
      status: 'A',
      usuario: '' + token.userName,
    });
    if (res.data.codResult != 1) {
      window.$('#modal-add-cluster').modal('hide');
      ShowAlert('Cluster', res.data.msg);
    }

    BuscaClusters();
  }

  async function EditarCluster(seqcluster, descricao) {
    currentCluster = seqcluster;
    $('#txtClusterName').val(descricao);
    window.$('#modal-add-cluster').modal('show');
  }

  async function AddEmpresaCluster() {
    var empresas = $('input[name=ckbEmpresa2]:checked');

    empresas.each(async function (item) {
      const res = await api.post('/empresa/v1/clusterEmpresa_create', {
        seqcluster: currentCluster,
        nroempresa: $(this).val(),
        status: 'A',
        usuario: '' + token.userName,
      });
      if (res.data.codResult != 1) {
        ShowAlert('Cluster', res.data.msg);
      }
      window.$('#modal-add').modal('hide');
    });

    BuscaClusters(false);
  }

  async function RemoverCluster(seqcluster, descricao) {
    const res = await api.post('/empresa/v1/cluster_create', {
      seqcluster: seqcluster,
      nroempresa: descricao,
      status: 'I',
      usuario: '' + token.userName,
    });
    if (res.data.codResult != 1) {
      window.$('#modal-add').modal('hide');
      ShowAlert('Cluster', res.data.msg);
    }

    BuscaClusters(false);
  }

  async function RemoveEmpresaCluster(id) {
    var empresas = $('input[name=' + id + 'ckb]:checked');
    currentCluster = id;

    empresas.each(async function (item) {
      const res = await api.post('/empresa/v1/clusterEmpresa_create', {
        seqcluster: currentCluster,
        nroempresa: $(this).val(),
        status: 'I',
        usuario: '' + token.userName,
      });
      if (res.data.codResult != 1) {
        window.$('#modal-add').modal('hide');
        ShowAlert('Cluster', res.data.msg);
      }
    });

    BuscaClusters(false);
  }

  async function SetClusterSelected() {
    var seqcluster = $('#ddlCluster').val();
    if (seqcluster != 0) {
      var currentClusterEmpresas = clusters.filter(
        (obj) => obj.seqcluster == seqcluster,
      );

      $('.empresas').parent().parent().parent().hide();
      $('.empresas').prop('checked', false);
      $('.local').prop('checked', false);
      $('.local-todos').prop('checked', false);
      for (var i = 0; i < currentClusterEmpresas[0].childs.length; i++) {
        $(
          'input[data-empresaid=' +
            currentClusterEmpresas[0].childs[i].nroempresa +
            ']',
        )
          .parent()
          .parent()
          .parent()
          .show();
        $(
          'input[data-empresaid=' +
            currentClusterEmpresas[0].childs[i].nroempresa +
            ']',
        ).prop('checked', true);
      }
    } else {
      $('.empresas').parent().parent().parent().show();
      $('.empresas').prop('checked', true);
      $('.local').prop('checked', true);
      $('.local-todos').prop('checked', true);
    }
  }

  async function BuscaEmpresas() {
    const res = await api.get('/empresa/listaempresa/');
    var result = null;

    if (res.data.results != null) {
      res.data.results.sort(dynamicSort('cidade'));
      setLocais(distinct(res.data.results, 'cidade'));
      res.data.results.sort(dynamicSort('nroempresa'));
      setEmpresas(res.data.results);
      console.log('---Empresas---');
      console.log(res.data.results);
    }
  }

  async function BuscaEmbalagens() {
    const res = await api.get(
      '/produto/v1/embalagem/' + currentProduto.seqfamilia,
    );
    var result = null;

    if (res.data.results != null) {
      setEmbalagens(res.data.results);
      console.log('---Embalagens---');
      console.log(res.data.results);
      $('.card-embalagem').show();
      $('.card-relacionados').show();
    }
  }

  async function BuscaProduto(event) {
    if (event.key === 'Enter') {
      try {
        const res = await api.get(
          '/produto/v1/produto/' +
            document.getElementById('txtCodigoProduto').value,
        );

        if (res.data.results != null) {
          SetSelectedProduto(res.data.results[0]);
          BuscaEmbalagens();
          BuscaProdutosRelacionados();
          setTimeout(function () {
            $('.embalagens').val('');
          }, 100);
        }
      } catch (ex) {
        ClearFields();
        alert('Nenhum produto foi localizado. ');
        console.log(ex);
      }
    }
  }

  async function BuscaProdutosRelacionados() {
    const res = await api.get(
      '/produto/v1/produtorelacionado/' +
        document.getElementById('txtCodigoProduto').value,
    );

    if (res.data.results != null) {
      setProdutosRelacionados(res.data.results);
    }
  }

  async function Salvar() {
    var empresasStr = '';
    var segmentosStr = '';
    var empresas = $('input[name=ckbEmpresa]:checked');
    var segmentos = $('input[name=ckbSegmento]:checked');
    var embalagensTemp = $('.embalagens');
    var embalagens = [];

    embalagensTemp.each(function (i) {
      if ($(embalagensTemp[i]).val() != '') {
        embalagens.push($(embalagensTemp[i]));
      }
    });

    empresas.each(function (i) {
      empresasStr += $(empresas[i]).val() + ',';
    });
    empresasStr = empresasStr.slice(0, -1);

    segmentos.each(function (i) {
      segmentosStr += $(segmentos[i]).val() + ',';
    });
    segmentosStr = segmentosStr.slice(0, -1);

    if (segmentos.length <= 0) {
      alert('Selectione ao menos um segmento.');
    } else if (
      moment(new Date(dtaAgendamento)).format('yyyy-MM-DD') <
      moment(new Date()).format('yyyy-MM-DD')
    ) {
      alert('Data de agendamento retroativa não é permitido.');
    } else if (currentProduto == null) {
      alert('Selecione o produto.');
    } else if (embalagens.length <= 0) {
      alert('Informe o preço de ao menos uma embalagem.');
    } else if (empresas.length <= 0) {
      alert('Selectione ao menos uma empresa.');
    } else {
      var responseMessage = '';
      var error = false;

      for (var i = 0; i < embalagens.length; i++) {
        const res = await api.post('/produto/v1/precomonitorado', {
          seqproduto: currentProduto.seqproduto,
          embalagem: parseFloat($(embalagens[i]).attr('data-qtdembalagem')),
          preconovo: parseFloat($(embalagens[i]).val()),
          dtaagendamento: moment(dtaAgendamento).format('DD/MM/yyyy'),
          chapaUsuario: '' + token.userName,
          nroempresa: empresasStr,
          motivo: 0,
          nrosegmento: segmentosStr,
        });
        console.log('Retorno do metodo salvar');
        console.log(res);

        if (res.data.codResult == 0) {
          responseMessage +=
            $(embalagens[i]).attr('data-nomecompleto') +
            ": <span class='text-green'>" +
            res.data.msg +
            '</span><br/>';
        } else {
          responseMessage +=
            $(embalagens[i]).attr('data-nomecompleto') +
            ": <span class='text-red'>" +
            res.data.msg +
            '</span><br/>';
          error = true;
        }

        if (i == embalagens.length - 1) {
        }
      }
      ShowAlert('Atenção', responseMessage);
      if (!error) {
        ClearFields();
      }
    }
  }

  function FindEmpresa(id) {
    var toReturn = null;
    empresas.forEach(function (item, a) {
      if (parseInt(empresas[a].nroempresa) == parseInt(id)) {
        toReturn = empresas[a].nome_reduzido;
      }
    });

    return toReturn;
  }

  async function EmbalagemChange(itemName) {
    if ($('#' + itemName).val() == '0.00') {
      $('#' + itemName).val('');
    }
  }

  async function Filtrar(url = null) {
    try {
      $('#table-spinner').show();
      var produto = $('#txtProdutoPesquisa').val();
      var empresasStr = '';
      var segmentosStr = '';
      var empresas = $('input[name=ckbEmpresaFiltro]:checked');
      var segmentos2 = $('input[name=ckbSegmentoFiltro]:checked');

      empresas.each(function (i) {
        empresasStr += $(empresas[i]).val() + ',';
      });
      empresasStr = empresasStr.slice(0, -1);

      segmentos2.each(function (i) {
        segmentosStr += $(segmentos2[i]).val() + ',';
      });
      segmentosStr = segmentosStr.slice(0, -1);

      if (empresasStr == '') empresasStr = '0';

      if (segmentosStr == '') segmentosStr = '0';

      if (produto == '') produto = '0';

      var res = null;
      if (url == null) {
        res = await api.get(
          '/produto/v1/preco_monitorado_api_get/' +
            moment(startDate).format('DD-MM-yyyy') +
            '/' +
            moment(endDate).format('DD-MM-yyyy') +
            '/' +
            empresasStr +
            '/' +
            segmentosStr +
            '/' +
            produto +
            '/' +
            $('#cboStatus').val(),
        );
      } else {
        res = await api.get(url.replace(api.baseURL, ''));
      }

      console.log('---Filtro---');
      console.log(res.data.results);

      if (res.data.results != null) {
        if (res.data.results.length > 0) {
          for (var i = 0; i < res.data.results.length; i++) {
            segmentos.forEach(function (item, a) {
              if (
                parseInt(segmentos[a].nrosegmento) ==
                parseInt(res.data.results[i].nrosegmento)
              ) {
                res.data.results[i].nomesegmento = segmentos[a].descsegmento;
              }
            });

            res.data.results[i].nomeempresa = FindEmpresa(
              res.data.results[i].nroempresa,
            );
          }

          $('#btnNext').attr('data-url', res.data.next);
          $('#btnPrev').attr('data-url', res.data.previous);

          if ($('#btnNext').attr('data-url') == null) {
            $('#btnNext').hide();
          } else {
            $('#btnNext').show();
          }

          if ($('#btnPrev').attr('data-url') == null) {
            $('#btnPrev').hide();
          } else {
            $('#btnPrev').show();
          }
          setPrecosRelatorio(res.data.results);
          $('#tblRelatorio').show();
          $('#table-spinner').hide();
        } else {
          setPrecosRelatorio([]);
          toast.error('Nenhuma registro foi localizado');
        }
      }
    } catch (ex) {
      alert('Ocorreu um erro ao processar os dados.');
      console.log(ex);
    }
  }

  async function ProximaPagina() {
    $('html, body, #tblRelatorio .card-body').animate(
      { scrollTop: 0 },
      'fast',
      function () {
        Filtrar($('#btnNext').attr('data-url'));
      },
    );
  }

  async function PaginaAnterior() {
    $('html, body, #tblRelatorio .card-body').animate(
      { scrollTop: 0 },
      'fast',
      function () {
        Filtrar($('#btnPrev').attr('data-url'));
      },
    );
  }

  async function AcaoSelecionada() {
    if ($('#ddlAcao').val() == 'Cancelar') {
      const options = {
        title: 'Cancelamento',
        message: 'Deseja realmente cancelar os registros selecionados?',
        buttons: [
          {
            label: 'Sim',
            onClick: () => Cancelar(),
          },
          {
            label: 'Não',
            onClick: () => ResetAcao(),
          },
        ],
      };

      confirmAlert(options);
    }
  }

  async function ResetAcao() {
    $('#ddlAcao option').eq(0).prop('selected', true);
    $('.table-line').prop('checked', false);
  }

  async function Cancelar() {
    window.respMsg = '';
    $('.table-line').each(
      await async function (i) {
        console.log(precosRelatorio[$(this).val()]);
        if ($(this).is(':checked')) {
          var dtaagendamento = precosRelatorio[$(this).val()].dtaagendamento;
          try {
            dtaagendamento = precosRelatorio[
              $(this).val()
            ].dtaagendamento.replace(' 00:00:00', '');
          } catch {}

          const res = await api.post('/produto/v1/precomonitorado/cancelar/', {
            seqproduto: precosRelatorio[$(this).val()].seqproduto,
            embalagem: precosRelatorio[$(this).val()].embalagem,
            preconovo: precosRelatorio[$(this).val()].preconovo,
            dtaagendamento: dtaagendamento,
            chapaUsuario: precosRelatorio[$(this).val()].chapaUsuario,
            nroempresa: precosRelatorio[$(this).val()].nroempresa,
            motivo: precosRelatorio[$(this).val()].motivo,
            nrosegmento: precosRelatorio[$(this).val()].nrosegmento,
          });

          if (res.data.codResult == 0) {
            window.respMsg +=
              precosRelatorio[i].seqproduto +
              ' ' +
              precosRelatorio[i].descricao +
              ": <span class='text-red'>" +
              res.data.msg +
              '</span><br/>';
          } else {
            window.respMsg +=
              precosRelatorio[i].seqproduto +
              ' ' +
              precosRelatorio[i].descricao +
              ": <span class='text-green'>" +
              res.data.msg +
              '</span><br/>';
          }

          ShowAlert('Atenção', window.respMsg);
        }
      },
    );

    ResetAcao();

    Filtrar();
  }

  function SetSelectedProduto(p) {
    currentProduto = p;
    document.getElementById('txtDescricaoProduto').value =
      currentProduto.descreduzida;
    console.log(currentProduto);
  }

  function ClearFields() {
    document.getElementById('txtCodigoProduto').value = '';
    document.getElementById('txtDescricaoProduto').value = '';
    setEmbalagens([]);
    $('.card-embalagem').hide();
    $('.card-relacionados').hide();
    currentProduto = null;
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function distinct(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  function findSegmentoByNro(array, id) {
    return array.find((element) => {
      return element.nrosegmento === id;
    });
  }

  const handleClickUpload = (event) => {
    hiddenFileInput.current.click();
  };

  async function readUploadFile(e) {
    setImportacaoData([]);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: 'array',
          cellDates: true,
          dateNF: 'dd/mm/yyyy',
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });

        for (var i = 0; i < json.length; i++) {
          //console.log(new Date(moment(json[i].dtahoraagenda, "DD/MM/YYYY").format('MM/DD/yyyy')) +"###"+ new Date(moment(new Date()).format('MM/DD/yyyy')));

          json[i].status =
            "<span class='text-orange'><i class='fa fa-clock'></i> Aguardando processamento</span>";
          if (
            new Date(
              moment(json[i].dtahoraagenda, 'DD/MM/YYYY').format('MM/DD/yyyy'),
            ) < new Date(moment(new Date()).format('MM/DD/yyyy'))
          ) {
            json[i].status =
              "<span class='text-red'><i class='fa fa-times'></i> Data retroativa não é permitida.</span>";
          }
        }
        console.log(json);

        $('#tblImportacao').show();
        $('#btnProcessar').show();

        setImportacaoData(json);
        jsonPlanilha = json;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  async function ProcessarPlanilha() {
    var json = jsonPlanilha;
    var errorCount = 0;
    var successCount = 0;
    var scrollInitial = 0;
    $('#btnUpload, #btnDownload').hide();

    for (var i = 0; i < json.length; i++) {
      try {
        if (
          new Date(
            moment(json[i].dtahoraagenda, 'DD/MM/YYYY').format('MM/DD/yyyy'),
          ) >= new Date(moment(new Date()).format('MM/DD/yyyy'))
        ) {
          const res = await api.post('/produto/v1/precomonitorado', {
            seqproduto: json[i].codproduto,
            embalagem: parseFloat(json[i].qtdembalagem).toString(),
            preconovo: parseFloat(
              parseFloat(
                json[i].preconovo.toString().replace(',', '.'),
              ).toFixed(2),
            ),
            dtaagendamento: json[i].dtahoraagenda,
            chapaUsuario: '' + token.userName,
            nroempresa: json[i].nroempresas.toString(),
            motivo: 0,
            nrosegmento: json[i].nrosegmentos.toString(),
          });
          if (res.data.codResult == 0) {
            json[i].status =
              "<span class='text-success'><i class='fa fa-check'></i> Processado com sucesso</span>";
            successCount += 1;
          } else {
            json[i].status =
              "<span class='text-red'><i class='fa fa-times'></i> " +
              res.data.msg +
              '</span>';
            errorCount += 1;
          }
        } else {
          json[i].status =
            "<span class='text-red'><i class='fa fa-times'></i> Data retroativa não é permitida.</span>";
          errorCount += 1;
        }
      } catch (ex) {
        json[i].status =
          "<span class='text-red'><i class='fa fa-times'></i> Erro de processamento. (" +
          ex +
          ')</span>';
        errorCount += 1;
      }

      setImportacaoData(json);
      forceUpdate();

      if (
        $('.import-row').eq(i).offset().top >
        $(window).scrollTop() + $(window).height()
      ) {
        $('html, body').animate(
          { scrollTop: $(window).scrollTop() + $(window).height() },
          'slow',
          function () {},
        );
      }

      scrollInitial += 110;
    }
    $('#btnUpload, #btnDownload').show();
    hiddenFileInput.current.value = null;
    $('html, body').animate({ scrollTop: 0 }, 'slow', function () {
      ShowAlert(
        "<i class='fa fa-check'></i> Importação Finalizada",
        'A importação da planilha em excel foi finalizada. <br/><br/><b>Total de registros:</b> ' +
          json.length +
          " <br/><span class='text-success'><b>Processados com sucesso:</b> " +
          successCount +
          "</span> <br/><span class='text-danger'><b>Processados com erro:</b> " +
          errorCount +
          '</span>',
      );
    });
  }

  async function BtnEmpresasFiltroClicked() {
    window.$('#boxEmpresas3').fadeIn('fast');
  }

  async function BtnSegmentoFiltroClicked() {
    window.$('#boxSegmentos2').fadeIn('fast');
  }
  async function Closemodal() {
    window.$('.modal').modal('hide');
  }
  return (
    <Fragment>
      <div className="content-header">
        <h1 style={{ marginLeft: 20 }}>Alteração de Preço</h1>
      </div>

      <div className="content">
        <Tabs
          defaultActiveKey="preco"
          onSelect={(firstTab, lastTab) => SelectedTab(`${firstTab}`)}
        >
          <Tab eventKey="preco" title="Preço">
            <div id="box-preco-manual">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="card">
                        <div
                          className="card-body"
                          style={{ height: 300, overflow: 'auto' }}
                        >
                          <h4 className="text-md">Segmentos</h4>
                          <hr />
                          <div id="boxSegmentos" className="row">
                            <div className="col-sm-12">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="ckbSegTodos"
                                    className="form-check-input segmento-todos"
                                    type="checkbox"
                                  />
                                  Marcar Todos
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="ckbSegInvert"
                                    className="form-check-input segmento-inverter"
                                    type="checkbox"
                                  />
                                  Inverter Seleção
                                </label>
                              </div>
                            </div>
                            {segmentos.map((item, index) => (
                              <div className="col-sm-12" key={index}>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      name="ckbSegmento"
                                      className="form-check-input segmento-preco"
                                      value={item.nrosegmento}
                                      type="checkbox"
                                    />
                                    {item.descsegmento}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="card">
                        <div className="card-body" style={{ height: 300 }}>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Data do agendamento</label>
                                <DatePicker
                                  selected={dtaAgendamento}
                                  onChange={(date: Date) =>
                                    setDtaAgendamento(date)
                                  }
                                  className="form-control "
                                  locale="pt"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Código do Produto [Enter]</label>
                                <input
                                  id="txtCodigoProduto"
                                  className="form-control"
                                  onKeyUp={(event) => BuscaProduto(event)}
                                  placeholder="Entre com o código do produto"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>Descrição</label>
                                <input
                                  disabled="disabled"
                                  id="txtDescricaoProduto"
                                  className="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="card card-embalagem">
                        <div
                          className="card-body"
                          style={{ height: 300, overflow: 'auto' }}
                        >
                          <h4 className="text-md">Embalagens</h4>
                          <hr />

                          <div id="boxEmbalagens" className="row">
                            {embalagens.map((item, index) => (
                              <div className="col-sm-4" key={index}>
                                <div className="row">
                                  <div className="col-sm-6 text-right pt-2">
                                    {item.embalagem + ' - ' + item.qtdembalagem}
                                  </div>
                                  <div className="col-sm-6">
                                    <CurrencyInput
                                      id={'embalagem' + index}
                                      type="search"
                                      allowempty="true"
                                      allowleadingzeroes="false"
                                      onBlur={() =>
                                        EmbalagemChange('embalagem' + index)
                                      }
                                      className="form-control embalagens"
                                      data-qtdembalagem={item.qtdembalagem}
                                      data-nomecompleto={
                                        item.embalagem +
                                        ' - ' +
                                        item.qtdembalagem
                                      }
                                      placeholder="R$ 0,00"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="card card-relacionados">
                        <div
                          className="card-body"
                          style={{ height: 300, overflow: 'auto' }}
                        >
                          <h4 className="text-md">Produtos Relacionados</h4>
                          <hr />
                          <div className="row">
                            <div
                              className={
                                'col-sm-12 text-sm  ' +
                                (produtosRelacionados.length == 0
                                  ? ' d-block'
                                  : ' d-none')
                              }
                            >
                              Nenhum produto relacionado
                            </div>
                            {produtosRelacionados.map((item, index) => (
                              <div className="col-sm-12" key={index}>
                                {item.seqproduto + ' - ' + item.desccompleta}
                                <hr />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <h4 className="text-md">Empresas</h4>

                      <hr />

                      <div className="row">
                        <div className="col-sm-3">
                          <select
                            id="ddlCluster"
                            className="form-control mb-3"
                            onChange={() => {
                              SetClusterSelected();
                            }}
                          >
                            <option value="0">Selecione um cluster</option>
                            {clusters.map((item, index) => (
                              <option key={index} value={item.seqcluster}>
                                {item.descricao}
                              </option>
                            ))}
                          </select>
                          <div id="boxLocais" className="row">
                            <div className="col-sm-12">
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input"
                                    className="local-todos"
                                    type="checkbox"
                                  />
                                  &nbsp; Marcar Todos
                                </label>
                              </div>
                            </div>
                            {locais.map((item, index) => (
                              <div className="col-sm-12" key={index}>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      className="local"
                                      data-cidade={item}
                                      value={item}
                                      type="checkbox"
                                    />
                                    &nbsp;&nbsp;
                                    {item}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="col-sm-9">
                          <div
                            id="boxEmpresas"
                            className="row bg-gray-light p-3 rounded"
                          >
                            {empresas.map((item, index) => (
                              <div
                                className="col-sm-3 box-empresa"
                                data-cidade={item.cidade}
                                key={index}
                              >
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      name="ckbEmpresa"
                                      className="form-check-input empresas"
                                      data-cidade={item.cidade}
                                      data-empresaid={item.nroempresa}
                                      value={item.nroempresa}
                                      type="checkbox"
                                    />
                                    {item.nome_reduzido}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <input
                        type="button"
                        value="Salvar"
                        className="btn btn-primary mt-3"
                        style={{ float: 'right' }}
                        onClick={() => Salvar()}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-2" style={{ display: 'flex' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="importacao" title="Importação">
            <div id="box-preco-importacao">
              <div className="card">
                <div className="card-body">
                  <h4>Importação de Preços</h4>
                  <p className="mb-0">
                    Utilize esta esta ferramenta para fazer importação de preços
                    em massa.
                  </p>
                  <p>
                    <small>
                      Faça o download da planilha modelo e após preencher faça o
                      upload para a alteração automática de preços.
                    </small>
                  </p>
                  <hr />
                  <form>
                    <a
                      id="btnDownload"
                      href="../apoio/Modelo.xlsx"
                      target="_blank"
                      download
                      className="btn btn-primary mr-3"
                    >
                      <span className="fa fa-file-download fa-fw"></span> Baixar
                      Planilha Modelo
                    </a>
                    <div
                      id="btnUpload"
                      className="btn btn-primary"
                      onClick={handleClickUpload}
                    >
                      <span className="fa fa-file-upload fa-fw"></span> Enviar
                      Planilha
                    </div>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      ref={hiddenFileInput}
                      onChange={readUploadFile}
                      style={{ display: 'none' }}
                    />
                  </form>
                  <div id="tblImportacao" className="mt-3">
                    <div className="alert alert-dark">
                      <div className="row">
                        <div className="col-1 text-center">
                          <i className="fa fa-clock fa-5x"></i>
                        </div>
                        <div className="col-10">
                          <h4 className="alert-heading">Atenção</h4>
                          <p className="mb-0">
                            Não feche esta janela até que todos os registros
                            tenham sido concluídos.
                          </p>
                          <p>
                            <small>
                              Acompanhe a evolução do processamento na coluna{' '}
                              <b>Status</b>.
                            </small>
                          </p>
                          <button
                            id="btnProcessar"
                            className="btn btn-success "
                            onClick={() => {
                              ProcessarPlanilha();
                            }}
                          >
                            <span className="fa fa-play fa-fw"></span> Iniciar
                            processamento da planilha
                          </button>
                        </div>
                      </div>
                    </div>
                    <table className="table table-striped table-hover tabl e-sm ">
                      <thead className="">
                        <tr>
                          <th scope="col">Segmentos</th>
                          <th scope="col">Empresas</th>
                          <th scope="col">Produto</th>
                          <th scope="col">Embalagem</th>
                          <th scope="col">Preço Novo</th>
                          <th scope="col">Data</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>

                      <tbody id="table-body">
                        {importacaoData.map((item, index) => (
                          <tr className="import-row" key={index}>
                            <td>{item.nrosegmentos}</td>
                            <td>{item.nroempresas}</td>
                            <td>{item.codproduto}</td>
                            <td>{item.qtdembalagem}</td>
                            <td>
                              R$
                              {parseFloat(
                                item.preconovo.toString().replace(',', '.'),
                              )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')}
                            </td>
                            <td>{item.dtahoraagenda}</td>
                            <td
                              dangerouslySetInnerHTML={{ __html: item.status }}
                            ></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="relatorio"
            title="Relatório"
            style={{ background: 'white' }}
          >
            <div className="card">
              <div className="card-body">
                <div className="box-pesquisa bg-gray-light p-3 rounded mb-3 border">
                  <div className="row">
                    <div className="col-sm-1">
                      <label>Data inicial</label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        className="form-control"
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>

                    <div className="col-sm-1">
                      <label>Data final</label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                        className="form-control"
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="col-2">
                      <label>Empresas</label>
                      <div className="dropdown">
                        <button
                          onClick={() => {
                            BtnEmpresasFiltroClicked();
                          }}
                          className="btn bg-white w-100 text-left border"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Selecione
                        </button>

                        <div
                          id="boxEmpresas3"
                          className="row bg-white border p-3 rounded dropdown-dialog"
                          style={{
                            zIndex: 99999,
                            position: 'absolute',
                            width: 660,
                            height: 350,
                            overflow: 'auto',
                            marginLeft: 0,
                            boxShadow: '0 8px 9px 0px rgb(0 0 0 / 30%)',
                          }}
                        >
                          <div
                            className="col-6 "
                            style={{
                              paddingLeft: '27px',
                              paddingBottom: '15px',
                              paddingTop: '8px',
                            }}
                          >
                            <label className="form-check-label">
                              <input
                                className="form-check-input empresas-filtro-todos"
                                type="checkbox"
                              />
                              Marcar todos
                            </label>
                          </div>
                          <div
                            className="col-6 "
                            style={{
                              paddingLeft: '27px',
                              paddingBottom: '15px',
                              paddingTop: '8px',
                            }}
                          >
                            <label className="form-check-label">
                              <input
                                name="ckbEmpresaFiltroInverter"
                                className="form-check-input empresas-filtro-inverter"
                                type="checkbox"
                              />
                              Inverter seleção
                            </label>
                          </div>
                          {empresas.map((item, index) => (
                            <div
                              className="col-sm-3 box-empresa-filtro"
                              data-cidade={item.cidade}
                              key={index}
                            >
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="ckbEmpresaFiltro"
                                    className="form-check-input empresas-filtro"
                                    data-cidade={item.cidade}
                                    data-empresaid={item.nroempresa}
                                    value={item.nroempresa}
                                    type="checkbox"
                                  />
                                  {item.nome_reduzido}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <label>Segmento</label>
                      <div className="dropdown">
                        <button
                          onClick={() => {
                            BtnSegmentoFiltroClicked();
                          }}
                          className="btn bg-white w-100 text-left border"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Selecione
                        </button>

                        <div
                          id="boxSegmentos2"
                          className="row bg-white border p-3 rounded dropdown-dialog"
                          style={{
                            zIndex: 99999,
                            position: 'absolute',
                            width: 660,
                            height: 150,
                            overflow: 'auto',
                            marginLeft: 0,
                            boxShadow: '0 8px 9px 0px rgb(0 0 0 / 30%)',
                          }}
                        >
                          <div
                            className="col-6 "
                            style={{
                              paddingLeft: '27px',
                              paddingBottom: '15px',
                              paddingTop: '8px',
                            }}
                          >
                            <label className="form-check-label">
                              <input
                                className="form-check-input segmentos-filtro-todos"
                                type="checkbox"
                              />
                              Marcar todos
                            </label>
                          </div>
                          <div
                            className="col-6 "
                            style={{
                              paddingLeft: '27px',
                              paddingBottom: '15px',
                              paddingTop: '8px',
                            }}
                          >
                            <label className="form-check-label">
                              <input
                                name="ckbSegmentosFiltroInverter"
                                className="form-check-input segmentos-filtro-inverter"
                                type="checkbox"
                              />
                              Inverter seleção
                            </label>
                          </div>
                          {segmentos.map((item, index) => (
                            <div
                              className="col-sm-4 box-emp resa"
                              data-cidade={item.cidade}
                              key={index}
                            >
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="ckbSegmentoFiltro"
                                    className="form-check-input segmentos-filtro"
                                    value={item.nrosegmento}
                                    type="checkbox"
                                  />
                                  {item.descsegmento}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <label>Status</label>
                      <select id="cboStatus" className="form-control">
                        <option value="T">Todos</option>
                        <option value="P">Pedente</option>
                        <option value="X">Alterados </option>
                        <option value="I">Inativos</option>
                        <option value="C">Cancelado</option>
                      </select>
                    </div>

                    <div className="col-sm-2">
                      <label>Produto</label>
                      <input
                        id="txtProdutoPesquisa"
                        type="text"
                        className="form-control"
                        placeholder="Código do produto"
                      />
                    </div>

                    <div className="col-sm-2">
                      <button
                        className="btn btn-primary"
                        style={{ marginTop: 31 }}
                        onClick={() => Filtrar()}
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  id="table-spinner"
                  className="mb-3"
                  style={{
                    textAlign: 'center',
                    textAlign: 'center',
                    position: 'absolute',
                    zIndex: '9',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,.5)',
                    paddingTop: '110px',
                  }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
                <div id="tblRelatorio" className="card">
                  <div
                    className=""
                    style={{ overflow: 'auto', maxHeight: '50vh' }}
                  >
                    <table
                      id="tblRelatorio"
                      className="table table-striped table-hover tabl e-sm  "
                    >
                      <thead className="">
                        <tr>
                          <th scope="col" style={{ textAlign: 'center' }}>
                            <input
                              id="table-line-todos"
                              type="checkbox"
                              value=""
                            />
                          </th>
                          <th scope="col">Produto</th>
                          <th scope="col">Embalagem</th>
                          <th scope="col">Preço Novo</th>
                          <th scope="col">Agendamento</th>
                          <th scope="col">Usuário</th>
                          <th scope="col">Empresa</th>
                          <th scope="col">Segmento</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>

                      <tbody id="table-body">
                        {precosRelatorio.map((item, index) => (
                          <tr className="table-fields" key={index}>
                            <td scope="row" style={{ textAlign: 'center' }}>
                              <input
                                className="table-line"
                                type="checkbox"
                                value={index}
                              />
                            </td>
                            <td>{item.seqproduto + ' - ' + item.descricao}</td>
                            <td>{item.qtdembalagem}</td>
                            <td>
                              R$
                              {parseFloat(item.preconovo)
                                .toFixed(2)
                                .toString()
                                .replace('.', ',')}
                            </td>
                            <td>{item.dtaagendamento}</td>
                            <td>{item.chapaUsuario}</td>
                            <td>{item.nomeempresa}</td>
                            <td>{item.nomesegmento}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <div
                        className=" mt-3 mb-3"
                        style={{ float: 'left', marginLeft: '20px' }}
                      >
                        <select
                          id="ddlAcao"
                          className="form-control"
                          onChange={() => AcaoSelecionada()}
                        >
                          <option value="Cancelar">Ações</option>
                          <option value="Cancelar">Cancelar</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-9">
                      <div style={{ float: 'right', marginRight: '15px' }}>
                        <nav className="mt-3">
                          <ul className="pagination">
                            <li className="page-item">
                              <button
                                id="btnPrev"
                                className="page-link"
                                onClick={() => PaginaAnterior()}
                              >
                                Anterior
                              </button>
                            </li>
                            <li className="page-item">
                              <button
                                id="btnNext"
                                className="page-link"
                                onClick={() => ProximaPagina()}
                              >
                                Próximo
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="cluster" title="Cluster">
            <div className="card">
              <div className="card-body">
                <h4>Cluster</h4>
                <p className="mb-3">Crie ou adicione empresas à um grupo.</p>
                <button
                  title="Incluir empresa no cluster"
                  onClick={() => {
                    OpenModalAddCluster();
                  }}
                  data-toggle="tooltip"
                  className="btn btn-primary btn-sm mb-4"
                >
                  <i className="fa fa-plus fa-fw"></i> Novo Cluster
                </button>
                <div className="row">
                  <div className="col-12">
                    <TreeView
                      style={{
                        flexGrow: 1,
                      }}
                      defaultExpandIcon={<ChevronRightIcon />}
                      defaultCollapseIcon={<ExpandMoreIcon />}
                    >
                      <table className="table table-borde red">
                        <tbody>
                          {clusters.map((item, index) => (
                            <tr key={index}>
                              <td style={{ verticalAlign: 'middle' }}>
                                <div
                                  className="box-cluster cursor-pointer"
                                  role="button"
                                  onClick={() => {
                                    ShowClusterEmpresas('bxemp' + index);
                                  }}
                                >
                                  <i className="fa fa-layer-group fa-fw"></i>{' '}
                                  <b>{item.descricao}</b>
                                  <div style={{ marginLeft: 20 }}>
                                    {clusters[index].childs.length} empresa(s)
                                  </div>
                                </div>

                                <div
                                  className="box-cluster-empresa"
                                  data-id={'bxemp' + index}
                                >
                                  <div className=" mb-0 mt-3 bg-gray-light rounded p-3 ">
                                    <div className="controles mb-3">
                                      <button
                                        title="Incluir empresa no cluster"
                                        onClick={() => {
                                          OpenModalAddEmpresa(item.seqcluster);
                                        }}
                                        data-toggle="tooltip"
                                        className="btn btn-primary btn-sm"
                                      >
                                        <i className="fa fa-plus fa-fw"></i>{' '}
                                        Nova Empresa
                                      </button>
                                      <button
                                        title="Remover empresa do cluster"
                                        onClick={() => {
                                          RemoveEmpresaCluster(item.seqcluster);
                                        }}
                                        data-toggle="tooltip"
                                        className="btn btn-secondary btn-sm ml-3"
                                      >
                                        <i className="fa fa-times fa-fw"></i>{' '}
                                        Remover Selecionados
                                      </button>
                                    </div>
                                    <div className="row">
                                      {clusters[index].childs.map(
                                        (item2, index2) => (
                                          <div key={index2} className="col-3">
                                            <label className="ml-5">
                                              <input
                                                className="form-check-input "
                                                name={item.seqcluster + 'ckb'}
                                                value={item2.nroempresa}
                                                type="checkbox"
                                              />
                                              {FindEmpresa(item2.nroempresa)}
                                            </label>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ verticalAlign: 'top' }} width="190">
                                <button
                                  title="Editar cluster"
                                  data-toggle="tooltip"
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    EditarCluster(
                                      item.seqcluster,
                                      item.descricao,
                                    );
                                  }}
                                >
                                  <i className="fa fa-edit fa-fw"></i> Editar
                                </button>
                                <button
                                  title="Excluir cluster"
                                  data-toggle="tooltip"
                                  className="btn btn-secondary btn-sm ml-2"
                                  onClick={() => {
                                    RemoverCluster(
                                      item.seqcluster,
                                      item.descricao,
                                    );
                                  }}
                                >
                                  <i className="fa fa-times fa-fw"></i> Excluir
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TreeView>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      <div id="modal-alert" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ocorreu um erro durante a requisição.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-add" className="modal " role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content " style={{ width: 900 }}>
            <div className="modal-header">
              <h5 className="modal-title">Adicionar empresa ao cluster</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row">
                  <div className="col-sm-3">
                    <div id="boxLocais" className="row">
                      <div className="col-sm-12">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              className="local-todos-cluster"
                              type="checkbox"
                            />
                            &nbsp; Marcar Todos
                          </label>
                        </div>
                      </div>
                      {locais.map((item, index) => (
                        <div className="col-sm-12" key={index}>
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                className="local-cluster"
                                data-cidade={item}
                                value={item}
                                type="checkbox"
                              />
                              &nbsp;&nbsp;
                              {item}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-sm-9">
                    <div
                      id="boxEmpresas2"
                      className="row bg-gray-light p-3 rounded"
                    >
                      {empresas.map((item, index) => (
                        <div
                          className="col-sm-3 box-empresa-cluster"
                          data-cidade={item.cidade}
                          key={index}
                        >
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                name="ckbEmpresa2"
                                className="form-check-input empresas-cluster"
                                data-cidade={item.cidade}
                                value={item.nroempresa}
                                type="checkbox"
                              />
                              {item.nome_reduzido}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  AddEmpresaCluster();
                }}
              >
                Incluir Selecionados
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="modal-add-cluster" className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Adicionar novo cluster</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <input
                  type="text"
                  id="txtClusterName"
                  className="form-control"
                  placeholder="Digite o nome do cluster"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  AddCluster();
                }}
              >
                Incluir
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  Closemodal('show');
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AlteraPreco;
