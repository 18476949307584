import { Spinner } from "react-bootstrap";

export function FaturamentoNFTab({ cds, onBillingNF }) {
  return (
    <div className="card">
      <div
        id="spinner-nf"
        className="mb-3"
        style={{
          textAlign: 'center',
          position: 'absolute',
          zIndex: '9',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,.7)',
          paddingTop: '70px',
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
      <div className="card-body">
        <div className="row bg-gray-light rounded p-3 mb-3">
          <div className="col-sm-12 col-md-3">
            <div className="form-group">
              <label>CD de Origem</label>
              <select id="cdOrigem" className="form-control">
                {cds.map((item, index) => (
                  <option key={index} value={item.numero_empresa}>
                    {item.nome_reduzido}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-sm-12 col-md-3">
            <div className="form-group">
              <label>Número NF</label>
              <input
                id="txtNumeroNF"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-3">
            <div className="form-group">
              <label>&nbsp;</label>
              <div>
                <button
                  id="btnFaturarNF"
                  className="btn btn-primary"
                  onClick={onBillingNF}
                >
                  Faturar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
