import { Spinner } from "react-bootstrap";
import { LoteRow } from "./LoteRow";

export function ProductsBox({
  products,
  currentCompany,
  lotes,
  onLoteRowClicked
}) {
  return (
    <div className="col-sm-9">
      <div
        id="table-spinner"
        className="mb-3"
        style={{
          textAlign: 'center',
          position: 'absolute',
          zIndex: '9',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,.5)',
          paddingTop: '110px',
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>

      <div id="boxProdutos" className='h-100'>
        <div className="card h-100" style={{ overflow: 'hidden' }}>
          <div
            className="card-body p-0"
            style={{ height: '100%', overflow: 'auto' }}
          >
            <div
              id="no-products"
              className={
                'text-center pt-5 ' +
                (products.length === 0 ? 'd-block' : 'd-none')
              }
              style={{ marginTop: '25vh' }}
            >
              <h4>
                <i className="fa fa-info-circle fa-fw"></i>{' '}
                Nenhum produto
              </h4>
              Selecione uma empresa na lista ao lado para
              visualizar o produtos.
            </div>
            <div
              id="tblProdutos"
              className={
                'h-100 ' + (products.length === 0 ? 'd-none' : 'd-block')
              }
            >
              <div
                style={{
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                {products.map((item, index) => (
                  <div
                    id={'produto-linha' + index}
                    key={index}
                    className="produto-linh"
                    data-key={index}
                    data-empresa={item.nroempresa}
                    data-id={item.seqproduto}
                  >
                    <h4
                      className="pl-3 pt-3 pb-3 mb-0 bg-light"
                      style={{
                        borderBottom: '1px solid #dfdfdf',
                        fontSize: 18,
                        boxShadow:
                          '1px 1px 4px 0px rgb(0 0 0 / 10%)',
                      }}
                    >
                      <i className="fa fa-list mr-2 "></i>{' '}
                      {currentCompany.nome_reduzido +
                        ' | ' +
                        item.sequencia_produto +
                        ' - ' +
                        item.descricao_completa}
                    </h4>
                  </div>
                ))}

                <table className="table table-striped table-hover tabl e-sm table-bordered">
                  <thead className="">
                    <tr>
                      <th scope="col" width="40%">
                        Lote
                      </th>
                      <th scope="col">Data</th>
                      <th scope="col">Emb.</th>
                      <th scope="col">Disponível</th>
                      <th scope="col">Gerado</th>
                      <th scope="col">Conferido</th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {lotes.map((item, index) => {
                      let groups = [];
                      if (item.produtos) {
                        groups = Object.entries(
                          item.produtos,
                        );
                      }
                      if (!groups.length) {
                        return (
                          <LoteRow
                            lote={item}
                            index={index}
                            onLoteRowClicked={
                              onLoteRowClicked
                            }
                          />
                        );
                      }

                      return groups.map(
                        ([group, products]) => {
                          if (
                            products[0].bonificado === 'S' &&
                            products[0]
                              .quantidade_conferida === 0
                          ) {
                            return null;
                          }

                          return (
                            <LoteRow
                              lote={item}
                              index={index}
                              bonificado={group === 'S'}
                              conferidos={
                                group === 'S'
                                  ? item.conferido_bonificado
                                  : item.conferido_normal
                              }
                              onLoteRowClicked={
                                onLoteRowClicked
                              }
                            />
                          );
                        },
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
