import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";

export function ListasTab({ onSearchCd,
  cds,
  enableElement,
  onSearchProducts,
  isLoading,
  onToggleAllLists,
  checkboxListsRef,
  lotes,
  onAddCheckedList,
  statusList,
  onFinish,
  onReopenList,
  onOrderListModalOpen
}) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="c ol-sm">
          <div className="row bg-gray-light rounded p-3 mb-3">
            <div className="col-sm">
              <div className="form-group">
                <label>Centro de Distribuição</label>
                <select
                  id="centroDistribuicaoSelect"
                  className="form-control"
                  onChange={onSearchCd}
                >
                  <option defaultValue>Selecione o CD ..</option>
                  {cds.map((item, index) => (
                    <option key={index} value={item.numero_empresa}>
                      {item.nome_reduzido}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>Data Inicial</label>
                <input
                  type="date"
                  className="form-control"
                  id="dataInicial"
                  disabled={enableElement}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>Data Final</label>
                <input
                  type="date"
                  className="form-control"
                  id="dataFinal"
                  disabled={enableElement}
                />
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>Status </label>
                <select
                  className="form-control"
                  id="selStatusFixo"
                  disabled={enableElement}
                >
                  <option defaultValue value="P,C,L,F,X">
                    Todos
                  </option>
                  <option value="F">Finalizado</option>
                  <option value="L">Liberado</option>
                  <option value="X">Cancelado</option>
                  <option value="C">Conferido</option>
                  <option value="P">Pendente</option>
                </select>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-group">
                <label>&nbsp;</label>
                <button
                  className="form-control btn btn-primary float-right"
                  id="btnPesquisar"
                  disabled={enableElement || isLoading}
                  onClick={onSearchProducts}
                >
                  {isLoading ? (
                    <Spinner
                      className="ml-2"
                      animation="border"
                      variant="light"
                      size="sm"
                    />
                  ) : (
                    'Pesquisar'
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2">
            <h5>Listas</h5>
            {isLoading && (
              <Spinner
                className="ml-2"
                animation="border"
                variant="primary"
                size="sm"
              />
            )}
          </div>
          <div className="faturamentoProd">
            <table
              className="table table-striped table-hover"
              border={0}
            >
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="lotesCheckboxAll"
                      onChange={(e) => onToggleAllLists(e.target.checked)}
                    />
                  </th>
                  <th>Sequência Lista</th>
                  <th>Comprador</th>
                  <th>Usuário</th>
                  <th>Finalizador</th>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Criação</th>
                </tr>
              </thead>
              <tbody ref={checkboxListsRef}>
                {lotes.map((item, index) => (
                  <tr key={index} id={item.sequencia_lista}>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox ckbLotes"
                        value={item.sequencia_lista}
                        onChange={() => onAddCheckedList(item.sequencia_lista)}
                      />
                    </td>
                    <td>{item.sequencia_lista}</td>
                    <td>
                      {item.sequencia_comprador +
                        ' - ' +
                        item.comprador}
                    </td>
                    <td>{item.nome_usuario}</td>
                    <td>{item.nome_usuario_finalizador ? item.nome_usuario_finalizador : '-'}</td>
                    <td>{statusList[item.situacao]}</td>
                    <td>
                      {moment(
                        item.data_criacao_inicio,
                        'DD/MM/yyyy',
                      ).format('DD/MM/yyyy')}
                    </td>
                    <td>
                      {moment(
                        item.data_log,
                        'DD/MM/yyyy hh:mm:ss',
                      ).format('DD/MM/yyyy [às] hh:mm:ss')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm mt-2">
            <button
              className="btn btn-primary "
              id="btnFinalizar"
              // style={{ margin: '3px' }}
              onClick={onFinish}
            >
              Finalizar
            </button>
            <button
              className="btn btn-secondary ml-4"
              onClick={onOrderListModalOpen}
            >
              Listar Pedidos
            </button>
            <button
              className="btn btn-warning text-white ml-4"
              onClick={onReopenList}
            >
              Reabrir Lista
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
