import moment from "moment-timezone";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function ModalOrders({
  onCloseModal,
  onToggleAllOrders,
  checkboxOrdersRef,
  orders,
  onAddCheckedOrders,
  onCancelOrders
}) {
  return (
    <div id="modal-list-prod" className="modal" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl scrollable"
        role="document"
      >
        <div className="modal-content" style={{ width: '85vw' }}>
          <div className="modal-header">
            <h5 className="modal-title">Lista de Pedidos</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-0">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id=""
                      onChange={(e) =>
                        onToggleAllOrders(e.target.checked)
                      }
                    />
                  </th>
                  <th className="text-center">Nro. Cd.</th>
                  <th className="text-center">Nro. Pedido</th>
                  <th className="text-center">Nome Loja</th>
                  <th className="text-center">Compradores</th>
                  <th className="text-center">Dt. Criação</th>
                  <th className="text-center">Dt. Alteração</th>
                  <th className="text-center">Situação</th>
                  <th className="text-center">Usuário Criação</th>
                  <th className="text-center">Usuário Alteração</th>
                </tr>
              </thead>
              <tbody ref={checkboxOrdersRef}>
                {orders.map((order) => (
                  <tr
                    key={order.numero_pedido_venda}
                    id={order.numero_pedido_venda}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox ckbOrders"
                        onChange={() =>
                          onAddCheckedOrders(
                            order.numero_pedido_venda,
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      {order.numero_empresa}
                    </td>
                    <td className="text-center">
                      {order.numero_pedido_venda}
                    </td>

                    <td className="text-center">{order.destinatario}</td>
                    <td className="d-flex justify-content-center align-items-center">
                      {order.compradoresTruncated ||
                        order.compradores}
                      {order.compradoresTruncated && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              {order.compradores}
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle fa-fw text-gray ml-auto"></i>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-center">
                      {moment(order.data_inclusao).format(
                        'DD/MM/yyyy [às] hh:mm:ss',
                      )}
                    </td>
                    <td className="text-center">
                      {moment(order.data_alteracao).format(
                        'DD/MM/yyyy [às] hh:mm:ss',
                      )}
                    </td>
                    <td className="text-center">
                      {order.situacao_pedido === 'L'
                        ? 'Liberado'
                        : 'Faturado'}
                    </td>
                    <td className="text-center">
                      {order.usuario_inclusao}
                    </td>
                    <td className="text-center">
                      {order.usuario_alteracao}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary mr-4"
              data-dismiss="modal"
              onClick={onCancelOrders}
            >
              Cancelar Pedido
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onCloseModal}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
