import { TablePrinter } from './TablePrinter';

import { useState, useRef } from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import api from '../../services/api';
import { useLoading } from '../../hooks/useLoading';
import obaLogo from '../../assets/oba.png';

export function CompanyTab({ cdList, empresas }) {
  const [selectedCd, setSelectedCd] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [separationMapHeader, setSeparationMapHeader] = useState({});
  const [products, setProducts] = useState([]);
  const companySeparationMapPrinterRef = useRef(null);
  const loading = useLoading();

  function searchCompanyFilter() {
    const filterElement = document.querySelector('#filterCompany');
    const companies = document.querySelectorAll('.companyButton');

    if (filterElement !== '') {
      for (const company of companies) {
        let companyTitle = company.querySelector('span');
        companyTitle = companyTitle.textContent.toLocaleLowerCase();

        let filterText = filterElement.value.toLowerCase();

        if (!companyTitle.includes(filterText)) {
          company.style.display = 'none';
        } else {
          company.style.display = 'block';
        }
      }
    } else {
      for (const company of companies) {
        company.style.display = 'none';
      }
    }
  }

  function companyRowClicked(company, index) {
    setSelectedCompany(company);

    const companies = document.querySelectorAll('.companyButton');
    const companyIndex = companies[index];
    companies.forEach((company) =>
      company.classList.remove('active-conferencia'),
    );
    companyIndex.classList.add('active-conferencia');
  }

  async function generatePDF() {
    try {
      if (selectedCd === '' || Object.keys(selectedCd).length === 0)
        return toast.warning('Selecione um centro de distribuição.');

      if (Object.keys(selectedCompany).length === 0)
        return toast.warning('Selecione uma empresa.');

      loading.setLoading(true);

      const data = {
        numero_empresa: selectedCd,
        sequencia_pessoa: String(selectedCompany.seqpessoa),
      };

      const headingResponse = await api.post(
        '/listaseparacao/mapa_separacao_cabecalho',
        data,
      );

      setSeparationMapHeader(headingResponse.data);

      const productsResponse = await api.post(
        '/listaseparacao/mapa_separacao_produto',
        data,
      );

      if (productsResponse.data.resposta) {
        setProducts([]);
        loading.setLoading(false);
        return toast.error(productsResponse.data.resposta);
      }

      const productsResults = productsResponse.data;

      for (const product of productsResults) {
        product.lotes = [];

        const lots = await getLotsByProduct(
          selectedCd,
          selectedCompany,
          product,
        );
        product.lotes = lots;
      }

      loading.setLoading(false);
      setProducts(productsResults);
    } catch (err) {
      loading.setLoading(false);
      console.log(err.message);
      toast.error('Falha no envio dos dados.');
    }
  }

  async function getLotsByProduct(cd, company, product) {
    const lotsResponse = await api.get(
      `/listaseparacao/busca_lote_conferencia/${cd}/${String(
        product.sequencia_produto,
      )}/${company.seqpessoa}`,
    );

    const { results: lotsResults } = lotsResponse.data;

    const productsConferedResponse = await api.post(
      '/listaseparacao/busca_conferencia_v2/',
      {
        numero_empresa: cd,
        sequencia_lista: product.sequencia_lista,
        sequencia_produto: product.sequencia_produto,
        sequencia_pessoa: company.seqpessoa,
        status: 'P,C,L',
      },
    );

    const productsConfered = productsConferedResponse.data;

    if (productsConfered !== 0) {
      for (const lot of lotsResults) {
        lot.quantidade_conferida = 0;

        for (const productConfered of productsConfered) {
          if (
            lot.sequencia_lote === productConfered.sequencia_lote &&
            product.bonificado === productConfered.bonificado
          ) {
            if (productConfered.sequencia_pessoa === company.seqpessoa) {
              lot.quantidade_conferida += productConfered.quantidade_conferida;
              lot.quantidade_atendida += productConfered.quantidade_atendida;
            }
          }
        }
      }

      return lotsResults;
    }
  }

  const handlePrint = useReactToPrint({
    content: () => companySeparationMapPrinterRef.current,
  });

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card" style={{ overflow: 'hidden' }}>
          <div
            className="card-body d-flex"
            style={{ height: '80vh', overflow: 'hidden' }}
          >
            <div className="col-3 pr-0 d-flex flex-column h-100">
              <div className="card mb-0 h-100">
                <div className="card-body row-col h-100 py-0">
                  <div className="mb-2 row-col-2">
                    <label htmlFor="selectCompanies">
                      Centro de Distribuição
                    </label>
                    <select
                      name=""
                      id="selectCompanies"
                      className="form-control"
                      onChange={(event) => setSelectedCd(event.target.value)}
                    >
                      <option defaultValue value="">
                        Selecione o CD...
                      </option>
                      {cdList.map((cd) => (
                        <option
                          value={cd.numero_empresa}
                          key={cd.numero_empresa}
                        >
                          {cd.nome_reduzido}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    id="searchCompanies"
                    className="mb-2 row-col-2"
                    style={{ overflow: 'hidden' }}
                  >
                    <label htmlFor="">Empresas</label>
                    <input
                      type="search"
                      name=""
                      id="filterCompany"
                      className="form-control"
                      placeholder="Procurar empresa"
                      onChange={() => searchCompanyFilter()}
                    />
                  </div>

                  <div
                    id="companiesList"
                    className="bg-gray-light row-col-5 mb-3"
                    style={{
                      overflow: 'scroll',
                      height: '330px',
                      border: '1px solid #f1f1f1',
                      alignContent: 'baseline',
                    }}
                  >
                    {empresas.map((company, index) => (
                      <div
                        id={company.seqpessoa}
                        className="companyButton"
                        key={company.seqpessoa}
                        onClick={() => companyRowClicked(company, index)}
                      >
                        <span>{company.nome_reduzido}</span>
                      </div>
                    ))}
                  </div>

                  <div className="row-col-3 mt-auto">
                    <button
                      className="btn btn-success w-100"
                      onClick={() => generatePDF()}
                    >
                      <i className="fa fa-file-pdf mr-1"></i> Gerar PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              {loading.isLoading ? (
                <div className="loading h-100 w-100 d-flex align-items-center justify-content-center">
                  <Spinner
                    className="ml-2"
                    animation="border"
                    variant="primary"
                    size="lg"
                  />
                </div>
              ) : (
                <div id="productsBox" className="h-100">
                  <div className="card h-100">
                    {products.length === 0 && (
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <div className="text-center pt-5">
                          <h4>
                            <i className="fa fa-info-circle fa-fw"></i> Mapa de
                            Separação
                          </h4>
                          Selecione um CD e uma empresa para gerar o PDF de Mapa
                          de Separação.
                        </div>
                      </div>
                    )}
                    {products.length !== 0 && (
                      <div
                        id="productsTable"
                        className="card-body p-0 overflow-auto"
                      >
                        <nav className="navbar navbar-expand-lg navbar-light w-100 bg-gray rounded-top d-flex align-items-center justify-content-between">
                          <div className="navbar-brand ml-3 text-light">
                            <i className="fa fa-file-pdf mr-3"></i>
                            PDF - Mapa Separação
                          </div>

                          <div
                            className="collapse navbar-collapse"
                            id="printerPDF"
                            style={{
                              position: 'absolute',
                              right: 0,
                            }}
                          >
                            <button
                              className="btn text-white border-none"
                              onClick={() => handlePrint()}
                            >
                              <i className="fa fa-print mr-1"></i>
                              Imprimir
                            </button>
                          </div>
                        </nav>
                        <div
                          className="PDFContainer"
                          ref={companySeparationMapPrinterRef}
                        >
                          <div className="PDFHeader border-bottom p-2">
                            <img
                              height="32px"
                              src={obaLogo}
                              alt="Logo Oba Hortifruti"
                            />
                            <div className="mt-2 d-flex align-items-center border-bottom border-dark-subtle">
                              <p className="mr-4">
                                <strong>CD:</strong>{' '}
                                {separationMapHeader.numero_centro_distribuicao}
                              </p>
                              <p className="mr-4">
                                <strong>Listas:</strong>{' '}
                                {separationMapHeader.listas}
                              </p>
                              <p className="mr-4">
                                <strong> Data hora:</strong>{' '}
                                {moment(new Date()).format(
                                  'DD/MM/YYYY - hh:mm a',
                                )}
                              </p>
                            </div>
                            <div className="border-bottom border-dark-subtle">
                              <p>
                                <strong>Loja:</strong>{' '}
                                {separationMapHeader.fantasia}
                              </p>
                            </div>
                            <div className="border-bottom border-dark-subtle">
                              <p>
                                <strong>Conferente:</strong>{' '}
                                {separationMapHeader.codigo_conferente} -{' '}
                                {separationMapHeader.nome}
                              </p>
                            </div>
                            <div className="border-bottom border-dark-subtle">
                              <p>
                                <strong>Separador:</strong>
                              </p>
                            </div>
                          </div>

                          <div className="PDFBody overflow-hidden">
                            {products.map((product) => (
                              <table
                                key={
                                  product.sequencia_produto + product.bonificado
                                }
                                className="table table-bordered border-dark"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" className="col-2">
                                      {product.sequencia_produto}
                                    </th>
                                    <th scope="col" className="col-6">
                                      {product.descricao}
                                      {product.bonificado === 'S' && (
                                        <Badge
                                          bg="primary"
                                          title="Produto Bonificado"
                                          className=" fs-4 ml-3"
                                        >
                                          B
                                        </Badge>
                                      )}
                                    </th>
                                    <th scope="col" className="col-2">
                                      {product.embalagem}
                                    </th>
                                    <th scope="col" className="col-2">
                                      ATENDIDO: {product.quantidade_atendida}
                                    </th>
                                  </tr>
                                </thead>
                                {!!product.lotes.length && (
                                  <tbody>
                                    <tr>
                                      <td colSpan="4">
                                        <table className="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th className="col-3 text-bold">
                                                LOTE
                                              </th>
                                              <th className="col-4 text-bold">
                                                EMBALAGEM
                                              </th>
                                              <th className="col-2 text-bold">
                                                ATENDIDO
                                              </th>
                                              <th className="col-2 text-bold">
                                                CONFERIDO
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {product.lotes.map((lot) => (
                                              <tr key={lot.sequencia_lote}>
                                                <td className="col-3">
                                                  {lot.sequencia_lote}
                                                </td>
                                                <td className="col-4">
                                                  {lot.embalagem}
                                                </td>
                                                <td className="col-2">
                                                  {lot.quantidade_atendida}
                                                </td>

                                                <td className="col-2">
                                                  {lot.quantidade_conferida > 0
                                                    ? lot.quantidade_conferida
                                                    : ''}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            ))}
                          </div>
                        </div>

                        <div className="d-none">
                          <TablePrinter
                            separationMapHeader={separationMapHeader}
                            products={products}
                            companySeparationMapPrinterRef={
                              companySeparationMapPrinterRef
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
