import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import Header from './components/Header';
import Menu from './components/Menu';
import Dashboard from './pages/Dashboard';
import SeparationList from './pages/SeparationList';
import Appointment from './pages/Appointment';
import ProductBilling from './pages/ProductBilling';
import Conferencia from './pages/Logistica/ExpedicaoFLV/Conferencia/Conferencia';
import ConferenciaAvulsa from './pages/Logistica/ExpedicaoFLV/ConferenciaAvulsa/ConferenciaAvulsa';
import MapaDeSeparacao from './pages/Logistica/ExpedicaoFLV/MapaDeSeparacao/MapaDeSeparacao';
import AtivarProduto from './pages/Comercial/AtivarProduto/AtivarProduto';
import AlteraPreco from './pages/Comercial/AlteraPreco';
import Login from './pages/Login';
import isAuthenticated from './utils/auth';
import Produto from './pages/Comercial/Produto';
import EstornoDeTitulo from './pages/Financeiro/EstornoDeTitulo';
import EstoqueLote from './pages/Estoque/EstoqueLote';
import Entrada from './pages/Entrada';
import ResumoParticular from './pages/ResumoParticular';
import ObaAdmPermission from './pages/Admin/ObaAdmPermission';
import { RegisterSupplier } from './pages/Logistica/ExpedicaoFLV/RegisterSupplier';

function PrivateOutlet() {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}

function AppRoutes() {
  return (
    <BrowserRouter>
      {/* <Header />
      <Menu /> */}
      {isAuthenticated() ? (
        <>
          <Header />
          <Menu />
        </>
      ) : (
        <></>
      )}
      <div className={`${isAuthenticated() ? 'content-wrapper' : ''}`}>
        <Routes>
          {/* public login route */}
          <Route path="/login" element={<Login />} />

          {/* private routes */}
          <Route element={<PrivateOutlet />}>
            <Route path="/" exact element={<Dashboard />} />
            <Route path="/separation-list" element={<SeparationList />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/product-billing" element={<ProductBilling />} />
            <Route
              path="/Logistica/ExpedicaoFLV/Conferencia/conferencia"
              element={<Conferencia />}
            />
            <Route
              path="/Logistica/ExpedicaoFLV/ConferenciaAvulsa/ConferenciaAvulsa"
              element={<ConferenciaAvulsa />}
            />
            <Route
              path="/Logistica/ExpedicaoFLV/MapaDeSeparacao/MapaDeSeparacao"
              element={<MapaDeSeparacao />}
            />
            <Route
              path="/Comercial/AtivarProduto/AtivarProduto"
              element={<AtivarProduto />}
            />
            <Route path="/Pages/Entrada" element={<Entrada />} />
            <Route
              path="/Pages/ResumoParticular"
              element={<ResumoParticular />}
            />
            <Route path="/Logistica/register-supplier" element={<RegisterSupplier />}
            />
            <Route path="/Comercial/AlteraPreco" element={<AlteraPreco />} />
            <Route path="/Comercial/Produto" element={<Produto />} />
            <Route path="/Estoque/EstoqueLote" element={<EstoqueLote />} />
            <Route
              path="/Financeiro/EstornoDeTitulo"
              element={<EstornoDeTitulo />}
            />
            <Route
              path="/Admin/ObaAdmPermission"
              element={<ObaAdmPermission />}
            />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default AppRoutes;
