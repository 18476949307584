import { Spinner } from "react-bootstrap";

export function ModalFaturarTipo({
  onCloseModal,
  onBilling,
  isLoading,
  onCloseModalAndHideSpinner
}) {
  return (
    <div
      id="modal-faturar-tipo"
      className="modal"
      role="dialog"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Informe um tipo de faturamento</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body d-flex justify-content-around">
            <div className="d-flex align-items-center">
              <input
                type="radio"
                id="faturar-bonificado"
                name="faturar-tipo"
                value="S"
              />
              <label htmlFor="faturar-bonificado" className="m-0 ml-1">
                Faturar bonificado
              </label>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="radio"
                id="faturar-normal"
                name="faturar-tipo"
                value="N"
                checked
              />
              <label htmlFor="faturar-normal" className="m-0 ml-1">
                Faturar normal
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onBilling(true)}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  className="ml-2 mr-2"
                  animation="border"
                  variant="light"
                  size="sm"
                />
              ) : (
                'Faturar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onCloseModalAndHideSpinner}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
