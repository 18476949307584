import { Spinner } from 'react-bootstrap';

export function PermissionsTab(props) {
  return (
    <div className="box-menu-permission">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center gap-2">
            <h4>Buscar Usuário</h4>
            {props.isLoading1.isLoading && (
              <Spinner
                className="ml-2"
                animation="border"
                variant="primary"
                size="sm"
              />
            )}
          </div>
          <hr />

          <div className="d-flex flex-column w-100">
            <div
              className=""
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <form
                className="d-flex"
                style={{ width: '100%', gap: '64px' }}
                onSubmit={props.onSubmitUser}
              >
                <div className="form-group">
                  <label htmlFor="userCode">Código</label>
                  <div className="d-flex">
                    <input
                      id="userCode"
                      className="form-control"
                      placeholder="Código do usuário"
                      onChange={(e) => props.onChangeUser(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="form-control btn-info ml-4 w-25"
                    >
                      🔎
                    </button>
                  </div>
                </div>

                <div className="d-flex flex-column w-100">
                  <label>Usuário</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Usuário"
                    value={
                      props.user
                        ? `${props.user.codigo_usuario} - ${props.user.nome}`
                        : ''
                    }
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {props.user ? (
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center gap-2">
              <h4>Permissões</h4>
              {props.isLoading1.isLoading && (
                <Spinner
                  className="ml-2"
                  animation="border"
                  variant="primary"
                  size="sm"
                />
              )}
            </div>
            <hr />
            <div className="d-flex justify-content-end mb-2">
              <div className="d-flex align-items-center justify-content-end my-2">
                <input
                  type="checkbox"
                  name="permission"
                  id={props.idCheckboxAll}
                  onChange={(e) => props.onToggleAllCheckbox(e.target.checked)}
                />
                <label htmlFor="checkboxAll" className="form-check-label ml-2">
                  Marcar todas permissões
                </label>
              </div>
              <div className="ml-auto">
                <button
                  type="submit"
                  className="btn btn-primary"
                  form="submitPermissions"
                  disabled={props.isLoading3.isLoading}
                >
                  {props.isLoading3.isLoading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    'Salvar'
                  )}
                </button>
              </div>
            </div>

            <div
              style={{
                height: 'calc(100vh - 505px)',
                overflowY: 'auto',
              }}
            >
              {props.permissionsModule.map((mod, i) => (
                <div className="card mb-4 border-bottom" key={`${mod}-${i}`}>
                  <div className="card-body">
                    <h5>{mod}</h5>
                    <div className="d-flex">
                      {props.tabType === 'empresa' ? (
                        <></>
                      ) : (
                        <div
                          className="d-flex align-items-center ml-4 pr-4 border-right align-self-start"
                          style={{ height: '100%' }}
                        >
                          <input
                            type="checkbox"
                            name="permission"
                            className="appCheckboxGroup"
                            data-group={mod}
                            onChange={(e) =>
                              props.onToggleGroupCheckbox(mod, e.target.checked)
                            }
                          />
                          <label
                            className="form-check-label ml-2"
                            style={{
                              marginBottom: 0,
                              fontSize: '12px',
                            }}
                          >
                            Marcar todos
                          </label>
                        </div>
                      )}

                      <div
                        data-group={mod}
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gap: '8px',
                          borderLeft: '1px solid rgba(0,0,0,.125)',
                        }}
                      >
                        {props.permissionsPerModule(mod).map((permission) => (
                          <form
                            className="d-flex align-items-center ml-4"
                            key={
                              props.tabType === 'app'
                                ? permission.sequencia_aplicacao
                                : permission.numero_empresa
                            }
                            id="submitPermissions"
                            onSubmit={(e) => {
                              props.onSubmitChangedPermissions(e, props.tab);
                            }}
                          >
                            <input
                              type="checkbox"
                              name="permission"
                              className="appCheckboxItem"
                              onChange={() =>
                                props.onPermissionUpdate(
                                  permission,
                                  props.tabType,
                                )
                              }
                              checked={permission.permitido === 'S'}
                            />
                            <label
                              className="form-check-label ml-2"
                              style={{
                                marginBottom: 0,
                                fontSize: '12px',
                              }}
                            >
                              {props.tabType === 'app'
                                ? permission.aplicacao
                                : permission.nome_reduzido}
                            </label>
                          </form>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <span className="card-body fw-bold">
            Procure um usuário para visualizar as permissões
          </span>
        </div>
      )}
    </div>
  );
}
