import { useLoading } from "../../../../hooks/useLoading"
import { useState } from "react"
import { toast } from "react-toastify"
import { Spinner } from "react-bootstrap"

import { RegisterSupplierModal } from "../../../../components/RegisterSupplier/RegisterSupplierModal"
import { ResetSupplierModal } from "../../../../components/RegisterSupplier/ResetSupplierModal"
import { InactivateSupplierModal } from "../../../../components/RegisterSupplier/InactivateSupplierModal"
import { UserRegisterInfo } from "../../../../components/RegisterSupplier/UserRegisterInfo"
import { RegisterSupplierPageInfo } from "../../../../components/RegisterSupplier/RegisterSupplierPageInfo"

import api from "../../../../services/api"

export function RegisterSupplier() {
  const [cnpj, setCnpj] = useState('')
  const [supplier, setSupplier] = useState(null)
  const [userHasRegister, setUserHasRegister] = useState(false)
  const [supplierRegistered, setSupplierRegistered] = useState(null)

  const loading = useLoading()

  function onChangeCNPJ(e) {
    setCnpj(e.target.value)
    setSupplier(null)
    setSupplierRegistered(null)
    setUserHasRegister(false)
  }

  async function handleGetSupplier(e) {
    try {
      if (e.key && e.key !== 'Enter') return

      if (!cnpj) return toast.warning('Informe um Cpf ou Cnpj')

      const cnpjWith14Digits = cnpj.padStart(14, '0')
      loading.setLoading(true)

      const [response, registerResponse] = await Promise.all([
        api.get('/pessoa/fornecedor/' + 0 + '/' + cnpj),
        api.post('/v1/busca_fornecedor_unico_api/', {
          cpf_cnpj: cnpjWith14Digits
        })
      ])

      const data = response.data.results[0]
      const registerData = registerResponse.data[0]

      if (!data) {
        toast.error('Fornecedor não encontrado')
        setSupplier(null)
        loading.setLoading(false)
        return
      }

      if (registerData) {
        setSupplierRegistered(registerData)
        setUserHasRegister(true)
      } else {
        setSupplierRegistered(null)
        setUserHasRegister(false)
      }

      setSupplier(data)
      loading.setLoading(false)
    } catch (err) {
      console.log(err.message);
      toast.error('Erro ao realizar busca pelo fornecedor.')
      loading.setLoading(false)
    }
  }

  return (
    <>
      <div className="content-header d-flex justify-content-between">
        <h1>Cadastrar Fornecedor</h1>
        <button
          type="button"
          className="btn btn-warning"
          data-bs-toggle="modal"
          data-bs-target="#infoRegisterSupplier"
        >
          <i className="fa fa-solid fa-question"></i>
        </button>
      </div>

      <div className="content">
        <div className="card">
          <div className="card-body">
            <div className="row col">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">CNPJ [Enter]:</label>
                  <input
                    id="txtCodigoCNPJ"
                    className="form-control"
                    onKeyUp={(e) => handleGetSupplier(e)}
                    onChange={(e) => onChangeCNPJ(e)}
                    placeholder="Entre com o CNPJ / CPF"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Empresa:</label>
                  {supplier ? < i className='fa fa-check fa-fw  text-green ml-2' /> : ''}
                  <input
                    id="txtSeqPessoa"
                    className="form-control"
                    placeholder="Nome da empresa"
                    disabled
                    value={supplier ? (
                      supplier.fantasia
                    ) : ''}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center float-right">
              <button
                className="btn btn-primary mr-4"
                style={{ width: "150px" }}
                onClick={handleGetSupplier}
              >
                {loading.isLoading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Buscar Cadastro'
                )}

              </button>

              <button
                className="btn btn-info mr-4"
                style={{ width: "150px" }}
                data-bs-toggle="modal"
                data-bs-target="#registerUser"
                disabled={!supplier}
              >
                Novo Cadastro
              </button>
            </div>
          </div>
        </div>

        {userHasRegister && (
          <UserRegisterInfo supplierRegistered={supplierRegistered} />
        )}
      </div>

      {/* New Register Modal */}
      <RegisterSupplierModal supplier={supplier} />

      {/* Reset modal */}
      <ResetSupplierModal supplierRegistered={supplierRegistered} />

      {/* Inactivate Modal */}
      <InactivateSupplierModal supplierRegistered={supplierRegistered} />

      {/* Page Info */}
      <RegisterSupplierPageInfo />
    </>
  )
}
