import { useState, useCallback, React } from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PermissionsTab } from '../../components/Permissions/PermissionsTab';
import { useLoading } from '../../hooks/useLoading';

import api from '../../services/api';

var tempPermissionsArray = [];
let selectedTab = 'app';

export default function ObaAdmPermission() {
  const [searchUser, setSearchUser] = useState('');
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const loading1 = useLoading();
  const loading3 = useLoading();
  let navigate = useNavigate();

  /* Get User */
  async function findUser(credential) {
    try {
      loading1.setLoading(true);

      const response = await api.post(`/controle_permissao/busca_usuario/`, {
        codigo_usuario: credential,
      });

      if (!response) {
        loading1.setLoading(false);
        alert('Usuário não encontrado! Digite um código de usuário válido.');
        return;
      }

      if (response.status === 403) {
        loading1.setLoading(false);
        alert('Usuário não possui permissão de acesso.');
        navigate('/');
        return;
      }

      setUser(response.data);
      loading1.setLoading(false);
      return response.data;
    } catch (err) {
      loading1.setLoading(false);
      console.log(err);
      toast.error('Falha na requisição dos dados.', err.message);
    }
  }

  /* Get App Permissions */
  async function getPermissionsApp(credential) {
    try {
      loading1.setLoading(true);

      const response = await api.get(
        `/controle_permissao/busca_permissao/${credential}`,
      );

      const { results } = response.data;
      setPermissions(results);

      loading1.setLoading(false);
    } catch (err) {
      loading1.setLoading(false);
      console.log(err);
      toast.error('Falha na requisição dos dados.', err.message);
    }
  }

  /* Get Company Permissions */
  async function getPermissionsCompany(credential) {
    try {
      loading1.setLoading(true);

      const response = await api.get(
        `/controle_permissao/busca_permissao_empresa/${credential}`,
      );
      const { results } = response.data;
      setPermissions(results);

      loading1.setLoading(false);
    } catch (err) {
      loading1.setLoading(false);
      console.log(err);
      toast.error('Falha na requisição dos dados.', err.message);
    }
  }

  function moduleOfPermissions(array, field) {
    return array.reduce((accumulator, current) => {
      if (!accumulator.includes(current[field])) {
        accumulator.push(current[field]);
      }
      return accumulator;
    }, []);
  }

  /* Arra of Modules */
  const permissionsAppModule = moduleOfPermissions(permissions, 'modulo');

  const permissionsCompanyModule = moduleOfPermissions(
    permissions,
    'nome_reduzido',
  );

  /* Permissions Filtered Per Module  */
  const menuPermissionsPerModule = (module) => {
    return permissions.filter((permission) => permission.modulo === module);
  };

  const companyPermissionsPerModule = (module) => {
    return permissions.filter(
      (permission) => permission.nome_reduzido === module,
    );
  };

  /* Search User Action */
  async function handleSearchUserInMenuPermission(e) {
    e.preventDefault();

    if (!searchUser) {
      alert('Insira um código usuário.');
      loading1.setLoading(false);
      return;
    }

    const userFound = await findUser(searchUser);

    if (!userFound) return;

    await getPermissionsApp(userFound.codigo_usuario);
  }

  async function handleSearchUserInCompanyPermission(e) {
    e.preventDefault();

    if (!searchUser) {
      alert('Insira um código usuário.');
      loading1.setLoading(false);
      return;
    }

    const userFound = await findUser(searchUser);

    if (!userFound) return;

    await getPermissionsCompany(userFound.codigo_usuario);
  }

  /* Checkbox Check Action */
  async function handleUpdatePermissions(permission, type) {
    permission.permitido === 'S'
      ? (permission.permitido = 'N')
      : (permission.permitido = 'S');

    forceUpdate();
    if (type === 'app') {
      addPermitionToChange(permission);
    } else {
      addPermitionCompanyToChange(permission);
    }
  }

  /*Add Permission to Array tha will be changed */
  function addPermitionToChange(permission) {
    var permissionsArray = tempPermissionsArray.filter(
      (item) => item.sequencia_aplicacao !== permission.sequencia_aplicacao,
    );

    permissionsArray.push(permission);

    tempPermissionsArray = permissionsArray;

    forceUpdate();
  }

  function addPermitionCompanyToChange(permission) {
    var permissionsArray = tempPermissionsArray.filter(
      (item) => item.numero_empresa !== permission.numero_empresa,
    );

    permissionsArray.push(permission);

    tempPermissionsArray = permissionsArray;

    forceUpdate();
  }

  /* Format Data to send to API Post */
  function formatData(permission, type) {
    if (type === 'app') {
      return {
        codigo_usuario: Number(user.sequencia_usuario),
        status_permissao: permission.permitido,
        sequencia_modulo: Number(permission.sequencia_modulo),
        sequencia_aplicacao: Number(permission.sequencia_aplicacao),
      };
    }

    return {
      codigo_usuario: Number(user.sequencia_usuario),
      permitido: permission.permitido,
      numero_empresa: Number(permission.numero_empresa),
    };
  }

  /*Form Submit */
  async function handleSubmit(e, type) {
    e.preventDefault();
    let errorsCount = 0;
    let successCount = 0;

    try {
      if (tempPermissionsArray.length === 0)
        return toast.warning('Nenhuma alteração foi feita.');

      loading3.setLoading(true);

      const results = await Promise.all(
        tempPermissionsArray.map(async (permissionChanged) => {
          const formattedChangedPermission = formatData(
            permissionChanged,
            type,
          );
          return await api.post(
            `/controle_permissao/controle_permissao_${type}/`,
            formattedChangedPermission,
          );
        }),
      );

      results.map((result) => {
        if (result.data.codResult === 1)
          // return toast.error('Nroempresa invalido.');
          return errorsCount++;

        // return toast.success('Alteração feita com sucesso');
        return successCount++;
      });

      if (errorsCount) {
        return toast.error(
          `${errorsCount} ocorrência(s) de: Nroempresa invalido.`,
        );
      }

      toast.success(`${successCount} ocorrência(s) alterada(s) com sucesso`);

      loading3.setLoading(false);
      tempPermissionsArray = [];
    } catch (err) {
      loading3.setLoading(false);
      console.log(err);
      toast.error('Falha no envio dos dados.', err.message);
    }
  }

  function toggleAllCheckboxApp() {
    const appCheckboxAll = document.querySelector('#appCheckboxAll');
    const isAllChecked = appCheckboxAll.checked;

    for (const permission of permissions) {
      if (isAllChecked === true) {
        permission.permitido = 'S';
      } else {
        permission.permitido = 'N';
      }

      forceUpdate();
      addPermitionToChange(permission);
    }
  }

  function toggleGroupCheckboxApp(group, isGroupChecked) {
    const filteredPermissions = permissions.filter(
      (item) => item.modulo === group,
    );

    for (const permission of filteredPermissions) {
      if (isGroupChecked === true) {
        permission.permitido = 'S';
      } else {
        permission.permitido = 'N';
      }

      addPermitionToChange(permission);
      forceUpdate();
    }
  }

  function toggleAllCheckboxCompany() {
    const companyCheckboxAll = document.querySelector('#companyCheckboxAll');
    const isAllChecked = companyCheckboxAll.checked;

    for (const permission of permissions) {
      if (isAllChecked === true) {
        permission.permitido = 'S';
      } else {
        permission.permitido = 'N';
      }

      forceUpdate();
      addPermitionCompanyToChange(permission);
    }
  }

  function toggleGroupCheckboxCompany(group, isGroupChecked) {
    const filteredPermissions = permissions.filter(
      (item) => item.nome_reduzido === group,
    );

    for (const permission of filteredPermissions) {
      if (isGroupChecked === true) {
        permission.permitido = 'S';
      } else {
        permission.permitido = 'N';
      }

      addPermitionCompanyToChange(permission);
      forceUpdate();
    }
  }

  return (
    <div>
      <div className="content-header">
        <h1 className="ml-3">Permissão Oba Adm</h1>
      </div>

      <div className="content">
        <Tabs
          defaultActiveKey="app"
          onSelect={(firstTab, lastTab) => {
            selectedTab = firstTab;
            setUser(null);
            setPermissions([]);
            tempPermissionsArray = [];
          }}
        >
          <Tab eventKey="app" title="Permissão Aplicação">
            <PermissionsTab
              tabType="app"
              tab={selectedTab}
              user={user}
              idCheckboxAll="appCheckboxAll"
              onSubmitUser={handleSearchUserInMenuPermission}
              onChangeUser={setSearchUser}
              permissionsModule={permissionsAppModule}
              permissionsPerModule={menuPermissionsPerModule}
              onPermissionUpdate={handleUpdatePermissions}
              onSubmitChangedPermissions={handleSubmit}
              onToggleAllCheckbox={toggleAllCheckboxApp}
              onToggleGroupCheckbox={toggleGroupCheckboxApp}
              isLoading1={loading1}
              isLoading3={loading3}
            />
          </Tab>

          <Tab eventKey="empresa" title="Permissão Empresa">
            <PermissionsTab
              tabType="empresa"
              user={user}
              idCheckboxAll="companyCheckboxAll"
              onSubmitUser={handleSearchUserInCompanyPermission}
              onChangeUser={setSearchUser}
              permissionsModule={permissionsCompanyModule}
              permissionsPerModule={companyPermissionsPerModule}
              onPermissionUpdate={handleUpdatePermissions}
              onSubmitChangedPermissions={handleSubmit}
              onToggleAllCheckbox={toggleAllCheckboxCompany}
              onToggleGroupCheckbox={toggleGroupCheckboxCompany}
              isLoading1={loading1}
              isLoading3={loading3}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
