export function UserRegisterInfo({ supplierRegistered }) {
  return (
    <div className="card">
      <h5 className="p-4">Cadastro atual</h5>

      <div className="card-body">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Usuário</th>
              <th scope="col">Email</th>
              <th scope="col">Empresa</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{supplierRegistered?.usuario}</th>
              <td>{supplierRegistered?.email}</td>
              <td>{supplierRegistered?.empresa}</td>
              <td>{supplierRegistered?.status}</td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex align-items-center float-right mt-4 ">
          <button
            className="btn btn-secondary mr-4"
            data-bs-toggle="modal"
            data-bs-target="#resetUser"
            disabled={!supplierRegistered}
            style={{ width: '85px' }}
          >
            Resetar
          </button>

          <button
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#inactiveUser"
            disabled={!supplierRegistered}
            style={{ width: '85px' }}
          >
            Inativar
          </button>
        </div>
      </div>
    </div>
  )
}
